import { OrderStates, OrderStatus, PaymentStates, TranslateFunction } from '~/config/types';
import { dateFormatter } from './format';
import { DayFormat, FullDateFormat } from '~/config/constant';
import { FulfillmentMethodType, Order } from '~/api';
import { Action } from '~/components/ActionButton/props';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
type OrderStatesValues = OrderStates[];

/**
 * NOTE: order flow
 * Delivery: Draft => Pending => Confirmed => Shipping => Completed
 * Pickup: Draft => Pending => Confirmed => Shipping => Completed
 *
 * (to be comfirmed)
 * DineIn (only backoffice): Confirmed => Completed
 */

// Excluded 'Created' & 'AddingItems'
// Because POS order will be in Draft state, and backoffice need to see the order
const Draft: OrderStatesValues = ['Draft'];
const Pending: OrderStatesValues = ['ArrangingPayment', 'ArrangingAdditionalPayment'];
const Confirmed: OrderStatesValues = ['PaymentAuthorized', 'PaymentSettled'];
const Preparing: OrderStatesValues = ['Preparing'];
const Shipping: OrderStatesValues = ['Shipped', 'PartiallyShipped'];
const Completed: OrderStatesValues = ['Delivered'];
const Cancelled: OrderStatesValues = ['Cancelled'];

export type RefactoredOrderStates =
  | 'All'
  | 'Draft'
  | 'To Pay'
  | 'Paid'
  | 'Preparing'
  | 'Shipped'
  | 'Delivered'
  | 'Cancelled';

/**
 * @description translate vendure order states into simplified status
 * @param state vendure's order state
 * @returns OrderStatus
 */
export const getOrderStatus = (state: string): OrderStatus | '' => {
  const s = state as OrderStates;
  if (Draft.includes(s)) return OrderStatus.Draft;
  if (Pending.includes(s)) return OrderStatus.Pending;
  if (Confirmed.includes(s)) return OrderStatus.Confirmed;
  if (Preparing.includes(s)) return OrderStatus.Preparing;
  if (Shipping.includes(s)) return OrderStatus.Shipping;
  if (Completed.includes(s)) return OrderStatus.Completed;
  if (Cancelled.includes(s)) return OrderStatus.Cancelled;
  return '';
};

/**
 * @description Get vendure order states by simplified status
 * @param status OrderStatus
 * @returns vendure's order states group (string[])
 */
export const getOrderStateByStatus = (status: RefactoredOrderStates): string[] => {
  switch (status) {
    case 'Draft':
      return Draft;
    case 'To Pay':
      return Pending;
    case 'Paid':
      return Confirmed;
    case 'Shipped':
      return Shipping;
    case 'Delivered':
      return Completed;
    case 'Preparing':
      return Preparing;
    case 'Cancelled':
      return Cancelled;
    default:
      return [
        ...Draft,
        ...Pending,
        ...Preparing,
        ...Confirmed,
        ...Shipping,
        ...Completed,
        ...Cancelled,
      ];
  }
};

export const getOrderStatusTag = (status: OrderStatus) => {
  const Dict = {
    [OrderStatus.Draft]: '!text-gray-500 !border-gray-500 !bg-gray-100',
    [OrderStatus.Pending]: '!text-warning-500 !border-warning-500 !bg-warning-100',
    [OrderStatus.Confirmed]: '!text-blue-500 !border-blue-500 !bg-blue-100',
    [OrderStatus.Preparing]: '!text-purple-500 !border-purple-500 !bg-purple-100',
    [OrderStatus.Shipping]: '!text-blue-500 !border-blue-500 !bg-blue-100',
    [OrderStatus.Completed]: '!text-success-500 !border-success-500 !bg-success-100',
    [OrderStatus.Cancelled]: '!text-error-300 !border-error-300 !bg-error-100',
  };

  return Dict[status] ?? '';
};

export const canDownloadInvoice = (status: OrderStatus): boolean => {
  if ([OrderStatus.Confirmed, OrderStatus.Shipping, OrderStatus.Completed].includes(status)) {
    return true;
  }

  return false;
};

export const getOrderFulfillmentSlot = (timeSlot = '', fulfillmentDate = new Date()): string => {
  if (!timeSlot) return '-';
  return `${timeSlot} (${dateFormatter(fulfillmentDate, `${DayFormat} ${FullDateFormat}`)})`;
};

/**
 * @description
 * this function returns the common actions for order
 * on Show / List Page
 *
 * @param order
 */
type OrderActions = {
  onDownloadInvoice?: (id: string, type?: string, url?: string) => Promise<void>;
  /**
   * @deprecated
   * Paid status is update by payment individually instead of updating from order
   */
  onUpdateStatus?: () => void;
  onArrangePayment?: () => void;
  onFulfillItem?: () => void;
  onCancel?: () => void;
};
type GetOrderActionsProps = {
  order?: Order;
  t: TranslateFunction;
  options: OrderActions;
};
export const getOrderActions = (input: GetOrderActionsProps): Action[] => {
  const { order, t, options } = input;
  const actions: Action[] = [];
  if (!order || isEmpty(order)) return actions;
  const { payments, fulfillments, state, customFields } = order;

  const deliveryMethod = customFields?.shippingMethodCode;
  const orderStatus = getOrderStatus(state as OrderStates) as OrderStatus;

  const isDineInDraft =
    orderStatus === OrderStatus.Draft && deliveryMethod === FulfillmentMethodType.DineIn;
  const noValidPayment =
    isEmpty(payments) ||
    isEmpty(
      payments?.filter((payment) =>
        (['Arranging', 'Authorized', 'Settled'] as PaymentStates[]).includes(
          payment.state as PaymentStates
        )
      )
    );
  /**
   * NOTE:
   * Before allow update status, need to done:
   * Delivery & Pickup: created in Pending, need to make fulfillment;
   * DineIn: created in Draft, need to add payment & fulfillment;
   */
  if ((isDineInDraft || orderStatus === OrderStatus.Pending) && noValidPayment) {
    actions.push({
      label: t('actions.arrangePayment', 'Arrange Payment'),
      name: 'arrange-payment',
      // onAction: () => onArrangePayment(),
      onAction: () => options?.onArrangePayment?.(),
    });
  }

  /**
   * Create Fulfillment for PaymentSettled order
   */
  if (!noValidPayment && orderStatus === OrderStatus.Confirmed) {
    // TODO: handle for dynamic fulfillments where if some lines haven't fulfill
    if (isEmpty(fulfillments)) {
      actions.push({
        label: t('actions.fulfillItem'),
        name: 'fulfill-item',
        onAction: () => options?.onFulfillItem?.(),
      });
    }
  }

  // @deprecated
  // if (
  //   !noPayment &&
  //   [OrderStatus.Draft, OrderStatus.Pending, OrderStatus.Confirmed].includes(orderStatus)
  // ) {
  //   actions.push({
  //     label: t('actions.updateStatus', 'Update Status'),
  //     name: 'update',
  //     // onAction: () => setOpenUpdate(true),
  //     onAction: () => options?.onUpdateStatus?.(),
  //   });
  // }

  if (orderStatus !== OrderStatus.Draft && canDownloadInvoice(orderStatus)) {
    actions.push({
      label: t('actions.downloadInvoice', 'Download Invoice'),
      name: 'download-invoice',
      onAction: () => {
        // !!id && downloadInvoice(id);
        !!order.id && options?.onDownloadInvoice?.(order.id);
      },
    });
  }

  actions.push({
    label: t('actions.cancel', 'Cancel'),
    buttonClassName: 'text-red-500',
    name: 'cancel',
    onAction: () => options?.onCancel?.(),
  });

  return actions;
};
