import React, { useState } from 'react';
import { Props } from './props';
import { ModifierGroup, Product } from '~/api';
import { useDelete, useNavigation, useTranslate } from '@refinedev/core';
import { ActionButton, flexRow, TriggerConfirmModal, ViewBuilder } from '~/components';
import {
  currencyForColHeader,
  formatBackendPrice,
  onlyOneCurrency,
  renderStatusTag,
  toCamelCaseWord,
} from '~/resources/helpers';
import { Table, Tag } from '@scalingworks/react-admin-ui';
import { FullDateTimeFormat, PRODUCT_DEEPLINK } from '~/config/constant';
import isEmpty from 'lodash/isEmpty';
import { numeralThousandFormat } from '~/config/helper';
import dayjs from 'dayjs';
import { resourceNames } from '~/resources/resource-names';
import { ViewConfigProps } from '~/components/ViewBuilder/props';

export const ProductShowPage: React.FC<Props> = (props) => {
  const { pageProps, channel, resourceName } = props;

  // ========================= VARIABLES
  const productData = pageProps?.queryResult?.data?.data as Product;
  const variantsData = productData?.variants;
  // Will hide certain UI for modifier type
  const isModifier = resourceName === resourceNames.modifier;

  // ========================= STATES
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  // ========================= HOOKS
  const { mutate } = useDelete();
  const { edit, list } = useNavigation();
  const t = useTranslate();

  let totalVal = 0;
  variantsData?.forEach((subItem) => {
    totalVal += subItem?.stockOnHand;
  });

  if (pageProps?.queryResult?.isLoading) return <div />;
  return (
    <section>
      <ViewBuilder
        {...pageProps}
        title={productData?.name}
        items={[
          {
            cardTitle: t('common.general'),
            cardHeaderExtra: () => {
              return (
                <div>
                  <ActionButton
                    customTitle={t('common.action').toUpperCase()}
                    actions={[
                      {
                        label: t('actions.edit'),
                        name: 'edit',
                        onAction: async () => {
                          await edit(resourceName, productData?.id);
                        },
                      },
                      {
                        label: t('actions.delete'),
                        name: 'delete',
                        onAction: async () => {
                          await mutate({
                            resource: resourceName,
                            id: productData?.id,
                          });
                          list(resourceName);
                        },
                        render: (onAction) => {
                          return (
                            <button type="button">
                              <TriggerConfirmModal
                                visible={showDeleteConfirmation}
                                onOpenChange={setShowDeleteConfirmation}
                                onPressConfirm={onAction}
                              />
                            </button>
                          );
                        },
                      },
                    ]}
                  />
                </div>
              );
            },
            config: [
              {
                title: t('product.column.name', { fallback: 'Name', ns: 'common' }),
                data: [productData?.name],
                type: 'text',
              },
              {
                title: t('product.column.description', {
                  fallback: 'Description',
                  ns: 'common',
                }),
                data: [productData?.description || ''],
                type: 'text',
              },
              {
                title: t('product.column.createdOn', {
                  fallback: 'Created On',
                  ns: 'common',
                }),
                data: [dayjs(productData?.createdAt).format(FullDateTimeFormat)],
                type: 'text',
              },
              {
                title: t('product.column.images', { fallback: 'Images', ns: 'common' }),
                data: productData?.assets?.map((subItem) => subItem?.source) || [],
                type: 'image',
              },
              (!isModifier && {
                title: t('product.column.fulfillmentMethod', {
                  fallback: 'Fulfillment Method',
                  ns: 'common',
                }),
                data: [
                  ...(productData?.customFields?.fulfillChannels?.map((subItem) =>
                    toCamelCaseWord(subItem?.name)
                  ) || []),
                ],
                type: 'text',
              }) as ViewConfigProps,
              {
                title: t('product.column.quantity', { fallback: 'Quantity', ns: 'common' }),
                data: [numeralThousandFormat(totalVal)],
                type: 'text',
              },
              {
                title: t('product.column.status', { fallback: 'Status', ns: 'common' }),
                data: [productData?.enabled],
                render: ({ data, flex, title }) => {
                  return (
                    <div style={{ ...flexRow }}>
                      {title}
                      <div style={{ flex }}>{renderStatusTag(data?.[0])}</div>
                    </div>
                  );
                },
              },
              (!isModifier && {
                title: t('product.column.collections', {
                  fallback: 'Collections',
                  ns: 'common',
                }),
                data: productData?.collections?.map((subItem) => subItem?.name) || [],
                type: 'tag',
              }) as ViewConfigProps,
              (!isModifier && {
                title: t('product.column.modifierGroups', {
                  fallback: 'Modifiers',
                  ns: 'common',
                }),
                data: productData.modifierGroups,
                type: 'tag',
                render: ({ data = [], flex, title }) => {
                  const modifierGroups = data as ModifierGroup[];

                  return (
                    <div style={{ ...flexRow }}>
                      {title}
                      <div style={{ flex }}>
                        <div className="flex flex-col space-y-1">
                          {modifierGroups.map((modGroup) => (
                            <Tag className="max-w-min !text-sm !text-primary-500" color="purple">
                              <p>
                                {modGroup.group}:{' '}
                                {(modGroup.modifiers || [])
                                  .map((mod: Product) => mod.name)
                                  .join(', ')}
                              </p>
                            </Tag>
                          ))}
                        </div>
                      </div>
                    </div>
                  );
                },
              }) as ViewConfigProps,
              (!isModifier && {
                title: t('product.column.qrCode', { fallback: 'QR Code', ns: 'common' }),
                data: [`${PRODUCT_DEEPLINK}${productData?.id}`],
                type: 'qr',
              }) as ViewConfigProps,
            ],
          },
        ]}
        extra={
          isEmpty(variantsData)
            ? []
            : [
                {
                  cardTitle: 'Variants',
                  render: () => {
                    return (
                      <Table>
                        <Table.Thead>
                          <Table.Tr>
                            {[
                              t('product.variants.column.image', {
                                fallback: 'Image',
                                ns: 'common',
                              }),
                              t('product.variants.column.name', {
                                fallback: 'Name',
                                ns: 'common',
                              }),
                              t('product.variants.column.price', {
                                fallback: 'Price',
                                ns: 'common',
                                currency: currencyForColHeader(channel),
                              }),
                              t('product.variants.column.quantity', {
                                fallback: 'Quantity',
                                ns: 'common',
                              }),
                              t('product.variants.column.enabled', {
                                fallback: 'Enabled',
                                ns: 'common',
                              }),
                            ]?.map((subItem) => (
                              <Table.Th>{subItem}</Table.Th>
                            ))}
                          </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                          {variantsData?.map((subItem, index) => {
                            return (
                              <Table.Tr key={`${subItem?.name}_${index}`}>
                                <Table.Td width={'15%'}>
                                  <img
                                    src={subItem?.assets?.[0]?.source as string}
                                    width={80}
                                    height={80}
                                  />
                                </Table.Td>
                                <Table.Td width={'25%'}>{subItem?.name}</Table.Td>
                                <Table.Td width={'20%'}>
                                  {!onlyOneCurrency(channel) && subItem?.currencyCode}
                                  {numeralThousandFormat(
                                    formatBackendPrice({
                                      price: subItem?.price,
                                      isDivide: true,
                                    }),
                                    true
                                  )}
                                </Table.Td>
                                <Table.Td width={'10%'}>
                                  {numeralThousandFormat(subItem?.stockOnHand)}
                                </Table.Td>
                                <Table.Td width={'10%'}>
                                  {renderStatusTag(subItem?.enabled)}
                                </Table.Td>
                              </Table.Tr>
                            );
                          })}
                        </Table.Tbody>
                      </Table>
                    );
                  },
                },
              ]
        }
      />
      {/* TODO: re-enable review */}
      {/* <Card className="relative z-0 mx-6">
        <Card.Header
          className="font-bold cursor-pointer"
          bordered
          onClick={() => setShowReviews(!showReviews)}
        >
          <section className="flex flex-row items-center justify-between">
            <h1>{t('product.details.reviews', undefined, 'Reviews')}</h1>
            <div className="flex flex-row space-x-2">
              <BsChevronUp
                size={30}
                className={`transition-transform ${showReviews ? 'rotate-180' : 'rotate-0'}`}
              />
            </div>
          </section>
        </Card.Header>
        {showReviews && (
          <Card.Body className="px-0 py-0">
            <ProductReviews
              productId={productData?.id}
              loading={helpers?.queryResult?.isLoading}
            />
          </Card.Body>
        )}
      </Card> */}
    </section>
  );
};
