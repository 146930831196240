import React from 'react';
import { Props } from './props';
import { codeFormat, parsePrice } from '~/resources/helpers';
import { useTranslate } from '@refinedev/core';
import ActionButton from '../ActionButton';
import { CopyButton } from '../CopyButton';
import camelCase from 'lodash/camelCase';
import startCase from 'lodash/startCase';
import dayjs from 'dayjs';
import { FullDateTimeFormat } from '~/config/constant';
import { paymentStateTagDict } from '~/resources/helpers/payment';
import { PaymentStates } from '~/config/types';
import { Tag } from '@scalingworks/react-admin-ui';
import { isEmpty } from 'lodash';

export const PaymentRow: React.FC<Props> = (props) => {
  const { payment, actions, currency = '' } = props;

  const { id, createdAt, transactionId, method, amount, state, refunds } = payment;

  // ===================== HOOKS
  const t = useTranslate();

  // ===================== VARIABLES
  const paymentTag = paymentStateTagDict[state as PaymentStates];

  // ===================== VIEWS
  const subText = (text: string) => {
    return <p className="text-sm text-gray-600">{text}</p>;
  };
  return (
    <div className="flex flex-col space-y-1">
      <div className="flex flex-row justify-between items-center w-full mb-2">
        <div className="flex flex-row items-center space-x-2">
          <p className="font-semibold">{startCase(camelCase(method))}</p>
          {!isEmpty(paymentTag) && (
            <Tag color={paymentTag.tagColor as any}>
              {t(`payment.tag.${paymentTag.name}`).toUpperCase()}
            </Tag>
          )}
        </div>
        <ActionButton
          key={id}
          customTitle={t('common.action', undefined, 'ACTION').toUpperCase()}
          actions={actions}
        />
      </div>

      <div className="flex flex-row justify-between items-center w-full">
        <div className="flex flex-col">
          <div className="flex flex-row space-x-1 items-center w-full">
            {!!transactionId &&
              subText(`${t('payment.transactionId')}: ${codeFormat(transactionId)}`)}
            <CopyButton textToCopy={transactionId} iconClassName="!text-gray-600" />
          </div>
          {!!createdAt && subText(dayjs(createdAt).format(FullDateTimeFormat))}
        </div>
        <p className="font-bold">
          {currency} {parsePrice(amount)}
        </p>
      </div>
    </div>
  );
};
