import { useApiUrl, useCustomMutation, useTranslate } from '@refinedev/core';
import { Button } from '@scalingworks/react-admin-ui';
import React, { useState } from 'react';
import { ModalWrapper, MultiModifierSelection } from '~/components';
import { Props } from './props';
import { compact, isEmpty } from 'lodash';
import { AddModifiersToGroupInput, Mutation } from '~/api';

export const LinkModifierSelect: React.FC<Props> = (props) => {
  const { modifierGroupId, initialModifierIds = [], onComplete } = props;

  // ===================== HOOKS
  const t = useTranslate();
  const apiUrl = useApiUrl();
  const { mutate: addModifierToGroup, isLoading: updating } = useCustomMutation();

  // ===================== STATES
  const [selectedModifiers, setSelectedModifiers] = useState([]);

  // ===================== EVENTS
  const onConfirmSelection = async (modifierIds: string[]) => {
    if (isEmpty(compact(modifierIds))) return;

    // Is actually adding Group's facetValues to the modifiers (Product)
    await addModifierToGroup({
      method: 'post',
      url: apiUrl,
      values: {},
      meta: {
        operation: 'addModifiersToGroup' as keyof Mutation,
        fields: ['id'],
        variables: {
          input: {
            value: {
              facetValueId: modifierGroupId,
              modifierIds,
            } as AddModifiersToGroupInput,
            type: 'AddModifiersToGroupInput!',
          },
        },
      },
      errorNotification: {
        message: t('modifierGroup.edit.failed', {}, 'Failed to update modifier group info'),
        type: 'error',
      },
      successNotification: {
        message: t('modifierGroup.edit.success', {}, 'Updated modifier group info'),
        type: 'success',
      },
    });

    setSelectedModifiers([]);
    onComplete?.();
  };

  // ===================== VIEWS
  return (
    <section className="space-y-16">
      <MultiModifierSelection
        size={'lg'}
        onSelect={(ids) => setSelectedModifiers(ids)}
        excludeIds={initialModifierIds}
        autoFocus={false}
      />

      <Button
        variant="solid"
        className="w-full"
        onClick={() => onConfirmSelection(selectedModifiers)}
        loading={updating}
      >
        {t('common.update')}
      </Button>
    </section>
  );
};
