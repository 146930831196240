import { AccessControlProvider, AuthBindings } from '@refinedev/core';
import {
  AccessActionPermission,
  AccessConfig,
  VendureUserIdentity,
} from '@scalingworks/refine-react-admin/src/types';
import difference from 'lodash/difference';

export const createAccessControlProvider = (
  authProvider: AuthBindings,
  accessConfig: AccessConfig,
  options?: { refreshIntervalMs: number }
): AccessControlProvider => {
  const { refreshIntervalMs = 1000 * 60 } = options || {};
  let prevRequest: Promise<VendureUserIdentity> | null = null;

  return {
    can: async ({ resource, action: _action, params }) => {
      if (resource) {
        try {
          if (!prevRequest) {
            prevRequest = authProvider.getIdentity!() as Promise<VendureUserIdentity>;
            prevRequest.then(() => {
              setTimeout(() => (prevRequest = null), refreshIntervalMs);
            });
          }
          const identity = await prevRequest;
          const action = _action as keyof AccessActionPermission;

          // Extract the target channel's permissions
          const { channelToken = '', resourceControl } = accessConfig;
          const permissions =
            identity.me.channels.find((channel) => channel.token === channelToken)?.permissions ||
            [];

          // Validate access
          const requirePermissions = resourceControl?.[resource][action];
          const hasAccess = difference(requirePermissions, permissions).length === 0;

          return {
            can: hasAccess,
            reason: `Accessed to ${action} ${resource} through ${JSON.stringify(
              requirePermissions
            )}`,
          };
        } catch (err) {
          console.error(`[Access Control Error]: `, err);
          prevRequest = null;
        }
      }

      return {
        can: false,
      };
    },
  };
};
