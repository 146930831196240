import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
  Money: any;
  Upload: any;
};

export type ActivateOutletDeviceInput = {
  activationKey?: InputMaybe<Scalars['String']>;
  adminId?: InputMaybe<Scalars['ID']>;
  channelId?: InputMaybe<Scalars['ID']>;
  deviceToken: Scalars['String'];
  outletId?: InputMaybe<Scalars['ID']>;
};

export type AddFulfillmentToOrderResult = CreateFulfillmentError | EmptyOrderLineSelectionError | Fulfillment | FulfillmentStateTransitionError | InsufficientStockOnHandError | InvalidFulfillmentHandlerError | ItemsAlreadyFulfilledError;

export type AddItemInput = {
  customFields?: InputMaybe<OrderLineCustomFieldsInput>;
  productVariantId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type AddItemToDraftOrderInput = {
  customFields?: InputMaybe<OrderLineCustomFieldsInput>;
  productVariantId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type AddItemToOrder = {
  productVariantId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type AddItemToOrderWithAddOnInput = {
  orderId: Scalars['ID'];
  orderLines: Array<CreateOrderLineInput>;
};

export type AddManualPaymentProcessOrderInput = {
  metadata?: InputMaybe<Scalars['JSON']>;
  method: Scalars['String'];
};

export type AddManualPaymentToOrderResult = ManualPaymentStateError | Order;

export type AddModifiersToGroupInput = {
  facetValueId: Scalars['String'];
  modifierIds: Array<Scalars['String']>;
};

export type AddNoteToCustomerInput = {
  id: Scalars['ID'];
  isPublic: Scalars['Boolean'];
  note: Scalars['String'];
};

export type AddNoteToOrderInput = {
  id: Scalars['ID'];
  isPublic: Scalars['Boolean'];
  note: Scalars['String'];
};

export type AddOnInput = {
  group: Scalars['String'];
  modifiers: Array<ModifierInput>;
};

export type AddOnSnapshot = {
  __typename?: 'AddOnSnapshot';
  group: Scalars['String'];
  modifiers: Array<SnapshotModifier>;
};

export type AddSnapshotInput = {
  orderLineId: Scalars['ID'];
  snapshot: Array<SnapshotInput>;
};

export type AdditionalProperties = {
  __typename?: 'AdditionalProperties';
  courier?: Maybe<Scalars['String']>;
  driverContact?: Maybe<Scalars['String']>;
  driverName?: Maybe<Scalars['String']>;
  pickUpTime?: Maybe<Scalars['DateTime']>;
};

export type AdditionalPropertiesInput = {
  courier?: InputMaybe<Scalars['String']>;
  driverContact?: InputMaybe<Scalars['String']>;
  driverName?: InputMaybe<Scalars['String']>;
  pickUpTime?: InputMaybe<Scalars['DateTime']>;
};

export type Address = Node & {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  country: Country;
  countryCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<AddressCustomFields>;
  customer?: Maybe<Customer>;
  defaultBillingAddress?: Maybe<Scalars['Boolean']>;
  defaultShippingAddress?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  streetLine1: Scalars['String'];
  streetLine2?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type AddressCustomFields = {
  __typename?: 'AddressCustomFields';
  customerAddressId?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  posId?: Maybe<Scalars['String']>;
};

export type AddressFilterParameter = {
  _and?: InputMaybe<Array<AddressFilterParameter>>;
  _or?: InputMaybe<Array<AddressFilterParameter>>;
  city?: InputMaybe<StringOperators>;
  company?: InputMaybe<StringOperators>;
  countryCode?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  customerAddressId?: InputMaybe<StringOperators>;
  defaultBillingAddress?: InputMaybe<BooleanOperators>;
  defaultShippingAddress?: InputMaybe<BooleanOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  fullName?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  latitude?: InputMaybe<NumberOperators>;
  longitude?: InputMaybe<NumberOperators>;
  phoneNumber?: InputMaybe<StringOperators>;
  posId?: InputMaybe<StringOperators>;
  postalCode?: InputMaybe<StringOperators>;
  province?: InputMaybe<StringOperators>;
  streetLine1?: InputMaybe<StringOperators>;
  streetLine2?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type AddressInputPushRow = {
  assumedMasterState?: InputMaybe<AddressSyncInput>;
  newDocumentState: AddressSyncInput;
};

export type AddressList = PaginatedList & {
  __typename?: 'AddressList';
  items: Array<Address>;
  totalItems: Scalars['Int'];
};

export type AddressListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<AddressFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<AddressSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type AddressSortParameter = {
  city?: InputMaybe<SortOrder>;
  company?: InputMaybe<SortOrder>;
  countryCode?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customerAddressId?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  fullName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  latitude?: InputMaybe<SortOrder>;
  longitude?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  posId?: InputMaybe<SortOrder>;
  postalCode?: InputMaybe<SortOrder>;
  province?: InputMaybe<SortOrder>;
  streetLine1?: InputMaybe<SortOrder>;
  streetLine2?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AddressSyncInput = {
  city: Scalars['String'];
  company?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  countryName?: InputMaybe<Scalars['String']>;
  createdAt: Scalars['Float'];
  customer?: InputMaybe<Scalars['String']>;
  defaultBillingAddress?: InputMaybe<Scalars['Boolean']>;
  defaultShippingAddress?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['Float']>;
  fullName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  phoneNumber: Scalars['String'];
  postalCode: Scalars['String'];
  province: Scalars['String'];
  streetLine1: Scalars['String'];
  streetLine2?: InputMaybe<Scalars['String']>;
  updatedAt: Scalars['Float'];
};

export type AddressSynchronizerResponse = {
  __typename?: 'AddressSynchronizerResponse';
  data?: Maybe<Address>;
  error?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type AdjustDraftOrderLineInput = {
  customFields?: InputMaybe<OrderLineCustomFieldsInput>;
  orderLineId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type Adjustment = {
  __typename?: 'Adjustment';
  adjustmentSource: Scalars['String'];
  amount: Scalars['Money'];
  data?: Maybe<Scalars['JSON']>;
  description: Scalars['String'];
  type: AdjustmentType;
};

export enum AdjustmentType {
  DistributedOrderPromotion = 'DISTRIBUTED_ORDER_PROMOTION',
  Other = 'OTHER',
  Promotion = 'PROMOTION'
}

export type AdminMobileInput = {
  phoneNumber: Scalars['String'];
};

export enum AdminOrderProcess {
  AddManualPayment = 'ADD_MANUAL_PAYMENT',
  UpdateFulfillment = 'UPDATE_FULFILLMENT'
}

export type AdminRequestSigninInput = {
  phoneNumber: Scalars['String'];
};

export type AdminUpdateFulfillmentMethodInput = {
  fulfillment: OrderFulfillmentInput;
  orderId: Scalars['ID'];
};

export type Administrator = Node & {
  __typename?: 'Administrator';
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<AdministratorCustomFields>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastClockEntry?: Maybe<AdministratorClockEntry>;
  lastName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type AdministratorClockEntry = Node & {
  __typename?: 'AdministratorClockEntry';
  administrator: Administrator;
  administratorId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  entry: Scalars['DateTime'];
  exit?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  posId?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['DateTime'];
};

export type AdministratorClockEntryFilterParameter = {
  _and?: InputMaybe<Array<AdministratorClockEntryFilterParameter>>;
  _or?: InputMaybe<Array<AdministratorClockEntryFilterParameter>>;
  administratorId?: InputMaybe<IdOperators>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  entry?: InputMaybe<DateOperators>;
  exit?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  posId?: InputMaybe<IdOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type AdministratorClockEntryInput = {
  administrationId?: InputMaybe<Scalars['ID']>;
  type: ClockEntryType;
};

export type AdministratorClockEntryInputPushRow = {
  assumedMasterState?: InputMaybe<ClockEntrySyncInput>;
  newDocumentState: ClockEntrySyncInput;
};

export type AdministratorClockEntryList = PaginatedList & {
  __typename?: 'AdministratorClockEntryList';
  items: Array<AdministratorClockEntry>;
  totalItems: Scalars['Int'];
};

export type AdministratorClockEntryListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<AdministratorClockEntryFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<AdministratorClockEntrySortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type AdministratorClockEntrySortParameter = {
  administratorId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  entry?: InputMaybe<SortOrder>;
  exit?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  posId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AdministratorCustomFields = {
  __typename?: 'AdministratorCustomFields';
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  phoneNumber?: Maybe<Scalars['String']>;
  seller?: Maybe<Seller>;
  verificationNumber?: Maybe<Scalars['String']>;
};

export type AdministratorCustomFieldsInput = {
  dateOfBirth?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  verificationNumber?: InputMaybe<Scalars['String']>;
};

export type AdministratorFilterParameter = {
  _and?: InputMaybe<Array<AdministratorFilterParameter>>;
  _or?: InputMaybe<Array<AdministratorFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  dateOfBirth?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  emailAddress?: InputMaybe<StringOperators>;
  firstName?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  lastName?: InputMaybe<StringOperators>;
  phoneNumber?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
  verificationNumber?: InputMaybe<StringOperators>;
};

export type AdministratorList = PaginatedList & {
  __typename?: 'AdministratorList';
  items: Array<Administrator>;
  totalItems: Scalars['Int'];
};

export type AdministratorListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<AdministratorFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<AdministratorSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type AdministratorPaymentInput = {
  metadata?: InputMaybe<Scalars['JSON']>;
  paymentMethod?: InputMaybe<Scalars['String']>;
};

export type AdministratorRefundInput = {
  /**
   * The amount to be refunded to this particular Payment. This was introduced in
   * v2.2.0 as the preferred way to specify the refund amount. The `lines`, `shipping` and `adjustment`
   * fields will be removed in a future version.
   */
  amount?: InputMaybe<Scalars['Money']>;
  paymentId: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
};

export type AdministratorSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  dateOfBirth?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  emailAddress?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  seller?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  verificationNumber?: InputMaybe<SortOrder>;
};

export type AllOutletFulfillmentTypeByDay = {
  __typename?: 'AllOutletFulfillmentTypeByDay';
  days: Array<OutletFulfillmentByDayAndType>;
  type: OutletFulfillmentType;
};

export type Allocation = Node & StockMovement & {
  __typename?: 'Allocation';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  orderLine: OrderLine;
  posId?: Maybe<Scalars['String']>;
  productVariant: ProductVariant;
  quantity: Scalars['Int'];
  stockLocation: StockLocation;
  type: StockMovementType;
  updatedAt: Scalars['DateTime'];
};

/** Returned if an attempting to refund an OrderItem which has already been refunded */
export type AlreadyRefundedError = ErrorResult & {
  __typename?: 'AlreadyRefundedError';
  errorCode: ErrorCode;
  message: Scalars['String'];
  refundId: Scalars['ID'];
};

export type AppVersion = Node & {
  __typename?: 'AppVersion';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  minimumBuildNumber?: Maybe<Scalars['Int']>;
  minimumVersionName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  platform: Platform;
  updatedAt: Scalars['DateTime'];
  url?: Maybe<Scalars['String']>;
  versionName: Scalars['String'];
  versionNumber: Scalars['String'];
};

export type AppVersionList = PaginatedList & {
  __typename?: 'AppVersionList';
  items: Array<AppVersion>;
  totalItems: Scalars['Int'];
};

export type ApplyCouponCodeResult = CouponCodeExpiredError | CouponCodeInvalidError | CouponCodeLimitError | Order;

export type ApplyGiftOptionInput = {
  giftOptionId: Scalars['ID'];
};

export type Asset = Node & {
  __typename?: 'Asset';
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<Scalars['JSON']>;
  fileSize: Scalars['Int'];
  focalPoint?: Maybe<Coordinate>;
  height: Scalars['Int'];
  id: Scalars['ID'];
  mimeType: Scalars['String'];
  name: Scalars['String'];
  preview: Scalars['String'];
  source: Scalars['String'];
  tags: Array<Tag>;
  type: AssetType;
  updatedAt: Scalars['DateTime'];
  width: Scalars['Int'];
};

export type AssetFilterParameter = {
  _and?: InputMaybe<Array<AssetFilterParameter>>;
  _or?: InputMaybe<Array<AssetFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  fileSize?: InputMaybe<NumberOperators>;
  height?: InputMaybe<NumberOperators>;
  id?: InputMaybe<IdOperators>;
  mimeType?: InputMaybe<StringOperators>;
  name?: InputMaybe<StringOperators>;
  preview?: InputMaybe<StringOperators>;
  source?: InputMaybe<StringOperators>;
  type?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
  width?: InputMaybe<NumberOperators>;
};

export type AssetList = PaginatedList & {
  __typename?: 'AssetList';
  items: Array<Asset>;
  totalItems: Scalars['Int'];
};

export type AssetListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<AssetFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<AssetSortParameter>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  tagsOperator?: InputMaybe<LogicalOperator>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type AssetSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  fileSize?: InputMaybe<SortOrder>;
  height?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mimeType?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  preview?: InputMaybe<SortOrder>;
  source?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  width?: InputMaybe<SortOrder>;
};

export enum AssetType {
  Binary = 'BINARY',
  Image = 'IMAGE',
  Video = 'VIDEO'
}

export type AssignAssetsToChannelInput = {
  assetIds: Array<Scalars['ID']>;
  channelId: Scalars['ID'];
};

export type AssignBlastableUserInput = {
  userId: Scalars['ID'];
  userType: BlastableRole;
};

export type AssignCollectionsToChannelInput = {
  channelId: Scalars['ID'];
  collectionIds: Array<Scalars['ID']>;
};

export type AssignFacetsToChannelInput = {
  channelId: Scalars['ID'];
  facetIds: Array<Scalars['ID']>;
};

export type AssignModifierGroupsToProductInput = {
  facetValueIds: Array<Scalars['String']>;
  productId: Scalars['String'];
};

export type AssignPaymentMethodsToChannelInput = {
  channelId: Scalars['ID'];
  paymentMethodIds: Array<Scalars['ID']>;
};

export type AssignProductVariantsToChannelInput = {
  channelId: Scalars['ID'];
  priceFactor?: InputMaybe<Scalars['Float']>;
  productVariantIds: Array<Scalars['ID']>;
};

export type AssignProductsToChannelInput = {
  channelId: Scalars['ID'];
  priceFactor?: InputMaybe<Scalars['Float']>;
  productIds: Array<Scalars['ID']>;
};

export type AssignPromotionsToChannelInput = {
  channelId: Scalars['ID'];
  promotionIds: Array<Scalars['ID']>;
};

export type AssignShippingMethodsToChannelInput = {
  channelId: Scalars['ID'];
  shippingMethodIds: Array<Scalars['ID']>;
};

export type AssignShippingTierInput = {
  maxQuantity?: InputMaybe<Scalars['Int']>;
  minQuantity?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type AssignStockLocationsToChannelInput = {
  channelId: Scalars['ID'];
  stockLocationIds: Array<Scalars['ID']>;
};

export type AuditLog = Node & {
  __typename?: 'AuditLog';
  administrator: Administrator;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  ipAddress?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  table: Scalars['String'];
  title: Scalars['String'];
  userAction: UserActionType;
  userAgent?: Maybe<Scalars['String']>;
};

export type AuditLogFilterParameter = {
  _and?: InputMaybe<Array<AuditLogFilterParameter>>;
  _or?: InputMaybe<Array<AuditLogFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  ipAddress?: InputMaybe<StringOperators>;
  remarks?: InputMaybe<StringOperators>;
  table?: InputMaybe<StringOperators>;
  title?: InputMaybe<StringOperators>;
  userAction?: InputMaybe<StringOperators>;
  userAgent?: InputMaybe<StringOperators>;
};

export type AuditLogList = PaginatedList & {
  __typename?: 'AuditLogList';
  items: Array<AuditLog>;
  totalItems: Scalars['Int'];
};

export type AuditLogListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<AuditLogFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<AuditLogSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type AuditLogSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ipAddress?: InputMaybe<SortOrder>;
  remarks?: InputMaybe<SortOrder>;
  table?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  userAgent?: InputMaybe<SortOrder>;
};

export type AuthenticationInput = {
  native?: InputMaybe<NativeAuthInput>;
};

export type AuthenticationMethod = Node & {
  __typename?: 'AuthenticationMethod';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  strategy: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type AuthenticationResult = CurrentUser | InvalidCredentialsError;

export type Banner = Node & {
  __typename?: 'Banner';
  asset: Asset;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  displayType: BannerDisplayType;
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  name: Scalars['String'];
  products?: Maybe<Array<Product>>;
  reward?: Maybe<Reward>;
  rewardId?: Maybe<Scalars['ID']>;
  startDate: Scalars['DateTime'];
  status: BannerStatus;
  type: BannerType;
  updatedAt: Scalars['DateTime'];
  url?: Maybe<Scalars['String']>;
};

export enum BannerDisplayType {
  AnnouncementBar = 'ANNOUNCEMENT_BAR',
  BigImage = 'BIG_IMAGE',
  BigVideo = 'BIG_VIDEO',
  Image = 'IMAGE',
  Video = 'VIDEO'
}

export type BannerFilterParameter = {
  _and?: InputMaybe<Array<BannerFilterParameter>>;
  _or?: InputMaybe<Array<BannerFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  description?: InputMaybe<StringOperators>;
  displayType?: InputMaybe<StringOperators>;
  endDate?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  isDefault?: InputMaybe<BooleanOperators>;
  name?: InputMaybe<StringOperators>;
  rewardId?: InputMaybe<IdOperators>;
  startDate?: InputMaybe<DateOperators>;
  status?: InputMaybe<StringOperators>;
  type?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
  url?: InputMaybe<StringOperators>;
};

export type BannerList = PaginatedList & {
  __typename?: 'BannerList';
  items: Array<Banner>;
  totalItems: Scalars['Int'];
};

export type BannerListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<BannerFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<BannerSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type BannerPeriodInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
};

export type BannerSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  rewardId?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export enum BannerStatus {
  Available = 'AVAILABLE',
  Ended = 'ENDED',
  Upcoming = 'UPCOMING'
}

export enum BannerType {
  Editor = 'EDITOR',
  Product = 'PRODUCT',
  Reward = 'REWARD',
  Url = 'URL'
}

export enum BlastContentType {
  Announcement = 'ANNOUNCEMENT',
  Promotion = 'PROMOTION',
  Transaction = 'TRANSACTION',
  Update = 'UPDATE'
}

export enum BlastableChannel {
  Chat = 'CHAT',
  Email = 'EMAIL',
  InApp = 'IN_APP',
  Push = 'PUSH',
  Sms = 'SMS'
}

export enum BlastableReceiverType {
  All = 'ALL',
  Exclude = 'EXCLUDE',
  Include = 'INCLUDE'
}

export enum BlastableRole {
  Admin = 'ADMIN',
  Customer = 'CUSTOMER',
  User = 'USER'
}

export type BooleanCustomFieldConfig = CustomField & {
  __typename?: 'BooleanCustomFieldConfig';
  description?: Maybe<Array<LocalizedString>>;
  internal?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Array<LocalizedString>>;
  list: Scalars['Boolean'];
  name: Scalars['String'];
  nullable?: Maybe<Scalars['Boolean']>;
  readonly?: Maybe<Scalars['Boolean']>;
  requiresPermission?: Maybe<Array<Permission>>;
  type: Scalars['String'];
  ui?: Maybe<Scalars['JSON']>;
};

/** Operators for filtering on a list of Boolean fields */
export type BooleanListOperators = {
  inList: Scalars['Boolean'];
};

/** Operators for filtering on a Boolean field */
export type BooleanOperators = {
  eq?: InputMaybe<Scalars['Boolean']>;
  isNull?: InputMaybe<Scalars['Boolean']>;
};

export type BulkProductModifierInput = {
  modifierIds: Array<Scalars['ID']>;
  productId: Scalars['ID'];
};

/** Returned if an attempting to cancel lines from an Order which is still active */
export type CancelActiveOrderError = ErrorResult & {
  __typename?: 'CancelActiveOrderError';
  errorCode: ErrorCode;
  message: Scalars['String'];
  orderState: Scalars['String'];
};

export type CancelOrderInput = {
  /** Specify whether the shipping charges should also be cancelled. Defaults to false */
  cancelShipping?: InputMaybe<Scalars['Boolean']>;
  /** Optionally specify which OrderLines to cancel. If not provided, all OrderLines will be cancelled */
  lines?: InputMaybe<Array<OrderLineInput>>;
  /** The id of the order to be cancelled */
  orderId: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
};

export type CancelOrderResult = CancelActiveOrderError | EmptyOrderLineSelectionError | MultipleOrderError | Order | OrderStateTransitionError | QuantityTooGreatError;

/** Returned if the Payment cancellation fails */
export type CancelPaymentError = ErrorResult & {
  __typename?: 'CancelPaymentError';
  errorCode: ErrorCode;
  message: Scalars['String'];
  paymentErrorMessage: Scalars['String'];
};

export type CancelPaymentResult = CancelPaymentError | Payment | PaymentStateTransitionError;

export type Cancellation = Node & StockMovement & {
  __typename?: 'Cancellation';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  orderLine: OrderLine;
  posId?: Maybe<Scalars['String']>;
  productVariant: ProductVariant;
  quantity: Scalars['Int'];
  stockLocation: StockLocation;
  type: StockMovementType;
  updatedAt: Scalars['DateTime'];
};

export type CartOrderDetail = {
  customerId: Scalars['String'];
  fulfillmentMethod: Scalars['String'];
  shipping: Scalars['Money'];
  subTotal: Scalars['Money'];
  total: Scalars['Money'];
  totalLinePrice: Scalars['Money'];
};

export type Channel = Node & {
  __typename?: 'Channel';
  autoFulfillAllItemsOnPaid?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableCurrencyCodes: Array<CurrencyCode>;
  availableLanguageCodes?: Maybe<Array<LanguageCode>>;
  code: Scalars['String'];
  country?: Maybe<Country>;
  createdAt: Scalars['DateTime'];
  /** @deprecated Use defaultCurrencyCode instead */
  currencyCode: CurrencyCode;
  customFields?: Maybe<ChannelCustomFields>;
  defaultCurrencyCode: CurrencyCode;
  defaultLanguageCode: LanguageCode;
  defaultShippingZone?: Maybe<Zone>;
  defaultTaxRates?: Maybe<Array<TaxRate>>;
  defaultTaxZone?: Maybe<Zone>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  faq?: Maybe<Wysiwyg>;
  fulfillmentMethods?: Maybe<Array<Maybe<Scalars['String']>>>;
  help?: Maybe<Wysiwyg>;
  id: Scalars['ID'];
  info?: Maybe<ChannelInfo>;
  orderFlagConfig?: Maybe<ChannelOrderFlagConfig>;
  /** Not yet used - will be implemented in a future release. */
  outOfStockThreshold?: Maybe<Scalars['Int']>;
  pricesIncludeTax: Scalars['Boolean'];
  privacy?: Maybe<Wysiwyg>;
  refund?: Maybe<Wysiwyg>;
  salesContributionConfig?: Maybe<SalesContributionChannelConfig>;
  seller?: Maybe<Seller>;
  shipping?: Maybe<Wysiwyg>;
  tnc?: Maybe<Wysiwyg>;
  token: Scalars['String'];
  /** Not yet used - will be implemented in a future release. */
  trackInventory?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['DateTime'];
};

export type ChannelCustomFields = {
  __typename?: 'ChannelCustomFields';
  applyTaxOnServiceCharge?: Maybe<Scalars['Boolean']>;
  availableFulfillmentMethods?: Maybe<Array<Scalars['String']>>;
  companyDetail?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['String']>;
  country?: Maybe<Country>;
  days?: Maybe<Scalars['Int']>;
  eghlConfig?: Maybe<Scalars['String']>;
  enableMobileVerificationBypass?: Maybe<Scalars['Boolean']>;
  enableTableOccupancy?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['Int']>;
  info?: Maybe<Scalars['String']>;
  invoicePrefixDateFormat?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  loyaltyEnabled?: Maybe<Scalars['Boolean']>;
  maintenanceContent?: Maybe<Scalars['String']>;
  mergeDining?: Maybe<Scalars['Boolean']>;
  miniApps?: Maybe<Scalars['String']>;
  minutes?: Maybe<Scalars['Int']>;
  mobileVerificationBypassOTPCode?: Maybe<Scalars['String']>;
  months?: Maybe<Scalars['Int']>;
  orderFlagConfig?: Maybe<Scalars['String']>;
  posMustCreatePaidPayment?: Maybe<Scalars['Boolean']>;
  posMustFulfillAllItemAtOnce?: Maybe<Scalars['Boolean']>;
  posOrderListingDateType?: Maybe<Scalars['String']>;
  posSplitPaymentEnabled?: Maybe<Scalars['Boolean']>;
  posTableOrderingEnabled?: Maybe<Scalars['Boolean']>;
  referralContent?: Maybe<Scalars['String']>;
  referralContentImage?: Maybe<Scalars['String']>;
  referralContentTitle?: Maybe<Scalars['String']>;
  referralShareContent?: Maybe<Scalars['String']>;
  referralShareUrl?: Maybe<Scalars['String']>;
  salesContributionConfig?: Maybe<Scalars['String']>;
  serviceCharge?: Maybe<Scalars['Float']>;
  serviceChargeEnabled?: Maybe<Scalars['Boolean']>;
  setOrderAsInactiveAfterCheckout?: Maybe<Scalars['Boolean']>;
  supportedPhoneCodes?: Maybe<Array<Scalars['String']>>;
  taxRateRegistrationNumber?: Maybe<Scalars['String']>;
  tenantApiKey?: Maybe<Scalars['String']>;
  tenantCode?: Maybe<Scalars['String']>;
  tenantEndpoint?: Maybe<Scalars['String']>;
  underMaintenance?: Maybe<Scalars['Boolean']>;
  userGroupWithMobileVerificationBypass?: Maybe<Array<CustomerGroup>>;
};

/**
 * Returned when the default LanguageCode of a Channel is no longer found in the `availableLanguages`
 * of the GlobalSettings
 */
export type ChannelDefaultLanguageError = ErrorResult & {
  __typename?: 'ChannelDefaultLanguageError';
  channelCode: Scalars['String'];
  errorCode: ErrorCode;
  language: Scalars['String'];
  message: Scalars['String'];
};

export type ChannelFilterParameter = {
  _and?: InputMaybe<Array<ChannelFilterParameter>>;
  _or?: InputMaybe<Array<ChannelFilterParameter>>;
  applyTaxOnServiceCharge?: InputMaybe<BooleanOperators>;
  availableFulfillmentMethods?: InputMaybe<StringListOperators>;
  code?: InputMaybe<StringOperators>;
  companyDetail?: InputMaybe<StringOperators>;
  configuration?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  currencyCode?: InputMaybe<StringOperators>;
  days?: InputMaybe<NumberOperators>;
  defaultCurrencyCode?: InputMaybe<StringOperators>;
  defaultLanguageCode?: InputMaybe<StringOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  eghlConfig?: InputMaybe<StringOperators>;
  enableMobileVerificationBypass?: InputMaybe<BooleanOperators>;
  enableTableOccupancy?: InputMaybe<BooleanOperators>;
  hours?: InputMaybe<NumberOperators>;
  id?: InputMaybe<IdOperators>;
  info?: InputMaybe<StringOperators>;
  invoicePrefixDateFormat?: InputMaybe<StringOperators>;
  locale?: InputMaybe<StringOperators>;
  loyaltyEnabled?: InputMaybe<BooleanOperators>;
  maintenanceContent?: InputMaybe<StringOperators>;
  mergeDining?: InputMaybe<BooleanOperators>;
  miniApps?: InputMaybe<StringOperators>;
  minutes?: InputMaybe<NumberOperators>;
  mobileVerificationBypassOTPCode?: InputMaybe<StringOperators>;
  months?: InputMaybe<NumberOperators>;
  orderFlagConfig?: InputMaybe<StringOperators>;
  outOfStockThreshold?: InputMaybe<NumberOperators>;
  posMustCreatePaidPayment?: InputMaybe<BooleanOperators>;
  posMustFulfillAllItemAtOnce?: InputMaybe<BooleanOperators>;
  posOrderListingDateType?: InputMaybe<StringOperators>;
  posSplitPaymentEnabled?: InputMaybe<BooleanOperators>;
  posTableOrderingEnabled?: InputMaybe<BooleanOperators>;
  pricesIncludeTax?: InputMaybe<BooleanOperators>;
  referralContent?: InputMaybe<StringOperators>;
  referralContentImage?: InputMaybe<StringOperators>;
  referralContentTitle?: InputMaybe<StringOperators>;
  referralShareContent?: InputMaybe<StringOperators>;
  referralShareUrl?: InputMaybe<StringOperators>;
  salesContributionConfig?: InputMaybe<StringOperators>;
  serviceCharge?: InputMaybe<NumberOperators>;
  serviceChargeEnabled?: InputMaybe<BooleanOperators>;
  setOrderAsInactiveAfterCheckout?: InputMaybe<BooleanOperators>;
  supportedPhoneCodes?: InputMaybe<StringListOperators>;
  taxRateRegistrationNumber?: InputMaybe<StringOperators>;
  tenantApiKey?: InputMaybe<StringOperators>;
  tenantCode?: InputMaybe<StringOperators>;
  tenantEndpoint?: InputMaybe<StringOperators>;
  token?: InputMaybe<StringOperators>;
  trackInventory?: InputMaybe<BooleanOperators>;
  underMaintenance?: InputMaybe<BooleanOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type ChannelInfo = {
  __typename?: 'ChannelInfo';
  confirmOutletOnResume?: Maybe<Scalars['Boolean']>;
  icon?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ChannelList = PaginatedList & {
  __typename?: 'ChannelList';
  items: Array<Channel>;
  totalItems: Scalars['Int'];
};

export type ChannelListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<ChannelFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<ChannelSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type ChannelOrderFlagConfig = {
  __typename?: 'ChannelOrderFlagConfig';
  allowGuestOrderInPOS: Scalars['Boolean'];
};

export type ChannelSortParameter = {
  applyTaxOnServiceCharge?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  companyDetail?: InputMaybe<SortOrder>;
  configuration?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  days?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  eghlConfig?: InputMaybe<SortOrder>;
  enableMobileVerificationBypass?: InputMaybe<SortOrder>;
  enableTableOccupancy?: InputMaybe<SortOrder>;
  hours?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  info?: InputMaybe<SortOrder>;
  invoicePrefixDateFormat?: InputMaybe<SortOrder>;
  locale?: InputMaybe<SortOrder>;
  loyaltyEnabled?: InputMaybe<SortOrder>;
  maintenanceContent?: InputMaybe<SortOrder>;
  mergeDining?: InputMaybe<SortOrder>;
  miniApps?: InputMaybe<SortOrder>;
  minutes?: InputMaybe<SortOrder>;
  mobileVerificationBypassOTPCode?: InputMaybe<SortOrder>;
  months?: InputMaybe<SortOrder>;
  orderFlagConfig?: InputMaybe<SortOrder>;
  outOfStockThreshold?: InputMaybe<SortOrder>;
  posMustCreatePaidPayment?: InputMaybe<SortOrder>;
  posMustFulfillAllItemAtOnce?: InputMaybe<SortOrder>;
  posOrderListingDateType?: InputMaybe<SortOrder>;
  posSplitPaymentEnabled?: InputMaybe<SortOrder>;
  posTableOrderingEnabled?: InputMaybe<SortOrder>;
  referralContent?: InputMaybe<SortOrder>;
  referralContentImage?: InputMaybe<SortOrder>;
  referralContentTitle?: InputMaybe<SortOrder>;
  referralShareContent?: InputMaybe<SortOrder>;
  referralShareUrl?: InputMaybe<SortOrder>;
  salesContributionConfig?: InputMaybe<SortOrder>;
  serviceCharge?: InputMaybe<SortOrder>;
  serviceChargeEnabled?: InputMaybe<SortOrder>;
  setOrderAsInactiveAfterCheckout?: InputMaybe<SortOrder>;
  taxRateRegistrationNumber?: InputMaybe<SortOrder>;
  tenantApiKey?: InputMaybe<SortOrder>;
  tenantCode?: InputMaybe<SortOrder>;
  tenantEndpoint?: InputMaybe<SortOrder>;
  token?: InputMaybe<SortOrder>;
  underMaintenance?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type Channels = {
  __typename?: 'Channels';
  chat?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['Boolean']>;
  in_app?: Maybe<Scalars['Boolean']>;
  push?: Maybe<Scalars['Boolean']>;
  sms?: Maybe<Scalars['Boolean']>;
};

export type ChannelsInput = {
  chat?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['Boolean']>;
  in_app?: InputMaybe<Scalars['Boolean']>;
  push?: InputMaybe<Scalars['Boolean']>;
  sms?: InputMaybe<Scalars['Boolean']>;
};

export type ClockEntrySyncInput = {
  administrator: Scalars['String'];
  createdAt: Scalars['Float'];
  deletedAt?: InputMaybe<Scalars['Float']>;
  entry: Scalars['Float'];
  exit?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  updatedAt: Scalars['Float'];
};

export type ClockEntrySynchronizerResponse = {
  __typename?: 'ClockEntrySynchronizerResponse';
  data?: Maybe<AdministratorClockEntry>;
  error?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export enum ClockEntryType {
  In = 'IN',
  Out = 'OUT'
}

export type CodeTypes = {
  __typename?: 'CodeTypes';
  handlerCodes: Array<Scalars['String']>;
  sourceHandlerCodes: Array<Scalars['String']>;
};

export type Collection = Node & {
  __typename?: 'Collection';
  assets: Array<Asset>;
  breadcrumbs: Array<CollectionBreadcrumb>;
  children?: Maybe<Array<Collection>>;
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<CollectionCustomFields>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  featuredAsset?: Maybe<Asset>;
  filters: Array<ConfigurableOperation>;
  gradient?: Maybe<CollectionGradient>;
  id: Scalars['ID'];
  inheritFilters: Scalars['Boolean'];
  isPrivate: Scalars['Boolean'];
  languageCode?: Maybe<LanguageCode>;
  name: Scalars['String'];
  parent?: Maybe<Collection>;
  parentId: Scalars['ID'];
  position: Scalars['Int'];
  productVariants: ProductVariantList;
  products: ProductList;
  slug: Scalars['String'];
  totalProductCount: Scalars['Int'];
  translations: Array<CollectionTranslation>;
  updatedAt: Scalars['DateTime'];
};


export type CollectionProductVariantsArgs = {
  options?: InputMaybe<ProductVariantListOptions>;
};


export type CollectionProductsArgs = {
  options?: InputMaybe<ProductListOptions>;
};

export type CollectionBreadcrumb = {
  __typename?: 'CollectionBreadcrumb';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type CollectionCustomFields = {
  __typename?: 'CollectionCustomFields';
  gradient?: Maybe<Scalars['String']>;
  renderType?: Maybe<Scalars['String']>;
};

export type CollectionFilterParameter = {
  _and?: InputMaybe<Array<CollectionFilterParameter>>;
  _or?: InputMaybe<Array<CollectionFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  description?: InputMaybe<StringOperators>;
  gradient?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  inheritFilters?: InputMaybe<BooleanOperators>;
  isPrivate?: InputMaybe<BooleanOperators>;
  languageCode?: InputMaybe<StringOperators>;
  name?: InputMaybe<StringOperators>;
  parentId?: InputMaybe<IdOperators>;
  position?: InputMaybe<NumberOperators>;
  renderType?: InputMaybe<StringOperators>;
  slug?: InputMaybe<StringOperators>;
  totalProductCount?: InputMaybe<NumberOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type CollectionGradient = {
  __typename?: 'CollectionGradient';
  gradient: Array<Scalars['String']>;
  type: GradientEnum;
};

export type CollectionList = PaginatedList & {
  __typename?: 'CollectionList';
  items: Array<Collection>;
  totalItems: Scalars['Int'];
};

export type CollectionListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<CollectionFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<CollectionSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
  topLevelOnly?: InputMaybe<Scalars['Boolean']>;
};

export enum CollectionRenderType {
  Default = 'DEFAULT',
  Grid = 'GRID'
}

/**
 * Which Collections are present in the products returned
 * by the search, and in what quantity.
 */
export type CollectionResult = {
  __typename?: 'CollectionResult';
  collection: Collection;
  count: Scalars['Int'];
};

export type CollectionSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  gradient?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  parentId?: InputMaybe<SortOrder>;
  position?: InputMaybe<SortOrder>;
  renderType?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  totalProductCount?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CollectionTranslation = {
  __typename?: 'CollectionTranslation';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  slug: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ConfigArg = {
  __typename?: 'ConfigArg';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type ConfigArgDefinition = {
  __typename?: 'ConfigArgDefinition';
  defaultValue?: Maybe<Scalars['JSON']>;
  description?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  list: Scalars['Boolean'];
  name: Scalars['String'];
  required: Scalars['Boolean'];
  type: Scalars['String'];
  ui?: Maybe<Scalars['JSON']>;
};

export type ConfigArgInput = {
  name: Scalars['String'];
  /** A JSON stringified representation of the actual value */
  value: Scalars['String'];
};

export type ConfigurableOperation = {
  __typename?: 'ConfigurableOperation';
  args: Array<ConfigArg>;
  code: Scalars['String'];
};

export type ConfigurableOperationDefinition = {
  __typename?: 'ConfigurableOperationDefinition';
  args: Array<ConfigArgDefinition>;
  code: Scalars['String'];
  description: Scalars['String'];
};

export type ConfigurableOperationInput = {
  arguments: Array<ConfigArgInput>;
  code: Scalars['String'];
};

export type Coordinate = {
  __typename?: 'Coordinate';
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export type CoordinateInput = {
  x: Scalars['Float'];
  y: Scalars['Float'];
};

/**
 * A Country of the world which your shop operates in.
 *
 * The `code` field is typically a 2-character ISO code such as "GB", "US", "DE" etc. This code is used in certain inputs such as
 * `UpdateAddressInput` and `CreateAddressInput` to specify the country.
 */
export type Country = Node & Region & {
  __typename?: 'Country';
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  parent?: Maybe<Region>;
  parentId?: Maybe<Scalars['ID']>;
  translations: Array<RegionTranslation>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type CountryFilterParameter = {
  _and?: InputMaybe<Array<CountryFilterParameter>>;
  _or?: InputMaybe<Array<CountryFilterParameter>>;
  code?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  enabled?: InputMaybe<BooleanOperators>;
  id?: InputMaybe<IdOperators>;
  languageCode?: InputMaybe<StringOperators>;
  name?: InputMaybe<StringOperators>;
  parentId?: InputMaybe<IdOperators>;
  type?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type CountryList = PaginatedList & {
  __typename?: 'CountryList';
  items: Array<Country>;
  totalItems: Scalars['Int'];
};

export type CountryListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<CountryFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<CountrySortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type CountrySortParameter = {
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  parentId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CountryTranslationInput = {
  customFields?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['ID']>;
  languageCode: LanguageCode;
  name?: InputMaybe<Scalars['String']>;
};

/** Returned if the provided coupon code is invalid */
export type CouponCodeExpiredError = ErrorResult & {
  __typename?: 'CouponCodeExpiredError';
  couponCode: Scalars['String'];
  errorCode: ErrorCode;
  message: Scalars['String'];
};

/** Returned if the provided coupon code is invalid */
export type CouponCodeInvalidError = ErrorResult & {
  __typename?: 'CouponCodeInvalidError';
  couponCode: Scalars['String'];
  errorCode: ErrorCode;
  message: Scalars['String'];
};

/** Returned if the provided coupon code is invalid */
export type CouponCodeLimitError = ErrorResult & {
  __typename?: 'CouponCodeLimitError';
  couponCode: Scalars['String'];
  errorCode: ErrorCode;
  limit: Scalars['Int'];
  message: Scalars['String'];
};

export type CreateAddressCustomFieldsInput = {
  customerAddressId?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  posId?: InputMaybe<Scalars['String']>;
};

/**
 * Input used to create an Address.
 *
 * The countryCode must correspond to a `code` property of a Country that has been defined in the
 * Vendure server. The `code` property is typically a 2-character ISO code such as "GB", "US", "DE" etc.
 * If an invalid code is passed, the mutation will fail.
 */
export type CreateAddressInput = {
  city?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  countryCode: Scalars['String'];
  customFields?: InputMaybe<CreateAddressCustomFieldsInput>;
  defaultBillingAddress?: InputMaybe<Scalars['Boolean']>;
  defaultShippingAddress?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  province?: InputMaybe<Scalars['String']>;
  streetLine1: Scalars['String'];
  streetLine2?: InputMaybe<Scalars['String']>;
};

export type CreateAdministratorCustomFieldsInput = {
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  sellerId?: InputMaybe<Scalars['ID']>;
  verificationNumber?: InputMaybe<Scalars['String']>;
};

export type CreateAdministratorInput = {
  customFields?: InputMaybe<CreateAdministratorCustomFieldsInput>;
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  roleIds: Array<Scalars['ID']>;
};

export type CreateAppVersionInput = {
  minimumBuildNumber?: InputMaybe<Scalars['Int']>;
  minimumVersionName?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  platform: Platform;
  url?: InputMaybe<Scalars['String']>;
  versionName: Scalars['String'];
  versionNumber: Scalars['Int'];
};

export type CreateAssetInput = {
  customFields?: InputMaybe<Scalars['JSON']>;
  file: Scalars['Upload'];
  tags?: InputMaybe<Array<Scalars['String']>>;
};

export type CreateAssetResult = Asset | MimeTypeError;

export type CreateBannerInput = {
  assetId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  displayType?: InputMaybe<BannerDisplayType>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  productIds?: InputMaybe<Array<Scalars['ID']>>;
  rewardId?: InputMaybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  type: BannerType;
  url?: InputMaybe<Scalars['String']>;
};

export type CreateBlastQueueInput = {
  notificationBlastId: Scalars['ID'];
  trigger: Scalars['DateTime'];
};

export type CreateBlastableUserInput = {
  notificationBlastId: Scalars['ID'];
  userId: Scalars['ID'];
  userType: BlastableRole;
};

export type CreateChannelCustomFieldsInput = {
  applyTaxOnServiceCharge?: InputMaybe<Scalars['Boolean']>;
  availableFulfillmentMethods?: InputMaybe<Array<Scalars['String']>>;
  companyDetail?: InputMaybe<Scalars['String']>;
  configuration?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['ID']>;
  days?: InputMaybe<Scalars['Int']>;
  eghlConfig?: InputMaybe<Scalars['String']>;
  enableMobileVerificationBypass?: InputMaybe<Scalars['Boolean']>;
  enableTableOccupancy?: InputMaybe<Scalars['Boolean']>;
  hours?: InputMaybe<Scalars['Int']>;
  info?: InputMaybe<Scalars['String']>;
  invoicePrefixDateFormat?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  loyaltyEnabled?: InputMaybe<Scalars['Boolean']>;
  maintenanceContent?: InputMaybe<Scalars['String']>;
  mergeDining?: InputMaybe<Scalars['Boolean']>;
  miniApps?: InputMaybe<Scalars['String']>;
  minutes?: InputMaybe<Scalars['Int']>;
  mobileVerificationBypassOTPCode?: InputMaybe<Scalars['String']>;
  months?: InputMaybe<Scalars['Int']>;
  orderFlagConfig?: InputMaybe<Scalars['String']>;
  posMustCreatePaidPayment?: InputMaybe<Scalars['Boolean']>;
  posMustFulfillAllItemAtOnce?: InputMaybe<Scalars['Boolean']>;
  posOrderListingDateType?: InputMaybe<Scalars['String']>;
  posSplitPaymentEnabled?: InputMaybe<Scalars['Boolean']>;
  posTableOrderingEnabled?: InputMaybe<Scalars['Boolean']>;
  referralContent?: InputMaybe<Scalars['String']>;
  referralContentImage?: InputMaybe<Scalars['String']>;
  referralContentTitle?: InputMaybe<Scalars['String']>;
  referralShareContent?: InputMaybe<Scalars['String']>;
  referralShareUrl?: InputMaybe<Scalars['String']>;
  salesContributionConfig?: InputMaybe<Scalars['String']>;
  serviceCharge?: InputMaybe<Scalars['Float']>;
  serviceChargeEnabled?: InputMaybe<Scalars['Boolean']>;
  setOrderAsInactiveAfterCheckout?: InputMaybe<Scalars['Boolean']>;
  supportedPhoneCodes?: InputMaybe<Array<Scalars['String']>>;
  taxRateRegistrationNumber?: InputMaybe<Scalars['String']>;
  tenantApiKey?: InputMaybe<Scalars['String']>;
  tenantCode?: InputMaybe<Scalars['String']>;
  tenantEndpoint?: InputMaybe<Scalars['String']>;
  underMaintenance?: InputMaybe<Scalars['Boolean']>;
  userGroupWithMobileVerificationBypassIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type CreateChannelInput = {
  availableCurrencyCodes?: InputMaybe<Array<CurrencyCode>>;
  availableLanguageCodes?: InputMaybe<Array<LanguageCode>>;
  code: Scalars['String'];
  customFields?: InputMaybe<CreateChannelCustomFieldsInput>;
  defaultCurrencyCode?: InputMaybe<CurrencyCode>;
  defaultLanguageCode: LanguageCode;
  defaultShippingZoneId: Scalars['ID'];
  defaultTaxZoneId: Scalars['ID'];
  outOfStockThreshold?: InputMaybe<Scalars['Int']>;
  pricesIncludeTax: Scalars['Boolean'];
  sellerId?: InputMaybe<Scalars['ID']>;
  token: Scalars['String'];
  trackInventory?: InputMaybe<Scalars['Boolean']>;
};

export type CreateChannelResult = Channel | LanguageNotAvailableError;

export type CreateCollectionCustomFieldsInput = {
  gradient?: InputMaybe<Scalars['String']>;
  renderType?: InputMaybe<Scalars['String']>;
};

export type CreateCollectionInput = {
  assetIds?: InputMaybe<Array<Scalars['ID']>>;
  customFields?: InputMaybe<CreateCollectionCustomFieldsInput>;
  featuredAssetId?: InputMaybe<Scalars['ID']>;
  filters: Array<ConfigurableOperationInput>;
  inheritFilters?: InputMaybe<Scalars['Boolean']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  parentId?: InputMaybe<Scalars['ID']>;
  translations: Array<CreateCollectionTranslationInput>;
};

export type CreateCollectionProductInput = {
  assetIds?: InputMaybe<Array<Scalars['ID']>>;
  customFields?: InputMaybe<Scalars['JSON']>;
  featuredAssetId?: InputMaybe<Scalars['ID']>;
  inheritFilters?: InputMaybe<Scalars['Boolean']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  parentId?: InputMaybe<Scalars['ID']>;
  productIds?: InputMaybe<Array<Scalars['ID']>>;
  translations: Array<CreateCollectionTranslationInput>;
};

export type CreateCollectionTranslationInput = {
  customFields?: InputMaybe<Scalars['JSON']>;
  description: Scalars['String'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type CreateCountryInput = {
  code: Scalars['String'];
  customFields?: InputMaybe<Scalars['JSON']>;
  enabled: Scalars['Boolean'];
  translations: Array<CountryTranslationInput>;
};

export type CreateCustomerCustomFieldsInput = {
  avatar?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  gender?: InputMaybe<Scalars['String']>;
  isDefaultGuest?: InputMaybe<Scalars['Boolean']>;
  medium?: InputMaybe<Scalars['String']>;
  posId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referralCodeApplied?: InputMaybe<Scalars['String']>;
  signupThrough?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type CreateCustomerGroupInput = {
  customFields?: InputMaybe<Scalars['JSON']>;
  customerIds?: InputMaybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
};

export type CreateCustomerInput = {
  customFields?: InputMaybe<CreateCustomerCustomFieldsInput>;
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type CreateCustomerResult = Customer | EmailAddressConflictError;

export type CreateDiscountPromotionInput = {
  amount: Scalars['Int'];
  assetIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  featuredAssetId?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  productVariantIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  startDate: Scalars['DateTime'];
  type: DiscountType;
};

export type CreateFacetInput = {
  code: Scalars['String'];
  customFields?: InputMaybe<Scalars['JSON']>;
  isPrivate: Scalars['Boolean'];
  translations: Array<FacetTranslationInput>;
  values?: InputMaybe<Array<CreateFacetValueWithFacetInput>>;
};

export type CreateFacetValueCustomFieldsInput = {
  maxModifier?: InputMaybe<Scalars['Int']>;
  minModifier?: InputMaybe<Scalars['Int']>;
  modifierSorting?: InputMaybe<Array<Scalars['String']>>;
};

export type CreateFacetValueInput = {
  code: Scalars['String'];
  customFields?: InputMaybe<CreateFacetValueCustomFieldsInput>;
  facetId: Scalars['ID'];
  translations: Array<FacetValueTranslationInput>;
};

export type CreateFacetValueWithFacetInput = {
  code: Scalars['String'];
  translations: Array<FacetValueTranslationInput>;
};

export type CreateFulfillmentChannelInput = {
  code: FulfillmentMethodCode;
  description?: InputMaybe<Scalars['String']>;
  isPrivate: Scalars['Boolean'];
  name: Scalars['String'];
};

/** Returned if an error is thrown in a FulfillmentHandler's createFulfillment method */
export type CreateFulfillmentError = ErrorResult & {
  __typename?: 'CreateFulfillmentError';
  errorCode: ErrorCode;
  fulfillmentHandlerError: Scalars['String'];
  message: Scalars['String'];
};

export type CreateFulfillmentsWithOutletInput = {
  operations: Array<OutletFulfillmentOperatingHoursInput>;
  status?: InputMaybe<OutletFulfillmentStatus>;
  type: OutletFulfillmentType;
};

export type CreateGiftOptionInput = {
  description?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  label: Scalars['JSON'];
  name: Scalars['String'];
  price: Scalars['Int'];
};

export type CreateGroupOptionInput = {
  code: Scalars['String'];
  translations: Array<ProductOptionGroupTranslationInput>;
};

export type CreateGuestCustomerInput = {
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  gender?: InputMaybe<CustomerGender>;
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type CreateMembershipTierBenefitInput = {
  description: Scalars['String'];
  imageUrl?: InputMaybe<Scalars['String']>;
  membershipTierId: Scalars['String'];
  title: Scalars['String'];
};

export type CreateMembershipTierInput = {
  imageUrl?: InputMaybe<Scalars['String']>;
  level: Scalars['Int'];
  minimumPoints: Scalars['Int'];
  name: Scalars['String'];
  pointMultiplier?: InputMaybe<Scalars['String']>;
};

export type CreateModifierGroupInput = {
  code: Scalars['String'];
  maximumSelection?: InputMaybe<Scalars['Int']>;
  minimumSelection?: InputMaybe<Scalars['Int']>;
  translations: Array<FacetValueTranslationInput>;
};

export type CreateMultipleRedemptionHistoryInput = {
  membershipUserIds: Array<Scalars['String']>;
  promoCodeId?: InputMaybe<Scalars['String']>;
  redeemerId: Scalars['String'];
  redeemerRole: RedeemerRole;
  rewardId?: InputMaybe<Scalars['String']>;
};

export type CreateNewAdministratorInput = {
  customFields?: InputMaybe<AdministratorCustomFieldsInput>;
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  roleIds: Array<Scalars['ID']>;
};

export type CreateNotificationBlastInput = {
  blastableChannels: Array<BlastableChannel>;
  link: Scalars['String'];
  message: Scalars['String'];
  name: Scalars['String'];
  receiverType: BlastableReceiverType;
  subject: Scalars['String'];
  type: BlastContentType;
};

export type CreateOneCollectionInput = {
  assetIds: Array<Scalars['ID']>;
  customFields?: InputMaybe<CustomFieldsInput>;
  facet: CreateFacetInput;
  featuredAssetId?: InputMaybe<Scalars['ID']>;
  filters?: InputMaybe<Array<ConfigurableOperationInput>>;
  inheritFilters?: InputMaybe<Scalars['Boolean']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  isRoot?: InputMaybe<Scalars['Boolean']>;
  position: Scalars['Int'];
  translations?: InputMaybe<Array<CreateCollectionTranslationInput>>;
};

export type CreateOrderLineCustomFieldsInput = {
  metadata?: InputMaybe<Scalars['String']>;
  orderModifierReference?: InputMaybe<Scalars['String']>;
  posCustomAmountDiscount?: InputMaybe<Scalars['Int']>;
  posCustomRateDiscount?: InputMaybe<Scalars['Int']>;
  posId?: InputMaybe<Scalars['String']>;
  promoCodeIds?: InputMaybe<Array<Scalars['String']>>;
  rewardIds?: InputMaybe<Array<Scalars['String']>>;
  snapshot?: InputMaybe<Scalars['String']>;
  specialInstructions?: InputMaybe<Scalars['String']>;
};

export type CreateOrderLineInput = {
  addons?: InputMaybe<Array<OrderLineAddonInput>>;
  customFields?: InputMaybe<CreateOrderLineCustomFieldsInput>;
  item: AddItemToOrder;
  specialInstructions?: InputMaybe<Scalars['String']>;
};

export type CreateOutletAddressInput = {
  addressLineOne: Scalars['String'];
  addressLineTwo?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  countryCode: Scalars['String'];
  postalCode: Scalars['String'];
  province: Scalars['String'];
};

export type CreateOutletDeviceInput = {
  deviceToken: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export type CreateOutletFulfillmentInput = {
  capacity?: InputMaybe<Scalars['Int']>;
  operations: Array<OutletFulfillmentOperatingHoursInput>;
  outletId: Scalars['ID'];
  status?: InputMaybe<OutletFulfillmentStatus>;
  type: OutletFulfillmentType;
};

export type CreateOutletInput = {
  address: CreateOutletAddressInput;
  contactNumber: Scalars['String'];
  customFields?: InputMaybe<CustomOutletFieldsInput>;
  managerId: Scalars['String'];
  name: Scalars['String'];
  onlineOrdering?: InputMaybe<Scalars['Boolean']>;
  outletFulfillments: Array<InputMaybe<CreateFulfillmentsWithOutletInput>>;
  status?: InputMaybe<OutletStatus>;
};

export type CreateOutletSettingInput = {
  lastCallTime: Scalars['Int'];
  outletId?: InputMaybe<Scalars['ID']>;
  preorderBuffer?: InputMaybe<Scalars['Int']>;
  preparationTime: Scalars['Int'];
  timeSlotInterval: Scalars['Int'];
  type: OutletFulfillmentType;
};

export type CreateOutletTableAreaInput = {
  name: Scalars['String'];
  outletId: Scalars['ID'];
  prefix: Scalars['String'];
};

export type CreateOutletTableInput = {
  code: Scalars['String'];
  isAvailable?: InputMaybe<Scalars['Boolean']>;
  outletTableAreaId: Scalars['ID'];
  remarks?: InputMaybe<Scalars['String']>;
  seatCapacity: Scalars['Int'];
};

export type CreatePaymentMethodCustomFieldsInput = {
  exactAmountOnly?: InputMaybe<Scalars['Boolean']>;
  paymentChannel?: InputMaybe<Scalars['String']>;
  trackReference?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
};

export type CreatePaymentMethodInput = {
  checker?: InputMaybe<ConfigurableOperationInput>;
  code: Scalars['String'];
  customFields?: InputMaybe<CreatePaymentMethodCustomFieldsInput>;
  enabled: Scalars['Boolean'];
  handler: ConfigurableOperationInput;
  translations: Array<PaymentMethodTranslationInput>;
};

export type CreatePrinterInput = {
  cutAfterPrint: Scalars['Boolean'];
  ip: Scalars['String'];
  name: Scalars['String'];
  openCashboxAfterPrint: Scalars['Boolean'];
  paperWidth: Scalars['Int'];
  port: Scalars['Int'];
  timeout: Scalars['Int'];
};

export type CreatePrinterTagInput = {
  enabled: Scalars['Boolean'];
  name: Scalars['String'];
};

export type CreatePrinterTemplateInput = {
  handler: Scalars['String'];
  name: Scalars['String'];
  template: Scalars['String'];
  type: Scalars['String'];
};

export type CreateProductCustomFieldsInput = {
  fulfillChannelsIds?: InputMaybe<Array<Scalars['ID']>>;
  modifierGroupSorting?: InputMaybe<Array<Scalars['String']>>;
  posId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  warrantyConfig?: InputMaybe<Scalars['String']>;
};

export type CreateProductInput = {
  assetIds?: InputMaybe<Array<Scalars['ID']>>;
  customFields?: InputMaybe<CreateProductCustomFieldsInput>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  facetValueIds?: InputMaybe<Array<Scalars['ID']>>;
  featuredAssetId?: InputMaybe<Scalars['ID']>;
  translations: Array<ProductTranslationInput>;
};

export type CreateProductOptionGroupInput = {
  code: Scalars['String'];
  customFields?: InputMaybe<Scalars['JSON']>;
  options: Array<CreateGroupOptionInput>;
  translations: Array<ProductOptionGroupTranslationInput>;
};

export type CreateProductOptionInput = {
  code: Scalars['String'];
  customFields?: InputMaybe<Scalars['JSON']>;
  productOptionGroupId: Scalars['ID'];
  translations: Array<ProductOptionGroupTranslationInput>;
};

export type CreateProductVariantCustomFieldsInput = {
  omitFromOutletId?: InputMaybe<Array<Scalars['String']>>;
  outletPricing?: InputMaybe<Array<Scalars['String']>>;
  posId?: InputMaybe<Scalars['String']>;
};

export type CreateProductVariantInput = {
  assetIds?: InputMaybe<Array<Scalars['ID']>>;
  customFields?: InputMaybe<CreateProductVariantCustomFieldsInput>;
  facetValueIds?: InputMaybe<Array<Scalars['ID']>>;
  featuredAssetId?: InputMaybe<Scalars['ID']>;
  optionIds?: InputMaybe<Array<Scalars['ID']>>;
  outOfStockThreshold?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Money']>;
  productId: Scalars['ID'];
  sku: Scalars['String'];
  stockLevels?: InputMaybe<Array<StockLevelInput>>;
  stockOnHand?: InputMaybe<Scalars['Int']>;
  taxCategoryId?: InputMaybe<Scalars['ID']>;
  trackInventory?: InputMaybe<GlobalFlag>;
  translations: Array<ProductVariantTranslationInput>;
  useGlobalOutOfStockThreshold?: InputMaybe<Scalars['Boolean']>;
};

export type CreateProductVariantOptionInput = {
  code: Scalars['String'];
  optionGroupId: Scalars['ID'];
  translations: Array<ProductOptionTranslationInput>;
};

export type CreatePromoCodeInput = {
  cappedDiscount?: InputMaybe<Scalars['Float']>;
  code: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  endAt: Scalars['String'];
  entitledTierIds?: InputMaybe<Array<Scalars['String']>>;
  imageUrls?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  minimumSpendAmount?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  pointCost: Scalars['Float'];
  quantity?: InputMaybe<Scalars['Int']>;
  redeemCriteria: Scalars['String'];
  redeemPerUserLimit?: InputMaybe<Scalars['Int']>;
  startAt: Scalars['String'];
  termCondition: Scalars['String'];
  type: PromoCodeType;
  value?: InputMaybe<Scalars['Float']>;
};

export type CreatePromotionInput = {
  actions: Array<ConfigurableOperationInput>;
  conditions: Array<ConfigurableOperationInput>;
  couponCode?: InputMaybe<Scalars['String']>;
  customFields?: InputMaybe<Scalars['JSON']>;
  enabled: Scalars['Boolean'];
  endsAt?: InputMaybe<Scalars['DateTime']>;
  perCustomerUsageLimit?: InputMaybe<Scalars['Int']>;
  startsAt?: InputMaybe<Scalars['DateTime']>;
  translations: Array<PromotionTranslationInput>;
  usageLimit?: InputMaybe<Scalars['Int']>;
};

export type CreatePromotionResult = MissingConditionsError | Promotion;

export type CreateProvinceInput = {
  code: Scalars['String'];
  customFields?: InputMaybe<Scalars['JSON']>;
  enabled: Scalars['Boolean'];
  translations: Array<ProvinceTranslationInput>;
};

export type CreateRedemptionHistoryInput = {
  membershipUserId: Scalars['String'];
  promoCodeId?: InputMaybe<Scalars['String']>;
  redeemerId: Scalars['String'];
  redeemerRole: RedeemerRole;
  rewardId?: InputMaybe<Scalars['String']>;
};

export type CreateReservationInput = {
  adultPax?: InputMaybe<Scalars['Int']>;
  customerId: Scalars['ID'];
  diningDate: Scalars['DateTime'];
  guestName: Scalars['String'];
  kidPax?: InputMaybe<Scalars['Int']>;
  occasion: Array<InputMaybe<OccasionType>>;
  phoneNumber: Scalars['String'];
  remark?: InputMaybe<Scalars['String']>;
};

export type CreateRewardInput = {
  description?: InputMaybe<Scalars['String']>;
  endAt: Scalars['String'];
  entitledTierIds?: InputMaybe<Array<Scalars['String']>>;
  imageUrls?: InputMaybe<Array<Scalars['String']>>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pointCost: Scalars['Float'];
  productVariantId: Scalars['String'];
  quantity: Scalars['Int'];
  redeemCriteria: Scalars['String'];
  redeemPerUserLimit?: InputMaybe<Scalars['Int']>;
  startAt: Scalars['String'];
  termCondition: Scalars['String'];
  type: RewardType;
};

export type CreateRoleInput = {
  channelIds?: InputMaybe<Array<Scalars['ID']>>;
  code: Scalars['String'];
  description: Scalars['String'];
  permissions: Array<Permission>;
};

export type CreateSellerCustomFieldsInput = {
  description?: InputMaybe<Scalars['String']>;
  favicon?: InputMaybe<Scalars['String']>;
  footerConfig?: InputMaybe<Scalars['String']>;
  introductionConfig?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoWidthAndHeight?: InputMaybe<Scalars['String']>;
  metabaseDisplayIndex?: InputMaybe<Scalars['String']>;
  resources?: InputMaybe<Scalars['String']>;
};

export type CreateSellerInput = {
  customFields?: InputMaybe<CreateSellerCustomFieldsInput>;
  name: Scalars['String'];
};

export type CreateShippingMethodCustomFieldsInput = {
  applyRounding?: InputMaybe<Scalars['Boolean']>;
  fulfillmentChannelId?: InputMaybe<Scalars['ID']>;
  includeServiceCharge?: InputMaybe<Scalars['Boolean']>;
  posAutoFulfillAllItemsOnPaid?: InputMaybe<Scalars['Boolean']>;
  tierRangeIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type CreateShippingMethodInput = {
  calculator: ConfigurableOperationInput;
  checker: ConfigurableOperationInput;
  code: Scalars['String'];
  customFields?: InputMaybe<CreateShippingMethodCustomFieldsInput>;
  fulfillmentHandler: Scalars['String'];
  translations: Array<ShippingMethodTranslationInput>;
};

export type CreateShippingTierInput = {
  maxQuantity: Scalars['Int'];
  minQuantity: Scalars['Int'];
  shippingMethodId: Scalars['ID'];
  value: Scalars['Float'];
};

export type CreateStockLocationInput = {
  customFields?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateTableOrderQrInput = {
  tableAreaPrefix: Scalars['String'];
  tableCode: Scalars['String'];
  tableId: Scalars['String'];
};

export type CreateTableOrderQrResult = {
  __typename?: 'CreateTableOrderQrResult';
  expiresOn: Scalars['DateTime'];
  link: Scalars['String'];
};

export type CreateTagInput = {
  value: Scalars['String'];
};

export type CreateTaxCategoryInput = {
  customFields?: InputMaybe<Scalars['JSON']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type CreateTaxRateInput = {
  categoryId: Scalars['ID'];
  customFields?: InputMaybe<Scalars['JSON']>;
  customerGroupId?: InputMaybe<Scalars['ID']>;
  enabled: Scalars['Boolean'];
  name: Scalars['String'];
  value: Scalars['Float'];
  zoneId: Scalars['ID'];
};

export type CreateZoneInput = {
  customFields?: InputMaybe<Scalars['JSON']>;
  memberIds?: InputMaybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
};

export type CreatedRedemption = {
  __typename?: 'CreatedRedemption';
  createdRedemption?: Maybe<Array<RedemptionHistory>>;
};

/**
 * @description
 * ISO 4217 currency code
 *
 * @docsCategory common
 */
export enum CurrencyCode {
  /** United Arab Emirates dirham */
  Aed = 'AED',
  /** Afghan afghani */
  Afn = 'AFN',
  /** Albanian lek */
  All = 'ALL',
  /** Armenian dram */
  Amd = 'AMD',
  /** Netherlands Antillean guilder */
  Ang = 'ANG',
  /** Angolan kwanza */
  Aoa = 'AOA',
  /** Argentine peso */
  Ars = 'ARS',
  /** Australian dollar */
  Aud = 'AUD',
  /** Aruban florin */
  Awg = 'AWG',
  /** Azerbaijani manat */
  Azn = 'AZN',
  /** Bosnia and Herzegovina convertible mark */
  Bam = 'BAM',
  /** Barbados dollar */
  Bbd = 'BBD',
  /** Bangladeshi taka */
  Bdt = 'BDT',
  /** Bulgarian lev */
  Bgn = 'BGN',
  /** Bahraini dinar */
  Bhd = 'BHD',
  /** Burundian franc */
  Bif = 'BIF',
  /** Bermudian dollar */
  Bmd = 'BMD',
  /** Brunei dollar */
  Bnd = 'BND',
  /** Boliviano */
  Bob = 'BOB',
  /** Brazilian real */
  Brl = 'BRL',
  /** Bahamian dollar */
  Bsd = 'BSD',
  /** Bhutanese ngultrum */
  Btn = 'BTN',
  /** Botswana pula */
  Bwp = 'BWP',
  /** Belarusian ruble */
  Byn = 'BYN',
  /** Belize dollar */
  Bzd = 'BZD',
  /** Canadian dollar */
  Cad = 'CAD',
  /** Congolese franc */
  Cdf = 'CDF',
  /** Swiss franc */
  Chf = 'CHF',
  /** Chilean peso */
  Clp = 'CLP',
  /** Renminbi (Chinese) yuan */
  Cny = 'CNY',
  /** Colombian peso */
  Cop = 'COP',
  /** Costa Rican colon */
  Crc = 'CRC',
  /** Cuban convertible peso */
  Cuc = 'CUC',
  /** Cuban peso */
  Cup = 'CUP',
  /** Cape Verde escudo */
  Cve = 'CVE',
  /** Czech koruna */
  Czk = 'CZK',
  /** Djiboutian franc */
  Djf = 'DJF',
  /** Danish krone */
  Dkk = 'DKK',
  /** Dominican peso */
  Dop = 'DOP',
  /** Algerian dinar */
  Dzd = 'DZD',
  /** Egyptian pound */
  Egp = 'EGP',
  /** Eritrean nakfa */
  Ern = 'ERN',
  /** Ethiopian birr */
  Etb = 'ETB',
  /** Euro */
  Eur = 'EUR',
  /** Fiji dollar */
  Fjd = 'FJD',
  /** Falkland Islands pound */
  Fkp = 'FKP',
  /** Pound sterling */
  Gbp = 'GBP',
  /** Georgian lari */
  Gel = 'GEL',
  /** Ghanaian cedi */
  Ghs = 'GHS',
  /** Gibraltar pound */
  Gip = 'GIP',
  /** Gambian dalasi */
  Gmd = 'GMD',
  /** Guinean franc */
  Gnf = 'GNF',
  /** Guatemalan quetzal */
  Gtq = 'GTQ',
  /** Guyanese dollar */
  Gyd = 'GYD',
  /** Hong Kong dollar */
  Hkd = 'HKD',
  /** Honduran lempira */
  Hnl = 'HNL',
  /** Croatian kuna */
  Hrk = 'HRK',
  /** Haitian gourde */
  Htg = 'HTG',
  /** Hungarian forint */
  Huf = 'HUF',
  /** Indonesian rupiah */
  Idr = 'IDR',
  /** Israeli new shekel */
  Ils = 'ILS',
  /** Indian rupee */
  Inr = 'INR',
  /** Iraqi dinar */
  Iqd = 'IQD',
  /** Iranian rial */
  Irr = 'IRR',
  /** Icelandic króna */
  Isk = 'ISK',
  /** Jamaican dollar */
  Jmd = 'JMD',
  /** Jordanian dinar */
  Jod = 'JOD',
  /** Japanese yen */
  Jpy = 'JPY',
  /** Kenyan shilling */
  Kes = 'KES',
  /** Kyrgyzstani som */
  Kgs = 'KGS',
  /** Cambodian riel */
  Khr = 'KHR',
  /** Comoro franc */
  Kmf = 'KMF',
  /** North Korean won */
  Kpw = 'KPW',
  /** South Korean won */
  Krw = 'KRW',
  /** Kuwaiti dinar */
  Kwd = 'KWD',
  /** Cayman Islands dollar */
  Kyd = 'KYD',
  /** Kazakhstani tenge */
  Kzt = 'KZT',
  /** Lao kip */
  Lak = 'LAK',
  /** Lebanese pound */
  Lbp = 'LBP',
  /** Sri Lankan rupee */
  Lkr = 'LKR',
  /** Liberian dollar */
  Lrd = 'LRD',
  /** Lesotho loti */
  Lsl = 'LSL',
  /** Libyan dinar */
  Lyd = 'LYD',
  /** Moroccan dirham */
  Mad = 'MAD',
  /** Moldovan leu */
  Mdl = 'MDL',
  /** Malagasy ariary */
  Mga = 'MGA',
  /** Macedonian denar */
  Mkd = 'MKD',
  /** Myanmar kyat */
  Mmk = 'MMK',
  /** Mongolian tögrög */
  Mnt = 'MNT',
  /** Macanese pataca */
  Mop = 'MOP',
  /** Mauritanian ouguiya */
  Mru = 'MRU',
  /** Mauritian rupee */
  Mur = 'MUR',
  /** Maldivian rufiyaa */
  Mvr = 'MVR',
  /** Malawian kwacha */
  Mwk = 'MWK',
  /** Mexican peso */
  Mxn = 'MXN',
  /** Malaysian ringgit */
  Myr = 'MYR',
  /** Mozambican metical */
  Mzn = 'MZN',
  /** Namibian dollar */
  Nad = 'NAD',
  /** Nigerian naira */
  Ngn = 'NGN',
  /** Nicaraguan córdoba */
  Nio = 'NIO',
  /** Norwegian krone */
  Nok = 'NOK',
  /** Nepalese rupee */
  Npr = 'NPR',
  /** New Zealand dollar */
  Nzd = 'NZD',
  /** Omani rial */
  Omr = 'OMR',
  /** Panamanian balboa */
  Pab = 'PAB',
  /** Peruvian sol */
  Pen = 'PEN',
  /** Papua New Guinean kina */
  Pgk = 'PGK',
  /** Philippine peso */
  Php = 'PHP',
  /** Pakistani rupee */
  Pkr = 'PKR',
  /** Polish złoty */
  Pln = 'PLN',
  /** Paraguayan guaraní */
  Pyg = 'PYG',
  /** Qatari riyal */
  Qar = 'QAR',
  /** Romanian leu */
  Ron = 'RON',
  /** Serbian dinar */
  Rsd = 'RSD',
  /** Russian ruble */
  Rub = 'RUB',
  /** Rwandan franc */
  Rwf = 'RWF',
  /** Saudi riyal */
  Sar = 'SAR',
  /** Solomon Islands dollar */
  Sbd = 'SBD',
  /** Seychelles rupee */
  Scr = 'SCR',
  /** Sudanese pound */
  Sdg = 'SDG',
  /** Swedish krona/kronor */
  Sek = 'SEK',
  /** Singapore dollar */
  Sgd = 'SGD',
  /** Saint Helena pound */
  Shp = 'SHP',
  /** Sierra Leonean leone */
  Sll = 'SLL',
  /** Somali shilling */
  Sos = 'SOS',
  /** Surinamese dollar */
  Srd = 'SRD',
  /** South Sudanese pound */
  Ssp = 'SSP',
  /** São Tomé and Príncipe dobra */
  Stn = 'STN',
  /** Salvadoran colón */
  Svc = 'SVC',
  /** Syrian pound */
  Syp = 'SYP',
  /** Swazi lilangeni */
  Szl = 'SZL',
  /** Thai baht */
  Thb = 'THB',
  /** Tajikistani somoni */
  Tjs = 'TJS',
  /** Turkmenistan manat */
  Tmt = 'TMT',
  /** Tunisian dinar */
  Tnd = 'TND',
  /** Tongan paʻanga */
  Top = 'TOP',
  /** Turkish lira */
  Try = 'TRY',
  /** Trinidad and Tobago dollar */
  Ttd = 'TTD',
  /** New Taiwan dollar */
  Twd = 'TWD',
  /** Tanzanian shilling */
  Tzs = 'TZS',
  /** Ukrainian hryvnia */
  Uah = 'UAH',
  /** Ugandan shilling */
  Ugx = 'UGX',
  /** United States dollar */
  Usd = 'USD',
  /** Uruguayan peso */
  Uyu = 'UYU',
  /** Uzbekistan som */
  Uzs = 'UZS',
  /** Venezuelan bolívar soberano */
  Ves = 'VES',
  /** Vietnamese đồng */
  Vnd = 'VND',
  /** Vanuatu vatu */
  Vuv = 'VUV',
  /** Samoan tala */
  Wst = 'WST',
  /** CFA franc BEAC */
  Xaf = 'XAF',
  /** East Caribbean dollar */
  Xcd = 'XCD',
  /** CFA franc BCEAO */
  Xof = 'XOF',
  /** CFP franc (franc Pacifique) */
  Xpf = 'XPF',
  /** Yemeni rial */
  Yer = 'YER',
  /** South African rand */
  Zar = 'ZAR',
  /** Zambian kwacha */
  Zmw = 'ZMW',
  /** Zimbabwean dollar */
  Zwl = 'ZWL'
}

export type CurrentUser = {
  __typename?: 'CurrentUser';
  channels: Array<CurrentUserChannel>;
  id: Scalars['ID'];
  identifier: Scalars['String'];
};

export type CurrentUserChannel = {
  __typename?: 'CurrentUserChannel';
  code: Scalars['String'];
  id: Scalars['ID'];
  permissions: Array<Permission>;
  token: Scalars['String'];
};

export type CustomField = {
  description?: Maybe<Array<LocalizedString>>;
  internal?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Array<LocalizedString>>;
  list: Scalars['Boolean'];
  name: Scalars['String'];
  nullable?: Maybe<Scalars['Boolean']>;
  readonly?: Maybe<Scalars['Boolean']>;
  requiresPermission?: Maybe<Array<Permission>>;
  type: Scalars['String'];
  ui?: Maybe<Scalars['JSON']>;
};

export type CustomFieldConfig = BooleanCustomFieldConfig | DateTimeCustomFieldConfig | FloatCustomFieldConfig | IntCustomFieldConfig | LocaleStringCustomFieldConfig | LocaleTextCustomFieldConfig | RelationCustomFieldConfig | StringCustomFieldConfig | TextCustomFieldConfig;

/**
 * This type is deprecated in v2.2 in favor of the EntityCustomFields type,
 * which allows custom fields to be defined on user-supplies entities.
 */
export type CustomFields = {
  __typename?: 'CustomFields';
  Address: Array<CustomFieldConfig>;
  Administrator: Array<CustomFieldConfig>;
  Asset: Array<CustomFieldConfig>;
  Channel: Array<CustomFieldConfig>;
  Collection: Array<CustomFieldConfig>;
  Customer: Array<CustomFieldConfig>;
  CustomerGroup: Array<CustomFieldConfig>;
  Facet: Array<CustomFieldConfig>;
  FacetValue: Array<CustomFieldConfig>;
  Fulfillment: Array<CustomFieldConfig>;
  GlobalSettings: Array<CustomFieldConfig>;
  Order: Array<CustomFieldConfig>;
  OrderLine: Array<CustomFieldConfig>;
  OutletEntity: Array<CustomFieldConfig>;
  PaymentMethod: Array<CustomFieldConfig>;
  Product: Array<CustomFieldConfig>;
  ProductOption: Array<CustomFieldConfig>;
  ProductOptionGroup: Array<CustomFieldConfig>;
  ProductVariant: Array<CustomFieldConfig>;
  ProductVariantPrice: Array<CustomFieldConfig>;
  Promotion: Array<CustomFieldConfig>;
  Region: Array<CustomFieldConfig>;
  Seller: Array<CustomFieldConfig>;
  ShippingMethod: Array<CustomFieldConfig>;
  StockLocation: Array<CustomFieldConfig>;
  StockTransferEntity: Array<CustomFieldConfig>;
  StockTransferLineEntity: Array<CustomFieldConfig>;
  TaxCategory: Array<CustomFieldConfig>;
  TaxRate: Array<CustomFieldConfig>;
  User: Array<CustomFieldConfig>;
  Zone: Array<CustomFieldConfig>;
};

export type CustomFieldsInput = {
  gradient?: InputMaybe<GradientInput>;
};

export type CustomOutletFieldsInput = {
  stockLocationsIds?: InputMaybe<Array<Scalars['ID']>>;
};

export enum CustomPosPromotionNames {
  CustomOrderDiscount = 'CUSTOM_ORDER_DISCOUNT',
  CustomOrderLineDiscount = 'CUSTOM_ORDER_LINE_DISCOUNT'
}

export type Customer = Node & {
  __typename?: 'Customer';
  addresses?: Maybe<Array<Address>>;
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<CustomerCustomFields>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  groups: Array<CustomerGroup>;
  history: HistoryEntryList;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  membership?: Maybe<Membership>;
  orders: OrderList;
  phoneNumber?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
};


export type CustomerHistoryArgs = {
  options?: InputMaybe<HistoryEntryListOptions>;
};


export type CustomerOrdersArgs = {
  options?: InputMaybe<OrderListOptions>;
};

export type CustomerCard = Node & {
  __typename?: 'CustomerCard';
  cardNumber: Scalars['String'];
  cardType: CustomerCardType;
  createdAt: Scalars['DateTime'];
  customer: Customer;
  customerId: Scalars['ID'];
  expiry: Scalars['String'];
  id: Scalars['ID'];
  identifier: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type CustomerCardFilterParameter = {
  _and?: InputMaybe<Array<CustomerCardFilterParameter>>;
  _or?: InputMaybe<Array<CustomerCardFilterParameter>>;
  cardNumber?: InputMaybe<StringOperators>;
  cardType?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  customerId?: InputMaybe<IdOperators>;
  expiry?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  identifier?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type CustomerCardList = PaginatedList & {
  __typename?: 'CustomerCardList';
  items: Array<CustomerCard>;
  totalItems: Scalars['Int'];
};

export type CustomerCardListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<CustomerCardFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<CustomerCardSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type CustomerCardSortParameter = {
  cardNumber?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  expiry?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  identifier?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum CustomerCardType {
  Amex = 'AMEX',
  Diners = 'DINERS',
  Jcb = 'JCB',
  Mastercard = 'MASTERCARD',
  Visa = 'VISA'
}

export type CustomerCustomFields = {
  __typename?: 'CustomerCustomFields';
  avatar?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  isDefaultGuest?: Maybe<Scalars['Boolean']>;
  medium?: Maybe<Scalars['String']>;
  posId?: Maybe<Scalars['String']>;
  referralCode?: Maybe<Scalars['String']>;
  referralCodeApplied?: Maybe<Scalars['String']>;
  signupThrough?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type CustomerFilterParameter = {
  _and?: InputMaybe<Array<CustomerFilterParameter>>;
  _or?: InputMaybe<Array<CustomerFilterParameter>>;
  avatar?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  dateOfBirth?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  emailAddress?: InputMaybe<StringOperators>;
  firstName?: InputMaybe<StringOperators>;
  gender?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  isDefaultGuest?: InputMaybe<BooleanOperators>;
  lastName?: InputMaybe<StringOperators>;
  medium?: InputMaybe<StringOperators>;
  phoneNumber?: InputMaybe<StringOperators>;
  posId?: InputMaybe<StringOperators>;
  postalCode?: InputMaybe<StringOperators>;
  referralCode?: InputMaybe<StringOperators>;
  referralCodeApplied?: InputMaybe<StringOperators>;
  signupThrough?: InputMaybe<StringOperators>;
  status?: InputMaybe<StringOperators>;
  title?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export enum CustomerGender {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type CustomerGroup = Node & {
  __typename?: 'CustomerGroup';
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<Scalars['JSON']>;
  customers: CustomerList;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type CustomerGroupCustomersArgs = {
  options?: InputMaybe<CustomerListOptions>;
};

export type CustomerGroupFilterParameter = {
  _and?: InputMaybe<Array<CustomerGroupFilterParameter>>;
  _or?: InputMaybe<Array<CustomerGroupFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  name?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type CustomerGroupList = PaginatedList & {
  __typename?: 'CustomerGroupList';
  items: Array<CustomerGroup>;
  totalItems: Scalars['Int'];
};

export type CustomerGroupListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<CustomerGroupFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<CustomerGroupSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type CustomerGroupSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CustomerInputPushRow = {
  assumedMasterState?: InputMaybe<CustomerSyncInput>;
  newDocumentState: CustomerSyncInput;
};

export type CustomerList = PaginatedList & {
  __typename?: 'CustomerList';
  items: Array<Customer>;
  totalItems: Scalars['Int'];
};

export type CustomerListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<CustomerFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<CustomerSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type CustomerSnapshot = {
  __typename?: 'CustomerSnapshot';
  contact: Scalars['String'];
  emailAddress: Scalars['String'];
  name: Scalars['String'];
};

export type CustomerSnapshotInput = {
  contact: Scalars['String'];
  emailAddress: Scalars['String'];
  name: Scalars['String'];
};

export type CustomerSortParameter = {
  avatar?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dateOfBirth?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  emailAddress?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  gender?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDefaultGuest?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  medium?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  posId?: InputMaybe<SortOrder>;
  referralCode?: InputMaybe<SortOrder>;
  referralCodeApplied?: InputMaybe<SortOrder>;
  signupThrough?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum CustomerStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type CustomerSyncInput = {
  addresses?: InputMaybe<Array<Scalars['String']>>;
  createdAt: Scalars['Float'];
  deletedAt?: InputMaybe<Scalars['Float']>;
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  isDefaultGuest: Scalars['Boolean'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  signupThrough?: InputMaybe<Scalars['String']>;
  updatedAt: Scalars['Float'];
  user?: InputMaybe<Scalars['String']>;
};

export type CustomerSynchronizerResponse = {
  __typename?: 'CustomerSynchronizerResponse';
  data?: Maybe<Customer>;
  error?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Operators for filtering on a list of Date fields */
export type DateListOperators = {
  inList: Scalars['DateTime'];
};

/** Operators for filtering on a DateTime field */
export type DateOperators = {
  after?: InputMaybe<Scalars['DateTime']>;
  before?: InputMaybe<Scalars['DateTime']>;
  between?: InputMaybe<DateRange>;
  eq?: InputMaybe<Scalars['DateTime']>;
  isNull?: InputMaybe<Scalars['Boolean']>;
};

export type DateRange = {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

/**
 * Expects the same validation formats as the `<input type="datetime-local">` HTML element.
 * See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/datetime-local#Additional_attributes
 */
export type DateTimeCustomFieldConfig = CustomField & {
  __typename?: 'DateTimeCustomFieldConfig';
  description?: Maybe<Array<LocalizedString>>;
  internal?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Array<LocalizedString>>;
  list: Scalars['Boolean'];
  max?: Maybe<Scalars['String']>;
  min?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nullable?: Maybe<Scalars['Boolean']>;
  readonly?: Maybe<Scalars['Boolean']>;
  requiresPermission?: Maybe<Array<Permission>>;
  step?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  ui?: Maybe<Scalars['JSON']>;
};

export enum DayType {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export enum DefaultAddress {
  Billing = 'BILLING',
  Shipping = 'SHIPPING'
}

export type DeleteAppVersionInput = {
  id: Scalars['String'];
};

export type DeleteAssetInput = {
  assetId: Scalars['ID'];
  deleteFromAllChannels?: InputMaybe<Scalars['Boolean']>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type DeleteAssetsInput = {
  assetIds: Array<Scalars['ID']>;
  deleteFromAllChannels?: InputMaybe<Scalars['Boolean']>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type DeleteProductReviewInput = {
  id: Scalars['ID'];
};

export type DeleteStockLocationInput = {
  id: Scalars['ID'];
  transferToLocationId?: InputMaybe<Scalars['ID']>;
};

export type DeletionResponse = {
  __typename?: 'DeletionResponse';
  message?: Maybe<Scalars['String']>;
  result: DeletionResult;
};

export enum DeletionResult {
  /** The entity was successfully deleted */
  Deleted = 'DELETED',
  /** Deletion did not take place, reason given in message */
  NotDeleted = 'NOT_DELETED'
}

export type DeviceSettingInputPushRow = {
  assumedMasterState?: InputMaybe<DeviceSettingSyncInput>;
  newDocumentState: DeviceSettingSyncInput;
};

export type DeviceSettingSyncInput = {
  createdAt: Scalars['Float'];
  deletedAt?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  invoiceCodePrefix?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  orderCodePrefix?: InputMaybe<Scalars['String']>;
  outletDeviceId: Scalars['ID'];
  preferredAreas?: InputMaybe<Array<Scalars['String']>>;
  printerConfigurations?: InputMaybe<Array<PrinterConfigurationInput>>;
  printerTemplateActionConfigurations?: InputMaybe<Array<PrinterTemplateConfigurationInput>>;
  updatedAt: Scalars['Float'];
};

export type DeviceSettingSynchronizerResponse = {
  __typename?: 'DeviceSettingSynchronizerResponse';
  data?: Maybe<OutletDeviceSetting>;
  error?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DineInMetadata = {
  __typename?: 'DineInMetadata';
  adultPax?: Maybe<Scalars['Int']>;
  kidPax?: Maybe<Scalars['Int']>;
  tableCodeWithPrefix?: Maybe<Scalars['String']>;
};

export type DineInMetadataInput = {
  adultPax?: InputMaybe<Scalars['Int']>;
  kidPax?: InputMaybe<Scalars['Int']>;
  tableCodeWithPrefix?: InputMaybe<Scalars['String']>;
  tableId?: InputMaybe<Scalars['String']>;
};

export enum DineInOrderStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED'
}

export type Discount = {
  __typename?: 'Discount';
  adjustmentSource: Scalars['String'];
  amount: Scalars['Money'];
  amountWithTax: Scalars['Money'];
  description: Scalars['String'];
  promotion?: Maybe<Promotion>;
  type: AdjustmentType;
};

export type DiscountInput = {
  adjustmentSource?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['Float']>;
  amountWithTax?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type DiscountPromotion = Node & {
  __typename?: 'DiscountPromotion';
  amount: Scalars['Int'];
  assets?: Maybe<Array<DiscountPromotionAsset>>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  enabled: Scalars['Boolean'];
  endDate?: Maybe<Scalars['DateTime']>;
  featuredAsset?: Maybe<Asset>;
  id: Scalars['ID'];
  name: Scalars['String'];
  productVariants?: Maybe<Array<ProductVariant>>;
  promotion: Promotion;
  startDate: Scalars['DateTime'];
  type: DiscountType;
  updatedAt: Scalars['DateTime'];
};

export type DiscountPromotionAsset = Node & {
  __typename?: 'DiscountPromotionAsset';
  asset: Asset;
  assetId: Scalars['ID'];
  id: Scalars['ID'];
  position: Scalars['Int'];
};

export type DiscountPromotionFilterParameter = {
  _and?: InputMaybe<Array<DiscountPromotionFilterParameter>>;
  _or?: InputMaybe<Array<DiscountPromotionFilterParameter>>;
  amount?: InputMaybe<NumberOperators>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  enabled?: InputMaybe<BooleanOperators>;
  endDate?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  name?: InputMaybe<StringOperators>;
  startDate?: InputMaybe<DateOperators>;
  type?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type DiscountPromotionList = PaginatedList & {
  __typename?: 'DiscountPromotionList';
  items: Array<DiscountPromotion>;
  totalItems: Scalars['Int'];
};

export type DiscountPromotionListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<DiscountPromotionFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<DiscountPromotionSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type DiscountPromotionProductVariant = Node & {
  __typename?: 'DiscountPromotionProductVariant';
  assets: Array<Asset>;
  collections: CollectionList;
  createdAt: Scalars['DateTime'];
  currencyCode: CurrencyCode;
  enabled: Scalars['Boolean'];
  facetValues: Array<FacetValue>;
  featuredAsset?: Maybe<Asset>;
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  options: Array<ProductOption>;
  price: Scalars['Money'];
  priceWithTax: Scalars['Money'];
  product: Product;
  productId: Scalars['ID'];
  sku: Scalars['String'];
  stockLevel: Scalars['String'];
  taxCategory: TaxCategory;
  taxRateApplied: TaxRate;
  translations: Array<ProductVariantTranslation>;
  updatedAt: Scalars['DateTime'];
};


export type DiscountPromotionProductVariantCollectionsArgs = {
  options?: InputMaybe<CollectionListOptions>;
};

export type DiscountPromotionProductVariantFilterParameter = {
  _and?: InputMaybe<Array<DiscountPromotionProductVariantFilterParameter>>;
  _or?: InputMaybe<Array<DiscountPromotionProductVariantFilterParameter>>;
  collectionId?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  currencyCode?: InputMaybe<StringOperators>;
  enabled?: InputMaybe<BooleanOperators>;
  id?: InputMaybe<IdOperators>;
  languageCode?: InputMaybe<StringOperators>;
  name?: InputMaybe<StringOperators>;
  price?: InputMaybe<NumberOperators>;
  priceWithTax?: InputMaybe<NumberOperators>;
  productId?: InputMaybe<IdOperators>;
  sku?: InputMaybe<StringOperators>;
  stockLevel?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type DiscountPromotionProductVariantList = PaginatedList & {
  __typename?: 'DiscountPromotionProductVariantList';
  items: Array<DiscountPromotionProductVariant>;
  totalItems: Scalars['Int'];
};

export type DiscountPromotionProductVariantListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<DiscountPromotionProductVariantFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<DiscountPromotionProductVariantSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type DiscountPromotionProductVariantSortParameter = {
  collectionId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  priceWithTax?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  sku?: InputMaybe<SortOrder>;
  stockLevel?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type DiscountPromotionSortParameter = {
  amount?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum DiscountType {
  Fixed = 'FIXED',
  Percentage = 'PERCENTAGE'
}

export type DuplicateEntityError = ErrorResult & {
  __typename?: 'DuplicateEntityError';
  duplicationError: Scalars['String'];
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export type DuplicateEntityInput = {
  duplicatorInput: ConfigurableOperationInput;
  entityId: Scalars['ID'];
  entityName: Scalars['String'];
};

export type DuplicateEntityResult = DuplicateEntityError | DuplicateEntitySuccess;

export type DuplicateEntitySuccess = {
  __typename?: 'DuplicateEntitySuccess';
  newEntityId: Scalars['ID'];
};

export type EghlButton = {
  __typename?: 'EghlButton';
  color: Scalars['String'];
  textColor?: Maybe<Scalars['String']>;
};

export type EghlButtonInput = {
  color?: InputMaybe<Scalars['String']>;
  textColor?: InputMaybe<Scalars['String']>;
};

export type EghlConfig = {
  __typename?: 'EghlConfig';
  button: EghlButton;
  callbackUrl: Scalars['String'];
  fallbackRejectEndpoint: Scalars['String'];
  fallbackSuccessEndpoint: Scalars['String'];
  hostUrl: Scalars['String'];
  returnUrl: Scalars['String'];
  timeoutS: Scalars['Int'];
  urlExpireInSeconds: Scalars['Int'];
};

export type EghlConfigInput = {
  button?: InputMaybe<EghlButtonInput>;
  callbackUrl?: InputMaybe<Scalars['String']>;
  fallbackRejectEndpoint?: InputMaybe<Scalars['String']>;
  fallbackSuccessEndpoint?: InputMaybe<Scalars['String']>;
  hostUrl?: InputMaybe<Scalars['String']>;
  returnUrl?: InputMaybe<Scalars['String']>;
  timeoutS?: InputMaybe<Scalars['String']>;
  urlExpireInSeconds?: InputMaybe<Scalars['Int']>;
};

export type EghlCredentialsInput = {
  merchantId: Scalars['String'];
  merchantName: Scalars['String'];
  merchantPass: Scalars['String'];
};

/** Returned when attempting to create a Customer with an email address already registered to an existing User. */
export type EmailAddressConflictError = ErrorResult & {
  __typename?: 'EmailAddressConflictError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

/** Returned if no OrderLines have been specified for the operation */
export type EmptyOrderLineSelectionError = ErrorResult & {
  __typename?: 'EmptyOrderLineSelectionError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export type EntityCustomFields = {
  __typename?: 'EntityCustomFields';
  customFields: Array<CustomFieldConfig>;
  entityName: Scalars['String'];
};

export type EntityDuplicatorDefinition = {
  __typename?: 'EntityDuplicatorDefinition';
  args: Array<ConfigArgDefinition>;
  code: Scalars['String'];
  description: Scalars['String'];
  forEntities: Array<Scalars['String']>;
  requiresPermission: Array<Permission>;
};

export enum ErrorCode {
  AlreadyRefundedError = 'ALREADY_REFUNDED_ERROR',
  CancelActiveOrderError = 'CANCEL_ACTIVE_ORDER_ERROR',
  CancelPaymentError = 'CANCEL_PAYMENT_ERROR',
  ChannelDefaultLanguageError = 'CHANNEL_DEFAULT_LANGUAGE_ERROR',
  CouponCodeExpiredError = 'COUPON_CODE_EXPIRED_ERROR',
  CouponCodeInvalidError = 'COUPON_CODE_INVALID_ERROR',
  CouponCodeLimitError = 'COUPON_CODE_LIMIT_ERROR',
  CreateFulfillmentError = 'CREATE_FULFILLMENT_ERROR',
  DuplicateEntityError = 'DUPLICATE_ENTITY_ERROR',
  EmailAddressConflictError = 'EMAIL_ADDRESS_CONFLICT_ERROR',
  EmptyOrderLineSelectionError = 'EMPTY_ORDER_LINE_SELECTION_ERROR',
  FacetInUseError = 'FACET_IN_USE_ERROR',
  FulfillmentStateTransitionError = 'FULFILLMENT_STATE_TRANSITION_ERROR',
  GuestCheckoutError = 'GUEST_CHECKOUT_ERROR',
  IneligibleShippingMethodError = 'INELIGIBLE_SHIPPING_METHOD_ERROR',
  InsufficientStockError = 'INSUFFICIENT_STOCK_ERROR',
  InsufficientStockOnHandError = 'INSUFFICIENT_STOCK_ON_HAND_ERROR',
  InvalidCredentialsError = 'INVALID_CREDENTIALS_ERROR',
  InvalidFulfillmentHandlerError = 'INVALID_FULFILLMENT_HANDLER_ERROR',
  ItemsAlreadyFulfilledError = 'ITEMS_ALREADY_FULFILLED_ERROR',
  LanguageNotAvailableError = 'LANGUAGE_NOT_AVAILABLE_ERROR',
  ManualPaymentStateError = 'MANUAL_PAYMENT_STATE_ERROR',
  MimeTypeError = 'MIME_TYPE_ERROR',
  MissingConditionsError = 'MISSING_CONDITIONS_ERROR',
  MultipleOrderError = 'MULTIPLE_ORDER_ERROR',
  NativeAuthStrategyError = 'NATIVE_AUTH_STRATEGY_ERROR',
  NegativeQuantityError = 'NEGATIVE_QUANTITY_ERROR',
  NothingToRefundError = 'NOTHING_TO_REFUND_ERROR',
  NoActiveOrderError = 'NO_ACTIVE_ORDER_ERROR',
  NoChangesSpecifiedError = 'NO_CHANGES_SPECIFIED_ERROR',
  OrderLimitError = 'ORDER_LIMIT_ERROR',
  OrderModificationError = 'ORDER_MODIFICATION_ERROR',
  OrderModificationStateError = 'ORDER_MODIFICATION_STATE_ERROR',
  OrderStateTransitionError = 'ORDER_STATE_TRANSITION_ERROR',
  PaymentMethodMissingError = 'PAYMENT_METHOD_MISSING_ERROR',
  PaymentOrderMismatchError = 'PAYMENT_ORDER_MISMATCH_ERROR',
  PaymentStateTransitionError = 'PAYMENT_STATE_TRANSITION_ERROR',
  ProductOptionInUseError = 'PRODUCT_OPTION_IN_USE_ERROR',
  QuantityTooGreatError = 'QUANTITY_TOO_GREAT_ERROR',
  RefundAmountError = 'REFUND_AMOUNT_ERROR',
  RefundOrderStateError = 'REFUND_ORDER_STATE_ERROR',
  RefundPaymentIdMissingError = 'REFUND_PAYMENT_ID_MISSING_ERROR',
  RefundStateTransitionError = 'REFUND_STATE_TRANSITION_ERROR',
  SettlePaymentError = 'SETTLE_PAYMENT_ERROR',
  UnknownError = 'UNKNOWN_ERROR'
}

export type ErrorResult = {
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export type EstimatedTimeOfDelivery = {
  __typename?: 'EstimatedTimeOfDelivery';
  distance: Scalars['Float'];
  /** units in minutes */
  duration: Scalars['String'];
};

export type ExpiryOnRedeemConfig = {
  __typename?: 'ExpiryOnRedeemConfig';
  alignWithCampaignEnd?: Maybe<Scalars['Boolean']>;
  dateTimeUnit: Scalars['String'];
  value: Scalars['Int'];
};

export type Facet = Node & {
  __typename?: 'Facet';
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isPrivate: Scalars['Boolean'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  translations: Array<FacetTranslation>;
  updatedAt: Scalars['DateTime'];
  /** Returns a paginated, sortable, filterable list of the Facet's values. Added in v2.1.0. */
  valueList: FacetValueList;
  values: Array<FacetValue>;
};


export type FacetValueListArgs = {
  options?: InputMaybe<FacetValueListOptions>;
};

export type FacetFilterParameter = {
  _and?: InputMaybe<Array<FacetFilterParameter>>;
  _or?: InputMaybe<Array<FacetFilterParameter>>;
  code?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  isPrivate?: InputMaybe<BooleanOperators>;
  languageCode?: InputMaybe<StringOperators>;
  name?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type FacetInUseError = ErrorResult & {
  __typename?: 'FacetInUseError';
  errorCode: ErrorCode;
  facetCode: Scalars['String'];
  message: Scalars['String'];
  productCount: Scalars['Int'];
  variantCount: Scalars['Int'];
};

export type FacetList = PaginatedList & {
  __typename?: 'FacetList';
  items: Array<Facet>;
  totalItems: Scalars['Int'];
};

export type FacetListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<FacetFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<FacetSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type FacetSortParameter = {
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type FacetTranslation = {
  __typename?: 'FacetTranslation';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type FacetTranslationInput = {
  customFields?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['ID']>;
  languageCode: LanguageCode;
  name?: InputMaybe<Scalars['String']>;
};

export type FacetValue = Node & {
  __typename?: 'FacetValue';
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<FacetValueCustomFields>;
  facet: Facet;
  facetId: Scalars['ID'];
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  translations: Array<FacetValueTranslation>;
  updatedAt: Scalars['DateTime'];
};

export type FacetValueCustomFields = {
  __typename?: 'FacetValueCustomFields';
  maxModifier?: Maybe<Scalars['Int']>;
  minModifier?: Maybe<Scalars['Int']>;
  modifierSorting?: Maybe<Array<Scalars['String']>>;
};

/**
 * Used to construct boolean expressions for filtering search results
 * by FacetValue ID. Examples:
 *
 * * ID=1 OR ID=2: `{ facetValueFilters: [{ or: [1,2] }] }`
 * * ID=1 AND ID=2: `{ facetValueFilters: [{ and: 1 }, { and: 2 }] }`
 * * ID=1 AND (ID=2 OR ID=3): `{ facetValueFilters: [{ and: 1 }, { or: [2,3] }] }`
 */
export type FacetValueFilterInput = {
  and?: InputMaybe<Scalars['ID']>;
  or?: InputMaybe<Array<Scalars['ID']>>;
};

export type FacetValueFilterParameter = {
  _and?: InputMaybe<Array<FacetValueFilterParameter>>;
  _or?: InputMaybe<Array<FacetValueFilterParameter>>;
  code?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  facetId?: InputMaybe<IdOperators>;
  id?: InputMaybe<IdOperators>;
  languageCode?: InputMaybe<StringOperators>;
  maxModifier?: InputMaybe<NumberOperators>;
  minModifier?: InputMaybe<NumberOperators>;
  modifierSorting?: InputMaybe<StringListOperators>;
  name?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type FacetValueList = PaginatedList & {
  __typename?: 'FacetValueList';
  items: Array<FacetValue>;
  totalItems: Scalars['Int'];
};

export type FacetValueListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<FacetValueFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<FacetValueSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

/**
 * Which FacetValues are present in the products returned
 * by the search, and in what quantity.
 */
export type FacetValueResult = {
  __typename?: 'FacetValueResult';
  count: Scalars['Int'];
  facetValue: FacetValue;
};

export type FacetValueSortParameter = {
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  facetId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  maxModifier?: InputMaybe<SortOrder>;
  minModifier?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type FacetValueTranslation = {
  __typename?: 'FacetValueTranslation';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type FacetValueTranslationInput = {
  customFields?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['ID']>;
  languageCode: LanguageCode;
  name?: InputMaybe<Scalars['String']>;
};

export type FloatCustomFieldConfig = CustomField & {
  __typename?: 'FloatCustomFieldConfig';
  description?: Maybe<Array<LocalizedString>>;
  internal?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Array<LocalizedString>>;
  list: Scalars['Boolean'];
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  nullable?: Maybe<Scalars['Boolean']>;
  readonly?: Maybe<Scalars['Boolean']>;
  requiresPermission?: Maybe<Array<Permission>>;
  step?: Maybe<Scalars['Float']>;
  type: Scalars['String'];
  ui?: Maybe<Scalars['JSON']>;
};

export type FulfillOrderInput = {
  handler: ConfigurableOperationInput;
  lines: Array<OrderLineInput>;
};

export type Fulfillment = Node & {
  __typename?: 'Fulfillment';
  additionalProperties?: Maybe<AdditionalProperties>;
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<FulfillmentCustomFields>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  lines: Array<FulfillmentLine>;
  method: Scalars['String'];
  nextStates: Array<Scalars['String']>;
  state: Scalars['String'];
  /** @deprecated Use the `lines` field instead */
  summary: Array<FulfillmentLine>;
  trackingCode?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type FulfillmentAdditionalPropertiesSyncInput = {
  courier?: InputMaybe<Scalars['String']>;
  driverContact?: InputMaybe<Scalars['String']>;
  driverName?: InputMaybe<Scalars['String']>;
  pickUpTime?: InputMaybe<Scalars['Float']>;
};

export type FulfillmentChannel = Node & {
  __typename?: 'FulfillmentChannel';
  code: FulfillmentMethodCode;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isPrivate: Scalars['Boolean'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type FulfillmentChannelFilterParameter = {
  _and?: InputMaybe<Array<FulfillmentChannelFilterParameter>>;
  _or?: InputMaybe<Array<FulfillmentChannelFilterParameter>>;
  code?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  description?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  isPrivate?: InputMaybe<BooleanOperators>;
  name?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type FulfillmentChannelList = PaginatedList & {
  __typename?: 'FulfillmentChannelList';
  items: Array<FulfillmentChannel>;
  totalItems: Scalars['Int'];
};

export type FulfillmentChannelListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<FulfillmentChannelFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<FulfillmentChannelSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type FulfillmentChannelSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type FulfillmentCustomFields = {
  __typename?: 'FulfillmentCustomFields';
  additionalProperties?: Maybe<Scalars['String']>;
  orderInvoices?: Maybe<Array<OrderInvoice>>;
  posId?: Maybe<Scalars['String']>;
};

export type FulfillmentFilterParameter = {
  _and?: InputMaybe<Array<FulfillmentFilterParameter>>;
  _or?: InputMaybe<Array<FulfillmentFilterParameter>>;
  additionalProperties?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  method?: InputMaybe<StringOperators>;
  posId?: InputMaybe<StringOperators>;
  state?: InputMaybe<StringOperators>;
  trackingCode?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type FulfillmentLine = {
  __typename?: 'FulfillmentLine';
  fulfillment: Fulfillment;
  fulfillmentId: Scalars['ID'];
  orderLine: OrderLine;
  orderLineId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type FulfillmentList = PaginatedList & {
  __typename?: 'FulfillmentList';
  items: Array<Fulfillment>;
  totalItems: Scalars['Int'];
};

export type FulfillmentListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<FulfillmentFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<FulfillmentSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export enum FulfillmentMethod {
  Delivery = 'DELIVERY',
  DineIn = 'DINE_IN',
  Pickup = 'PICKUP'
}

export enum FulfillmentMethodCode {
  Delivery = 'DELIVERY',
  DineIn = 'DINE_IN',
  Pickup = 'PICKUP'
}

export type FulfillmentMethodInput = {
  additionalProperties?: InputMaybe<AdditionalPropertiesInput>;
  method: FulfillmentMethod;
};

export enum FulfillmentMethodType {
  Delivery = 'DELIVERY',
  DineIn = 'DINE_IN',
  Pickup = 'PICKUP'
}

export type FulfillmentSortParameter = {
  additionalProperties?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  method?: InputMaybe<SortOrder>;
  posId?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  trackingCode?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum FulfillmentState {
  Cancelled = 'Cancelled',
  Created = 'Created',
  Delivered = 'Delivered',
  Pending = 'Pending',
  Shipped = 'Shipped'
}

/** Returned when there is an error in transitioning the Fulfillment state */
export type FulfillmentStateTransitionError = ErrorResult & {
  __typename?: 'FulfillmentStateTransitionError';
  errorCode: ErrorCode;
  fromState: Scalars['String'];
  message: Scalars['String'];
  toState: Scalars['String'];
  transitionError: Scalars['String'];
};

export type FulfillmentTimeSlot = {
  date: Scalars['DateTime'];
  isPlacedNow: Scalars['Boolean'];
  outletFulfillmentId: Scalars['String'];
  timeSlot: Scalars['String'];
};

export type FullfillmentLineSyncInput = {
  orderLineId: Scalars['String'];
  quantity: Scalars['Float'];
};

export type GenerateAccessTokenOutletDeviceInput = {
  adminId?: InputMaybe<Scalars['ID']>;
  channelId?: InputMaybe<Scalars['ID']>;
  deviceToken: Scalars['String'];
  outletId?: InputMaybe<Scalars['ID']>;
};

export type GenericSyncFilter = {
  id?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type GetAllOutletFulfillmentsTypeByDayInput = {
  outletId?: InputMaybe<Scalars['String']>;
};

export type GetDefaultAddressInput = {
  type: DefaultAddress;
};

export type GetEstimatedTimeOfDeliveryInput = {
  orderId: Scalars['ID'];
  outletId?: InputMaybe<Scalars['ID']>;
};

export type GetOrderList = {
  __typename?: 'GetOrderList';
  items: Array<Order>;
  totalItems: Scalars['Int'];
};

export type GetOutletFulfillmentFilterParameter = {
  closingTime?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  day?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  openingTime?: InputMaybe<StringOperators>;
  outletId?: InputMaybe<StringOperators>;
  status?: InputMaybe<StringOperators>;
  type?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type GetOutletFulfillmentListOptions = {
  filter?: InputMaybe<GetOutletFulfillmentFilterParameter>;
  filterOperator?: InputMaybe<LogicalOperator>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<OutletFulfillmentSortParameter>;
  take?: InputMaybe<Scalars['Int']>;
};

export type GetOutletFulfillmentsByDayAndTypeInput = {
  outletId?: InputMaybe<Scalars['String']>;
  type: OutletFulfillmentType;
};

export type GetSearchedBannersInput = {
  options?: InputMaybe<BannerListOptions>;
  search?: InputMaybe<BannerPeriodInput>;
};

export type GetSlotAvailabilityInput = {
  date: Scalars['DateTime'];
};

export type GetTemplateInput = {
  code: Scalars['String'];
  identifier: TemplateIdentifierInput;
};

export type GetTimeSlotInput = {
  dateFormat?: InputMaybe<Scalars['String']>;
  isPreorder?: InputMaybe<Scalars['Boolean']>;
  outletId?: InputMaybe<Scalars['String']>;
  type: OutletFulfillmentType;
};

export type GiftOption = Node & {
  __typename?: 'GiftOption';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  label?: Maybe<Scalars['JSON']>;
  name: Scalars['String'];
  price: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type GiftOptionFilterParameter = {
  _and?: InputMaybe<Array<GiftOptionFilterParameter>>;
  _or?: InputMaybe<Array<GiftOptionFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  description?: InputMaybe<StringOperators>;
  enabled?: InputMaybe<BooleanOperators>;
  id?: InputMaybe<IdOperators>;
  name?: InputMaybe<StringOperators>;
  price?: InputMaybe<NumberOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type GiftOptionInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Float'];
};

export type GiftOptionList = PaginatedList & {
  __typename?: 'GiftOptionList';
  items: Array<GiftOption>;
  totalItems: Scalars['Int'];
};

export type GiftOptionListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<GiftOptionFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<GiftOptionSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type GiftOptionSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum GlobalFlag {
  False = 'FALSE',
  Inherit = 'INHERIT',
  True = 'TRUE'
}

export type GlobalSettings = {
  __typename?: 'GlobalSettings';
  availableLanguages: Array<LanguageCode>;
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  outOfStockThreshold: Scalars['Int'];
  serverConfig: ServerConfig;
  trackInventory: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export enum GradientEnum {
  Conic = 'CONIC',
  Linear = 'LINEAR',
  Radial = 'RADIAL'
}

export type GradientInput = {
  gradient: Array<Scalars['String']>;
  type: GradientEnum;
};

export type GroupResource = {
  __typename?: 'GroupResource';
  groupName?: Maybe<Scalars['String']>;
  resources?: Maybe<Array<SellerResource>>;
};

/** Returned when attempting to set the Customer on a guest checkout when the configured GuestCheckoutStrategy does not allow it. */
export type GuestCheckoutError = ErrorResult & {
  __typename?: 'GuestCheckoutError';
  errorCode: ErrorCode;
  errorDetail: Scalars['String'];
  message: Scalars['String'];
};

export type HideShowProductReviewInput = {
  id: Scalars['ID'];
  shouldHide: Scalars['Boolean'];
};

export type HistoryEntry = Node & {
  __typename?: 'HistoryEntry';
  administrator?: Maybe<Administrator>;
  createdAt: Scalars['DateTime'];
  data: Scalars['JSON'];
  id: Scalars['ID'];
  isPublic: Scalars['Boolean'];
  type: HistoryEntryType;
  updatedAt: Scalars['DateTime'];
};

export type HistoryEntryFilterParameter = {
  _and?: InputMaybe<Array<HistoryEntryFilterParameter>>;
  _or?: InputMaybe<Array<HistoryEntryFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  isPublic?: InputMaybe<BooleanOperators>;
  type?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type HistoryEntryList = PaginatedList & {
  __typename?: 'HistoryEntryList';
  items: Array<HistoryEntry>;
  totalItems: Scalars['Int'];
};

export type HistoryEntryListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<HistoryEntryFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<HistoryEntrySortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type HistoryEntrySortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum HistoryEntryType {
  CustomerAddedToGroup = 'CUSTOMER_ADDED_TO_GROUP',
  CustomerAddressCreated = 'CUSTOMER_ADDRESS_CREATED',
  CustomerAddressDeleted = 'CUSTOMER_ADDRESS_DELETED',
  CustomerAddressUpdated = 'CUSTOMER_ADDRESS_UPDATED',
  CustomerDetailUpdated = 'CUSTOMER_DETAIL_UPDATED',
  CustomerEmailUpdateRequested = 'CUSTOMER_EMAIL_UPDATE_REQUESTED',
  CustomerEmailUpdateVerified = 'CUSTOMER_EMAIL_UPDATE_VERIFIED',
  CustomerNote = 'CUSTOMER_NOTE',
  CustomerPasswordResetRequested = 'CUSTOMER_PASSWORD_RESET_REQUESTED',
  CustomerPasswordResetVerified = 'CUSTOMER_PASSWORD_RESET_VERIFIED',
  CustomerPasswordUpdated = 'CUSTOMER_PASSWORD_UPDATED',
  CustomerRegistered = 'CUSTOMER_REGISTERED',
  CustomerRemovedFromGroup = 'CUSTOMER_REMOVED_FROM_GROUP',
  CustomerVerified = 'CUSTOMER_VERIFIED',
  OrderCancellation = 'ORDER_CANCELLATION',
  OrderCouponApplied = 'ORDER_COUPON_APPLIED',
  OrderCouponRemoved = 'ORDER_COUPON_REMOVED',
  OrderCustomerUpdated = 'ORDER_CUSTOMER_UPDATED',
  OrderFulfillment = 'ORDER_FULFILLMENT',
  OrderFulfillmentTransition = 'ORDER_FULFILLMENT_TRANSITION',
  OrderLineChangedEvent = 'ORDER_LINE_CHANGED_EVENT',
  OrderModified = 'ORDER_MODIFIED',
  OrderNote = 'ORDER_NOTE',
  OrderPaymentTransition = 'ORDER_PAYMENT_TRANSITION',
  OrderRefundTransition = 'ORDER_REFUND_TRANSITION',
  OrderStateTransition = 'ORDER_STATE_TRANSITION'
}

/** Operators for filtering on a list of ID fields */
export type IdListOperators = {
  inList: Scalars['ID'];
};

/** Operators for filtering on an ID field */
export type IdOperators = {
  eq?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  notEq?: InputMaybe<Scalars['String']>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
};

export enum ISortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type ISortParameter = {
  field: Scalars['String'];
  order?: InputMaybe<SortOrder>;
};

export type Image = {
  __typename?: 'Image';
  id: Scalars['ID'];
  url: Scalars['String'];
};

export type ImportInfo = {
  __typename?: 'ImportInfo';
  errors?: Maybe<Array<Scalars['String']>>;
  imported: Scalars['Int'];
  processed: Scalars['Int'];
};

/** Returned when attempting to set a ShippingMethod for which the Order is not eligible */
export type IneligibleShippingMethodError = ErrorResult & {
  __typename?: 'IneligibleShippingMethodError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

/** Returned when attempting to add more items to the Order than are available */
export type InsufficientStockError = ErrorResult & {
  __typename?: 'InsufficientStockError';
  errorCode: ErrorCode;
  message: Scalars['String'];
  order: Order;
  quantityAvailable: Scalars['Int'];
};

/**
 * Returned if attempting to create a Fulfillment when there is insufficient
 * stockOnHand of a ProductVariant to satisfy the requested quantity.
 */
export type InsufficientStockOnHandError = ErrorResult & {
  __typename?: 'InsufficientStockOnHandError';
  errorCode: ErrorCode;
  message: Scalars['String'];
  productVariantId: Scalars['ID'];
  productVariantName: Scalars['String'];
  stockOnHand: Scalars['Int'];
};

export type IntCustomFieldConfig = CustomField & {
  __typename?: 'IntCustomFieldConfig';
  description?: Maybe<Array<LocalizedString>>;
  internal?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Array<LocalizedString>>;
  list: Scalars['Boolean'];
  max?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  nullable?: Maybe<Scalars['Boolean']>;
  readonly?: Maybe<Scalars['Boolean']>;
  requiresPermission?: Maybe<Array<Permission>>;
  step?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  ui?: Maybe<Scalars['JSON']>;
};

/** Returned if the user authentication credentials are not valid */
export type InvalidCredentialsError = ErrorResult & {
  __typename?: 'InvalidCredentialsError';
  authenticationError: Scalars['String'];
  errorCode: ErrorCode;
  message: Scalars['String'];
};

/** Returned if the specified FulfillmentHandler code is not valid */
export type InvalidFulfillmentHandlerError = ErrorResult & {
  __typename?: 'InvalidFulfillmentHandlerError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export enum InvoiceStatus {
  Cancelled = 'CANCELLED',
  Pending = 'PENDING',
  Settled = 'SETTLED'
}

/** Returned if the specified items are already part of a Fulfillment */
export type ItemsAlreadyFulfilledError = ErrorResult & {
  __typename?: 'ItemsAlreadyFulfilledError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export type Job = Node & {
  __typename?: 'Job';
  attempts: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  data?: Maybe<Scalars['JSON']>;
  duration: Scalars['Int'];
  error?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  isSettled: Scalars['Boolean'];
  progress: Scalars['Float'];
  queueName: Scalars['String'];
  result?: Maybe<Scalars['JSON']>;
  retries: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  state: JobState;
};

export type JobBufferSize = {
  __typename?: 'JobBufferSize';
  bufferId: Scalars['String'];
  size: Scalars['Int'];
};

export type JobFilterParameter = {
  _and?: InputMaybe<Array<JobFilterParameter>>;
  _or?: InputMaybe<Array<JobFilterParameter>>;
  attempts?: InputMaybe<NumberOperators>;
  createdAt?: InputMaybe<DateOperators>;
  duration?: InputMaybe<NumberOperators>;
  id?: InputMaybe<IdOperators>;
  isSettled?: InputMaybe<BooleanOperators>;
  progress?: InputMaybe<NumberOperators>;
  queueName?: InputMaybe<StringOperators>;
  retries?: InputMaybe<NumberOperators>;
  settledAt?: InputMaybe<DateOperators>;
  startedAt?: InputMaybe<DateOperators>;
  state?: InputMaybe<StringOperators>;
};

export type JobList = PaginatedList & {
  __typename?: 'JobList';
  items: Array<Job>;
  totalItems: Scalars['Int'];
};

export type JobListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<JobFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<JobSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type JobQueue = {
  __typename?: 'JobQueue';
  name: Scalars['String'];
  running: Scalars['Boolean'];
};

export type JobSortParameter = {
  attempts?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  progress?: InputMaybe<SortOrder>;
  queueName?: InputMaybe<SortOrder>;
  retries?: InputMaybe<SortOrder>;
  settledAt?: InputMaybe<SortOrder>;
  startedAt?: InputMaybe<SortOrder>;
};

/**
 * @description
 * The state of a Job in the JobQueue
 *
 * @docsCategory common
 */
export enum JobState {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Retrying = 'RETRYING',
  Running = 'RUNNING'
}

/**
 * @description
 * Languages in the form of a ISO 639-1 language code with optional
 * region or script modifier (e.g. de_AT). The selection available is based
 * on the [Unicode CLDR summary list](https://unicode-org.github.io/cldr-staging/charts/37/summary/root.html)
 * and includes the major spoken languages of the world and any widely-used variants.
 *
 * @docsCategory common
 */
export enum LanguageCode {
  /** Afrikaans */
  Af = 'af',
  /** Akan */
  Ak = 'ak',
  /** Amharic */
  Am = 'am',
  /** Arabic */
  Ar = 'ar',
  /** Assamese */
  As = 'as',
  /** Azerbaijani */
  Az = 'az',
  /** Belarusian */
  Be = 'be',
  /** Bulgarian */
  Bg = 'bg',
  /** Bambara */
  Bm = 'bm',
  /** Bangla */
  Bn = 'bn',
  /** Tibetan */
  Bo = 'bo',
  /** Breton */
  Br = 'br',
  /** Bosnian */
  Bs = 'bs',
  /** Catalan */
  Ca = 'ca',
  /** Chechen */
  Ce = 'ce',
  /** Corsican */
  Co = 'co',
  /** Czech */
  Cs = 'cs',
  /** Church Slavic */
  Cu = 'cu',
  /** Welsh */
  Cy = 'cy',
  /** Danish */
  Da = 'da',
  /** German */
  De = 'de',
  /** Austrian German */
  DeAt = 'de_AT',
  /** Swiss High German */
  DeCh = 'de_CH',
  /** Dzongkha */
  Dz = 'dz',
  /** Ewe */
  Ee = 'ee',
  /** Greek */
  El = 'el',
  /** English */
  En = 'en',
  /** Australian English */
  EnAu = 'en_AU',
  /** Canadian English */
  EnCa = 'en_CA',
  /** British English */
  EnGb = 'en_GB',
  /** American English */
  EnUs = 'en_US',
  /** Esperanto */
  Eo = 'eo',
  /** Spanish */
  Es = 'es',
  /** European Spanish */
  EsEs = 'es_ES',
  /** Mexican Spanish */
  EsMx = 'es_MX',
  /** Estonian */
  Et = 'et',
  /** Basque */
  Eu = 'eu',
  /** Persian */
  Fa = 'fa',
  /** Dari */
  FaAf = 'fa_AF',
  /** Fulah */
  Ff = 'ff',
  /** Finnish */
  Fi = 'fi',
  /** Faroese */
  Fo = 'fo',
  /** French */
  Fr = 'fr',
  /** Canadian French */
  FrCa = 'fr_CA',
  /** Swiss French */
  FrCh = 'fr_CH',
  /** Western Frisian */
  Fy = 'fy',
  /** Irish */
  Ga = 'ga',
  /** Scottish Gaelic */
  Gd = 'gd',
  /** Galician */
  Gl = 'gl',
  /** Gujarati */
  Gu = 'gu',
  /** Manx */
  Gv = 'gv',
  /** Hausa */
  Ha = 'ha',
  /** Hebrew */
  He = 'he',
  /** Hindi */
  Hi = 'hi',
  /** Croatian */
  Hr = 'hr',
  /** Haitian Creole */
  Ht = 'ht',
  /** Hungarian */
  Hu = 'hu',
  /** Armenian */
  Hy = 'hy',
  /** Interlingua */
  Ia = 'ia',
  /** Indonesian */
  Id = 'id',
  /** Igbo */
  Ig = 'ig',
  /** Sichuan Yi */
  Ii = 'ii',
  /** Icelandic */
  Is = 'is',
  /** Italian */
  It = 'it',
  /** Japanese */
  Ja = 'ja',
  /** Javanese */
  Jv = 'jv',
  /** Georgian */
  Ka = 'ka',
  /** Kikuyu */
  Ki = 'ki',
  /** Kazakh */
  Kk = 'kk',
  /** Kalaallisut */
  Kl = 'kl',
  /** Khmer */
  Km = 'km',
  /** Kannada */
  Kn = 'kn',
  /** Korean */
  Ko = 'ko',
  /** Kashmiri */
  Ks = 'ks',
  /** Kurdish */
  Ku = 'ku',
  /** Cornish */
  Kw = 'kw',
  /** Kyrgyz */
  Ky = 'ky',
  /** Latin */
  La = 'la',
  /** Luxembourgish */
  Lb = 'lb',
  /** Ganda */
  Lg = 'lg',
  /** Lingala */
  Ln = 'ln',
  /** Lao */
  Lo = 'lo',
  /** Lithuanian */
  Lt = 'lt',
  /** Luba-Katanga */
  Lu = 'lu',
  /** Latvian */
  Lv = 'lv',
  /** Malagasy */
  Mg = 'mg',
  /** Maori */
  Mi = 'mi',
  /** Macedonian */
  Mk = 'mk',
  /** Malayalam */
  Ml = 'ml',
  /** Mongolian */
  Mn = 'mn',
  /** Marathi */
  Mr = 'mr',
  /** Malay */
  Ms = 'ms',
  /** Maltese */
  Mt = 'mt',
  /** Burmese */
  My = 'my',
  /** Norwegian Bokmål */
  Nb = 'nb',
  /** North Ndebele */
  Nd = 'nd',
  /** Nepali */
  Ne = 'ne',
  /** Dutch */
  Nl = 'nl',
  /** Flemish */
  NlBe = 'nl_BE',
  /** Norwegian Nynorsk */
  Nn = 'nn',
  /** Nyanja */
  Ny = 'ny',
  /** Oromo */
  Om = 'om',
  /** Odia */
  Or = 'or',
  /** Ossetic */
  Os = 'os',
  /** Punjabi */
  Pa = 'pa',
  /** Polish */
  Pl = 'pl',
  /** Pashto */
  Ps = 'ps',
  /** Portuguese */
  Pt = 'pt',
  /** Brazilian Portuguese */
  PtBr = 'pt_BR',
  /** European Portuguese */
  PtPt = 'pt_PT',
  /** Quechua */
  Qu = 'qu',
  /** Romansh */
  Rm = 'rm',
  /** Rundi */
  Rn = 'rn',
  /** Romanian */
  Ro = 'ro',
  /** Moldavian */
  RoMd = 'ro_MD',
  /** Russian */
  Ru = 'ru',
  /** Kinyarwanda */
  Rw = 'rw',
  /** Sanskrit */
  Sa = 'sa',
  /** Sindhi */
  Sd = 'sd',
  /** Northern Sami */
  Se = 'se',
  /** Sango */
  Sg = 'sg',
  /** Sinhala */
  Si = 'si',
  /** Slovak */
  Sk = 'sk',
  /** Slovenian */
  Sl = 'sl',
  /** Samoan */
  Sm = 'sm',
  /** Shona */
  Sn = 'sn',
  /** Somali */
  So = 'so',
  /** Albanian */
  Sq = 'sq',
  /** Serbian */
  Sr = 'sr',
  /** Southern Sotho */
  St = 'st',
  /** Sundanese */
  Su = 'su',
  /** Swedish */
  Sv = 'sv',
  /** Swahili */
  Sw = 'sw',
  /** Congo Swahili */
  SwCd = 'sw_CD',
  /** Tamil */
  Ta = 'ta',
  /** Telugu */
  Te = 'te',
  /** Tajik */
  Tg = 'tg',
  /** Thai */
  Th = 'th',
  /** Tigrinya */
  Ti = 'ti',
  /** Turkmen */
  Tk = 'tk',
  /** Tongan */
  To = 'to',
  /** Turkish */
  Tr = 'tr',
  /** Tatar */
  Tt = 'tt',
  /** Uyghur */
  Ug = 'ug',
  /** Ukrainian */
  Uk = 'uk',
  /** Urdu */
  Ur = 'ur',
  /** Uzbek */
  Uz = 'uz',
  /** Vietnamese */
  Vi = 'vi',
  /** Volapük */
  Vo = 'vo',
  /** Wolof */
  Wo = 'wo',
  /** Xhosa */
  Xh = 'xh',
  /** Yiddish */
  Yi = 'yi',
  /** Yoruba */
  Yo = 'yo',
  /** Chinese */
  Zh = 'zh',
  /** Simplified Chinese */
  ZhHans = 'zh_Hans',
  /** Traditional Chinese */
  ZhHant = 'zh_Hant',
  /** Zulu */
  Zu = 'zu'
}

/** Returned if attempting to set a Channel's defaultLanguageCode to a language which is not enabled in GlobalSettings */
export type LanguageNotAvailableError = ErrorResult & {
  __typename?: 'LanguageNotAvailableError';
  errorCode: ErrorCode;
  languageCode: Scalars['String'];
  message: Scalars['String'];
};

export type LinkCustomerToOrderInput = {
  customerId: Scalars['ID'];
  orderId: Scalars['ID'];
};

export type LocaleStringCustomFieldConfig = CustomField & {
  __typename?: 'LocaleStringCustomFieldConfig';
  description?: Maybe<Array<LocalizedString>>;
  internal?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Array<LocalizedString>>;
  length?: Maybe<Scalars['Int']>;
  list: Scalars['Boolean'];
  name: Scalars['String'];
  nullable?: Maybe<Scalars['Boolean']>;
  pattern?: Maybe<Scalars['String']>;
  readonly?: Maybe<Scalars['Boolean']>;
  requiresPermission?: Maybe<Array<Permission>>;
  type: Scalars['String'];
  ui?: Maybe<Scalars['JSON']>;
};

export type LocaleTextCustomFieldConfig = CustomField & {
  __typename?: 'LocaleTextCustomFieldConfig';
  description?: Maybe<Array<LocalizedString>>;
  internal?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Array<LocalizedString>>;
  list: Scalars['Boolean'];
  name: Scalars['String'];
  nullable?: Maybe<Scalars['Boolean']>;
  readonly?: Maybe<Scalars['Boolean']>;
  requiresPermission?: Maybe<Array<Permission>>;
  type: Scalars['String'];
  ui?: Maybe<Scalars['JSON']>;
};

export type LocalizedString = {
  __typename?: 'LocalizedString';
  languageCode: LanguageCode;
  value: Scalars['String'];
};

export enum LogicalOperator {
  And = 'AND',
  Or = 'OR'
}

export type ManualAdjustPointInput = {
  action: PointTransactionAdminActionType;
  point: Scalars['Int'];
  remarks?: InputMaybe<Scalars['String']>;
  toUserIds: Array<Scalars['String']>;
};

export type ManualAdjustStarInput = {
  action: StarTransactionAdminActionType;
  point: Scalars['Int'];
  remarks?: InputMaybe<Scalars['String']>;
  toUserIds: Array<Scalars['String']>;
};

export type ManualPaymentInput = {
  metadata?: InputMaybe<Scalars['JSON']>;
  method: Scalars['String'];
  orderId: Scalars['ID'];
  transactionId?: InputMaybe<Scalars['String']>;
};

/**
 * Returned when a call to addManualPaymentToOrder is made but the Order
 * is not in the required state.
 */
export type ManualPaymentStateError = ErrorResult & {
  __typename?: 'ManualPaymentStateError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export type Membership = {
  __typename?: 'Membership';
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  periodEndAt: Scalars['String'];
  pointBalance: Scalars['Int'];
  pointEarned: Scalars['Int'];
  pointRequired: Scalars['Int'];
  pointValidUntil?: Maybe<Scalars['String']>;
  starBalance: Scalars['Int'];
  starRequired: Scalars['Int'];
  starValidUntil?: Maybe<Scalars['String']>;
  tierProgress: TierProgress;
};

export type MembershipTier = Node & {
  __typename?: 'MembershipTier';
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  level: Scalars['Int'];
  minimumPoints: Scalars['Int'];
  name: Scalars['String'];
  pointMultiplier: Scalars['String'];
};

export type MembershipTierFilterParameter = {
  _and?: InputMaybe<Array<MembershipTierFilterParameter>>;
  _or?: InputMaybe<Array<MembershipTierFilterParameter>>;
  id?: InputMaybe<IdOperators>;
  imageUrl?: InputMaybe<StringOperators>;
  level?: InputMaybe<NumberOperators>;
  minimumPoints?: InputMaybe<NumberOperators>;
  name?: InputMaybe<StringOperators>;
  pointMultiplier?: InputMaybe<StringOperators>;
};

export type MembershipTierList = PaginatedList & {
  __typename?: 'MembershipTierList';
  items: Array<MembershipTier>;
  totalItems: Scalars['Int'];
};

export type MembershipTierListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<MembershipTierFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<MembershipTierSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type MembershipTierSortParameter = {
  id?: InputMaybe<SortOrder>;
  imageUrl?: InputMaybe<SortOrder>;
  level?: InputMaybe<SortOrder>;
  minimumPoints?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  pointMultiplier?: InputMaybe<SortOrder>;
};

export enum MetabaseDashboard {
  Overview = 'OVERVIEW'
}

export type MetabaseIframeUrlOptions = {
  border?: InputMaybe<Scalars['Boolean']>;
  dashboard?: InputMaybe<MetabaseDashboard>;
  parameters?: InputMaybe<Scalars['JSON']>;
  question?: InputMaybe<MetabaseQuestion>;
  theme?: InputMaybe<MetabaseTheme>;
  title?: InputMaybe<Scalars['Boolean']>;
};

export enum MetabaseQuestion {
  AllSales = 'ALL_SALES'
}

export enum MetabaseTheme {
  Dark = 'dark',
  Light = 'light',
  Transparent = 'transparent'
}

export enum MetricInterval {
  Daily = 'Daily'
}

export type MetricSummary = {
  __typename?: 'MetricSummary';
  entries: Array<MetricSummaryEntry>;
  interval: MetricInterval;
  title: Scalars['String'];
  type: MetricType;
};

export type MetricSummaryEntry = {
  __typename?: 'MetricSummaryEntry';
  label: Scalars['String'];
  value: Scalars['Float'];
};

export type MetricSummaryInput = {
  interval: MetricInterval;
  refresh?: InputMaybe<Scalars['Boolean']>;
  types: Array<MetricType>;
};

export enum MetricType {
  AverageOrderValue = 'AverageOrderValue',
  OrderCount = 'OrderCount',
  OrderTotal = 'OrderTotal'
}

export type MimeTypeError = ErrorResult & {
  __typename?: 'MimeTypeError';
  errorCode: ErrorCode;
  fileName: Scalars['String'];
  message: Scalars['String'];
  mimeType: Scalars['String'];
};

export enum MiniAppsType {
  Attendance = 'ATTENDANCE',
  Customers = 'CUSTOMERS',
  Debug = 'DEBUG',
  Inventory = 'INVENTORY',
  Menu = 'MENU',
  Orders = 'ORDERS',
  Outlet = 'OUTLET',
  Report = 'REPORT',
  Settings = 'SETTINGS',
  Stocks = 'STOCKS'
}

/** Returned if a PromotionCondition has neither a couponCode nor any conditions set */
export type MissingConditionsError = ErrorResult & {
  __typename?: 'MissingConditionsError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export enum MobileVerificationResponse {
  Calimed = 'CALIMED',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  Invalid = 'INVALID',
  Success = 'SUCCESS',
  Verified = 'VERIFIED'
}

export type MobileVerificationResponseDetail = {
  __typename?: 'MobileVerificationResponseDetail';
  status: MobileVerificationResponse;
  token?: Maybe<Scalars['String']>;
};

export enum MobileVerificationStatus {
  Claimed = 'CLAIMED',
  Invalid = 'INVALID',
  Unclaimed = 'UNCLAIMED',
  Verified = 'VERIFIED'
}

export enum MobileVerificationType {
  PasswordResetRequested = 'PASSWORD_RESET_REQUESTED',
  UpdatePassword = 'UPDATE_PASSWORD',
  UpdateSensitiveData = 'UPDATE_SENSITIVE_DATA',
  VerifyRegistration = 'VERIFY_REGISTRATION',
  VerifySignIn = 'VERIFY_SIGN_IN'
}

export type Modifier = Node & {
  __typename?: 'Modifier';
  assets: Array<Asset>;
  collections: Array<Collection>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  enabled: Scalars['Boolean'];
  facetValues: Array<FacetValue>;
  featuredAsset?: Maybe<Asset>;
  id: Scalars['ID'];
  languageCode: LanguageCode;
  modifierGroups: Array<ModifierGroup>;
  name: Scalars['String'];
  optionGroups: Array<ProductOptionGroup>;
  slug: Scalars['String'];
  testing?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  variantList: ProductVariantList;
  variants: Array<ProductVariant>;
};


export type ModifierVariantListArgs = {
  options?: InputMaybe<ProductVariantListOptions>;
};

export type ModifierFilterParameter = {
  _and?: InputMaybe<Array<ModifierFilterParameter>>;
  _or?: InputMaybe<Array<ModifierFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  description?: InputMaybe<StringOperators>;
  enabled?: InputMaybe<BooleanOperators>;
  id?: InputMaybe<IdOperators>;
  languageCode?: InputMaybe<StringOperators>;
  name?: InputMaybe<StringOperators>;
  slug?: InputMaybe<StringOperators>;
  testing?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type ModifierGroup = {
  __typename?: 'ModifierGroup';
  group: Scalars['String'];
  maxSelect?: Maybe<Scalars['Int']>;
  minSelect?: Maybe<Scalars['Int']>;
  modifiers: Array<Product>;
};

export type ModifierGroupFilterParameter = {
  _and?: InputMaybe<Array<ModifierGroupFilterParameter>>;
  _or?: InputMaybe<Array<ModifierGroupFilterParameter>>;
  code?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  group?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  languageCode?: InputMaybe<StringOperators>;
  maxSelect?: InputMaybe<NumberOperators>;
  minSelect?: InputMaybe<NumberOperators>;
  name?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type ModifierGroupList = PaginatedList & {
  __typename?: 'ModifierGroupList';
  items: Array<FacetValue>;
  totalItems: Scalars['Int'];
};

export type ModifierGroupListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<ModifierGroupFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<ModifierGroupSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type ModifierGroupSortParameter = {
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  group?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  maxSelect?: InputMaybe<SortOrder>;
  minSelect?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ModifierInput = {
  modifierId: Scalars['ID'];
  variants: Array<ModifierVariantInput>;
};

export type ModifierList = PaginatedList & {
  __typename?: 'ModifierList';
  items: Array<Product>;
  totalItems: Scalars['Int'];
};

export type ModifierListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<ModifierFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<ModifierSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type ModifierSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  testing?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ModifierVariantInput = {
  name: Scalars['String'];
  price: Scalars['Int'];
  quantity: Scalars['Int'];
  variantId: Scalars['ID'];
};

export type ModifierVariantSnapshot = {
  __typename?: 'ModifierVariantSnapshot';
  name: Scalars['String'];
  price: Scalars['Int'];
  quantity: Scalars['Int'];
  variantId: Scalars['String'];
};

export type ModifyOrderInput = {
  addItems?: InputMaybe<Array<AddItemInput>>;
  adjustOrderLines?: InputMaybe<Array<OrderLineInput>>;
  couponCodes?: InputMaybe<Array<Scalars['String']>>;
  customFields?: InputMaybe<UpdateOrderCustomFieldsInput>;
  dryRun: Scalars['Boolean'];
  note?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<ModifyOrderOptions>;
  orderId: Scalars['ID'];
  /**
   * Deprecated in v2.2.0. Use `refunds` instead to allow multiple refunds to be
   * applied in the case that multiple payment methods have been used on the order.
   */
  refund?: InputMaybe<AdministratorRefundInput>;
  refunds?: InputMaybe<Array<AdministratorRefundInput>>;
  /** Added in v2.2 */
  shippingMethodIds?: InputMaybe<Array<Scalars['ID']>>;
  surcharges?: InputMaybe<Array<SurchargeInput>>;
  updateBillingAddress?: InputMaybe<UpdateOrderAddressInput>;
  updateShippingAddress?: InputMaybe<UpdateOrderAddressInput>;
};

export type ModifyOrderOptions = {
  freezePromotions?: InputMaybe<Scalars['Boolean']>;
  recalculateShipping?: InputMaybe<Scalars['Boolean']>;
};

export type ModifyOrderResult = CouponCodeExpiredError | CouponCodeInvalidError | CouponCodeLimitError | IneligibleShippingMethodError | InsufficientStockError | NegativeQuantityError | NoChangesSpecifiedError | Order | OrderLimitError | OrderModificationStateError | PaymentMethodMissingError | RefundPaymentIdMissingError;

export type MoveCollectionInput = {
  collectionId: Scalars['ID'];
  index: Scalars['Int'];
  parentId: Scalars['ID'];
};

export type MoveOutletTableAreaInput = {
  index: Scalars['Int'];
  outletTableAreaId: Scalars['ID'];
};

/** Returned if an operation has specified OrderLines from multiple Orders */
export type MultipleOrderError = ErrorResult & {
  __typename?: 'MultipleOrderError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activateDevice: Scalars['String'];
  /** Add Customers to a CustomerGroup */
  addCustomersToGroup: CustomerGroup;
  addFulfillmentToOrder: AddFulfillmentToOrderResult;
  /** Adds an item to the draft Order. */
  addItemToDraftOrder: UpdateOrderItemsResult;
  addItemToOrderWithAddOn: Order;
  /**
   * Used to manually create a new Payment against an Order.
   * This can be used by an Administrator when an Order is in the ArrangingPayment state.
   *
   * It is also used when a completed Order
   * has been modified (using `modifyOrder`) and the price has increased. The extra payment
   * can then be manually arranged by the administrator, and the details used to create a new
   * Payment.
   */
  addManualPaymentToOrder: AddManualPaymentToOrderResult;
  /** Add members to a Zone */
  addMembersToZone: Zone;
  addModifiersToGroup: Array<Maybe<Product>>;
  addNoteToCustomer: Customer;
  addNoteToOrder: Order;
  /** Add an OptionGroup to a Product */
  addOptionGroupToProduct: Product;
  addSnapshotToOrderLine: OrderLine;
  /** Adjusts a draft OrderLine. If custom fields are defined on the OrderLine entity, a third argument 'customFields' of type `OrderLineCustomFieldsInput` will be available. */
  adjustDraftOrderLine: UpdateOrderItemsResult;
  administratorClockEntryMutation: AdministratorClockEntry;
  /** Applies the given coupon code to the draft Order */
  applyCouponCodeToDraftOrder: ApplyCouponCodeResult;
  /** Assign assets to channel */
  assignAssetsToChannel: Array<Asset>;
  /** Assigns Collections to the specified Channel */
  assignCollectionsToChannel: Array<Collection>;
  /** Assigns Facets to the specified Channel */
  assignFacetsToChannel: Array<Facet>;
  assignModifierGroupsToProduct: Product;
  assignModifiersToProduct: Array<ProductModifier>;
  /** Assigns PaymentMethods to the specified Channel */
  assignPaymentMethodsToChannel: Array<PaymentMethod>;
  /** Assigns ProductVariants to the specified Channel */
  assignProductVariantsToChannel: Array<ProductVariant>;
  /** Assigns all ProductVariants of Product to the specified Channel */
  assignProductsToChannel: Array<Product>;
  /** Assigns Promotions to the specified Channel */
  assignPromotionsToChannel: Array<Promotion>;
  /** Assign a Role to an Administrator */
  assignRoleToAdministrator: Administrator;
  /** Assigns ShippingMethods to the specified Channel */
  assignShippingMethodsToChannel: Array<ShippingMethod>;
  assignShippingTierToShippingMethod: Array<ShippingTier>;
  /** Assigns StockLocations to the specified Channel */
  assignStockLocationsToChannel: Array<StockLocation>;
  assignUserToNotificationBlast: Array<Maybe<NotificationBlastUser>>;
  /** Authenticates the user using a named authentication strategy */
  authenticate: AuthenticationResult;
  cancelJob: Job;
  cancelOrder: CancelOrderResult;
  cancelPayment: CancelPaymentResult;
  completeStockTransferFromSync: StockTransferSynchronizerResponse;
  createAddressFromSync: AddressSynchronizerResponse;
  /** Create a new Administrator */
  createAdministrator: Administrator;
  createAppVersion: AppVersion;
  /** Create a new Asset */
  createAssets: Array<CreateAssetResult>;
  createBanner: Banner;
  /** Create a new Channel */
  createChannel: CreateChannelResult;
  createClockEntryFromSync: ClockEntrySynchronizerResponse;
  /** Create a new Collection */
  createCollection: Collection;
  createCollectionWithFacet: Collection;
  /** Create a new Country */
  createCountry: Country;
  /** Create a new Customer. If a password is provided, a new User will also be created an linked to the Customer. */
  createCustomer: CreateCustomerResult;
  /** Create a new Address and associate it with the Customer specified by customerId */
  createCustomerAddress: Address;
  createCustomerFromSync: CustomerSynchronizerResponse;
  /** Create a new CustomerGroup */
  createCustomerGroup: CustomerGroup;
  createDiscountPromotion: DiscountPromotion;
  /** Creates a draft Order */
  createDraftOrder: Order;
  /** Create a new Facet */
  createFacet: Facet;
  /** Create one or more FacetValues */
  createFacetValues: Array<FacetValue>;
  createFulfillmentChannel: FulfillmentChannel;
  createGiftOption: GiftOption;
  createGuestCustomer: Customer;
  createManyMembershipTierBenefit?: Maybe<Array<TierBenefit>>;
  createMembershipTier?: Maybe<MembershipTier>;
  createMobileAdministrator: Administrator;
  createModifierGroup: FacetValue;
  createNewAdministrator: Administrator;
  createNotificationBlast: NotificationBlast;
  createNotificationBlastQueue: NotificationBlastQueue;
  createNotificationBlastUser: NotificationBlastUser;
  createOneCollection: Collection;
  createOrderSychronizerOrder: OrderSychronizerResponse;
  createOutlet: Outlet;
  createOutletDevice: OutletDevice;
  createOutletFulfillments: Array<Maybe<OutletFulfillment>>;
  createOutletNotificationSychronizer: OutletNotificationSychronizerResponse;
  createOutletSetting?: Maybe<OutletSetting>;
  createOutletTable: OutletTable;
  createOutletTableArea: OutletTableArea;
  /** Create existing PaymentMethod */
  createPaymentMethod: PaymentMethod;
  createPdfGeneratorSychronizer: PdfGeneratorSychronizerResponse;
  createPrinter: Printer;
  createPrinterTag: PrinterTag;
  createPrinterTemplate: PrinterTemplate;
  /** Create a new Product */
  createProduct: Product;
  createProductFromSync: ProductSynchronizerResponse;
  createProductModifier: ProductModifier;
  /** Create a new ProductOption within a ProductOptionGroup */
  createProductOption: ProductOption;
  /** Create a new ProductOptionGroup */
  createProductOptionGroup: ProductOptionGroup;
  createProductVariantFromSync: ProductVariantSynchronizerResponse;
  /** Create a set of ProductVariants based on the OptionGroups assigned to the given Product */
  createProductVariants: Array<Maybe<ProductVariant>>;
  createPromoCode?: Maybe<PromoCode>;
  createPromotion: CreatePromotionResult;
  /** Create a new Province */
  createProvince: Province;
  createReservation: Reservation;
  createReward?: Maybe<Reward>;
  /** Create a new Role */
  createRole: Role;
  /** Create a new Seller */
  createSeller: Seller;
  /** Create a new ShippingMethod */
  createShippingMethod: ShippingMethod;
  createShippingTier: ShippingTier;
  createShippingTiers: Array<ShippingTier>;
  createStockLocation: StockLocation;
  createStockMovementFromSync: StockMovementSynchronizerResponse;
  createStockTransferFromSync: StockTransferSynchronizerResponse;
  createSychronizerPrinter: PrinterSychronizerResponse;
  createTableOrderQr: CreateTableOrderQrResult;
  /** Create a new Tag */
  createTag: Tag;
  /** Create a new TaxCategory */
  createTaxCategory: TaxCategory;
  /** Create a new TaxRate */
  createTaxRate: TaxRate;
  /** Create a new Zone */
  createZone: Zone;
  deleteAddressFromSync: AddressSynchronizerResponse;
  /** Delete an Administrator */
  deleteAdministrator: DeletionResponse;
  /** Delete multiple Administrators */
  deleteAdministrators: Array<DeletionResponse>;
  deleteAppVersion: AppVersion;
  /** Delete an Asset */
  deleteAsset: DeletionResponse;
  /** Delete multiple Assets */
  deleteAssets: DeletionResponse;
  deleteBanner: DeletionResponse;
  /** Delete a Channel */
  deleteChannel: DeletionResponse;
  /** Delete multiple Channels */
  deleteChannels: Array<DeletionResponse>;
  /** Delete a Collection and all of its descendants */
  deleteCollection: DeletionResponse;
  /** Delete multiple Collections and all of their descendants */
  deleteCollections: Array<DeletionResponse>;
  /** Delete multiple Countries */
  deleteCountries: Array<DeletionResponse>;
  /** Delete a Country */
  deleteCountry: DeletionResponse;
  /** Delete a Customer */
  deleteCustomer: DeletionResponse;
  /** Update an existing Address */
  deleteCustomerAddress: Success;
  /** Delete a CustomerGroup */
  deleteCustomerGroup: DeletionResponse;
  /** Delete multiple CustomerGroups */
  deleteCustomerGroups: Array<DeletionResponse>;
  deleteCustomerNote: DeletionResponse;
  /** Deletes Customers */
  deleteCustomers: Array<DeletionResponse>;
  deleteDiscountPromotion: DeletionResponse;
  /** Deletes a draft Order */
  deleteDraftOrder: DeletionResponse;
  /** Delete an existing Facet */
  deleteFacet: DeletionResponse;
  /** Delete one or more FacetValues */
  deleteFacetValues: Array<DeletionResponse>;
  /** Delete multiple existing Facets */
  deleteFacets: Array<DeletionResponse>;
  deleteFulfillmentChannel: Scalars['Boolean'];
  deleteGiftOption: DeletionResponse;
  deleteManyMembershipTierBenefit: Scalars['Boolean'];
  deleteMembershipTier: Scalars['Boolean'];
  deleteModifierGroup: DeletionResponse;
  deleteNotificationBlast: DeletionResponse;
  deleteNotificationBlastQueue: DeletionResponse;
  deleteNotificationBlastUser: DeletionResponse;
  deleteOneCollection: DeletionResponse;
  deleteOrderNote: DeletionResponse;
  deleteOutlet: DeletionResponse;
  deleteOutletFulfillment: DeletionResponse;
  deleteOutletTable: DeletionResponse;
  deleteOutletTableArea: DeletionResponse;
  /** Delete a PaymentMethod */
  deletePaymentMethod: DeletionResponse;
  /** Delete multiple PaymentMethods */
  deletePaymentMethods: Array<DeletionResponse>;
  deletePdfGeneratorSychronizer: PdfGeneratorSychronizerResponse;
  deletePrinter: Scalars['Boolean'];
  deletePrinterTag: Scalars['Boolean'];
  deletePrinterTemplate: Scalars['Boolean'];
  /** Delete a Product */
  deleteProduct: DeletionResponse;
  /** Delete a ProductOption */
  deleteProductOption: DeletionResponse;
  deleteProductReview: DeletionResponse;
  /** Delete a ProductVariant */
  deleteProductVariant: DeletionResponse;
  /** Delete multiple ProductVariants */
  deleteProductVariants: Array<DeletionResponse>;
  /** Delete multiple Products */
  deleteProducts: Array<DeletionResponse>;
  deletePromoCode: Scalars['Boolean'];
  deletePromotion: DeletionResponse;
  deletePromotions: Array<DeletionResponse>;
  /** Delete a Province */
  deleteProvince: DeletionResponse;
  deleteReward: Scalars['Boolean'];
  /** Delete an existing Role */
  deleteRole: DeletionResponse;
  /** Delete multiple Roles */
  deleteRoles: Array<DeletionResponse>;
  /** Delete a Seller */
  deleteSeller: DeletionResponse;
  /** Delete multiple Sellers */
  deleteSellers: Array<DeletionResponse>;
  /** Delete a ShippingMethod */
  deleteShippingMethod: DeletionResponse;
  /** Delete multiple ShippingMethods */
  deleteShippingMethods: Array<DeletionResponse>;
  deleteShippingTier: DeletionResponse;
  deleteStockLocation: DeletionResponse;
  deleteStockLocations: Array<DeletionResponse>;
  deleteSychronizerPrinter: PrinterSychronizerResponse;
  /** Delete an existing Tag */
  deleteTag: DeletionResponse;
  /** Deletes multiple TaxCategories */
  deleteTaxCategories: Array<DeletionResponse>;
  /** Deletes a TaxCategory */
  deleteTaxCategory: DeletionResponse;
  /** Delete a TaxRate */
  deleteTaxRate: DeletionResponse;
  /** Delete multiple TaxRates */
  deleteTaxRates: Array<DeletionResponse>;
  /** Delete a Zone */
  deleteZone: DeletionResponse;
  /** Delete a Zone */
  deleteZones: Array<DeletionResponse>;
  /**
   * Duplicate an existing entity using a specific EntityDuplicator.
   * Since v2.2.0.
   */
  duplicateEntity: DuplicateEntityResult;
  flushBufferedJobs: Success;
  generateAccessTokenForOutletDevice: Scalars['String'];
  generateDefaultPrinter: PrinterGeneration;
  giveUserPoints: Scalars['Boolean'];
  hideShowProductReview: ProductReview;
  importProducts?: Maybe<ImportInfo>;
  linkCustomerToOrder: Order;
  /**
   * Authenticates the user using the native authentication strategy. This mutation is an alias for authenticate({ native: { ... }})
   *
   * The `rememberMe` option applies when using cookie-based sessions, and if `true` it will set the maxAge of the session cookie
   * to 1 year.
   */
  login: NativeAuthenticationResult;
  logout: Success;
  makeTableAvailable: OutletTable;
  manualAdjustPoints?: Maybe<Array<PointTransactionHistory>>;
  manualAdjustStars?: Maybe<Array<StarTransactionHistory>>;
  markAsUsed?: Maybe<RedemptionHistory>;
  markForModeration: ProductReview;
  /**
   * Allows an Order to be modified after it has been completed by the Customer. The Order must first
   * be in the `Modifying` state.
   */
  modifyOrder: ModifyOrderResult;
  /** Move a Collection to a different parent or index */
  moveCollection: Collection;
  moveOutletTableArea: OutletTableArea;
  patchProductFacets: Scalars['JSON'];
  placeOrder: Order;
  processOrder: Array<Maybe<ProcessOrderResult>>;
  redeemForCustomer?: Maybe<RedemptionHistory>;
  redeemForMultipleCustomer?: Maybe<RedeemForMultipleCustomerResult>;
  refundOrder: RefundOrderResult;
  reindex: Job;
  /** Removes Collections from the specified Channel */
  removeCollectionsFromChannel: Array<Collection>;
  /** Removes the given coupon code from the draft Order */
  removeCouponCodeFromDraftOrder?: Maybe<Order>;
  /** Remove Customers from a CustomerGroup */
  removeCustomersFromGroup: CustomerGroup;
  /** Remove an OrderLine from the draft Order */
  removeDraftOrderLine: RemoveOrderItemsResult;
  removeDraftOrderLineWithModifier: RemoveOrderItemsResult;
  /** Removes Facets from the specified Channel */
  removeFacetsFromChannel: Array<RemoveFacetFromChannelResult>;
  removeManyProductModifier: DeletionResponse;
  /** Remove members from a Zone */
  removeMembersFromZone: Zone;
  removeModifiersFromGroup: Array<Scalars['String']>;
  /**
   * Remove an OptionGroup from a Product. If the OptionGroup is in use by any ProductVariants
   * the mutation will return a ProductOptionInUseError, and the OptionGroup will not be removed.
   * Setting the `force` argument to `true` will override this and remove the OptionGroup anyway,
   * as well as removing any of the group's options from the Product's ProductVariants.
   */
  removeOptionGroupFromProduct: RemoveOptionGroupFromProductResult;
  /** Removes PaymentMethods from the specified Channel */
  removePaymentMethodsFromChannel: Array<PaymentMethod>;
  removeProductModifier: DeletionResponse;
  /** Removes ProductVariants from the specified Channel */
  removeProductVariantsFromChannel: Array<ProductVariant>;
  /** Removes all ProductVariants of Product from the specified Channel */
  removeProductsFromChannel: Array<Product>;
  /** Removes Promotions from the specified Channel */
  removePromotionsFromChannel: Array<Promotion>;
  /** Remove all settled jobs in the given queues older than the given date. Returns the number of jobs deleted. */
  removeSettledJobs: Scalars['Int'];
  /** Removes ShippingMethods from the specified Channel */
  removeShippingMethodsFromChannel: Array<ShippingMethod>;
  /** Removes StockLocations from the specified Channel */
  removeStockLocationsFromChannel: Array<StockLocation>;
  requestMobileSignin: Scalars['Boolean'];
  runPendingSearchIndexUpdates: Success;
  setCustomerForDraftOrder: SetCustomerForDraftOrderResult;
  /** Sets the billing address for a draft Order */
  setDraftOrderBillingAddress: Order;
  /** Allows any custom fields to be set for the active order */
  setDraftOrderCustomFields: Order;
  /** Sets the shipping address for a draft Order */
  setDraftOrderShippingAddress: Order;
  /** Sets the shipping method by id, which can be obtained with the `eligibleShippingMethodsForDraftOrder` query */
  setDraftOrderShippingMethod: SetOrderShippingMethodResult;
  setEghlConfig: Scalars['Boolean'];
  setEghlCredentials: Scalars['Boolean'];
  setExpoToken: Scalars['Boolean'];
  setNovuCredentials: Scalars['Boolean'];
  setOrderCustomFields?: Maybe<Order>;
  /** Allows a different Customer to be assigned to an Order. Added in v2.2.0. */
  setOrderCustomer?: Maybe<Order>;
  setToken: Scalars['Boolean'];
  settlePayment: SettlePaymentResult;
  settleRefund: SettleRefundResult;
  toggleShouldReset?: Maybe<TenantSetting>;
  transitionFulfillmentToNewState: Fulfillment;
  transitionFulfillmentToState: TransitionFulfillmentToStateResult;
  transitionOrderToState?: Maybe<TransitionOrderToStateResult>;
  transitionPaymentToState: TransitionPaymentToStateResult;
  unsetExpoToken: Scalars['Boolean'];
  unsetToken: Scalars['Boolean'];
  /** Update the active (currently logged-in) Administrator */
  updateActiveAdministrator: Administrator;
  updateAddressFromSync: AddressSynchronizerResponse;
  /** Update an existing Administrator */
  updateAdministrator: Administrator;
  updateAdministratorAccountStatus: Administrator;
  updateAppVersion: AppVersion;
  /** Update an existing Asset */
  updateAsset: Asset;
  updateBanner: Banner;
  /** Update an existing Channel */
  updateChannel: UpdateChannelResult;
  updateClockEntryFromSync: ClockEntrySynchronizerResponse;
  /** Update an existing Collection */
  updateCollection: Collection;
  updateCollectionWithFacet: Collection;
  /** Update an existing Country */
  updateCountry: Country;
  updateCurrentSubscriberPreference?: Maybe<Array<Maybe<NovuSubscriberPreference>>>;
  /** Update an existing Customer */
  updateCustomer: UpdateCustomerResult;
  /** Update an existing Address */
  updateCustomerAddress: Address;
  updateCustomerFromSync: CustomerSynchronizerResponse;
  /** Update an existing CustomerGroup */
  updateCustomerGroup: CustomerGroup;
  updateCustomerNote: HistoryEntry;
  updateCustomerVerificationStatus: User;
  updateDeviceSettingFromSync: DeviceSettingSynchronizerResponse;
  updateDineInOrderStatus: Order;
  updateDiscountPromotion: DiscountPromotion;
  /** Update an existing Facet */
  updateFacet: Facet;
  /** Update one or more FacetValues */
  updateFacetValues: Array<FacetValue>;
  updateFulfillmentChannel: FulfillmentChannel;
  updateFulfillmentChannelToProduct?: Maybe<Array<FulfillmentChannel>>;
  updateFulfillmentMethod: Order;
  updateGiftOption: GiftOption;
  updateGlobalSettings: UpdateGlobalSettingsResult;
  updateManyMembershipTierBenefit?: Maybe<Array<TierBenefit>>;
  updateManyOutletSettings: Array<OutletSetting>;
  updateMembership?: Maybe<Membership>;
  updateMembershipTier?: Maybe<MembershipTier>;
  updateNotificationBlast: NotificationBlast;
  updateNotificationBlastQueue: NotificationBlastQueue;
  updateNotificationBlastUser: NotificationBlastUser;
  updateOrderInfo: Order;
  updateOrderNote: HistoryEntry;
  updateOrderState: Order;
  updateOrderSychronizerOrder: OrderSychronizerResponse;
  updateOutlet: Outlet;
  updateOutletDevice: OutletDevice;
  updateOutletFulfillments: Array<Maybe<OutletFulfillment>>;
  updateOutletNotificationSychronizer: OutletNotificationSychronizerResponse;
  updateOutletSetting?: Maybe<OutletSetting>;
  updateOutletTable: OutletTable;
  updateOutletTableArea: OutletTableArea;
  /** Update an existing PaymentMethod */
  updatePaymentMethod: PaymentMethod;
  updatePaymentSetting: TaxRate;
  updatePdfGeneratorSychronizer: PdfGeneratorSychronizerResponse;
  updatePointSetting?: Maybe<Array<PointSetting>>;
  updatePrinter: Printer;
  updatePrinterTag: PrinterTag;
  updatePrinterTemplate: PrinterTemplate;
  /** Update an existing Product */
  updateProduct: Product;
  updateProductFromSync: ProductSynchronizerResponse;
  /** Create a new ProductOption within a ProductOptionGroup */
  updateProductOption: ProductOption;
  /** Update an existing ProductOptionGroup */
  updateProductOptionGroup: ProductOptionGroup;
  updateProductVariantFromSync: ProductVariantSynchronizerResponse;
  /** Update existing ProductVariants */
  updateProductVariants: Array<Maybe<ProductVariant>>;
  /** Update multiple existing Products */
  updateProducts: Array<Product>;
  updatePromoCode?: Maybe<PromoCode>;
  updatePromotion: UpdatePromotionResult;
  /** Update an existing Province */
  updateProvince: Province;
  updateReservation: Reservation;
  updateReservationStatus: Reservation;
  updateReward?: Maybe<Reward>;
  /** Update an existing Role */
  updateRole: Role;
  /** Update an existing Seller */
  updateSeller: Seller;
  /** Update an existing ShippingMethod */
  updateShippingMethod: ShippingMethod;
  updateShippingTier: ShippingTier;
  updateStockLocation: StockLocation;
  updateStockTransferFromSync: StockTransferSynchronizerResponse;
  updateSychronizerPrinter: PrinterSychronizerResponse;
  /** Update an existing Tag */
  updateTag: Tag;
  /** Update an existing TaxCategory */
  updateTaxCategory: TaxCategory;
  /** Update an existing TaxRate */
  updateTaxRate: TaxRate;
  updateTemplate: Scalars['JSON'];
  /** Update an existing Zone */
  updateZone: Zone;
  upsertChannelInfo: Wysiwyg;
  upsertManyPolicyInfo: Array<Wysiwyg>;
  verifyMobileOtp: MobileVerificationResponseDetail;
};


export type MutationActivateDeviceArgs = {
  input: ActivateOutletDeviceInput;
};


export type MutationAddCustomersToGroupArgs = {
  customerGroupId: Scalars['ID'];
  customerIds: Array<Scalars['ID']>;
};


export type MutationAddFulfillmentToOrderArgs = {
  input: FulfillOrderInput;
};


export type MutationAddItemToDraftOrderArgs = {
  input: AddItemToDraftOrderInput;
  orderId: Scalars['ID'];
};


export type MutationAddItemToOrderWithAddOnArgs = {
  input: AddItemToOrderWithAddOnInput;
};


export type MutationAddManualPaymentToOrderArgs = {
  input: ManualPaymentInput;
};


export type MutationAddMembersToZoneArgs = {
  memberIds: Array<Scalars['ID']>;
  zoneId: Scalars['ID'];
};


export type MutationAddModifiersToGroupArgs = {
  input: AddModifiersToGroupInput;
};


export type MutationAddNoteToCustomerArgs = {
  input: AddNoteToCustomerInput;
};


export type MutationAddNoteToOrderArgs = {
  input: AddNoteToOrderInput;
};


export type MutationAddOptionGroupToProductArgs = {
  optionGroupId: Scalars['ID'];
  productId: Scalars['ID'];
};


export type MutationAddSnapshotToOrderLineArgs = {
  input: AddSnapshotInput;
};


export type MutationAdjustDraftOrderLineArgs = {
  input: AdjustDraftOrderLineInput;
  orderId: Scalars['ID'];
};


export type MutationAdministratorClockEntryMutationArgs = {
  input: AdministratorClockEntryInput;
};


export type MutationApplyCouponCodeToDraftOrderArgs = {
  couponCode: Scalars['String'];
  orderId: Scalars['ID'];
};


export type MutationAssignAssetsToChannelArgs = {
  input: AssignAssetsToChannelInput;
};


export type MutationAssignCollectionsToChannelArgs = {
  input: AssignCollectionsToChannelInput;
};


export type MutationAssignFacetsToChannelArgs = {
  input: AssignFacetsToChannelInput;
};


export type MutationAssignModifierGroupsToProductArgs = {
  input: AssignModifierGroupsToProductInput;
};


export type MutationAssignModifiersToProductArgs = {
  input: BulkProductModifierInput;
};


export type MutationAssignPaymentMethodsToChannelArgs = {
  input: AssignPaymentMethodsToChannelInput;
};


export type MutationAssignProductVariantsToChannelArgs = {
  input: AssignProductVariantsToChannelInput;
};


export type MutationAssignProductsToChannelArgs = {
  input: AssignProductsToChannelInput;
};


export type MutationAssignPromotionsToChannelArgs = {
  input: AssignPromotionsToChannelInput;
};


export type MutationAssignRoleToAdministratorArgs = {
  administratorId: Scalars['ID'];
  roleId: Scalars['ID'];
};


export type MutationAssignShippingMethodsToChannelArgs = {
  input: AssignShippingMethodsToChannelInput;
};


export type MutationAssignShippingTierToShippingMethodArgs = {
  input: Array<InputMaybe<AssignShippingTierInput>>;
  shippingMethodId: Scalars['ID'];
};


export type MutationAssignStockLocationsToChannelArgs = {
  input: AssignStockLocationsToChannelInput;
};


export type MutationAssignUserToNotificationBlastArgs = {
  notificationBlastId: Scalars['ID'];
  users: Array<AssignBlastableUserInput>;
};


export type MutationAuthenticateArgs = {
  input: AuthenticationInput;
  rememberMe?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCancelJobArgs = {
  jobId: Scalars['ID'];
};


export type MutationCancelOrderArgs = {
  input: CancelOrderInput;
};


export type MutationCancelPaymentArgs = {
  id: Scalars['ID'];
};


export type MutationCompleteStockTransferFromSyncArgs = {
  input: StockTransferSyncInput;
};


export type MutationCreateAddressFromSyncArgs = {
  input: AddressInputPushRow;
};


export type MutationCreateAdministratorArgs = {
  input: CreateAdministratorInput;
};


export type MutationCreateAppVersionArgs = {
  input: CreateAppVersionInput;
};


export type MutationCreateAssetsArgs = {
  input: Array<CreateAssetInput>;
};


export type MutationCreateBannerArgs = {
  input: CreateBannerInput;
};


export type MutationCreateChannelArgs = {
  input: CreateChannelInput;
};


export type MutationCreateClockEntryFromSyncArgs = {
  input: AdministratorClockEntryInputPushRow;
};


export type MutationCreateCollectionArgs = {
  input: CreateCollectionInput;
};


export type MutationCreateCollectionWithFacetArgs = {
  input: CreateCollectionProductInput;
};


export type MutationCreateCountryArgs = {
  input: CreateCountryInput;
};


export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
  password?: InputMaybe<Scalars['String']>;
};


export type MutationCreateCustomerAddressArgs = {
  customerId: Scalars['ID'];
  input: CreateAddressInput;
};


export type MutationCreateCustomerFromSyncArgs = {
  input: CustomerInputPushRow;
};


export type MutationCreateCustomerGroupArgs = {
  input: CreateCustomerGroupInput;
};


export type MutationCreateDiscountPromotionArgs = {
  input: CreateDiscountPromotionInput;
};


export type MutationCreateFacetArgs = {
  input: CreateFacetInput;
};


export type MutationCreateFacetValuesArgs = {
  input: Array<CreateFacetValueInput>;
};


export type MutationCreateFulfillmentChannelArgs = {
  input: CreateFulfillmentChannelInput;
};


export type MutationCreateGiftOptionArgs = {
  input: CreateGiftOptionInput;
};


export type MutationCreateGuestCustomerArgs = {
  input: CreateGuestCustomerInput;
};


export type MutationCreateManyMembershipTierBenefitArgs = {
  input: Array<CreateMembershipTierBenefitInput>;
};


export type MutationCreateMembershipTierArgs = {
  input: CreateMembershipTierInput;
};


export type MutationCreateMobileAdministratorArgs = {
  input: RegisterAdminMobileInput;
};


export type MutationCreateModifierGroupArgs = {
  input: CreateModifierGroupInput;
};


export type MutationCreateNewAdministratorArgs = {
  input: CreateNewAdministratorInput;
};


export type MutationCreateNotificationBlastArgs = {
  input: CreateNotificationBlastInput;
};


export type MutationCreateNotificationBlastQueueArgs = {
  input: CreateBlastQueueInput;
};


export type MutationCreateNotificationBlastUserArgs = {
  input: CreateBlastableUserInput;
};


export type MutationCreateOneCollectionArgs = {
  input: CreateOneCollectionInput;
};


export type MutationCreateOrderSychronizerOrderArgs = {
  input: OrderInputPushRow;
};


export type MutationCreateOutletArgs = {
  input: CreateOutletInput;
};


export type MutationCreateOutletDeviceArgs = {
  input: CreateOutletDeviceInput;
};


export type MutationCreateOutletFulfillmentsArgs = {
  input: CreateOutletFulfillmentInput;
};


export type MutationCreateOutletNotificationSychronizerArgs = {
  input: OutletNotificationInputPushRow;
};


export type MutationCreateOutletSettingArgs = {
  input?: InputMaybe<CreateOutletSettingInput>;
};


export type MutationCreateOutletTableArgs = {
  input: CreateOutletTableInput;
};


export type MutationCreateOutletTableAreaArgs = {
  input: CreateOutletTableAreaInput;
};


export type MutationCreatePaymentMethodArgs = {
  input: CreatePaymentMethodInput;
};


export type MutationCreatePdfGeneratorSychronizerArgs = {
  input: PdfGeneratorInputPushRow;
};


export type MutationCreatePrinterArgs = {
  input: CreatePrinterInput;
};


export type MutationCreatePrinterTagArgs = {
  input: CreatePrinterTagInput;
};


export type MutationCreatePrinterTemplateArgs = {
  input: CreatePrinterTemplateInput;
};


export type MutationCreateProductArgs = {
  input: CreateProductInput;
};


export type MutationCreateProductFromSyncArgs = {
  input: ProductInputPushRow;
};


export type MutationCreateProductModifierArgs = {
  input: ProductModifierInput;
};


export type MutationCreateProductOptionArgs = {
  input: CreateProductOptionInput;
};


export type MutationCreateProductOptionGroupArgs = {
  input: CreateProductOptionGroupInput;
};


export type MutationCreateProductVariantFromSyncArgs = {
  input: ProductVariantInputPushRow;
};


export type MutationCreateProductVariantsArgs = {
  input: Array<CreateProductVariantInput>;
};


export type MutationCreatePromoCodeArgs = {
  input: CreatePromoCodeInput;
};


export type MutationCreatePromotionArgs = {
  input: CreatePromotionInput;
};


export type MutationCreateProvinceArgs = {
  input: CreateProvinceInput;
};


export type MutationCreateReservationArgs = {
  input: CreateReservationInput;
};


export type MutationCreateRewardArgs = {
  input: CreateRewardInput;
};


export type MutationCreateRoleArgs = {
  input: CreateRoleInput;
};


export type MutationCreateSellerArgs = {
  input: CreateSellerInput;
};


export type MutationCreateShippingMethodArgs = {
  input: CreateShippingMethodInput;
};


export type MutationCreateShippingTierArgs = {
  input: CreateShippingTierInput;
};


export type MutationCreateShippingTiersArgs = {
  input: Array<CreateShippingTierInput>;
};


export type MutationCreateStockLocationArgs = {
  input: CreateStockLocationInput;
};


export type MutationCreateStockMovementFromSyncArgs = {
  input: StockMovementInputPushRow;
};


export type MutationCreateStockTransferFromSyncArgs = {
  input: StockTransferInputPushRow;
};


export type MutationCreateSychronizerPrinterArgs = {
  input: PrinterInputPushRow;
};


export type MutationCreateTableOrderQrArgs = {
  input: CreateTableOrderQrInput;
};


export type MutationCreateTagArgs = {
  input: CreateTagInput;
};


export type MutationCreateTaxCategoryArgs = {
  input: CreateTaxCategoryInput;
};


export type MutationCreateTaxRateArgs = {
  input: CreateTaxRateInput;
};


export type MutationCreateZoneArgs = {
  input: CreateZoneInput;
};


export type MutationDeleteAddressFromSyncArgs = {
  input: AddressInputPushRow;
};


export type MutationDeleteAdministratorArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteAdministratorsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteAppVersionArgs = {
  input: DeleteAppVersionInput;
};


export type MutationDeleteAssetArgs = {
  input: DeleteAssetInput;
};


export type MutationDeleteAssetsArgs = {
  input: DeleteAssetsInput;
};


export type MutationDeleteBannerArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteChannelArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteChannelsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteCollectionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCollectionsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteCountriesArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteCountryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCustomerArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCustomerAddressArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCustomerGroupArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCustomerGroupsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteCustomerNoteArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCustomersArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteDiscountPromotionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteDraftOrderArgs = {
  orderId: Scalars['ID'];
};


export type MutationDeleteFacetArgs = {
  force?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};


export type MutationDeleteFacetValuesArgs = {
  force?: InputMaybe<Scalars['Boolean']>;
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteFacetsArgs = {
  force?: InputMaybe<Scalars['Boolean']>;
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteFulfillmentChannelArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteGiftOptionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteManyMembershipTierBenefitArgs = {
  ids?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationDeleteMembershipTierArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteModifierGroupArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteNotificationBlastArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteNotificationBlastQueueArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteNotificationBlastUserArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOneCollectionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOrderNoteArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOutletArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOutletFulfillmentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOutletTableArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOutletTableAreaArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePaymentMethodArgs = {
  force?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};


export type MutationDeletePaymentMethodsArgs = {
  force?: InputMaybe<Scalars['Boolean']>;
  ids: Array<Scalars['ID']>;
};


export type MutationDeletePdfGeneratorSychronizerArgs = {
  input: PdfGeneratorInputPushRow;
};


export type MutationDeletePrinterArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePrinterTagArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePrinterTemplateArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteProductArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteProductOptionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteProductReviewArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteProductVariantArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteProductVariantsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteProductsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeletePromoCodeArgs = {
  id: Scalars['String'];
};


export type MutationDeletePromotionArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePromotionsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteProvinceArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteRewardArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteRoleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteRolesArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteSellerArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSellersArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteShippingMethodArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteShippingMethodsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteShippingTierArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteStockLocationArgs = {
  input: DeleteStockLocationInput;
};


export type MutationDeleteStockLocationsArgs = {
  input: Array<DeleteStockLocationInput>;
};


export type MutationDeleteSychronizerPrinterArgs = {
  input: PrinterInputPushRow;
};


export type MutationDeleteTagArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTaxCategoriesArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteTaxCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTaxRateArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTaxRatesArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteZoneArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteZonesArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDuplicateEntityArgs = {
  input: DuplicateEntityInput;
};


export type MutationFlushBufferedJobsArgs = {
  bufferIds?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationGenerateAccessTokenForOutletDeviceArgs = {
  input: GenerateAccessTokenOutletDeviceInput;
};


export type MutationGiveUserPointsArgs = {
  userId: Scalars['ID'];
};


export type MutationHideShowProductReviewArgs = {
  input: HideShowProductReviewInput;
};


export type MutationImportProductsArgs = {
  csvFile: Scalars['Upload'];
};


export type MutationLinkCustomerToOrderArgs = {
  input: LinkCustomerToOrderInput;
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  rememberMe?: InputMaybe<Scalars['Boolean']>;
  username: Scalars['String'];
};


export type MutationMakeTableAvailableArgs = {
  id: Scalars['ID'];
};


export type MutationManualAdjustPointsArgs = {
  input: ManualAdjustPointInput;
};


export type MutationManualAdjustStarsArgs = {
  input: ManualAdjustStarInput;
};


export type MutationMarkAsUsedArgs = {
  input: UpdateRedemptionHistoryIsUsedInput;
};


export type MutationMarkForModerationArgs = {
  id: Scalars['ID'];
  markForModeration: Scalars['Boolean'];
};


export type MutationModifyOrderArgs = {
  input: ModifyOrderInput;
};


export type MutationMoveCollectionArgs = {
  input: MoveCollectionInput;
};


export type MutationMoveOutletTableAreaArgs = {
  input: MoveOutletTableAreaInput;
};


export type MutationPatchProductFacetsArgs = {
  products: Array<ProductFacetInput>;
};


export type MutationPlaceOrderArgs = {
  input: PlaceOrderInput;
};


export type MutationProcessOrderArgs = {
  input?: InputMaybe<ProcessOrderInput>;
};


export type MutationRedeemForCustomerArgs = {
  input: CreateRedemptionHistoryInput;
};


export type MutationRedeemForMultipleCustomerArgs = {
  input: CreateMultipleRedemptionHistoryInput;
};


export type MutationRefundOrderArgs = {
  input: RefundOrderInput;
};


export type MutationRemoveCollectionsFromChannelArgs = {
  input: RemoveCollectionsFromChannelInput;
};


export type MutationRemoveCouponCodeFromDraftOrderArgs = {
  couponCode: Scalars['String'];
  orderId: Scalars['ID'];
};


export type MutationRemoveCustomersFromGroupArgs = {
  customerGroupId: Scalars['ID'];
  customerIds: Array<Scalars['ID']>;
};


export type MutationRemoveDraftOrderLineArgs = {
  orderId: Scalars['ID'];
  orderLineId: Scalars['ID'];
};


export type MutationRemoveDraftOrderLineWithModifierArgs = {
  orderId: Scalars['ID'];
  orderLineId: Scalars['ID'];
};


export type MutationRemoveFacetsFromChannelArgs = {
  input: RemoveFacetsFromChannelInput;
};


export type MutationRemoveManyProductModifierArgs = {
  input: BulkProductModifierInput;
};


export type MutationRemoveMembersFromZoneArgs = {
  memberIds: Array<Scalars['ID']>;
  zoneId: Scalars['ID'];
};


export type MutationRemoveModifiersFromGroupArgs = {
  modifierGroupId: Scalars['String'];
  modifierIds: Array<Scalars['String']>;
};


export type MutationRemoveOptionGroupFromProductArgs = {
  force?: InputMaybe<Scalars['Boolean']>;
  optionGroupId: Scalars['ID'];
  productId: Scalars['ID'];
};


export type MutationRemovePaymentMethodsFromChannelArgs = {
  input: RemovePaymentMethodsFromChannelInput;
};


export type MutationRemoveProductModifierArgs = {
  input: ProductModifierInput;
};


export type MutationRemoveProductVariantsFromChannelArgs = {
  input: RemoveProductVariantsFromChannelInput;
};


export type MutationRemoveProductsFromChannelArgs = {
  input: RemoveProductsFromChannelInput;
};


export type MutationRemovePromotionsFromChannelArgs = {
  input: RemovePromotionsFromChannelInput;
};


export type MutationRemoveSettledJobsArgs = {
  olderThan?: InputMaybe<Scalars['DateTime']>;
  queueNames?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationRemoveShippingMethodsFromChannelArgs = {
  input: RemoveShippingMethodsFromChannelInput;
};


export type MutationRemoveStockLocationsFromChannelArgs = {
  input: RemoveStockLocationsFromChannelInput;
};


export type MutationRequestMobileSigninArgs = {
  input: AdminRequestSigninInput;
};


export type MutationSetCustomerForDraftOrderArgs = {
  customerId?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<CreateCustomerInput>;
  orderId: Scalars['ID'];
};


export type MutationSetDraftOrderBillingAddressArgs = {
  input: CreateAddressInput;
  orderId: Scalars['ID'];
};


export type MutationSetDraftOrderCustomFieldsArgs = {
  input: UpdateOrderInput;
  orderId: Scalars['ID'];
};


export type MutationSetDraftOrderShippingAddressArgs = {
  input: CreateAddressInput;
  orderId: Scalars['ID'];
};


export type MutationSetDraftOrderShippingMethodArgs = {
  orderId: Scalars['ID'];
  shippingMethodId: Scalars['ID'];
};


export type MutationSetEghlConfigArgs = {
  config: EghlConfigInput;
};


export type MutationSetEghlCredentialsArgs = {
  credentials: EghlCredentialsInput;
};


export type MutationSetExpoTokenArgs = {
  token: Scalars['String'];
};


export type MutationSetNovuCredentialsArgs = {
  input?: InputMaybe<NovuCredentialInput>;
};


export type MutationSetOrderCustomFieldsArgs = {
  input: UpdateOrderInput;
};


export type MutationSetOrderCustomerArgs = {
  input: SetOrderCustomerInput;
};


export type MutationSetTokenArgs = {
  token: Scalars['String'];
};


export type MutationSettlePaymentArgs = {
  id: Scalars['ID'];
};


export type MutationSettleRefundArgs = {
  input: SettleRefundInput;
};


export type MutationToggleShouldResetArgs = {
  input: ToggleShouldResetInput;
};


export type MutationTransitionFulfillmentToNewStateArgs = {
  fulfillmentInput?: InputMaybe<FulfillmentMethodInput>;
  id: Scalars['ID'];
  state: FulfillmentState;
};


export type MutationTransitionFulfillmentToStateArgs = {
  id: Scalars['ID'];
  state: Scalars['String'];
};


export type MutationTransitionOrderToStateArgs = {
  id: Scalars['ID'];
  state: Scalars['String'];
};


export type MutationTransitionPaymentToStateArgs = {
  id: Scalars['ID'];
  state: Scalars['String'];
};


export type MutationUnsetExpoTokenArgs = {
  token: Scalars['String'];
};


export type MutationUnsetTokenArgs = {
  token: Scalars['String'];
};


export type MutationUpdateActiveAdministratorArgs = {
  input: UpdateActiveAdministratorInput;
};


export type MutationUpdateAddressFromSyncArgs = {
  input: AddressInputPushRow;
};


export type MutationUpdateAdministratorArgs = {
  input: UpdateAdministratorInput;
};


export type MutationUpdateAdministratorAccountStatusArgs = {
  input: UpdateAdministratorAccountStatusInput;
};


export type MutationUpdateAppVersionArgs = {
  input: UpdateAppVersionInput;
};


export type MutationUpdateAssetArgs = {
  input: UpdateAssetInput;
};


export type MutationUpdateBannerArgs = {
  input: UpdateBannerInput;
};


export type MutationUpdateChannelArgs = {
  input: UpdateChannelInput;
};


export type MutationUpdateClockEntryFromSyncArgs = {
  input: AdministratorClockEntryInputPushRow;
};


export type MutationUpdateCollectionArgs = {
  input: UpdateCollectionInput;
};


export type MutationUpdateCollectionWithFacetArgs = {
  input: UpdateCollectionProductInput;
};


export type MutationUpdateCountryArgs = {
  input: UpdateCountryInput;
};


export type MutationUpdateCurrentSubscriberPreferenceArgs = {
  input: UpdatePreferenceInput;
  templateIds: Array<Scalars['String']>;
};


export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};


export type MutationUpdateCustomerAddressArgs = {
  input: UpdateAddressInput;
};


export type MutationUpdateCustomerFromSyncArgs = {
  input: CustomerInputPushRow;
};


export type MutationUpdateCustomerGroupArgs = {
  input: UpdateCustomerGroupInput;
};


export type MutationUpdateCustomerNoteArgs = {
  input: UpdateCustomerNoteInput;
};


export type MutationUpdateCustomerVerificationStatusArgs = {
  input: UpdateUserVerificationInput;
};


export type MutationUpdateDeviceSettingFromSyncArgs = {
  input: DeviceSettingInputPushRow;
};


export type MutationUpdateDineInOrderStatusArgs = {
  input: UpdateDineInOrderStatusInput;
};


export type MutationUpdateDiscountPromotionArgs = {
  input: UpdateDiscountPromotionInput;
};


export type MutationUpdateFacetArgs = {
  input: UpdateFacetInput;
};


export type MutationUpdateFacetValuesArgs = {
  input: Array<UpdateFacetValueInput>;
};


export type MutationUpdateFulfillmentChannelArgs = {
  input: UpdateFulfillmentChannelInput;
};


export type MutationUpdateFulfillmentChannelToProductArgs = {
  fulfillmentChannelIds: Array<Scalars['String']>;
  productId: Scalars['String'];
};


export type MutationUpdateFulfillmentMethodArgs = {
  input: AdminUpdateFulfillmentMethodInput;
};


export type MutationUpdateGiftOptionArgs = {
  input: UpdateGiftOptionInput;
};


export type MutationUpdateGlobalSettingsArgs = {
  input: UpdateGlobalSettingsInput;
};


export type MutationUpdateManyMembershipTierBenefitArgs = {
  input: Array<UpdateMembershipTierBenefitInput>;
};


export type MutationUpdateManyOutletSettingsArgs = {
  input?: InputMaybe<UpdateManyOutletSettingsInput>;
};


export type MutationUpdateMembershipArgs = {
  input: UpdateMembershipInput;
};


export type MutationUpdateMembershipTierArgs = {
  input: UpdateMembershipTierInput;
};


export type MutationUpdateNotificationBlastArgs = {
  input: UpdateNotificationBlastInput;
};


export type MutationUpdateNotificationBlastQueueArgs = {
  input: UpdateBlastQueueInput;
};


export type MutationUpdateNotificationBlastUserArgs = {
  input: UpdateBlastableUserInput;
};


export type MutationUpdateOrderInfoArgs = {
  input: UpdateOrderInfoInput;
};


export type MutationUpdateOrderNoteArgs = {
  input: UpdateOrderNoteInput;
};


export type MutationUpdateOrderStateArgs = {
  input: UpdateOrderStatusInput;
};


export type MutationUpdateOrderSychronizerOrderArgs = {
  input: OrderInputPushRow;
};


export type MutationUpdateOutletArgs = {
  input: UpdateOutletInput;
};


export type MutationUpdateOutletDeviceArgs = {
  input: UpdateOutletDeviceInput;
};


export type MutationUpdateOutletFulfillmentsArgs = {
  input: CreateOutletFulfillmentInput;
};


export type MutationUpdateOutletNotificationSychronizerArgs = {
  input: OutletNotificationInputPushRow;
};


export type MutationUpdateOutletSettingArgs = {
  input?: InputMaybe<UpdateOutletSettingInput>;
};


export type MutationUpdateOutletTableArgs = {
  input: UpdateOutletTableInput;
};


export type MutationUpdateOutletTableAreaArgs = {
  input: UpdateOutletTableAreaInput;
};


export type MutationUpdatePaymentMethodArgs = {
  input: UpdatePaymentMethodInput;
};


export type MutationUpdatePaymentSettingArgs = {
  input: UpdatePaymentSettingInput;
};


export type MutationUpdatePdfGeneratorSychronizerArgs = {
  input: PdfGeneratorInputPushRow;
};


export type MutationUpdatePointSettingArgs = {
  input: Array<UpdatePointSettingInput>;
};


export type MutationUpdatePrinterArgs = {
  input: UpdatePrinterInput;
};


export type MutationUpdatePrinterTagArgs = {
  input: UpdatePrinterTagInput;
};


export type MutationUpdatePrinterTemplateArgs = {
  input: UpdatePrinterTemplateInput;
};


export type MutationUpdateProductArgs = {
  input: UpdateProductInput;
};


export type MutationUpdateProductFromSyncArgs = {
  input: ProductInputPushRow;
};


export type MutationUpdateProductOptionArgs = {
  input: UpdateProductOptionInput;
};


export type MutationUpdateProductOptionGroupArgs = {
  input: UpdateProductOptionGroupInput;
};


export type MutationUpdateProductVariantFromSyncArgs = {
  input: ProductVariantInputPushRow;
};


export type MutationUpdateProductVariantsArgs = {
  input: Array<UpdateProductVariantInput>;
};


export type MutationUpdateProductsArgs = {
  input: Array<UpdateProductInput>;
};


export type MutationUpdatePromoCodeArgs = {
  input: UpdatePromoCodeInput;
};


export type MutationUpdatePromotionArgs = {
  input: UpdatePromotionInput;
};


export type MutationUpdateProvinceArgs = {
  input: UpdateProvinceInput;
};


export type MutationUpdateReservationArgs = {
  input: UpdateReservationInput;
};


export type MutationUpdateReservationStatusArgs = {
  input: UpdateReservationStatusInput;
};


export type MutationUpdateRewardArgs = {
  input: UpdateRewardInput;
};


export type MutationUpdateRoleArgs = {
  input: UpdateRoleInput;
};


export type MutationUpdateSellerArgs = {
  input: UpdateSellerInput;
};


export type MutationUpdateShippingMethodArgs = {
  input: UpdateShippingMethodInput;
};


export type MutationUpdateShippingTierArgs = {
  input: UpdateShippingTierInput;
};


export type MutationUpdateStockLocationArgs = {
  input: UpdateStockLocationInput;
};


export type MutationUpdateStockTransferFromSyncArgs = {
  input: StockTransferInputPushRow;
};


export type MutationUpdateSychronizerPrinterArgs = {
  input: PrinterInputPushRow;
};


export type MutationUpdateTagArgs = {
  input: UpdateTagInput;
};


export type MutationUpdateTaxCategoryArgs = {
  input: UpdateTaxCategoryInput;
};


export type MutationUpdateTaxRateArgs = {
  input: UpdateTaxRateInput;
};


export type MutationUpdateTemplateArgs = {
  input: UpdateTemplateInput;
};


export type MutationUpdateZoneArgs = {
  input: UpdateZoneInput;
};


export type MutationUpsertChannelInfoArgs = {
  content?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<WysiwygType>;
};


export type MutationUpsertManyPolicyInfoArgs = {
  input?: InputMaybe<Array<InputMaybe<WysiwygUpdateInput>>>;
};


export type MutationVerifyMobileOtpArgs = {
  input: VerifyOtpVerifyInput;
};

export type NativeAuthInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

/** Returned when attempting an operation that relies on the NativeAuthStrategy, if that strategy is not configured. */
export type NativeAuthStrategyError = ErrorResult & {
  __typename?: 'NativeAuthStrategyError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export type NativeAuthenticationResult = CurrentUser | InvalidCredentialsError | NativeAuthStrategyError;

/** Returned when attempting to set a negative OrderLine quantity. */
export type NegativeQuantityError = ErrorResult & {
  __typename?: 'NegativeQuantityError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

/**
 * Returned when invoking a mutation which depends on there being an active Order on the
 * current session.
 */
export type NoActiveOrderError = ErrorResult & {
  __typename?: 'NoActiveOrderError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

/** Returned when a call to modifyOrder fails to specify any changes */
export type NoChangesSpecifiedError = ErrorResult & {
  __typename?: 'NoChangesSpecifiedError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export type Node = {
  id: Scalars['ID'];
};

export type NonRedeemableMemberships = {
  __typename?: 'NonRedeemableMemberships';
  membership: Membership;
  reason: Scalars['String'];
};

/** Returned if an attempting to refund an Order but neither items nor shipping refund was specified */
export type NothingToRefundError = ErrorResult & {
  __typename?: 'NothingToRefundError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export type NotificationBlast = Node & {
  __typename?: 'NotificationBlast';
  blastableChannels: Array<BlastableChannel>;
  blastableUsers?: Maybe<Array<NotificationBlastUser>>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  link: Scalars['String'];
  message: Scalars['String'];
  name: Scalars['String'];
  queues?: Maybe<Array<NotificationBlastQueue>>;
  receiverType: BlastableReceiverType;
  subject: Scalars['String'];
  type: BlastContentType;
  updatedAt: Scalars['DateTime'];
};

export type NotificationBlastFilterParameter = {
  _and?: InputMaybe<Array<NotificationBlastFilterParameter>>;
  _or?: InputMaybe<Array<NotificationBlastFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  link?: InputMaybe<StringOperators>;
  message?: InputMaybe<StringOperators>;
  name?: InputMaybe<StringOperators>;
  queuesTriggers?: InputMaybe<DateOperators>;
  receiverType?: InputMaybe<StringOperators>;
  subject?: InputMaybe<StringOperators>;
  type?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type NotificationBlastList = PaginatedList & {
  __typename?: 'NotificationBlastList';
  items: Array<NotificationBlast>;
  totalItems: Scalars['Int'];
};

export type NotificationBlastListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<NotificationBlastFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<NotificationBlastSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type NotificationBlastQueue = Node & {
  __typename?: 'NotificationBlastQueue';
  NotificationBlast: NotificationBlast;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isSent: Scalars['Boolean'];
  notificationBlastId: Scalars['ID'];
  trigger: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type NotificationBlastQueueFilterParameter = {
  _and?: InputMaybe<Array<NotificationBlastQueueFilterParameter>>;
  _or?: InputMaybe<Array<NotificationBlastQueueFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  isSent?: InputMaybe<BooleanOperators>;
  notificationBlastId?: InputMaybe<IdOperators>;
  trigger?: InputMaybe<DateOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type NotificationBlastQueueList = PaginatedList & {
  __typename?: 'NotificationBlastQueueList';
  items: Array<NotificationBlastQueue>;
  totalItems: Scalars['Int'];
};

export type NotificationBlastQueueListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<NotificationBlastQueueFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<NotificationBlastQueueSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type NotificationBlastQueueSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isSent?: InputMaybe<SortOrder>;
  notificationBlastId?: InputMaybe<SortOrder>;
  trigger?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type NotificationBlastSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  link?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  queuesTriggers?: InputMaybe<SortOrder>;
  subject?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type NotificationBlastUser = Node & {
  __typename?: 'NotificationBlastUser';
  NotificationBlast: NotificationBlast;
  administrator?: Maybe<Administrator>;
  createdAt: Scalars['DateTime'];
  customer?: Maybe<Customer>;
  id: Scalars['ID'];
  notificationBlastId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['ID'];
  userType: BlastableRole;
};

export type NotificationBlastUserFilterParameter = {
  _and?: InputMaybe<Array<NotificationBlastUserFilterParameter>>;
  _or?: InputMaybe<Array<NotificationBlastUserFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  notificationBlastId?: InputMaybe<IdOperators>;
  updatedAt?: InputMaybe<DateOperators>;
  userId?: InputMaybe<IdOperators>;
  userType?: InputMaybe<StringOperators>;
};

export type NotificationBlastUserList = PaginatedList & {
  __typename?: 'NotificationBlastUserList';
  items: Array<NotificationBlastUser>;
  totalItems: Scalars['Int'];
};

export type NotificationBlastUserListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<NotificationBlastUserFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<NotificationBlastUserSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type NotificationBlastUserSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  notificationBlastId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type NotificationSetting = Node & {
  __typename?: 'NotificationSetting';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  marketingUpdate: Scalars['Boolean'];
  membershipUpdate: Scalars['Boolean'];
  orderUpdate: Scalars['Boolean'];
  reservationUpdate: Scalars['Boolean'];
  rewardUpdate: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['ID'];
};

export type NotificationSettingFilterParameter = {
  _and?: InputMaybe<Array<NotificationSettingFilterParameter>>;
  _or?: InputMaybe<Array<NotificationSettingFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  marketingUpdate?: InputMaybe<BooleanOperators>;
  membershipUpdate?: InputMaybe<BooleanOperators>;
  orderUpdate?: InputMaybe<BooleanOperators>;
  reservationUpdate?: InputMaybe<BooleanOperators>;
  rewardUpdate?: InputMaybe<BooleanOperators>;
  updatedAt?: InputMaybe<DateOperators>;
  userId?: InputMaybe<IdOperators>;
};

export type NotificationSettingList = PaginatedList & {
  __typename?: 'NotificationSettingList';
  items: Array<NotificationSetting>;
  totalItems: Scalars['Int'];
};

export type NotificationSettingListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<NotificationSettingFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<NotificationSettingSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type NotificationSettingSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type NovuCredentails = {
  __typename?: 'NovuCredentails';
  apiKey?: Maybe<Scalars['String']>;
  webhookKey?: Maybe<Scalars['String']>;
};

export type NovuCredentialInput = {
  apiKey: Scalars['String'];
  webhookKey: Scalars['String'];
};

export type NovuSubscriber = {
  __typename?: 'NovuSubscriber';
  avatar?: Maybe<Scalars['String']>;
  channels?: Maybe<Scalars['JSON']>;
  data?: Maybe<Scalars['JSON']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  subscriberId?: Maybe<Scalars['String']>;
};

export type NovuSubscriberPreference = {
  __typename?: 'NovuSubscriberPreference';
  preference: Preference;
  template: Template;
};

export type NovuTemplateInput = {
  templateGroupId: Scalars['String'];
  templateId: Scalars['String'];
};

export type NovuTemplateUpdateInput = {
  body: Scalars['String'];
  subject: Scalars['String'];
};

/** Operators for filtering on a list of Number fields */
export type NumberListOperators = {
  inList: Scalars['Float'];
};

/** Operators for filtering on a Int or Float field */
export type NumberOperators = {
  between?: InputMaybe<NumberRange>;
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
};

export type NumberRange = {
  end: Scalars['Float'];
  start: Scalars['Float'];
};

export enum OccasionType {
  Anniversary = 'ANNIVERSARY',
  Birthday = 'BIRTHDAY',
  Business = 'BUSINESS',
  Date = 'DATE',
  Family = 'FAMILY',
  Friends = 'FRIENDS'
}

export type Order = Node & {
  __typename?: 'Order';
  /** An order is active as long as the payment process has not been completed */
  active: Scalars['Boolean'];
  aggregateOrder?: Maybe<Order>;
  aggregateOrderId?: Maybe<Scalars['ID']>;
  billingAddress?: Maybe<OrderAddress>;
  channels: Array<Channel>;
  /** A unique code for the Order */
  code: Scalars['String'];
  /** An array of all coupon codes applied to the Order */
  couponCodes: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  currencyCode: CurrencyCode;
  customFields?: Maybe<OrderCustomFields>;
  customer?: Maybe<Customer>;
  customerSnapshot?: Maybe<CustomerSnapshot>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  dineInMetadata?: Maybe<DineInMetadata>;
  discounts: Array<Discount>;
  fulfillmentMethod?: Maybe<Scalars['String']>;
  fulfillments?: Maybe<Array<Fulfillment>>;
  history: HistoryEntryList;
  id: Scalars['ID'];
  invoiceCodes: Array<Scalars['String']>;
  latestPayment?: Maybe<Payment>;
  latestTimeline: OrderTimeline;
  lines: Array<OrderLine>;
  modifications: Array<OrderModification>;
  nextStates: Array<Scalars['String']>;
  /**
   * The date & time that the Order was placed, i.e. the Customer
   * completed the checkout and the Order is no longer "active"
   */
  orderPlacedAt?: Maybe<Scalars['DateTime']>;
  orderPlacementMetadata?: Maybe<OrderPlacementMetadata>;
  payablePayment?: Maybe<Payment>;
  payments?: Maybe<Array<Payment>>;
  /** Promotions applied to the order. Only gets populated after the payment process has completed. */
  promotions: Array<Promotion>;
  sellerOrders?: Maybe<Array<Order>>;
  shipping: Scalars['Money'];
  shippingAddress?: Maybe<OrderAddress>;
  shippingLines: Array<ShippingLine>;
  shippingWithTax: Scalars['Money'];
  state: Scalars['String'];
  /**
   * The subTotal is the total of all OrderLines in the Order. This figure also includes any Order-level
   * discounts which have been prorated (proportionally distributed) amongst the items of each OrderLine.
   * To get a total of all OrderLines which does not account for prorated discounts, use the
   * sum of `OrderLine.discountedLinePrice` values.
   */
  subTotal: Scalars['Money'];
  /** Same as subTotal, but inclusive of tax */
  subTotalWithTax: Scalars['Money'];
  /**
   * Surcharges are arbitrary modifications to the Order total which are neither
   * ProductVariants nor discounts resulting from applied Promotions. For example,
   * one-off discounts based on customer interaction, or surcharges based on payment
   * methods.
   */
  surcharges: Array<Surcharge>;
  /** A summary of the taxes being applied to this Order */
  taxSummary: Array<OrderTaxSummary>;
  timeline: Array<Maybe<OrderTimeline>>;
  /** Equal to subTotal plus shipping */
  total: Scalars['Money'];
  totalLinePrice: Scalars['Money'];
  totalLinePriceWithTax: Scalars['Money'];
  totalQuantity: Scalars['Int'];
  /** The final payable amount. Equal to subTotalWithTax plus shippingWithTax */
  totalWithTax: Scalars['Money'];
  type: OrderType;
  updatedAt: Scalars['DateTime'];
  variants?: Maybe<ProductVariantList>;
};


export type OrderHistoryArgs = {
  options?: InputMaybe<HistoryEntryListOptions>;
};


export type OrderVariantsArgs = {
  options?: InputMaybe<ProductVariantListOptions>;
};

export type OrderAddress = {
  __typename?: 'OrderAddress';
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  customFields?: Maybe<AddressCustomFields>;
  fullName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  streetLine1?: Maybe<Scalars['String']>;
  streetLine2?: Maybe<Scalars['String']>;
};

export type OrderAddressInput = {
  city?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  customFields?: InputMaybe<OrderCustomFieldInput>;
  fullName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  province?: InputMaybe<Scalars['String']>;
  streetLine1?: InputMaybe<Scalars['String']>;
  streetLine2?: InputMaybe<Scalars['String']>;
};

export type OrderCustomFieldInput = {
  cancellationRemark?: InputMaybe<Scalars['String']>;
  checkoutThrough?: InputMaybe<Scalars['String']>;
  customerSnapshot?: InputMaybe<Scalars['String']>;
  dineInMetadata?: InputMaybe<Scalars['String']>;
  giftFee?: InputMaybe<Scalars['Float']>;
  giftOption?: InputMaybe<GiftOptionInput>;
  orderPlacementMetadata?: InputMaybe<Scalars['String']>;
  pointAwarded?: InputMaybe<Scalars['Float']>;
  promoCodeId?: InputMaybe<Scalars['String']>;
  promoCodeName?: InputMaybe<Scalars['String']>;
  redemptionHistoryIds?: InputMaybe<Array<Scalars['String']>>;
  remark?: InputMaybe<Scalars['String']>;
  rewardId?: InputMaybe<Scalars['String']>;
  shippingMethodCode?: InputMaybe<Scalars['String']>;
  snapshot?: InputMaybe<Scalars['String']>;
  table?: InputMaybe<Scalars['String']>;
};

export type OrderCustomFields = {
  __typename?: 'OrderCustomFields';
  administrator?: Maybe<Administrator>;
  cancellationRemark?: Maybe<Scalars['String']>;
  checkoutThrough?: Maybe<Scalars['String']>;
  customShippingPrice?: Maybe<Scalars['Int']>;
  customerSnapshot?: Maybe<Scalars['String']>;
  dineInMetadata?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<Asset>>;
  giftFee?: Maybe<Scalars['Float']>;
  giftOption?: Maybe<GiftOption>;
  orderPlacementMetadata?: Maybe<Scalars['String']>;
  pointAwarded?: Maybe<Scalars['Int']>;
  posAcceptedAt?: Maybe<Scalars['DateTime']>;
  posAcceptedDevice?: Maybe<OutletDevice>;
  posCustomAmountDiscount?: Maybe<Scalars['Int']>;
  posCustomRateDiscount?: Maybe<Scalars['Int']>;
  posId?: Maybe<Scalars['String']>;
  posOrderCode?: Maybe<Scalars['String']>;
  posShippingMethods?: Maybe<Scalars['String']>;
  promoCodeId?: Maybe<Scalars['String']>;
  promoCodeName?: Maybe<Scalars['String']>;
  purchaseOutlet?: Maybe<Outlet>;
  purchaseStockLocation?: Maybe<StockLocation>;
  redemptionHistoryIds?: Maybe<Array<Scalars['String']>>;
  remark?: Maybe<Scalars['String']>;
  rewardId?: Maybe<Scalars['String']>;
  shippingMethodCode?: Maybe<Scalars['String']>;
  snapshot?: Maybe<Scalars['String']>;
  table?: Maybe<OutletTable>;
};

export type OrderFilterParameter = {
  _and?: InputMaybe<Array<OrderFilterParameter>>;
  _or?: InputMaybe<Array<OrderFilterParameter>>;
  active?: InputMaybe<BooleanOperators>;
  aggregateOrderId?: InputMaybe<IdOperators>;
  cancellationRemark?: InputMaybe<StringOperators>;
  checkoutThrough?: InputMaybe<StringOperators>;
  code?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  currencyCode?: InputMaybe<StringOperators>;
  customShippingPrice?: InputMaybe<NumberOperators>;
  customerFirstName?: InputMaybe<StringOperators>;
  customerId?: InputMaybe<IdOperators>;
  customerLastName?: InputMaybe<StringOperators>;
  customerSnapshot?: InputMaybe<StringOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  dineInMetadata?: InputMaybe<StringOperators>;
  fulfillmentMethod?: InputMaybe<StringOperators>;
  giftFee?: InputMaybe<NumberOperators>;
  id?: InputMaybe<IdOperators>;
  orderPlacedAt?: InputMaybe<DateOperators>;
  orderPlacementMetadata?: InputMaybe<StringOperators>;
  pointAwarded?: InputMaybe<NumberOperators>;
  posAcceptedAt?: InputMaybe<DateOperators>;
  posCustomAmountDiscount?: InputMaybe<NumberOperators>;
  posCustomRateDiscount?: InputMaybe<NumberOperators>;
  posId?: InputMaybe<StringOperators>;
  posOrderCode?: InputMaybe<StringOperators>;
  posShippingMethods?: InputMaybe<StringOperators>;
  promoCodeId?: InputMaybe<StringOperators>;
  promoCodeName?: InputMaybe<StringOperators>;
  purchaseOutletId?: InputMaybe<StringOperators>;
  redemptionHistoryIds?: InputMaybe<StringListOperators>;
  remark?: InputMaybe<StringOperators>;
  rewardId?: InputMaybe<StringOperators>;
  shipping?: InputMaybe<NumberOperators>;
  shippingMethodCode?: InputMaybe<StringOperators>;
  shippingWithTax?: InputMaybe<NumberOperators>;
  snapshot?: InputMaybe<StringOperators>;
  state?: InputMaybe<StringOperators>;
  subTotal?: InputMaybe<NumberOperators>;
  subTotalWithTax?: InputMaybe<NumberOperators>;
  total?: InputMaybe<NumberOperators>;
  totalLinePrice?: InputMaybe<NumberOperators>;
  totalLinePriceWithTax?: InputMaybe<NumberOperators>;
  totalQuantity?: InputMaybe<NumberOperators>;
  totalWithTax?: InputMaybe<NumberOperators>;
  transactionId?: InputMaybe<StringOperators>;
  type?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type OrderFulfillmentInput = {
  additionalProperties?: InputMaybe<AdditionalPropertiesInput>;
  dineInMetadata?: InputMaybe<DineInMetadataInput>;
  method: FulfillmentMethodType;
};

export type OrderInput = {
  active: Scalars['Boolean'];
  billingAddress?: InputMaybe<OrderAddressInput>;
  code: Scalars['String'];
  couponCodes?: InputMaybe<Array<Scalars['String']>>;
  createdAt: Scalars['Float'];
  currencyCode: Scalars['String'];
  customFields: OrderSyncCustomFieldInput;
  customer?: InputMaybe<Scalars['String']>;
  customerDetail?: InputMaybe<CustomerSyncInput>;
  deletedAt?: InputMaybe<Scalars['Float']>;
  dineInMetadata?: InputMaybe<DineInMetadataInput>;
  discounts: Array<DiscountInput>;
  fulfillmentMethod?: InputMaybe<Scalars['String']>;
  fulfillments?: InputMaybe<Array<FulfillmentInput>>;
  id: Scalars['ID'];
  invoiceCodes?: InputMaybe<Array<Scalars['String']>>;
  isDeleted?: Scalars['Boolean'];
  lines: Array<OrderLineSyncInput>;
  orderPlacedAt?: InputMaybe<Scalars['Float']>;
  orderPlacementMetadata?: InputMaybe<OrderPlacementMetadataInput>;
  payments: Array<PaymentInput>;
  salesInfo?: InputMaybe<OrderSalesInfoInput>;
  shipping: Scalars['Float'];
  shippingAddress?: InputMaybe<OrderAddressInput>;
  shippingWithTax: Scalars['Float'];
  state: Scalars['String'];
  stateHistories?: InputMaybe<Array<OrderStateHistoriesInput>>;
  subTotal: Scalars['Float'];
  subTotalWithTax: Scalars['Float'];
  surcharges: Array<SurchargeInput>;
  taxSummary?: InputMaybe<Array<InputMaybe<TaxSummaryInput>>>;
  total: Scalars['Float'];
  totalLinePrice: Scalars['Float'];
  totalLinePriceWithTax: Scalars['Float'];
  totalWithTax: Scalars['Float'];
  updatedAt: Scalars['Float'];
};

export type OrderInputPushRow = {
  assumedMasterState?: InputMaybe<OrderInput>;
  newDocumentState: OrderInput;
};

export type OrderInvoice = Node & {
  __typename?: 'OrderInvoice';
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  metadata: Scalars['JSON'];
  orderId: Scalars['String'];
  prefix?: Maybe<Scalars['String']>;
  status: InvoiceStatus;
  updatedAt: Scalars['DateTime'];
};

export type OrderInvoiceFilterParameter = {
  _and?: InputMaybe<Array<OrderInvoiceFilterParameter>>;
  _or?: InputMaybe<Array<OrderInvoiceFilterParameter>>;
  code?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  dueDate?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  orderId?: InputMaybe<StringOperators>;
  prefix?: InputMaybe<StringOperators>;
  status?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type OrderInvoiceInput = {
  code: Scalars['String'];
  createdAt: Scalars['Float'];
  deletedAt?: InputMaybe<Scalars['Float']>;
  dueDate?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['String']>;
  prefix?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt: Scalars['Float'];
};

export type OrderInvoiceList = PaginatedList & {
  __typename?: 'OrderInvoiceList';
  items: Array<OrderInvoice>;
  totalItems: Scalars['Int'];
};

export type OrderInvoiceListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<OrderInvoiceFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<OrderInvoiceSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type OrderInvoiceSortParameter = {
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  dueDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  orderId?: InputMaybe<SortOrder>;
  prefix?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** Returned when the maximum order size limit has been reached. */
export type OrderLimitError = ErrorResult & {
  __typename?: 'OrderLimitError';
  errorCode: ErrorCode;
  maxItems: Scalars['Int'];
  message: Scalars['String'];
};

export type OrderLine = Node & {
  __typename?: 'OrderLine';
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<OrderLineCustomFields>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  discountLinePriceWithAddon: Scalars['Money'];
  /** The price of the line including discounts, excluding tax */
  discountedLinePrice: Scalars['Money'];
  /** The price of the line including discounts and tax */
  discountedLinePriceWithTax: Scalars['Money'];
  /**
   * The price of a single unit including discounts, excluding tax.
   *
   * If Order-level discounts have been applied, this will not be the
   * actual taxable unit price (see `proratedUnitPrice`), but is generally the
   * correct price to display to customers to avoid confusion
   * about the internal handling of distributed Order-level discounts.
   */
  discountedUnitPrice: Scalars['Money'];
  /** The price of a single unit including discounts and tax */
  discountedUnitPriceWithTax: Scalars['Money'];
  discounts: Array<Discount>;
  featuredAsset?: Maybe<Asset>;
  fulfillmentLines?: Maybe<Array<FulfillmentLine>>;
  id: Scalars['ID'];
  isAddOn: Scalars['Boolean'];
  /** The total price of the line excluding tax and discounts. */
  linePrice: Scalars['Money'];
  linePriceWithAddon: Scalars['Money'];
  /** The total price of the line including tax but excluding discounts. */
  linePriceWithTax: Scalars['Money'];
  /** The total tax on this line */
  lineTax: Scalars['Money'];
  order: Order;
  /** The quantity at the time the Order was placed */
  orderPlacedQuantity: Scalars['Int'];
  productVariant: ProductVariant;
  /**
   * The actual line price, taking into account both item discounts _and_ prorated (proportionally-distributed)
   * Order-level discounts. This value is the true economic value of the OrderLine, and is used in tax
   * and refund calculations.
   */
  proratedLinePrice: Scalars['Money'];
  /** The proratedLinePrice including tax */
  proratedLinePriceWithTax: Scalars['Money'];
  /**
   * The actual unit price, taking into account both item discounts _and_ prorated (proportionally-distributed)
   * Order-level discounts. This value is the true economic value of the OrderItem, and is used in tax
   * and refund calculations.
   */
  proratedUnitPrice: Scalars['Money'];
  /** The proratedUnitPrice including tax */
  proratedUnitPriceWithTax: Scalars['Money'];
  /** The quantity of items purchased */
  quantity: Scalars['Int'];
  salesContributions?: Maybe<Array<OrderSalesContribution>>;
  snapshot?: Maybe<OrderLineSnapshot>;
  taxDiscountLinePriceWithAddon: Scalars['Money'];
  taxLines: Array<TaxLine>;
  taxRate: Scalars['Float'];
  taxedLinePriceWithAddon: Scalars['Money'];
  /** The price of a single unit, excluding tax and discounts */
  unitPrice: Scalars['Money'];
  /** Non-zero if the unitPrice has changed since it was initially added to Order */
  unitPriceChangeSinceAdded: Scalars['Money'];
  /** The price of a single unit, including tax but excluding discounts */
  unitPriceWithTax: Scalars['Money'];
  /** Non-zero if the unitPriceWithTax has changed since it was initially added to Order */
  unitPriceWithTaxChangeSinceAdded: Scalars['Money'];
  updatedAt: Scalars['DateTime'];
};

export type OrderLineAddonInput = {
  modifierGroup: Scalars['String'];
  modifierVariantIds: Array<Scalars['String']>;
};

export type OrderLineContributionsSyncInput = {
  administratorId: Scalars['String'];
  createdAt: Scalars['Float'];
  deletedAt?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  percentage: Scalars['Float'];
  remark?: InputMaybe<Scalars['String']>;
  updatedAt: Scalars['Float'];
};

export type OrderLineCustomFields = {
  __typename?: 'OrderLineCustomFields';
  metadata?: Maybe<Scalars['String']>;
  orderModifierReference?: Maybe<Scalars['String']>;
  posCustomAmountDiscount?: Maybe<Scalars['Int']>;
  posCustomRateDiscount?: Maybe<Scalars['Int']>;
  posId?: Maybe<Scalars['String']>;
  promoCodeIds?: Maybe<Array<Scalars['String']>>;
  rewardIds?: Maybe<Array<Scalars['String']>>;
  snapshot?: Maybe<Scalars['String']>;
  specialInstructions?: Maybe<Scalars['String']>;
};

export type OrderLineCustomFieldsInput = {
  metadata?: InputMaybe<Scalars['String']>;
  orderModifierReference?: InputMaybe<Scalars['String']>;
  posCustomAmountDiscount?: InputMaybe<Scalars['Int']>;
  posCustomRateDiscount?: InputMaybe<Scalars['Int']>;
  posId?: InputMaybe<Scalars['String']>;
  promoCodeIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  rewardIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  snapshot?: InputMaybe<Scalars['String']>;
  specialInstructions?: InputMaybe<Scalars['String']>;
};

export type OrderLineFilterParameter = {
  _and?: InputMaybe<Array<OrderLineFilterParameter>>;
  _or?: InputMaybe<Array<OrderLineFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  discountLinePriceWithAddon?: InputMaybe<NumberOperators>;
  discountedLinePrice?: InputMaybe<NumberOperators>;
  discountedLinePriceWithTax?: InputMaybe<NumberOperators>;
  discountedUnitPrice?: InputMaybe<NumberOperators>;
  discountedUnitPriceWithTax?: InputMaybe<NumberOperators>;
  id?: InputMaybe<IdOperators>;
  isAddOn?: InputMaybe<BooleanOperators>;
  linePrice?: InputMaybe<NumberOperators>;
  linePriceWithAddon?: InputMaybe<NumberOperators>;
  linePriceWithTax?: InputMaybe<NumberOperators>;
  lineTax?: InputMaybe<NumberOperators>;
  metadata?: InputMaybe<StringOperators>;
  orderModifierReference?: InputMaybe<StringOperators>;
  orderPlacedQuantity?: InputMaybe<NumberOperators>;
  posCustomAmountDiscount?: InputMaybe<NumberOperators>;
  posCustomRateDiscount?: InputMaybe<NumberOperators>;
  posId?: InputMaybe<StringOperators>;
  promoCodeIds?: InputMaybe<StringListOperators>;
  proratedLinePrice?: InputMaybe<NumberOperators>;
  proratedLinePriceWithTax?: InputMaybe<NumberOperators>;
  proratedUnitPrice?: InputMaybe<NumberOperators>;
  proratedUnitPriceWithTax?: InputMaybe<NumberOperators>;
  quantity?: InputMaybe<NumberOperators>;
  rewardIds?: InputMaybe<StringListOperators>;
  snapshot?: InputMaybe<StringOperators>;
  specialInstructions?: InputMaybe<StringOperators>;
  taxDiscountLinePriceWithAddon?: InputMaybe<NumberOperators>;
  taxRate?: InputMaybe<NumberOperators>;
  taxedLinePriceWithAddon?: InputMaybe<NumberOperators>;
  unitPrice?: InputMaybe<NumberOperators>;
  unitPriceChangeSinceAdded?: InputMaybe<NumberOperators>;
  unitPriceWithTax?: InputMaybe<NumberOperators>;
  unitPriceWithTaxChangeSinceAdded?: InputMaybe<NumberOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type OrderLineInput = {
  customFields?: InputMaybe<OrderLineCustomFieldsInput>;
  orderLineId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type OrderLineList = PaginatedList & {
  __typename?: 'OrderLineList';
  items: Array<OrderLine>;
  totalItems: Scalars['Int'];
};

export type OrderLineListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<OrderLineFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<OrderLineSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type OrderLineSalesInfoSyncInput = {
  contributions?: InputMaybe<Array<OrderLineContributionsSyncInput>>;
};

export type OrderLineSnapshot = {
  __typename?: 'OrderLineSnapshot';
  addons: Array<AddOnSnapshot>;
  productVariantId: Scalars['String'];
  quantity: Scalars['Int'];
  warrantyConfig?: Maybe<WarrantyConfig>;
};

export type OrderLineSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  discountLinePriceWithAddon?: InputMaybe<SortOrder>;
  discountedLinePrice?: InputMaybe<SortOrder>;
  discountedLinePriceWithTax?: InputMaybe<SortOrder>;
  discountedUnitPrice?: InputMaybe<SortOrder>;
  discountedUnitPriceWithTax?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  linePrice?: InputMaybe<SortOrder>;
  linePriceWithAddon?: InputMaybe<SortOrder>;
  linePriceWithTax?: InputMaybe<SortOrder>;
  lineTax?: InputMaybe<SortOrder>;
  metadata?: InputMaybe<SortOrder>;
  orderModifierReference?: InputMaybe<SortOrder>;
  orderPlacedQuantity?: InputMaybe<SortOrder>;
  posCustomAmountDiscount?: InputMaybe<SortOrder>;
  posCustomRateDiscount?: InputMaybe<SortOrder>;
  posId?: InputMaybe<SortOrder>;
  proratedLinePrice?: InputMaybe<SortOrder>;
  proratedLinePriceWithTax?: InputMaybe<SortOrder>;
  proratedUnitPrice?: InputMaybe<SortOrder>;
  proratedUnitPriceWithTax?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  snapshot?: InputMaybe<SortOrder>;
  specialInstructions?: InputMaybe<SortOrder>;
  taxDiscountLinePriceWithAddon?: InputMaybe<SortOrder>;
  taxRate?: InputMaybe<SortOrder>;
  taxedLinePriceWithAddon?: InputMaybe<SortOrder>;
  unitPrice?: InputMaybe<SortOrder>;
  unitPriceChangeSinceAdded?: InputMaybe<SortOrder>;
  unitPriceWithTax?: InputMaybe<SortOrder>;
  unitPriceWithTaxChangeSinceAdded?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OrderLineSyncAddonInput = {
  group: Scalars['String'];
  modifiers?: InputMaybe<Array<OrderlineSyncModifierInput>>;
};

export type OrderLineSyncCustomFieldsInput = {
  posCustomAmountDiscount?: InputMaybe<Scalars['Int']>;
  posCustomRateDiscount?: InputMaybe<Scalars['Int']>;
  printMetadata?: InputMaybe<OrderLineSyncCustomFieldsPrintMetadataInput>;
  specialInstructions?: InputMaybe<Scalars['String']>;
};

export type OrderLineSyncCustomFieldsPrintMetadataInput = {
  customer?: InputMaybe<Scalars['Int']>;
  kitchen?: InputMaybe<Scalars['Int']>;
};

export type OrderLineSyncInput = {
  createdAt: Scalars['Float'];
  customFields: OrderLineSyncCustomFieldsInput;
  deletedAt?: InputMaybe<Scalars['Float']>;
  discountLinePriceWithAddon?: Scalars['Float'];
  discountedLinePrice?: Scalars['Float'];
  discountedLinePriceWithTax?: Scalars['Float'];
  discountedUnitPrice?: Scalars['Float'];
  discountedUnitPriceWithTax?: Scalars['Float'];
  discounts?: InputMaybe<Array<DiscountInput>>;
  id: Scalars['ID'];
  isAddOn?: Scalars['Boolean'];
  isDeleted?: Scalars['Boolean'];
  linePrice?: Scalars['Float'];
  linePriceWithAddon?: Scalars['Float'];
  linePriceWithTax?: Scalars['Float'];
  lineTax?: Scalars['Float'];
  order?: InputMaybe<Scalars['String']>;
  orderPlacedQuantity?: InputMaybe<Scalars['Int']>;
  productVariant: Scalars['String'];
  quantity?: Scalars['Float'];
  refunded?: InputMaybe<Scalars['Boolean']>;
  salesInfo?: InputMaybe<OrderLineSalesInfoSyncInput>;
  snapshot?: InputMaybe<OrderlineSyncSnapshotInput>;
  taxDiscountLinePriceWithAddon?: Scalars['Float'];
  taxedLinePriceWithAddon?: Scalars['Float'];
  unitPrice?: Scalars['Float'];
  unitPriceWithTax?: Scalars['Float'];
  updatedAt: Scalars['Float'];
};

export type OrderList = PaginatedList & {
  __typename?: 'OrderList';
  items: Array<Order>;
  totalItems: Scalars['Int'];
};

export type OrderListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<OrderFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<OrderSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type OrderModification = Node & {
  __typename?: 'OrderModification';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isSettled: Scalars['Boolean'];
  lines: Array<OrderModificationLine>;
  note: Scalars['String'];
  payment?: Maybe<Payment>;
  priceChange: Scalars['Money'];
  refund?: Maybe<Refund>;
  surcharges?: Maybe<Array<Surcharge>>;
  updatedAt: Scalars['DateTime'];
};

/** Returned when attempting to modify the contents of an Order that is not in the `AddingItems` state. */
export type OrderModificationError = ErrorResult & {
  __typename?: 'OrderModificationError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export type OrderModificationLine = {
  __typename?: 'OrderModificationLine';
  modification: OrderModification;
  modificationId: Scalars['ID'];
  orderLine: OrderLine;
  orderLineId: Scalars['ID'];
  quantity: Scalars['Int'];
};

/** Returned when attempting to modify the contents of an Order that is not in the `Modifying` state. */
export type OrderModificationStateError = ErrorResult & {
  __typename?: 'OrderModificationStateError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export type OrderPlacementMetadata = {
  __typename?: 'OrderPlacementMetadata';
  date?: Maybe<Scalars['DateTime']>;
  isPlacedNow?: Maybe<Scalars['Boolean']>;
  outletFulfillmentId?: Maybe<Scalars['String']>;
  paymentExpiryDate?: Maybe<Scalars['DateTime']>;
  slotExpiredDate?: Maybe<Scalars['DateTime']>;
  timeSlot?: Maybe<Scalars['String']>;
};

export type OrderPlacementMetadataInput = {
  date?: InputMaybe<Scalars['Float']>;
  isPlacedNow?: InputMaybe<Scalars['Boolean']>;
  outletFulfillmentId?: InputMaybe<Scalars['String']>;
  timeSlot?: InputMaybe<Scalars['String']>;
};

export type OrderProcessState = {
  __typename?: 'OrderProcessState';
  name: Scalars['String'];
  to: Array<Scalars['String']>;
};

export type OrderProductVariantSummary = {
  __typename?: 'OrderProductVariantSummary';
  productVariant: ProductVariant;
  productVariantId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type OrderSalesContribution = Node & {
  __typename?: 'OrderSalesContribution';
  administrator: Administrator;
  administratorId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  orderLine: OrderLine;
  orderLineId: Scalars['ID'];
  percentage: Scalars['Float'];
  remark?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type OrderSalesInfoInput = {
  purchaseOutletId?: InputMaybe<Scalars['String']>;
  purchaseStockLocationId?: InputMaybe<Scalars['String']>;
};

export type OrderSortParameter = {
  administrator?: InputMaybe<SortOrder>;
  aggregateOrderId?: InputMaybe<SortOrder>;
  cancellationRemark?: InputMaybe<SortOrder>;
  checkoutThrough?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customShippingPrice?: InputMaybe<SortOrder>;
  customerLastName?: InputMaybe<SortOrder>;
  customerSnapshot?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  dineInMetadata?: InputMaybe<SortOrder>;
  fulfillmentMethod?: InputMaybe<SortOrder>;
  giftFee?: InputMaybe<SortOrder>;
  giftOption?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  orderPlacedAt?: InputMaybe<SortOrder>;
  orderPlacementMetadata?: InputMaybe<SortOrder>;
  pointAwarded?: InputMaybe<SortOrder>;
  posAcceptedAt?: InputMaybe<SortOrder>;
  posAcceptedDevice?: InputMaybe<SortOrder>;
  posCustomAmountDiscount?: InputMaybe<SortOrder>;
  posCustomRateDiscount?: InputMaybe<SortOrder>;
  posId?: InputMaybe<SortOrder>;
  posOrderCode?: InputMaybe<SortOrder>;
  posShippingMethods?: InputMaybe<SortOrder>;
  promoCodeId?: InputMaybe<SortOrder>;
  promoCodeName?: InputMaybe<SortOrder>;
  purchaseOutlet?: InputMaybe<SortOrder>;
  purchaseStockLocation?: InputMaybe<SortOrder>;
  remark?: InputMaybe<SortOrder>;
  rewardId?: InputMaybe<SortOrder>;
  shipping?: InputMaybe<SortOrder>;
  shippingMethodCode?: InputMaybe<SortOrder>;
  shippingWithTax?: InputMaybe<SortOrder>;
  snapshot?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  subTotal?: InputMaybe<SortOrder>;
  subTotalWithTax?: InputMaybe<SortOrder>;
  table?: InputMaybe<SortOrder>;
  total?: InputMaybe<SortOrder>;
  totalLinePrice?: InputMaybe<SortOrder>;
  totalLinePriceWithTax?: InputMaybe<SortOrder>;
  totalQuantity?: InputMaybe<SortOrder>;
  totalWithTax?: InputMaybe<SortOrder>;
  transactionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OrderStateHistoriesInput = {
  createdAt: Scalars['Float'];
  from: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  to: Scalars['String'];
  type: Scalars['String'];
};

/** Returned if there is an error in transitioning the Order state */
export type OrderStateTransitionError = ErrorResult & {
  __typename?: 'OrderStateTransitionError';
  errorCode: ErrorCode;
  fromState: Scalars['String'];
  message: Scalars['String'];
  toState: Scalars['String'];
  transitionError: Scalars['String'];
};

export enum OrderStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Confirmed = 'CONFIRMED',
  Draft = 'DRAFT',
  Modifying = 'MODIFYING',
  Pending = 'PENDING',
  Preparing = 'PREPARING',
  ReadyToPickup = 'READY_TO_PICKUP',
  Shipping = 'SHIPPING'
}

export type OrderSummaryDto = {
  __typename?: 'OrderSummaryDto';
  cancellations: Scalars['Int'];
  completed: Scalars['Int'];
  refunds: Scalars['Int'];
  totalSales: Scalars['Float'];
};

export type OrderSummaryInput = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};

export type OrderSychronizerResponse = {
  __typename?: 'OrderSychronizerResponse';
  data?: Maybe<Order>;
  error?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type OrderSyncCustomFieldInput = {
  administratorId?: InputMaybe<Scalars['String']>;
  cancellationRemark?: InputMaybe<Scalars['String']>;
  checkoutThrough: Scalars['String'];
  customShippingPrice?: InputMaybe<Scalars['Int']>;
  customerSnapshot?: InputMaybe<Scalars['String']>;
  dineInMetadata?: InputMaybe<Scalars['String']>;
  fulfillmentMethodCode?: InputMaybe<Scalars['String']>;
  giftFee?: InputMaybe<Scalars['Float']>;
  giftOption?: InputMaybe<GiftOptionInput>;
  orderPlacementMetadata?: InputMaybe<Scalars['JSON']>;
  pointAwarded?: InputMaybe<Scalars['Float']>;
  posAcceptedAt?: InputMaybe<Scalars['Float']>;
  posAcceptedDeviceId?: InputMaybe<Scalars['String']>;
  posCustomAmountDiscount?: InputMaybe<Scalars['Int']>;
  posCustomRateDiscount?: InputMaybe<Scalars['Int']>;
  posShippingMethodId?: InputMaybe<Scalars['String']>;
  promoCodeId?: InputMaybe<Scalars['String']>;
  promoCodeName?: InputMaybe<Scalars['String']>;
  redemptionHistoryIds?: InputMaybe<Array<Scalars['String']>>;
  remark?: InputMaybe<Scalars['String']>;
  rewardId?: InputMaybe<Scalars['String']>;
  shippingMethodCode?: InputMaybe<Scalars['String']>;
  snapshot?: InputMaybe<Scalars['String']>;
  tableId?: InputMaybe<Scalars['String']>;
};

/**
 * A summary of the taxes being applied to this order, grouped
 * by taxRate.
 */
export type OrderTaxSummary = {
  __typename?: 'OrderTaxSummary';
  /** A description of this tax */
  description: Scalars['String'];
  /** The total net price of OrderLines to which this taxRate applies */
  taxBase: Scalars['Money'];
  /** The taxRate as a percentage */
  taxRate: Scalars['Float'];
  /** The total tax being applied to the Order at this taxRate */
  taxTotal: Scalars['Money'];
};

export type OrderTimeline = {
  __typename?: 'OrderTimeline';
  active?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  inactive?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export enum OrderType {
  Aggregate = 'Aggregate',
  Regular = 'Regular',
  Seller = 'Seller'
}

export type OrderlineSyncModifierInput = {
  modifierId: Scalars['String'];
  variants?: InputMaybe<Array<OrderlineSyncVariantInput>>;
};

export type OrderlineSyncSnapshotInput = {
  addons?: InputMaybe<Array<OrderLineSyncAddonInput>>;
  productVariantId: Scalars['String'];
  quantity: Scalars['Float'];
  warrantyConfig?: InputMaybe<WarrantyConfigInput>;
};

export type OrderlineSyncVariantInput = {
  name: Scalars['String'];
  price: Scalars['Float'];
  quantity: Scalars['Float'];
  variantId: Scalars['String'];
};

export type Outlet = Node & {
  __typename?: 'Outlet';
  address: OutletAddress;
  contactNumber: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  fulfillments?: Maybe<Array<OutletFulfillment>>;
  id: Scalars['ID'];
  manager: Administrator;
  managerId: Scalars['String'];
  name: Scalars['String'];
  onlineOrdering: Scalars['Boolean'];
  settings: Array<Maybe<OutletSetting>>;
  staff: Array<Administrator>;
  status: OutletStatus;
  updatedAt: Scalars['DateTime'];
};

export type OutletAddress = {
  __typename?: 'OutletAddress';
  addressLineOne: Scalars['String'];
  addressLineTwo?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  countryCode: Scalars['String'];
  postalCode: Scalars['String'];
  province: Scalars['String'];
};

export type OutletDevice = Node & {
  __typename?: 'OutletDevice';
  accessToken?: Maybe<Scalars['String']>;
  activationKey?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  deviceToken?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  outletId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type OutletDeviceFilterParameter = {
  _and?: InputMaybe<Array<OutletDeviceFilterParameter>>;
  _or?: InputMaybe<Array<OutletDeviceFilterParameter>>;
  accessToken?: InputMaybe<StringOperators>;
  activationKey?: InputMaybe<StringOperators>;
  active?: InputMaybe<BooleanOperators>;
  createdAt?: InputMaybe<DateOperators>;
  deviceToken?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  name?: InputMaybe<StringOperators>;
  outletId?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type OutletDeviceList = PaginatedList & {
  __typename?: 'OutletDeviceList';
  items: Array<OutletDevice>;
  totalItems: Scalars['Int'];
};

export type OutletDeviceListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<OutletDeviceFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<OutletDeviceSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type OutletDeviceSetting = Node & {
  __typename?: 'OutletDeviceSetting';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  invoiceCodePrefix?: Maybe<Scalars['String']>;
  orderCodePrefix?: Maybe<Scalars['String']>;
  outletDevice?: Maybe<OutletDevice>;
  outletDeviceId: Scalars['String'];
  preferredAreas: Array<Scalars['String']>;
  printerConfigurations: Array<PrinterConfiguration>;
  printerTemplateActionConfigurations: Array<PrinterTemplateConfiguration>;
  updatedAt: Scalars['DateTime'];
};

export type OutletDeviceSettingFilterParameter = {
  _and?: InputMaybe<Array<OutletDeviceSettingFilterParameter>>;
  _or?: InputMaybe<Array<OutletDeviceSettingFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  invoiceCodePrefix?: InputMaybe<StringOperators>;
  orderCodePrefix?: InputMaybe<StringOperators>;
  outletDeviceId?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type OutletDeviceSettingList = PaginatedList & {
  __typename?: 'OutletDeviceSettingList';
  items: Array<OutletDeviceSetting>;
  totalItems: Scalars['Int'];
};

export type OutletDeviceSettingListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<OutletDeviceSettingFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<OutletDeviceSettingSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type OutletDeviceSettingSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  invoiceCodePrefix?: InputMaybe<SortOrder>;
  orderCodePrefix?: InputMaybe<SortOrder>;
  outletDeviceId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OutletDeviceSortParameter = {
  accessToken?: InputMaybe<SortOrder>;
  activationKey?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deviceToken?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  outletId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OutletFilterParameter = {
  _and?: InputMaybe<Array<OutletFilterParameter>>;
  _or?: InputMaybe<Array<OutletFilterParameter>>;
  contactNumber?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  managerId?: InputMaybe<StringOperators>;
  name?: InputMaybe<StringOperators>;
  onlineOrdering?: InputMaybe<BooleanOperators>;
  staffId?: InputMaybe<IdOperators>;
  status?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type OutletFulfillment = Node & {
  __typename?: 'OutletFulfillment';
  capacity: Scalars['Int'];
  closingTime: Scalars['String'];
  createdAt: Scalars['DateTime'];
  day: DayType;
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  openingTime: Scalars['String'];
  outlet: Outlet;
  startDate?: Maybe<Scalars['DateTime']>;
  status: OutletFulfillmentStatus;
  type: OutletFulfillmentType;
  updatedAt: Scalars['DateTime'];
};

export type OutletFulfillmentByDayAndType = {
  __typename?: 'OutletFulfillmentByDayAndType';
  day: DayType;
  outletFulfillments: Array<OutletFulfillment>;
};

export type OutletFulfillmentFilterParameter = {
  _and?: InputMaybe<Array<OutletFulfillmentFilterParameter>>;
  _or?: InputMaybe<Array<OutletFulfillmentFilterParameter>>;
  capacity?: InputMaybe<NumberOperators>;
  closingTime?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  day?: InputMaybe<StringOperators>;
  endDate?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  openingTime?: InputMaybe<StringOperators>;
  startDate?: InputMaybe<DateOperators>;
  status?: InputMaybe<StringOperators>;
  type?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type OutletFulfillmentList = PaginatedList & {
  __typename?: 'OutletFulfillmentList';
  items: Array<OutletFulfillment>;
  totalItems: Scalars['Int'];
};

export type OutletFulfillmentListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<OutletFulfillmentFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<OutletFulfillmentSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type OutletFulfillmentOperatingHoursInput = {
  closingTime: Scalars['String'];
  day: DayType;
  openingTime: Scalars['String'];
};

export type OutletFulfillmentSlot = {
  __typename?: 'OutletFulfillmentSlot';
  outletFulfillmentId: Scalars['String'];
  slot: Scalars['String'];
};

export type OutletFulfillmentSortParameter = {
  capacity?: InputMaybe<SortOrder>;
  closingTime?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  day?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  openingTime?: InputMaybe<SortOrder>;
  outletId?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum OutletFulfillmentStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum OutletFulfillmentType {
  Delivery = 'DELIVERY',
  DineIn = 'DINE_IN',
  Pickup = 'PICKUP',
  Reservation = 'RESERVATION'
}

export type OutletList = PaginatedList & {
  __typename?: 'OutletList';
  items: Array<Outlet>;
  totalItems: Scalars['Int'];
};

export type OutletListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<OutletFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<OutletSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type OutletNotification = Node & {
  __typename?: 'OutletNotification';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  metadata?: Maybe<Scalars['JSON']>;
  outlet: Outlet;
  outletId?: Maybe<Scalars['ID']>;
  read: Scalars['Boolean'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type OutletNotificationFilterParameter = {
  _and?: InputMaybe<Array<OutletNotificationFilterParameter>>;
  _or?: InputMaybe<Array<OutletNotificationFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  description?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  outletId?: InputMaybe<IdOperators>;
  read?: InputMaybe<BooleanOperators>;
  title?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type OutletNotificationInput = {
  createdAt: Scalars['Float'];
  description: Scalars['String'];
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  metadata?: InputMaybe<Scalars['JSON']>;
  outlet: Scalars['ID'];
  read: Scalars['Boolean'];
  title: Scalars['String'];
  updatedAt: Scalars['Float'];
};

export type OutletNotificationInputPushRow = {
  assumedMasterState?: InputMaybe<OutletNotificationInput>;
  newDocumentState: OutletNotificationInput;
};

export type OutletNotificationList = PaginatedList & {
  __typename?: 'OutletNotificationList';
  items: Array<OutletNotification>;
  totalItems: Scalars['Int'];
};

export type OutletNotificationListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<OutletNotificationFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<OutletNotificationSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type OutletNotificationSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  outletId?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OutletNotificationSychronizerResponse = {
  __typename?: 'OutletNotificationSychronizerResponse';
  data?: Maybe<OutletNotification>;
  error?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type OutletSetting = Node & {
  __typename?: 'OutletSetting';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  lastCallTime: Scalars['Int'];
  preorderBuffer: Scalars['Int'];
  preparationTime: Scalars['Int'];
  timeSlotInterval: Scalars['Int'];
  type: OutletFulfillmentType;
  updatedAt: Scalars['DateTime'];
};

export type OutletSettingFilterParameter = {
  _and?: InputMaybe<Array<OutletSettingFilterParameter>>;
  _or?: InputMaybe<Array<OutletSettingFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  lastCallTime?: InputMaybe<NumberOperators>;
  preorderBuffer?: InputMaybe<NumberOperators>;
  preparationTime?: InputMaybe<NumberOperators>;
  timeSlotInterval?: InputMaybe<NumberOperators>;
  type?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type OutletSettingList = PaginatedList & {
  __typename?: 'OutletSettingList';
  items: Array<OutletSetting>;
  totalItems: Scalars['Int'];
};

export type OutletSettingListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<OutletSettingFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<OutletSettingSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type OutletSettingSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastCallTime?: InputMaybe<SortOrder>;
  preorderBuffer?: InputMaybe<SortOrder>;
  preparationTime?: InputMaybe<SortOrder>;
  timeSlotInterval?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OutletSortParameter = {
  contactNumber?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  managerId?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum OutletStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type OutletTable = Node & {
  __typename?: 'OutletTable';
  code: Scalars['String'];
  codeWithPrefix: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isAvailable: Scalars['Boolean'];
  outletTableArea?: Maybe<OutletTableArea>;
  outletTableAreaId: Scalars['ID'];
  remarks?: Maybe<Scalars['String']>;
  seatCapacity: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type OutletTableArea = Node & {
  __typename?: 'OutletTableArea';
  channels: Array<Channel>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  outlet: Outlet;
  outletId: Scalars['String'];
  outletTables: Array<OutletTable>;
  position: Scalars['Int'];
  prefix: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type OutletTableAreaFilterParameter = {
  _and?: InputMaybe<Array<OutletTableAreaFilterParameter>>;
  _or?: InputMaybe<Array<OutletTableAreaFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  name?: InputMaybe<StringOperators>;
  outletId?: InputMaybe<StringOperators>;
  position?: InputMaybe<NumberOperators>;
  prefix?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type OutletTableAreaList = PaginatedList & {
  __typename?: 'OutletTableAreaList';
  items: Array<OutletTableArea>;
  totalItems: Scalars['Int'];
};

export type OutletTableAreaListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<OutletTableAreaFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<OutletTableAreaSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type OutletTableAreaSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  outletId?: InputMaybe<SortOrder>;
  position?: InputMaybe<SortOrder>;
  prefix?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OutletTableFilterParameter = {
  _and?: InputMaybe<Array<OutletTableFilterParameter>>;
  _or?: InputMaybe<Array<OutletTableFilterParameter>>;
  code?: InputMaybe<StringOperators>;
  codeWithPrefix?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  isAvailable?: InputMaybe<BooleanOperators>;
  outletTableAreaId?: InputMaybe<IdOperators>;
  remarks?: InputMaybe<StringOperators>;
  seatCapacity?: InputMaybe<NumberOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type OutletTableList = PaginatedList & {
  __typename?: 'OutletTableList';
  items: Array<OutletTable>;
  totalItems: Scalars['Int'];
};

export type OutletTableListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<OutletTableFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<OutletTableSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type OutletTableSortParameter = {
  code?: InputMaybe<SortOrder>;
  codeWithPrefix?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  outletTableAreaId?: InputMaybe<SortOrder>;
  remarks?: InputMaybe<SortOrder>;
  seatCapacity?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum PosNovuEventTypes {
  OrderCancelled = 'ORDER_CANCELLED',
  OrderCreated = 'ORDER_CREATED'
}

export type PaginatedList = {
  items: Array<Node>;
  totalItems: Scalars['Int'];
};

export type Payment = Node & {
  __typename?: 'Payment';
  amount: Scalars['Money'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  errorMessage?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  metadata?: Maybe<Scalars['JSON']>;
  method: Scalars['String'];
  nextStates: Array<Scalars['String']>;
  refunds: Array<Refund>;
  state: Scalars['String'];
  transactionId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type PaymentFilterParameter = {
  _and?: InputMaybe<Array<PaymentFilterParameter>>;
  _or?: InputMaybe<Array<PaymentFilterParameter>>;
  amount?: InputMaybe<NumberOperators>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  errorMessage?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  method?: InputMaybe<StringOperators>;
  state?: InputMaybe<StringOperators>;
  transactionId?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type PaymentInput = {
  amount: Scalars['Float'];
  amountReceived?: InputMaybe<Scalars['Float']>;
  createdAt: Scalars['Float'];
  deletedAt?: InputMaybe<Scalars['Float']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isDeleted?: Scalars['Boolean'];
  metadata?: InputMaybe<Scalars['JSON']>;
  method: Scalars['String'];
  order?: InputMaybe<Scalars['String']>;
  outletDeviceId?: InputMaybe<Scalars['String']>;
  referenceMetadata?: InputMaybe<Scalars['JSON']>;
  refunds?: InputMaybe<Array<Scalars['String']>>;
  refundsDetail?: InputMaybe<Array<RefundDetailInput>>;
  state: Scalars['String'];
  transactionId: Scalars['String'];
  updatedAt: Scalars['Float'];
};

export type PaymentList = PaginatedList & {
  __typename?: 'PaymentList';
  items: Array<Payment>;
  totalItems: Scalars['Int'];
};

export type PaymentListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<PaymentFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<PaymentSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type PaymentMetadataRemarkInput = {
  remarks?: InputMaybe<Scalars['String']>;
};

export type PaymentMethod = Node & {
  __typename?: 'PaymentMethod';
  channelType?: Maybe<PaymentMethodType>;
  checker?: Maybe<ConfigurableOperation>;
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<PaymentMethodCustomFields>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  enabled: Scalars['Boolean'];
  handler: ConfigurableOperation;
  id: Scalars['ID'];
  name: Scalars['String'];
  translations: Array<PaymentMethodTranslation>;
  updatedAt: Scalars['DateTime'];
};

export enum PaymentMethodChannel {
  App = 'APP',
  Pos = 'POS',
  Web = 'WEB'
}

export type PaymentMethodCustomFields = {
  __typename?: 'PaymentMethodCustomFields';
  exactAmountOnly?: Maybe<Scalars['Boolean']>;
  paymentChannel?: Maybe<Scalars['String']>;
  trackReference?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
};

export type PaymentMethodFilterParameter = {
  _and?: InputMaybe<Array<PaymentMethodFilterParameter>>;
  _or?: InputMaybe<Array<PaymentMethodFilterParameter>>;
  channelType?: InputMaybe<StringOperators>;
  code?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  description?: InputMaybe<StringOperators>;
  enabled?: InputMaybe<BooleanOperators>;
  exactAmountOnly?: InputMaybe<BooleanOperators>;
  id?: InputMaybe<IdOperators>;
  name?: InputMaybe<StringOperators>;
  paymentChannel?: InputMaybe<StringOperators>;
  trackReference?: InputMaybe<BooleanOperators>;
  type?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type PaymentMethodList = PaginatedList & {
  __typename?: 'PaymentMethodList';
  items: Array<PaymentMethod>;
  totalItems: Scalars['Int'];
};

export type PaymentMethodListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<PaymentMethodFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<PaymentMethodSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

/**
 * Returned when a call to modifyOrder fails to include a paymentMethod even
 * though the price has increased as a result of the changes.
 */
export type PaymentMethodMissingError = ErrorResult & {
  __typename?: 'PaymentMethodMissingError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export type PaymentMethodQuote = {
  __typename?: 'PaymentMethodQuote';
  code: Scalars['String'];
  customFields?: Maybe<PaymentMethodCustomFields>;
  description: Scalars['String'];
  eligibilityMessage?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isEligible: Scalars['Boolean'];
  name: Scalars['String'];
};

export type PaymentMethodSortParameter = {
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  exactAmountOnly?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  paymentChannel?: InputMaybe<SortOrder>;
  trackReference?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PaymentMethodTranslation = {
  __typename?: 'PaymentMethodTranslation';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PaymentMethodTranslationInput = {
  customFields?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  languageCode: LanguageCode;
  name?: InputMaybe<Scalars['String']>;
};

export enum PaymentMethodType {
  BankTransfer = 'BANK_TRANSFER',
  Cash = 'CASH',
  CreditCard = 'CREDIT_CARD',
  DebitCard = 'DEBIT_CARD',
  EWallet = 'E_WALLET',
  OnlineBanking = 'ONLINE_BANKING',
  Unknown = 'UNKNOWN'
}

/** Returned if an attempting to refund a Payment against OrderLines from a different Order */
export type PaymentOrderMismatchError = ErrorResult & {
  __typename?: 'PaymentOrderMismatchError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export enum PaymentResponseType {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Goback = 'GOBACK'
}

export type PaymentSortParameter = {
  amount?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  errorMessage?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  method?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  transactionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** Returned when there is an error in transitioning the Payment state */
export type PaymentStateTransitionError = ErrorResult & {
  __typename?: 'PaymentStateTransitionError';
  errorCode: ErrorCode;
  fromState: Scalars['String'];
  message: Scalars['String'];
  toState: Scalars['String'];
  transitionError: Scalars['String'];
};

export type PdfGenerator = Node & {
  __typename?: 'PdfGenerator';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  jobId: Scalars['String'];
  metadata: Scalars['JSON'];
  source: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PdfGeneratorFilterParameter = {
  _and?: InputMaybe<Array<PdfGeneratorFilterParameter>>;
  _or?: InputMaybe<Array<PdfGeneratorFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  jobId?: InputMaybe<StringOperators>;
  source?: InputMaybe<StringOperators>;
  type?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type PdfGeneratorInput = {
  createdAt: Scalars['Float'];
  deletedAt?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  jobId: Scalars['String'];
  metadata: Scalars['JSON'];
  source: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['Float'];
};

export type PdfGeneratorInputPushRow = {
  assumedMasterState?: InputMaybe<PdfGeneratorInput>;
  newDocumentState: PdfGeneratorInput;
};

export type PdfGeneratorList = PaginatedList & {
  __typename?: 'PdfGeneratorList';
  items: Array<PdfGenerator>;
  totalItems: Scalars['Int'];
};

export type PdfGeneratorListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<PdfGeneratorFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<PdfGeneratorSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type PdfGeneratorSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  jobId?: InputMaybe<SortOrder>;
  source?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PdfGeneratorSychronizerResponse = {
  __typename?: 'PdfGeneratorSychronizerResponse';
  data?: Maybe<PdfGenerator>;
  error?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type PdfTemplate = Node & {
  __typename?: 'PdfTemplate';
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  template: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PdfTemplateFilterParameter = {
  _and?: InputMaybe<Array<PdfTemplateFilterParameter>>;
  _or?: InputMaybe<Array<PdfTemplateFilterParameter>>;
  code?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  template?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type PdfTemplateList = PaginatedList & {
  __typename?: 'PdfTemplateList';
  items: Array<PdfTemplate>;
  totalItems: Scalars['Int'];
};

export type PdfTemplateListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<PdfTemplateFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<PdfTemplateSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type PdfTemplateSortParameter = {
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  template?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/**
 * @description
 * Permissions for administrators and customers. Used to control access to
 * GraphQL resolvers via the {@link Allow} decorator.
 *
 * ## Understanding Permission.Owner
 *
 * `Permission.Owner` is a special permission which is used in some Vendure resolvers to indicate that that resolver should only
 * be accessible to the "owner" of that resource.
 *
 * For example, the Shop API `activeCustomer` query resolver should only return the Customer object for the "owner" of that Customer, i.e.
 * based on the activeUserId of the current session. As a result, the resolver code looks like this:
 *
 * @example
 * ```TypeScript
 * \@Query()
 * \@Allow(Permission.Owner)
 * async activeCustomer(\@Ctx() ctx: RequestContext): Promise<Customer | undefined> {
 *   const userId = ctx.activeUserId;
 *   if (userId) {
 *     return this.customerService.findOneByUserId(ctx, userId);
 *   }
 * }
 * ```
 *
 * Here we can see that the "ownership" must be enforced by custom logic inside the resolver. Since "ownership" cannot be defined generally
 * nor statically encoded at build-time, any resolvers using `Permission.Owner` **must** include logic to enforce that only the owner
 * of the resource has access. If not, then it is the equivalent of using `Permission.Public`.
 *
 *
 * @docsCategory common
 */
export enum Permission {
  /** Authenticated means simply that the user is logged in */
  Authenticated = 'Authenticated',
  /** Allows bypassing of payments via Admin API */
  BypassPayment = 'BypassPayment',
  /** Grants permission to create Administrator */
  CreateAdministrator = 'CreateAdministrator',
  /** Grants permission to create AppVersion */
  CreateAppVersion = 'CreateAppVersion',
  /** Grants permission to create Asset */
  CreateAsset = 'CreateAsset',
  /** Grants permission to create Products, Facets, Assets, Collections */
  CreateCatalog = 'CreateCatalog',
  /** Grants permission to create Channel */
  CreateChannel = 'CreateChannel',
  /** Grants permission to create Collection */
  CreateCollection = 'CreateCollection',
  /** Grants permission to create Country */
  CreateCountry = 'CreateCountry',
  /** Grants permission to create Customer */
  CreateCustomer = 'CreateCustomer',
  /** Grants permission to create CustomerGroup */
  CreateCustomerGroup = 'CreateCustomerGroup',
  /** Grants permission to create Facet */
  CreateFacet = 'CreateFacet',
  /** Grants permission to create MembershipTier */
  CreateMembershipTier = 'CreateMembershipTier',
  /** Grants permission to create NotificationBlast */
  CreateNotificationBlast = 'CreateNotificationBlast',
  /** Grants permission to create Order */
  CreateOrder = 'CreateOrder',
  /** Grants permission to create OutletDevice */
  CreateOutletDevice = 'CreateOutletDevice',
  /** Grants permission to create PaymentMethod */
  CreatePaymentMethod = 'CreatePaymentMethod',
  /** Grants permission to create Product */
  CreateProduct = 'CreateProduct',
  /** Grants permission to create Promotion */
  CreatePromotion = 'CreatePromotion',
  /** Grants permission to create Seller */
  CreateSeller = 'CreateSeller',
  /** Grants permission to create PaymentMethods, ShippingMethods, TaxCategories, TaxRates, Zones, Countries, System & GlobalSettings */
  CreateSettings = 'CreateSettings',
  /** Grants permission to create ShippingMethod */
  CreateShippingMethod = 'CreateShippingMethod',
  /** Grants permission to create StockLocation */
  CreateStockLocation = 'CreateStockLocation',
  /** Grants permission to create System */
  CreateSystem = 'CreateSystem',
  /** Grants permission to create Tag */
  CreateTag = 'CreateTag',
  /** Grants permission to create TaxCategory */
  CreateTaxCategory = 'CreateTaxCategory',
  /** Grants permission to create TaxRate */
  CreateTaxRate = 'CreateTaxRate',
  /** Grants permission to create Zone */
  CreateZone = 'CreateZone',
  /** Grants permission to delete Administrator */
  DeleteAdministrator = 'DeleteAdministrator',
  /** Grants permission to delete AppVersion */
  DeleteAppVersion = 'DeleteAppVersion',
  /** Grants permission to delete Asset */
  DeleteAsset = 'DeleteAsset',
  /** Grants permission to delete Products, Facets, Assets, Collections */
  DeleteCatalog = 'DeleteCatalog',
  /** Grants permission to delete Channel */
  DeleteChannel = 'DeleteChannel',
  /** Grants permission to delete Collection */
  DeleteCollection = 'DeleteCollection',
  /** Grants permission to delete Country */
  DeleteCountry = 'DeleteCountry',
  /** Grants permission to delete Customer */
  DeleteCustomer = 'DeleteCustomer',
  /** Grants permission to delete CustomerGroup */
  DeleteCustomerGroup = 'DeleteCustomerGroup',
  /** Grants permission to delete Facet */
  DeleteFacet = 'DeleteFacet',
  /** Grants permission to delete MembershipTier */
  DeleteMembershipTier = 'DeleteMembershipTier',
  /** Grants permission to delete NotificationBlast */
  DeleteNotificationBlast = 'DeleteNotificationBlast',
  /** Grants permission to delete Order */
  DeleteOrder = 'DeleteOrder',
  /** Grants permission to delete OutletDevice */
  DeleteOutletDevice = 'DeleteOutletDevice',
  /** Grants permission to delete PaymentMethod */
  DeletePaymentMethod = 'DeletePaymentMethod',
  /** Grants permission to delete Product */
  DeleteProduct = 'DeleteProduct',
  /** Grants permission to delete Promotion */
  DeletePromotion = 'DeletePromotion',
  /** Grants permission to delete Seller */
  DeleteSeller = 'DeleteSeller',
  /** Grants permission to delete PaymentMethods, ShippingMethods, TaxCategories, TaxRates, Zones, Countries, System & GlobalSettings */
  DeleteSettings = 'DeleteSettings',
  /** Grants permission to delete ShippingMethod */
  DeleteShippingMethod = 'DeleteShippingMethod',
  /** Grants permission to delete StockLocation */
  DeleteStockLocation = 'DeleteStockLocation',
  /** Grants permission to delete System */
  DeleteSystem = 'DeleteSystem',
  /** Grants permission to delete Tag */
  DeleteTag = 'DeleteTag',
  /** Grants permission to delete TaxCategory */
  DeleteTaxCategory = 'DeleteTaxCategory',
  /** Grants permission to delete TaxRate */
  DeleteTaxRate = 'DeleteTaxRate',
  /** Grants permission to delete Zone */
  DeleteZone = 'DeleteZone',
  /** Owner means the user owns this entity, e.g. a Customer's own Order */
  Owner = 'Owner',
  /** Public means any unauthenticated user may perform the operation */
  Public = 'Public',
  /** Grants permission to read Administrator */
  ReadAdministrator = 'ReadAdministrator',
  /** Grants permission to read AppVersion */
  ReadAppVersion = 'ReadAppVersion',
  /** Grants permission to read Asset */
  ReadAsset = 'ReadAsset',
  /** Read AuditLog Permission */
  ReadAuditLog = 'ReadAuditLog',
  /** Grants permission to read Products, Facets, Assets, Collections */
  ReadCatalog = 'ReadCatalog',
  /** Grants permission to read Channel */
  ReadChannel = 'ReadChannel',
  /** Grants permission to read Collection */
  ReadCollection = 'ReadCollection',
  /** Grants permission to read Country */
  ReadCountry = 'ReadCountry',
  /** Grants permission to read Customer */
  ReadCustomer = 'ReadCustomer',
  /** Grants permission to read CustomerGroup */
  ReadCustomerGroup = 'ReadCustomerGroup',
  /** Grants permission to read Facet */
  ReadFacet = 'ReadFacet',
  /** Grants permission to read MembershipTier */
  ReadMembershipTier = 'ReadMembershipTier',
  /** Grants permission to read NotificationBlast */
  ReadNotificationBlast = 'ReadNotificationBlast',
  /** Grants permission to read Order */
  ReadOrder = 'ReadOrder',
  /** Grants permission to read OutletDevice */
  ReadOutletDevice = 'ReadOutletDevice',
  /** Grants permission to read PaymentMethod */
  ReadPaymentMethod = 'ReadPaymentMethod',
  /** Grants permission to read Product */
  ReadProduct = 'ReadProduct',
  /** Grants permission to read Promotion */
  ReadPromotion = 'ReadPromotion',
  /** Grants permission to read Seller */
  ReadSeller = 'ReadSeller',
  /** Grants permission to read PaymentMethods, ShippingMethods, TaxCategories, TaxRates, Zones, Countries, System & GlobalSettings */
  ReadSettings = 'ReadSettings',
  /** Grants permission to read ShippingMethod */
  ReadShippingMethod = 'ReadShippingMethod',
  /** Grants permission to read StockLocation */
  ReadStockLocation = 'ReadStockLocation',
  /** Grants permission to read System */
  ReadSystem = 'ReadSystem',
  /** Grants permission to read Tag */
  ReadTag = 'ReadTag',
  /** Grants permission to read TaxCategory */
  ReadTaxCategory = 'ReadTaxCategory',
  /** Grants permission to read TaxRate */
  ReadTaxRate = 'ReadTaxRate',
  /** Grants permission to read Zone */
  ReadZone = 'ReadZone',
  /** SuperAdmin has unrestricted access to all operations */
  SuperAdmin = 'SuperAdmin',
  /** Grants permission to update Administrator */
  UpdateAdministrator = 'UpdateAdministrator',
  /** Grants permission to update AppVersion */
  UpdateAppVersion = 'UpdateAppVersion',
  /** Grants permission to update Asset */
  UpdateAsset = 'UpdateAsset',
  /** Grants permission to update Products, Facets, Assets, Collections */
  UpdateCatalog = 'UpdateCatalog',
  /** Grants permission to update Channel */
  UpdateChannel = 'UpdateChannel',
  /** Grants permission to update Collection */
  UpdateCollection = 'UpdateCollection',
  /** Grants permission to update Country */
  UpdateCountry = 'UpdateCountry',
  /** Grants permission to update Customer */
  UpdateCustomer = 'UpdateCustomer',
  /** Grants permission to update CustomerGroup */
  UpdateCustomerGroup = 'UpdateCustomerGroup',
  /** Grants permission to update Facet */
  UpdateFacet = 'UpdateFacet',
  /** Grants permission to update GlobalSettings */
  UpdateGlobalSettings = 'UpdateGlobalSettings',
  /** Grants permission to update MembershipTier */
  UpdateMembershipTier = 'UpdateMembershipTier',
  /** Grants permission to update NotificationBlast */
  UpdateNotificationBlast = 'UpdateNotificationBlast',
  /** Grants permission to update Order */
  UpdateOrder = 'UpdateOrder',
  /** Grants permission to update OutletDevice */
  UpdateOutletDevice = 'UpdateOutletDevice',
  /** Grants permission to update PaymentMethod */
  UpdatePaymentMethod = 'UpdatePaymentMethod',
  /** Grants permission to update Product */
  UpdateProduct = 'UpdateProduct',
  /** Grants permission to update Promotion */
  UpdatePromotion = 'UpdatePromotion',
  /** Grants permission to update Seller */
  UpdateSeller = 'UpdateSeller',
  /** Grants permission to update PaymentMethods, ShippingMethods, TaxCategories, TaxRates, Zones, Countries, System & GlobalSettings */
  UpdateSettings = 'UpdateSettings',
  /** Grants permission to update ShippingMethod */
  UpdateShippingMethod = 'UpdateShippingMethod',
  /** Grants permission to update StockLocation */
  UpdateStockLocation = 'UpdateStockLocation',
  /** Grants permission to update System */
  UpdateSystem = 'UpdateSystem',
  /** Grants permission to update Tag */
  UpdateTag = 'UpdateTag',
  /** Grants permission to update TaxCategory */
  UpdateTaxCategory = 'UpdateTaxCategory',
  /** Grants permission to update TaxRate */
  UpdateTaxRate = 'UpdateTaxRate',
  /** Grants permission to update Zone */
  UpdateZone = 'UpdateZone',
  /** Allow user to view dashboard */
  ViewDashboard = 'ViewDashboard'
}

export type PermissionDefinition = {
  __typename?: 'PermissionDefinition';
  assignable: Scalars['Boolean'];
  description: Scalars['String'];
  name: Scalars['String'];
};

export type PlaceOrderInput = {
  billingAddress?: InputMaybe<CreateAddressInput>;
  customerId?: InputMaybe<Scalars['ID']>;
  customerSnapshot?: InputMaybe<CustomerSnapshotInput>;
  fulfillment: OrderFulfillmentInput;
  giftOption?: InputMaybe<ApplyGiftOptionInput>;
  orderLines: Array<CreateOrderLineInput>;
  remark?: InputMaybe<Scalars['String']>;
  shippingAddress?: InputMaybe<CreateAddressInput>;
  timeSlot?: InputMaybe<FulfillmentTimeSlot>;
};

export enum Platform {
  Android = 'ANDROID',
  Ios = 'IOS',
  Others = 'OTHERS'
}

export type PointCalculationResult = {
  __typename?: 'PointCalculationResult';
  amountToCalculate: Scalars['String'];
  awardPointPerUnit: Scalars['String'];
  message: Scalars['String'];
  pointAwarded: Scalars['Int'];
  type: PointSettingType;
  unitValue: Scalars['String'];
};

export type PointSetting = Node & {
  __typename?: 'PointSetting';
  awardPointPerUnit: Scalars['Int'];
  id: Scalars['ID'];
  type: PointSettingType;
  unitValue: Scalars['String'];
};

export type PointSettingFilterParameter = {
  _and?: InputMaybe<Array<PointSettingFilterParameter>>;
  _or?: InputMaybe<Array<PointSettingFilterParameter>>;
  awardPointPerUnit?: InputMaybe<NumberOperators>;
  id?: InputMaybe<IdOperators>;
  type?: InputMaybe<StringOperators>;
  unitValue?: InputMaybe<StringOperators>;
};

export type PointSettingList = PaginatedList & {
  __typename?: 'PointSettingList';
  items: Array<PointSetting>;
  totalItems: Scalars['Int'];
};

export type PointSettingListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<PointSettingFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<PointSettingSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type PointSettingSortParameter = {
  awardPointPerUnit?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  unitValue?: InputMaybe<SortOrder>;
};

export enum PointSettingType {
  Review = 'REVIEW',
  Spending = 'SPENDING'
}

export enum PointSignType {
  Negative = 'NEGATIVE',
  Positive = 'POSITIVE'
}

export enum PointTransactionAdminActionType {
  Deduct = 'DEDUCT',
  Grant = 'GRANT'
}

export type PointTransactionHistory = Node & {
  __typename?: 'PointTransactionHistory';
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  membership: Membership;
  membershipId: Scalars['ID'];
  point: Scalars['Float'];
  pointSign: PointSignType;
  remarks?: Maybe<Scalars['String']>;
  source: PointTransactionSourceType;
  staff?: Maybe<Staff>;
};

export type PointTransactionHistoryFilterParameter = {
  _and?: InputMaybe<Array<PointTransactionHistoryFilterParameter>>;
  _or?: InputMaybe<Array<PointTransactionHistoryFilterParameter>>;
  createdAt?: InputMaybe<StringOperators>;
  createdBy?: InputMaybe<StringOperators>;
  description?: InputMaybe<StringOperators>;
  expiryDate?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  membershipId?: InputMaybe<IdOperators>;
  point?: InputMaybe<NumberOperators>;
  pointSign?: InputMaybe<StringOperators>;
  remarks?: InputMaybe<StringOperators>;
  source?: InputMaybe<StringOperators>;
};

export type PointTransactionHistoryList = PaginatedList & {
  __typename?: 'PointTransactionHistoryList';
  items: Array<PointTransactionHistory>;
  totalItems: Scalars['Int'];
};

export type PointTransactionHistoryListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<PointTransactionHistoryFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<PointTransactionHistorySortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type PointTransactionHistorySortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  expiryDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  membershipId?: InputMaybe<SortOrder>;
  point?: InputMaybe<SortOrder>;
  remarks?: InputMaybe<SortOrder>;
};

export enum PointTransactionSourceType {
  Deduct = 'DEDUCT',
  Earned = 'EARNED',
  Grant = 'GRANT',
  Spent = 'SPENT'
}

export type PointValidityInfo = {
  __typename?: 'PointValidityInfo';
  description?: Maybe<Scalars['String']>;
  instruction: Scalars['String'];
  isExtended: Scalars['Boolean'];
};

export enum PosOrderListingDateType {
  Date = 'DATE',
  Time = 'TIME'
}

export type Preference = {
  __typename?: 'Preference';
  channels: Channels;
  enabled: Scalars['Boolean'];
};

export type PreviewCollectionVariantsInput = {
  filters: Array<ConfigurableOperationInput>;
  inheritFilters: Scalars['Boolean'];
  parentId?: InputMaybe<Scalars['ID']>;
};

/** The price range where the result has more than one price */
export type PriceRange = {
  __typename?: 'PriceRange';
  max: Scalars['Money'];
  min: Scalars['Money'];
};

export type Printer = Node & {
  __typename?: 'Printer';
  createdAt: Scalars['DateTime'];
  cutAfterPrint: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  ip: Scalars['String'];
  name: Scalars['String'];
  openCashboxAfterPrint: Scalars['Boolean'];
  paperWidth: Scalars['Int'];
  port: Scalars['Int'];
  timeout: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type PrinterConfiguration = {
  __typename?: 'PrinterConfiguration';
  printer: Scalars['String'];
  tags: Array<Scalars['String']>;
};

export type PrinterConfigurationInput = {
  printer: Scalars['String'];
  tags?: InputMaybe<Array<Scalars['String']>>;
};

export type PrinterFilterParameter = {
  _and?: InputMaybe<Array<PrinterFilterParameter>>;
  _or?: InputMaybe<Array<PrinterFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  cutAfterPrint?: InputMaybe<BooleanOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  ip?: InputMaybe<StringOperators>;
  name?: InputMaybe<StringOperators>;
  openCashboxAfterPrint?: InputMaybe<BooleanOperators>;
  paperWidth?: InputMaybe<NumberOperators>;
  port?: InputMaybe<NumberOperators>;
  timeout?: InputMaybe<NumberOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type PrinterGeneration = {
  __typename?: 'PrinterGeneration';
  printerTags: Array<PrinterTag>;
  printerTemplates: Array<PrinterTemplate>;
  printers: Array<Printer>;
};

export type PrinterInput = {
  createdAt: Scalars['Float'];
  cutAfterPrint: Scalars['Boolean'];
  deletedAt?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  ip: Scalars['String'];
  isDeleted?: Scalars['Boolean'];
  name: Scalars['String'];
  openCashboxAfterPrint: Scalars['Boolean'];
  paperWidth: Scalars['Int'];
  port: Scalars['Int'];
  timeout: Scalars['Int'];
  updatedAt: Scalars['Float'];
};

export type PrinterInputPushRow = {
  assumedMasterState?: InputMaybe<PrinterInput>;
  newDocumentState: PrinterInput;
};

export type PrinterList = PaginatedList & {
  __typename?: 'PrinterList';
  items: Array<Printer>;
  totalItems: Scalars['Int'];
};

export type PrinterListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<PrinterFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<PrinterSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type PrinterSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ip?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  paperWidth?: InputMaybe<SortOrder>;
  port?: InputMaybe<SortOrder>;
  timeout?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PrinterSychronizerResponse = {
  __typename?: 'PrinterSychronizerResponse';
  data?: Maybe<Printer>;
  error?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type PrinterTag = Node & {
  __typename?: 'PrinterTag';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PrinterTagFilterParameter = {
  _and?: InputMaybe<Array<PrinterTagFilterParameter>>;
  _or?: InputMaybe<Array<PrinterTagFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  enabled?: InputMaybe<BooleanOperators>;
  id?: InputMaybe<IdOperators>;
  name?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type PrinterTagList = PaginatedList & {
  __typename?: 'PrinterTagList';
  items: Array<PrinterTag>;
  totalItems: Scalars['Int'];
};

export type PrinterTagListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<PrinterTagFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<PrinterTagSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type PrinterTagSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PrinterTemplate = Node & {
  __typename?: 'PrinterTemplate';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  handler: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  template: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PrinterTemplateConfiguration = {
  __typename?: 'PrinterTemplateConfiguration';
  tags: Array<Scalars['String']>;
  template: Scalars['String'];
};

export type PrinterTemplateConfigurationInput = {
  tags?: InputMaybe<Array<Scalars['String']>>;
  template: Scalars['String'];
};

export type PrinterTemplateFilterParameter = {
  _and?: InputMaybe<Array<PrinterTemplateFilterParameter>>;
  _or?: InputMaybe<Array<PrinterTemplateFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  handler?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  name?: InputMaybe<StringOperators>;
  template?: InputMaybe<StringOperators>;
  type?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type PrinterTemplateList = PaginatedList & {
  __typename?: 'PrinterTemplateList';
  items: Array<PrinterTemplate>;
  totalItems: Scalars['Int'];
};

export type PrinterTemplateListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<PrinterTemplateFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<PrinterTemplateSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type PrinterTemplateSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  handler?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  template?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum PrinterTemplateType {
  Customer = 'CUSTOMER',
  GuestCheck = 'GUEST_CHECK',
  Kitchen = 'KITCHEN',
  Receipt = 'RECEIPT',
  TableQr = 'TABLE_QR'
}

export type ProcessOrderDataInput = {
  ADD_MANUAL_PAYMENT?: InputMaybe<AddManualPaymentProcessOrderInput>;
  UPDATE_FULFILLMENT?: InputMaybe<UpdateFulfillmentProcessOrderInput>;
};

export type ProcessOrderInput = {
  data?: InputMaybe<ProcessOrderDataInput>;
  orderId: Scalars['ID'];
  types: Array<AdminOrderProcess>;
};

export type ProcessOrderResult = {
  __typename?: 'ProcessOrderResult';
  errorMessage?: Maybe<Scalars['String']>;
  key?: Maybe<AdminOrderProcess>;
  value?: Maybe<Scalars['Boolean']>;
};

export type Product = Node & {
  __typename?: 'Product';
  assets: Array<Asset>;
  channels: Array<Channel>;
  collections: Array<Collection>;
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<ProductCustomFields>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  enabled: Scalars['Boolean'];
  facetValues: Array<FacetValue>;
  featuredAsset?: Maybe<Asset>;
  fulfillmentChannels?: Maybe<Array<FulfillmentChannel>>;
  id: Scalars['ID'];
  languageCode: LanguageCode;
  modifierGroups: Array<ModifierGroup>;
  name: Scalars['String'];
  optionGroups: Array<ProductOptionGroup>;
  rating: Scalars['Float'];
  reviews: ProductReviewList;
  slug: Scalars['String'];
  translations: Array<ProductTranslation>;
  updatedAt: Scalars['DateTime'];
  /** Returns a paginated, sortable, filterable list of ProductVariants */
  variantList: ProductVariantList;
  /** Returns all ProductVariants */
  variants: Array<ProductVariant>;
  warrantyConfig?: Maybe<WarrantyConfig>;
};


export type ProductReviewsArgs = {
  options?: InputMaybe<ProductReviewListOptions>;
};


export type ProductVariantListArgs = {
  options?: InputMaybe<ProductVariantListOptions>;
};

export type ProductCustomFields = {
  __typename?: 'ProductCustomFields';
  fulfillChannels?: Maybe<Array<FulfillmentChannel>>;
  modifierGroupSorting?: Maybe<Array<Scalars['String']>>;
  posId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  warrantyConfig?: Maybe<Scalars['String']>;
};

export type ProductFacetInput = {
  productIds: Array<Scalars['String']>;
  productTypeFacetValue: Scalars['String'];
};

export type ProductFilterParameter = {
  _and?: InputMaybe<Array<ProductFilterParameter>>;
  _or?: InputMaybe<Array<ProductFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  description?: InputMaybe<StringOperators>;
  enabled?: InputMaybe<BooleanOperators>;
  facetValueId?: InputMaybe<IdOperators>;
  id?: InputMaybe<IdOperators>;
  languageCode?: InputMaybe<StringOperators>;
  modifierGroupSorting?: InputMaybe<StringListOperators>;
  name?: InputMaybe<StringOperators>;
  posId?: InputMaybe<StringOperators>;
  rating?: InputMaybe<NumberOperators>;
  sku?: InputMaybe<StringOperators>;
  slug?: InputMaybe<StringOperators>;
  type?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
  warrantyConfig?: InputMaybe<StringOperators>;
};

export type ProductInputPushRow = {
  assumedMasterState?: InputMaybe<ProductSyncInput>;
  newDocumentState: ProductSyncInput;
};

export type ProductList = PaginatedList & {
  __typename?: 'ProductList';
  items: Array<Product>;
  totalItems: Scalars['Int'];
};

export type ProductListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<ProductFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<ProductSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type ProductModifier = Node & {
  __typename?: 'ProductModifier';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  modifierId: Scalars['ID'];
  productId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type ProductModifierGroupSyncInput = {
  group?: InputMaybe<Scalars['String']>;
  maxSelect?: InputMaybe<Scalars['Int']>;
  minSelect?: InputMaybe<Scalars['Int']>;
  modifiers?: InputMaybe<Array<ProductModifierSyncInput>>;
};

export type ProductModifierInput = {
  modifierId: Scalars['ID'];
  productId: Scalars['ID'];
};

export type ProductModifierSyncInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  variants?: InputMaybe<Array<InputMaybe<ProductModifierSyncVariantInput>>>;
};

export type ProductModifierSyncVariantInput = {
  assetUrls?: InputMaybe<Array<Scalars['String']>>;
  collections?: InputMaybe<Array<Scalars['String']>>;
  createdAt: Scalars['Float'];
  currencyCode?: InputMaybe<Scalars['String']>;
  deletedAt?: InputMaybe<Scalars['Float']>;
  discountPromotion?: InputMaybe<Scalars['Int']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  facetValues?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  id: Scalars['ID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<Array<InputMaybe<ProductModifierSyncVariantOptionInput>>>;
  price?: InputMaybe<Scalars['Int']>;
  product?: InputMaybe<Scalars['String']>;
  sku?: InputMaybe<Scalars['String']>;
  stockLevel?: InputMaybe<Scalars['String']>;
  stockLevels?: InputMaybe<Array<InputMaybe<ProductModifierSyncVariantStockLevelsInput>>>;
  updatedAt: Scalars['Float'];
};

export type ProductModifierSyncVariantOptionGroupInput = {
  code?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type ProductModifierSyncVariantOptionInput = {
  code?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<ProductModifierSyncVariantOptionGroupInput>;
  groupId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type ProductModifierSyncVariantStockLevelsInput = {
  stockAllocated?: InputMaybe<Scalars['Int']>;
  stockOnHand?: InputMaybe<Scalars['Int']>;
};

export type ProductOption = Node & {
  __typename?: 'ProductOption';
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<Scalars['JSON']>;
  group: ProductOptionGroup;
  groupId: Scalars['ID'];
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  translations: Array<ProductOptionTranslation>;
  updatedAt: Scalars['DateTime'];
};

export type ProductOptionGroup = Node & {
  __typename?: 'ProductOptionGroup';
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  options: Array<ProductOption>;
  translations: Array<ProductOptionGroupTranslation>;
  updatedAt: Scalars['DateTime'];
};

export type ProductOptionGroupSyncInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<Array<ProductOptionSyncInput>>;
};

export type ProductOptionGroupTranslation = {
  __typename?: 'ProductOptionGroupTranslation';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ProductOptionGroupTranslationInput = {
  customFields?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['ID']>;
  languageCode: LanguageCode;
  name?: InputMaybe<Scalars['String']>;
};

export type ProductOptionInUseError = ErrorResult & {
  __typename?: 'ProductOptionInUseError';
  errorCode: ErrorCode;
  message: Scalars['String'];
  optionGroupCode: Scalars['String'];
  productVariantCount: Scalars['Int'];
};

export type ProductOptionSyncInput = {
  code: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ProductOptionTranslation = {
  __typename?: 'ProductOptionTranslation';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ProductOptionTranslationInput = {
  customFields?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['ID']>;
  languageCode: LanguageCode;
  name?: InputMaybe<Scalars['String']>;
};

export type ProductReview = Node & {
  __typename?: 'ProductReview';
  createdAt: Scalars['DateTime'];
  customer?: Maybe<Customer>;
  customerId: Scalars['String'];
  customerName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  order?: Maybe<Order>;
  orderId: Scalars['ID'];
  product?: Maybe<Product>;
  productId: Scalars['ID'];
  rating: Scalars['Int'];
  remark?: Maybe<Scalars['String']>;
  reviewerNameIsPublic: Scalars['Boolean'];
  state?: Maybe<ReviewStateEnum>;
  updatedAt: Scalars['DateTime'];
};

export type ProductReviewFilterParameter = {
  _and?: InputMaybe<Array<ProductReviewFilterParameter>>;
  _or?: InputMaybe<Array<ProductReviewFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  customerId?: InputMaybe<StringOperators>;
  customerName?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  orderId?: InputMaybe<IdOperators>;
  productId?: InputMaybe<IdOperators>;
  rating?: InputMaybe<NumberOperators>;
  remark?: InputMaybe<StringOperators>;
  reviewerNameIsPublic?: InputMaybe<BooleanOperators>;
  state?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type ProductReviewList = PaginatedList & {
  __typename?: 'ProductReviewList';
  items: Array<ProductReview>;
  totalItems: Scalars['Int'];
};

export type ProductReviewListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<ProductReviewFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<ProductReviewSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type ProductReviewSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  customerName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  orderId?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  rating?: InputMaybe<SortOrder>;
  remark?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ProductSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  posId?: InputMaybe<SortOrder>;
  rating?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  warrantyConfig?: InputMaybe<SortOrder>;
};

export type ProductSyncInput = {
  assetUrls?: InputMaybe<Array<Scalars['String']>>;
  collections?: InputMaybe<Array<Scalars['String']>>;
  createdAt: Scalars['Float'];
  deletedAt?: InputMaybe<Scalars['Float']>;
  description: Scalars['String'];
  enabled?: InputMaybe<Scalars['Boolean']>;
  fulfillmentChannels?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifierGroups?: InputMaybe<Array<ProductModifierGroupSyncInput>>;
  name: Scalars['String'];
  optionGroups?: InputMaybe<Array<ProductOptionGroupSyncInput>>;
  productType: ProductType;
  rating?: InputMaybe<Scalars['Float']>;
  slug: Scalars['String'];
  updatedAt: Scalars['Float'];
  variants?: InputMaybe<Array<Scalars['String']>>;
  variantsDetail?: InputMaybe<Array<ProductVariantSyncInput>>;
};

export type ProductSynchronizerResponse = {
  __typename?: 'ProductSynchronizerResponse';
  data?: Maybe<Product>;
  error?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ProductTranslation = {
  __typename?: 'ProductTranslation';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  slug: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ProductTranslationInput = {
  customFields?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  languageCode: LanguageCode;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export enum ProductType {
  Modifier = 'MODIFIER',
  Product = 'PRODUCT'
}

export type ProductVariant = Node & {
  __typename?: 'ProductVariant';
  assets: Array<Asset>;
  canAddToDiscountPromotion: Scalars['Boolean'];
  channels: Array<Channel>;
  collections: CollectionList;
  createdAt: Scalars['DateTime'];
  currencyCode: CurrencyCode;
  customFields?: Maybe<ProductVariantCustomFields>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  discountPromotion: Scalars['Money'];
  discountPromotionData?: Maybe<DiscountPromotion>;
  discountPromotions: DiscountPromotionList;
  enabled: Scalars['Boolean'];
  facetValues: Array<FacetValue>;
  featuredAsset?: Maybe<Asset>;
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  options: Array<ProductOption>;
  outOfStockThreshold: Scalars['Int'];
  price: Scalars['Money'];
  priceWithTax: Scalars['Money'];
  prices: Array<ProductVariantPrice>;
  product: Product;
  productId: Scalars['ID'];
  sku: Scalars['String'];
  /** @deprecated use stockLevels */
  stockAllocated: Scalars['Int'];
  stockLevel: Scalars['String'];
  stockLevels: Array<StockLevel>;
  stockMovements: StockMovementList;
  /** @deprecated use stockLevels */
  stockOnHand: Scalars['Int'];
  taxCategory: TaxCategory;
  taxRateApplied: TaxRate;
  trackInventory: GlobalFlag;
  translations: Array<ProductVariantTranslation>;
  updatedAt: Scalars['DateTime'];
  useGlobalOutOfStockThreshold: Scalars['Boolean'];
};


export type ProductVariantCanAddToDiscountPromotionArgs = {
  endDate: Scalars['DateTime'];
  excludeDiscountPromotionId?: InputMaybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
};


export type ProductVariantCollectionsArgs = {
  options?: InputMaybe<CollectionListOptions>;
};


export type ProductVariantDiscountPromotionsArgs = {
  options?: InputMaybe<DiscountPromotionListOptions>;
};


export type ProductVariantStockMovementsArgs = {
  options?: InputMaybe<StockMovementListOptions>;
};

export type ProductVariantCustomFields = {
  __typename?: 'ProductVariantCustomFields';
  omitFromOutletId?: Maybe<Array<Scalars['String']>>;
  outletPricing?: Maybe<Array<Scalars['String']>>;
  posId?: Maybe<Scalars['String']>;
};

export type ProductVariantFacetValueSyncInput = {
  code: Scalars['String'];
  id: Scalars['String'];
  languageCode: Scalars['String'];
  name: Scalars['String'];
};

export type ProductVariantFilterParameter = {
  _and?: InputMaybe<Array<ProductVariantFilterParameter>>;
  _or?: InputMaybe<Array<ProductVariantFilterParameter>>;
  canAddToDiscountPromotion?: InputMaybe<BooleanOperators>;
  createdAt?: InputMaybe<DateOperators>;
  currencyCode?: InputMaybe<StringOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  discountPromotion?: InputMaybe<NumberOperators>;
  enabled?: InputMaybe<BooleanOperators>;
  facetValueId?: InputMaybe<IdOperators>;
  id?: InputMaybe<IdOperators>;
  languageCode?: InputMaybe<StringOperators>;
  name?: InputMaybe<StringOperators>;
  omitFromOutletId?: InputMaybe<StringListOperators>;
  outOfStockThreshold?: InputMaybe<NumberOperators>;
  outletPricing?: InputMaybe<StringListOperators>;
  posId?: InputMaybe<StringOperators>;
  price?: InputMaybe<NumberOperators>;
  priceWithTax?: InputMaybe<NumberOperators>;
  productId?: InputMaybe<IdOperators>;
  sku?: InputMaybe<StringOperators>;
  stockAllocated?: InputMaybe<NumberOperators>;
  stockLevel?: InputMaybe<StringOperators>;
  stockOnHand?: InputMaybe<NumberOperators>;
  trackInventory?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
  useGlobalOutOfStockThreshold?: InputMaybe<BooleanOperators>;
};

export type ProductVariantGroupSyncInput = {
  code: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ProductVariantInputPushRow = {
  assumedMasterState?: InputMaybe<ProductVariantSyncInput>;
  newDocumentState: ProductVariantSyncInput;
};

export type ProductVariantList = PaginatedList & {
  __typename?: 'ProductVariantList';
  items: Array<ProductVariant>;
  totalItems: Scalars['Int'];
};

export type ProductVariantListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<ProductVariantFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<ProductVariantSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type ProductVariantOptionSyncInput = {
  code: Scalars['String'];
  group: ProductVariantGroupSyncInput;
  groupId: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ProductVariantPrice = {
  __typename?: 'ProductVariantPrice';
  currencyCode: CurrencyCode;
  customFields?: Maybe<Scalars['JSON']>;
  price: Scalars['Money'];
};

/**
 * Used to set up update the price of a ProductVariant in a particular Channel.
 * If the `delete` flag is `true`, the price will be deleted for the given Channel.
 */
export type ProductVariantPriceInput = {
  currencyCode: CurrencyCode;
  delete?: InputMaybe<Scalars['Boolean']>;
  price: Scalars['Money'];
};

export type ProductVariantSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  discountPromotion?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  outOfStockThreshold?: InputMaybe<SortOrder>;
  posId?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  priceWithTax?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  sku?: InputMaybe<SortOrder>;
  stockAllocated?: InputMaybe<SortOrder>;
  stockLevel?: InputMaybe<SortOrder>;
  stockOnHand?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ProductVariantStockLevelSyncInput = {
  createdAt?: InputMaybe<Scalars['Float']>;
  deletedAt?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  stockAllocated: Scalars['Float'];
  stockLocationId?: InputMaybe<Scalars['String']>;
  stockOnHand: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['Float']>;
};

export type ProductVariantSyncInput = {
  assetUrls?: InputMaybe<Array<Scalars['String']>>;
  collections?: InputMaybe<Array<Scalars['String']>>;
  createdAt: Scalars['Float'];
  currencyCode: Scalars['String'];
  deletedAt?: InputMaybe<Scalars['Float']>;
  discountPromotion: Scalars['Float'];
  enabled: Scalars['Boolean'];
  facetValues?: InputMaybe<Array<ProductVariantFacetValueSyncInput>>;
  id: Scalars['ID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  options?: InputMaybe<Array<ProductVariantOptionSyncInput>>;
  price: Scalars['Float'];
  priceWithTax: Scalars['Float'];
  product: Scalars['String'];
  sku: Scalars['String'];
  stockLevel: Scalars['String'];
  stockLevels?: InputMaybe<Array<ProductVariantStockLevelSyncInput>>;
  updatedAt: Scalars['Float'];
};

export type ProductVariantSynchronizerResponse = {
  __typename?: 'ProductVariantSynchronizerResponse';
  data?: Maybe<ProductVariant>;
  error?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ProductVariantTranslation = {
  __typename?: 'ProductVariantTranslation';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ProductVariantTranslationInput = {
  customFields?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['ID']>;
  languageCode: LanguageCode;
  name?: InputMaybe<Scalars['String']>;
};

export type PromoCode = Node & {
  __typename?: 'PromoCode';
  cappedDiscount?: Maybe<Scalars['Float']>;
  code: Scalars['String'];
  createdAt: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  endAt: Scalars['String'];
  id: Scalars['ID'];
  imageUrls?: Maybe<Array<Scalars['String']>>;
  isApplicable?: Maybe<Scalars['Boolean']>;
  membershipTiers?: Maybe<Array<MembershipTier>>;
  minimumClaimDifferent?: Maybe<Scalars['Int']>;
  minimumSpendAmount: Scalars['Float'];
  name: Scalars['String'];
  onRedeemConfig?: Maybe<ExpiryOnRedeemConfig>;
  pointCost: Scalars['Int'];
  quantity?: Maybe<Scalars['Int']>;
  redeemCriteria: Scalars['String'];
  redeemPerUserLimit?: Maybe<Scalars['Int']>;
  runningId: Scalars['String'];
  startAt: Scalars['String'];
  status: PromoCodeStatus;
  termCondition: Scalars['String'];
  type: PromoCodeType;
  updatedAt: Scalars['String'];
  value: Scalars['Float'];
};

export type PromoCodeFilterParameter = {
  _and?: InputMaybe<Array<PromoCodeFilterParameter>>;
  _or?: InputMaybe<Array<PromoCodeFilterParameter>>;
  cappedDiscount?: InputMaybe<NumberOperators>;
  code?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<StringOperators>;
  description?: InputMaybe<StringOperators>;
  endAt?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  isApplicable?: InputMaybe<BooleanOperators>;
  minimumClaimDifferent?: InputMaybe<NumberOperators>;
  minimumSpendAmount?: InputMaybe<NumberOperators>;
  name?: InputMaybe<StringOperators>;
  pointCost?: InputMaybe<NumberOperators>;
  quantity?: InputMaybe<NumberOperators>;
  redeemCriteria?: InputMaybe<StringOperators>;
  redeemPerUserLimit?: InputMaybe<NumberOperators>;
  runningId?: InputMaybe<StringOperators>;
  startAt?: InputMaybe<StringOperators>;
  status?: InputMaybe<StringOperators>;
  termCondition?: InputMaybe<StringOperators>;
  type?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<StringOperators>;
  value?: InputMaybe<NumberOperators>;
};

export type PromoCodeList = PaginatedList & {
  __typename?: 'PromoCodeList';
  items: Array<PromoCode>;
  totalItems: Scalars['Int'];
};

export type PromoCodeListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<PromoCodeFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<PromoCodeSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type PromoCodeSortParameter = {
  cappedDiscount?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  endAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  minimumClaimDifferent?: InputMaybe<SortOrder>;
  minimumSpendAmount?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  pointCost?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  redeemCriteria?: InputMaybe<SortOrder>;
  redeemPerUserLimit?: InputMaybe<SortOrder>;
  runningId?: InputMaybe<SortOrder>;
  startAt?: InputMaybe<SortOrder>;
  termCondition?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export enum PromoCodeStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum PromoCodeType {
  DiscountPercentage = 'DISCOUNT_PERCENTAGE',
  FixedValue = 'FIXED_VALUE'
}

export type Promotion = Node & {
  __typename?: 'Promotion';
  actions: Array<ConfigurableOperation>;
  conditions: Array<ConfigurableOperation>;
  couponCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<Scalars['JSON']>;
  description: Scalars['String'];
  enabled: Scalars['Boolean'];
  endsAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  perCustomerUsageLimit?: Maybe<Scalars['Int']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  translations: Array<PromotionTranslation>;
  updatedAt: Scalars['DateTime'];
  usageLimit?: Maybe<Scalars['Int']>;
};

export type PromotionFilterParameter = {
  _and?: InputMaybe<Array<PromotionFilterParameter>>;
  _or?: InputMaybe<Array<PromotionFilterParameter>>;
  couponCode?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  description?: InputMaybe<StringOperators>;
  enabled?: InputMaybe<BooleanOperators>;
  endsAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  name?: InputMaybe<StringOperators>;
  perCustomerUsageLimit?: InputMaybe<NumberOperators>;
  startsAt?: InputMaybe<DateOperators>;
  updatedAt?: InputMaybe<DateOperators>;
  usageLimit?: InputMaybe<NumberOperators>;
};

export type PromotionList = PaginatedList & {
  __typename?: 'PromotionList';
  items: Array<Promotion>;
  totalItems: Scalars['Int'];
};

export type PromotionListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<PromotionFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<PromotionSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type PromotionSortParameter = {
  couponCode?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  endsAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  perCustomerUsageLimit?: InputMaybe<SortOrder>;
  startsAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  usageLimit?: InputMaybe<SortOrder>;
};

export type PromotionTranslation = {
  __typename?: 'PromotionTranslation';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PromotionTranslationInput = {
  customFields?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  languageCode: LanguageCode;
  name?: InputMaybe<Scalars['String']>;
};

export enum PromotionType {
  PromoCode = 'PROMO_CODE',
  Reward = 'REWARD'
}

export type Province = Node & Region & {
  __typename?: 'Province';
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<Scalars['JSON']>;
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  parent?: Maybe<Region>;
  parentId?: Maybe<Scalars['ID']>;
  translations: Array<RegionTranslation>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ProvinceFilterParameter = {
  _and?: InputMaybe<Array<ProvinceFilterParameter>>;
  _or?: InputMaybe<Array<ProvinceFilterParameter>>;
  code?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  enabled?: InputMaybe<BooleanOperators>;
  id?: InputMaybe<IdOperators>;
  languageCode?: InputMaybe<StringOperators>;
  name?: InputMaybe<StringOperators>;
  parentId?: InputMaybe<IdOperators>;
  type?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type ProvinceList = PaginatedList & {
  __typename?: 'ProvinceList';
  items: Array<Province>;
  totalItems: Scalars['Int'];
};

export type ProvinceListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<ProvinceFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<ProvinceSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type ProvinceSortParameter = {
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  parentId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ProvinceTranslationInput = {
  customFields?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['ID']>;
  languageCode: LanguageCode;
  name?: InputMaybe<Scalars['String']>;
};

export type PublicChannel = {
  __typename?: 'PublicChannel';
  code: Scalars['String'];
  country?: Maybe<Country>;
  description: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  outlets?: Maybe<Array<PublicOutlet>>;
  token: Scalars['String'];
};

export type PublicOutlet = {
  __typename?: 'PublicOutlet';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type PublicSeller = {
  __typename?: 'PublicSeller';
  channels?: Maybe<Array<PublicChannel>>;
  id: Scalars['ID'];
  logo: Scalars['String'];
  name: Scalars['String'];
  resourceConfig?: Maybe<Scalars['JSON']>;
};

/** Returned if the specified quantity of an OrderLine is greater than the number of items in that line */
export type QuantityTooGreatError = ErrorResult & {
  __typename?: 'QuantityTooGreatError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  activeAdministrator?: Maybe<Administrator>;
  activeChannel: Channel;
  administrator?: Maybe<Administrator>;
  administratorClockEntry?: Maybe<AdministratorClockEntry>;
  administrators: AdministratorList;
  appVersion?: Maybe<AppVersion>;
  /** Get a single Asset by id */
  asset?: Maybe<Asset>;
  /** Get a list of Assets */
  assets: AssetList;
  banner: Banner;
  canUserAccessInvoice: Scalars['Boolean'];
  channel?: Maybe<Channel>;
  channels: ChannelList;
  checkConflictingPostcodes: Array<Scalars['String']>;
  /** Get a Collection either by id or slug. If neither id nor slug is specified, an error will result. */
  collection?: Maybe<Collection>;
  collectionFilters: Array<ConfigurableOperationDefinition>;
  collections: CollectionList;
  countries: CountryList;
  country?: Maybe<Country>;
  currentSubscriber?: Maybe<NovuSubscriber>;
  currentSubscriberPreference?: Maybe<Array<Maybe<NovuSubscriberPreference>>>;
  customer?: Maybe<Customer>;
  customerGroup?: Maybe<CustomerGroup>;
  customerGroups: CustomerGroupList;
  customers: CustomerList;
  deviceStates: Scalars['JSON'];
  discountPromotion: DiscountPromotion;
  eghlConfig: EghlConfig;
  /** Returns a list of eligible shipping methods for the draft Order */
  eligibleShippingMethodsForDraftOrder: Array<ShippingMethodQuote>;
  /** Returns all configured EntityDuplicators. */
  entityDuplicators: Array<EntityDuplicatorDefinition>;
  facet?: Maybe<Facet>;
  facetValues: FacetValueList;
  facets: FacetList;
  fulfillment: Fulfillment;
  fulfillmentChannel: FulfillmentChannel;
  fulfillmentHandlers: Array<ConfigurableOperationDefinition>;
  getActiveOutletsByAdmin: Array<Outlet>;
  getAddresses: AddressList;
  getAdminSubscriberPreference?: Maybe<Array<Maybe<NovuSubscriberPreference>>>;
  getAdminSubscriberProfile?: Maybe<NovuSubscriber>;
  getAdministratorClockEntries: AdministratorClockEntryList;
  getAdministrators: AdministratorList;
  getAllModifiers: ProductList;
  getAllOutletFulfillmentsTypeByDay: Array<AllOutletFulfillmentTypeByDay>;
  getAllProducts: ProductList;
  getAssigningFacetCollection?: Maybe<Facet>;
  getAuditLogs: AuditLogList;
  getBanners: BannerList;
  getCollections: CollectionList;
  getCustomerCard: CustomerCard;
  getCustomerCards: CustomerCardList;
  getCustomerRewards: RewardList;
  getCustomerSubscriberPreference?: Maybe<Array<Maybe<NovuSubscriberPreference>>>;
  getCustomerSubscriberProfile?: Maybe<NovuSubscriber>;
  getCustomers: CustomerList;
  getCustomersWithMembership: CustomerList;
  getDiscountPromotionProductVariants: DiscountPromotionProductVariantList;
  getDiscountPromotions: DiscountPromotionList;
  getEstimatedTimeOfDelivery: EstimatedTimeOfDelivery;
  getFulfillmentChannels: FulfillmentChannelList;
  getFulfillmentTimeSlotsByType?: Maybe<Array<Maybe<TimeSlot>>>;
  getFulfillments: FulfillmentList;
  getGiftOption: GiftOption;
  getGiftOptions: GiftOptionList;
  getLatestOutlet: Outlet;
  getMainProductsByModifierId: ProductList;
  getMembership?: Maybe<Membership>;
  getMembershipTierBenefits: TierBenefitList;
  getMembershipTiers: MembershipTierList;
  getMetabaseIframeUrl: Scalars['String'];
  getModifierGroups: ModifierGroupList;
  getModifiers: ModifierList;
  getModifiersGroupingsByProduct: Array<ModifierGroup>;
  getNotificationBlastQueues: NotificationBlastQueueList;
  getNotificationBlastUsers: NotificationBlastUserList;
  getNotificationBlasts: NotificationBlastList;
  getNotificationSetting: NotificationSetting;
  getNotificationSettings: NotificationSettingList;
  getNovuCredentials: NovuCredentails;
  getOrder: Order;
  getOrderInvoices: OrderInvoiceList;
  getOrderLines: OrderLineList;
  getOrderProductQuantity?: Maybe<Array<OrderProductVariantSummary>>;
  getOrders: GetOrderList;
  getOutOfStockVariants: Array<ProductVariant>;
  getOutletFulfillments: OutletFulfillmentList;
  getOutletFulfillmentsByDayAndType: Array<OutletFulfillmentByDayAndType>;
  getOutletNotification: OutletNotification;
  getOutletNotifications: OutletNotificationList;
  getOutletSettings: OutletSettingList;
  getOutletTableAreas: OutletTableAreaList;
  getOutletTables: OutletTableList;
  getOutlets: OutletList;
  getPayments: PaymentList;
  getPointAwardWithOrderId: PointCalculationResult;
  getPointSettings: PointSettingList;
  getPointTransactionHistories: PointTransactionHistoryList;
  getProductReview: ProductReview;
  getProductReviews: ProductReviewList;
  getProductVariants: ProductVariantList;
  getProducts: ProductList;
  getProductsByCollection: ProductList;
  getProductsByModifierGroup: ProductList;
  getPromoCodes: PromoCodeList;
  getRecommendedProducts: ProductList;
  getRedemptionHistories: RedemptionHistoryList;
  getRedemptionHistoryDiscounts: Array<Maybe<RedemptionHistoryDiscountResult>>;
  getReservationSummary?: Maybe<Array<ReservationSummary>>;
  getReservations: ReservationList;
  getRewards: RewardList;
  getSearchedBanners: BannerList;
  getShippingMethods: ShippingMethodList;
  getShippingTier: ShippingTier;
  getShippingTiers: ShippingTierList;
  getSlotAvailability: Array<SlotAvailabilityResult>;
  getStarAwardWithOrderId: StarCalculationResult;
  getStarTransactionHistories: StarTransactionHistoryList;
  getStockLocations: StockLocationList;
  getStockMovements: StockMovementList;
  getStockTransfers: StockTransferList;
  getTemplate: Scalars['JSON'];
  getTemplateCodeTypes: CodeTypes;
  getTenantSetting?: Maybe<TenantSetting>;
  getUsers: UserList;
  getValidPostCodes: Array<Scalars['String']>;
  giftOptions: Array<GiftOption>;
  globalSettings: GlobalSettings;
  job?: Maybe<Job>;
  jobBufferSize: Array<JobBufferSize>;
  jobQueues: Array<JobQueue>;
  jobs: JobList;
  jobsById: Array<Job>;
  me?: Maybe<CurrentUser>;
  membershipTier: MembershipTier;
  /** Get metrics for the given interval and metric types. */
  metricSummary: Array<MetricSummary>;
  modifier?: Maybe<Modifier>;
  modifierGroup: FacetValue;
  notificationBlast: NotificationBlast;
  notificationBlastQueue: NotificationBlastQueue;
  notificationBlastUser: NotificationBlastUser;
  order?: Maybe<Order>;
  orderLine: OrderLine;
  orderSummary: OrderSummaryDto;
  orders: OrderList;
  outlet: Outlet;
  outletAcceptOnlineOrder: Scalars['Boolean'];
  outletDevice?: Maybe<OutletDevice>;
  outletDeviceByDeviceToken?: Maybe<OutletDevice>;
  outletDeviceSettings: OutletDeviceSettingList;
  outletDevices: OutletDeviceList;
  outletTable: OutletTable;
  outletTableArea: OutletTableArea;
  payment: Payment;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentMethodEligibilityCheckers: Array<ConfigurableOperationDefinition>;
  paymentMethodHandlers: Array<ConfigurableOperationDefinition>;
  paymentMethods: PaymentMethodList;
  pendingSearchIndexUpdates: Scalars['Int'];
  /** Used for real-time previews of the contents of a Collection */
  previewCollectionVariants: ProductVariantList;
  printer?: Maybe<Printer>;
  printerTag?: Maybe<PrinterTag>;
  printerTags: PrinterTagList;
  printerTemplate?: Maybe<PrinterTemplate>;
  printerTemplates: PrinterTemplateList;
  printers: PrinterList;
  /** Get a Product either by id or slug. If neither id nor slug is specified, an error will result. */
  product?: Maybe<Product>;
  productOptionGroup?: Maybe<ProductOptionGroup>;
  productOptionGroups: Array<ProductOptionGroup>;
  /** Get a ProductVariant by id */
  productVariant?: Maybe<ProductVariant>;
  /** List ProductVariants either all or for the specific product. */
  productVariants: ProductVariantList;
  /** List Products */
  products: ProductList;
  promoCode: PromoCode;
  promotion?: Maybe<Promotion>;
  promotionActions: Array<ConfigurableOperationDefinition>;
  promotionConditions: Array<ConfigurableOperationDefinition>;
  promotions: PromotionList;
  province?: Maybe<Province>;
  provinces: ProvinceList;
  publicChannel: PublicChannel;
  publicSeller: PublicSeller;
  queryExpoToken: Array<Scalars['String']>;
  queryToken: Array<Scalars['String']>;
  redemptionHistory: RedemptionHistory;
  reservation: Reservation;
  reward: Reward;
  role?: Maybe<Role>;
  roles: RoleList;
  search: SearchResponse;
  seller?: Maybe<Seller>;
  sellers: SellerList;
  shippingCalculators: Array<ConfigurableOperationDefinition>;
  shippingEligibilityCheckers: Array<ConfigurableOperationDefinition>;
  shippingMethod?: Maybe<ShippingMethod>;
  shippingMethods: ShippingMethodList;
  snapshot?: Maybe<SnapshotOrder>;
  stockLocation?: Maybe<StockLocation>;
  stockLocations: StockLocationList;
  stockTransfer: StockTransfer;
  syncQueryAddresses: AddressList;
  syncQueryAddressesWithOptions: AddressList;
  syncQueryAdministratorClockEntries: AdministratorClockEntryList;
  syncQueryAdministratorClockEntriesWithOptions: AdministratorClockEntryList;
  syncQueryAdministrators: AdministratorList;
  syncQueryAdministratorsWithOptions: AdministratorList;
  syncQueryChannels: ChannelList;
  syncQueryChannelsWithOptions: ChannelList;
  syncQueryCollections: CollectionList;
  syncQueryCollectionsWithOptions: CollectionList;
  syncQueryCountries: CountryList;
  syncQueryCountriesWithOptions: CountryList;
  syncQueryCustomers: CustomerList;
  syncQueryCustomersWithOptions: CustomerList;
  syncQueryDeviceSettings: OutletDeviceSettingList;
  syncQueryDeviceSettingsWithOptions: OutletDeviceSettingList;
  syncQueryFacets: FacetList;
  syncQueryFacetsWithOptions: FacetList;
  syncQueryFulfillmentChannels: FulfillmentChannelList;
  syncQueryFulfillmentChannelsWithOptions: FulfillmentChannelList;
  syncQueryFulfillments: FulfillmentList;
  syncQueryFulfillmentsWithOptions: FulfillmentList;
  syncQueryGiftOptions: GiftOptionList;
  syncQueryGiftOptionsWithOptions: GiftOptionList;
  syncQueryOrderInvoices: OrderInvoiceList;
  syncQueryOrderInvoicesWithOptions: OrderInvoiceList;
  syncQueryOrderLines: OrderLineList;
  syncQueryOrderLinesWithOptions: OrderLineList;
  syncQueryOrders: GetOrderList;
  syncQueryOrdersWithOptions: GetOrderList;
  syncQueryOutletTableAreas: OutletTableAreaList;
  syncQueryOutletTableAreasWithOptions: OutletTableAreaList;
  syncQueryOutletTables: OutletTableList;
  syncQueryOutletTablesWithOptions: OutletTableList;
  syncQueryOutlets: OutletList;
  syncQueryOutletsWithOptions: OutletList;
  syncQueryPaymentMethods: PaymentMethodList;
  syncQueryPaymentMethodsWithOptions: PaymentMethodList;
  syncQueryPayments: PaymentList;
  syncQueryPaymentsWithOptions: PaymentList;
  syncQueryPdfGenerators: PdfGeneratorList;
  syncQueryPdfGeneratorsWithOptions: PdfGeneratorList;
  syncQueryPdfTemplates: PdfTemplateList;
  syncQueryPdfTemplatesWithOptions: PdfTemplateList;
  syncQueryPrinterTags: PrinterTagList;
  syncQueryPrinterTagsWithOptions: PrinterTagList;
  syncQueryPrinterTemplates: PrinterTemplateList;
  syncQueryPrinterTemplatesWithOptions: PrinterTemplateList;
  syncQueryPrinters: PrinterList;
  syncQueryPrintersWithOptions: PrinterList;
  syncQueryProductVariants: ProductVariantList;
  syncQueryProductVariantsWithOptions: ProductVariantList;
  syncQueryProducts: ProductList;
  syncQueryProductsWithOptions: ProductList;
  syncQueryRoles: RoleList;
  syncQueryRolesWithOptions: RoleList;
  syncQueryShippingMethods: ShippingMethodList;
  syncQueryShippingMethodsWithOptions: ShippingMethodList;
  syncQueryShippingTiers: ShippingTierList;
  syncQueryShippingTiersWithOptions: ShippingTierList;
  syncQueryStockLocations: StockLocationList;
  syncQueryStockLocationsWithOptions: StockLocationList;
  syncQueryStockMovements: StockMovementList;
  syncQueryStockMovementsWithOptions: StockMovementList;
  syncQueryStockTransfers: StockTransferList;
  syncQueryStockTransfersWithOptions: StockTransferList;
  syncQueryUsers: UserList;
  syncQueryUsersWithOptions: UserList;
  tag: Tag;
  tags: TagList;
  taxCategories: TaxCategoryList;
  taxCategory?: Maybe<TaxCategory>;
  taxRate?: Maybe<TaxRate>;
  taxRates: TaxRateList;
  test: Scalars['Boolean'];
  testEligibleShippingMethods: Array<ShippingMethodQuote>;
  testShippingMethod: TestShippingMethodResult;
  user: User;
  zone?: Maybe<Zone>;
  zones: ZoneList;
};


export type QueryAdministratorArgs = {
  id: Scalars['ID'];
};


export type QueryAdministratorClockEntryArgs = {
  id: Scalars['ID'];
};


export type QueryAdministratorsArgs = {
  options?: InputMaybe<AdministratorListOptions>;
};


export type QueryAppVersionArgs = {
  id: Scalars['ID'];
};


export type QueryAssetArgs = {
  id: Scalars['ID'];
};


export type QueryAssetsArgs = {
  options?: InputMaybe<AssetListOptions>;
};


export type QueryBannerArgs = {
  id: Scalars['ID'];
};


export type QueryCanUserAccessInvoiceArgs = {
  id: Scalars['ID'];
};


export type QueryChannelArgs = {
  id: Scalars['ID'];
};


export type QueryChannelsArgs = {
  options?: InputMaybe<ChannelListOptions>;
};


export type QueryCheckConflictingPostcodesArgs = {
  postcodes: Array<Scalars['String']>;
};


export type QueryCollectionArgs = {
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
};


export type QueryCollectionsArgs = {
  options?: InputMaybe<CollectionListOptions>;
};


export type QueryCountriesArgs = {
  options?: InputMaybe<CountryListOptions>;
};


export type QueryCountryArgs = {
  id: Scalars['ID'];
};


export type QueryCustomerArgs = {
  id: Scalars['ID'];
};


export type QueryCustomerGroupArgs = {
  id: Scalars['ID'];
};


export type QueryCustomerGroupsArgs = {
  options?: InputMaybe<CustomerGroupListOptions>;
};


export type QueryCustomersArgs = {
  options?: InputMaybe<CustomerListOptions>;
};


export type QueryDiscountPromotionArgs = {
  id: Scalars['ID'];
};


export type QueryEligibleShippingMethodsForDraftOrderArgs = {
  orderId: Scalars['ID'];
};


export type QueryFacetArgs = {
  id: Scalars['ID'];
};


export type QueryFacetValuesArgs = {
  options?: InputMaybe<FacetValueListOptions>;
};


export type QueryFacetsArgs = {
  options?: InputMaybe<FacetListOptions>;
};


export type QueryFulfillmentArgs = {
  id: Scalars['ID'];
};


export type QueryFulfillmentChannelArgs = {
  id: Scalars['ID'];
};


export type QueryGetActiveOutletsByAdminArgs = {
  adminIds: Array<Scalars['ID']>;
};


export type QueryGetAddressesArgs = {
  options?: InputMaybe<AddressListOptions>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetAdminSubscriberPreferenceArgs = {
  adminId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetAdminSubscriberProfileArgs = {
  adminId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetAdministratorClockEntriesArgs = {
  options?: InputMaybe<AdministratorClockEntryListOptions>;
};


export type QueryGetAdministratorsArgs = {
  options?: InputMaybe<AdministratorListOptions>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetAllModifiersArgs = {
  options?: InputMaybe<ProductListOptions>;
};


export type QueryGetAllOutletFulfillmentsTypeByDayArgs = {
  input?: InputMaybe<GetAllOutletFulfillmentsTypeByDayInput>;
};


export type QueryGetAllProductsArgs = {
  options?: InputMaybe<ProductListOptions>;
};


export type QueryGetAuditLogsArgs = {
  options?: InputMaybe<AuditLogListOptions>;
};


export type QueryGetBannersArgs = {
  options?: InputMaybe<BannerListOptions>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetCollectionsArgs = {
  options?: InputMaybe<CollectionListOptions>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetCustomerCardArgs = {
  id: Scalars['ID'];
};


export type QueryGetCustomerCardsArgs = {
  options?: InputMaybe<CustomerCardListOptions>;
};


export type QueryGetCustomerRewardsArgs = {
  input?: InputMaybe<QueryRewardsInput>;
  options?: InputMaybe<RewardListOptions>;
};


export type QueryGetCustomerSubscriberPreferenceArgs = {
  customerId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetCustomerSubscriberProfileArgs = {
  customerId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetCustomersArgs = {
  containUserRelation?: InputMaybe<Scalars['Boolean']>;
  options?: InputMaybe<CustomerListOptions>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetCustomersWithMembershipArgs = {
  options?: InputMaybe<CustomerListOptions>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetDiscountPromotionProductVariantsArgs = {
  discountPromotionId: Scalars['String'];
  options?: InputMaybe<DiscountPromotionProductVariantListOptions>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetDiscountPromotionsArgs = {
  options?: InputMaybe<DiscountPromotionListOptions>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetEstimatedTimeOfDeliveryArgs = {
  input: GetEstimatedTimeOfDeliveryInput;
};


export type QueryGetFulfillmentChannelsArgs = {
  options?: InputMaybe<FulfillmentChannelListOptions>;
};


export type QueryGetFulfillmentTimeSlotsByTypeArgs = {
  input: GetTimeSlotInput;
};


export type QueryGetFulfillmentsArgs = {
  options?: InputMaybe<FulfillmentListOptions>;
};


export type QueryGetGiftOptionArgs = {
  id: Scalars['ID'];
};


export type QueryGetGiftOptionsArgs = {
  option?: InputMaybe<GiftOptionListOptions>;
};


export type QueryGetMainProductsByModifierIdArgs = {
  id: Scalars['ID'];
  options?: InputMaybe<ProductListOptions>;
};


export type QueryGetMembershipArgs = {
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryGetMembershipTierBenefitsArgs = {
  options?: InputMaybe<TierBenefitListOptions>;
  tierId?: InputMaybe<Scalars['String']>;
};


export type QueryGetMembershipTiersArgs = {
  options?: InputMaybe<MembershipTierListOptions>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetMetabaseIframeUrlArgs = {
  options: MetabaseIframeUrlOptions;
};


export type QueryGetModifierGroupsArgs = {
  options?: InputMaybe<ModifierGroupListOptions>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetModifiersArgs = {
  options?: InputMaybe<ModifierListOptions>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetModifiersGroupingsByProductArgs = {
  productId: Scalars['ID'];
};


export type QueryGetNotificationBlastQueuesArgs = {
  options?: InputMaybe<NotificationBlastQueueListOptions>;
};


export type QueryGetNotificationBlastUsersArgs = {
  options?: InputMaybe<NotificationBlastUserListOptions>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetNotificationBlastsArgs = {
  options?: InputMaybe<NotificationBlastListOptions>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetNotificationSettingArgs = {
  id: Scalars['ID'];
};


export type QueryGetNotificationSettingsArgs = {
  options?: InputMaybe<NotificationSettingListOptions>;
};


export type QueryGetOrderArgs = {
  filter: OrderFilterParameter;
};


export type QueryGetOrderInvoicesArgs = {
  options?: InputMaybe<OrderInvoiceListOptions>;
};


export type QueryGetOrderLinesArgs = {
  options?: InputMaybe<OrderLineListOptions>;
};


export type QueryGetOrderProductQuantityArgs = {
  orderId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetOrdersArgs = {
  options?: InputMaybe<OrderListOptions>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetOutOfStockVariantsArgs = {
  productVariantIds: Array<Scalars['String']>;
};


export type QueryGetOutletFulfillmentsArgs = {
  options?: InputMaybe<OutletFulfillmentListOptions>;
  search?: InputMaybe<GetOutletFulfillmentListOptions>;
};


export type QueryGetOutletFulfillmentsByDayAndTypeArgs = {
  input?: InputMaybe<GetOutletFulfillmentsByDayAndTypeInput>;
};


export type QueryGetOutletNotificationArgs = {
  id: Scalars['ID'];
};


export type QueryGetOutletNotificationsArgs = {
  options?: InputMaybe<OutletNotificationListOptions>;
};


export type QueryGetOutletSettingsArgs = {
  options?: InputMaybe<OutletSettingListOptions>;
};


export type QueryGetOutletTableAreasArgs = {
  options?: InputMaybe<OutletTableAreaListOptions>;
};


export type QueryGetOutletTablesArgs = {
  options?: InputMaybe<OutletTableListOptions>;
};


export type QueryGetOutletsArgs = {
  options?: InputMaybe<OutletListOptions>;
};


export type QueryGetPaymentsArgs = {
  options?: InputMaybe<PaymentListOptions>;
};


export type QueryGetPointAwardWithOrderIdArgs = {
  orderId: Scalars['ID'];
};


export type QueryGetPointSettingsArgs = {
  options?: InputMaybe<PointSettingListOptions>;
};


export type QueryGetPointTransactionHistoriesArgs = {
  input?: InputMaybe<QueryPointTransactionHistorysInput>;
  options?: InputMaybe<PointTransactionHistoryListOptions>;
};


export type QueryGetProductReviewArgs = {
  id: Scalars['ID'];
};


export type QueryGetProductReviewsArgs = {
  options?: InputMaybe<ProductReviewListOptions>;
};


export type QueryGetProductVariantsArgs = {
  options?: InputMaybe<ProductVariantListOptions>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetProductsArgs = {
  options?: InputMaybe<ProductListOptions>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetProductsByCollectionArgs = {
  collectionId: Scalars['String'];
  options?: InputMaybe<ProductListOptions>;
};


export type QueryGetProductsByModifierGroupArgs = {
  id: Scalars['ID'];
  options?: InputMaybe<ProductListOptions>;
};


export type QueryGetPromoCodesArgs = {
  input?: InputMaybe<QueryPromoCodesInput>;
  options?: InputMaybe<PromoCodeListOptions>;
};


export type QueryGetRecommendedProductsArgs = {
  options?: InputMaybe<ProductListOptions>;
  productId?: InputMaybe<Scalars['String']>;
};


export type QueryGetRedemptionHistoriesArgs = {
  input?: InputMaybe<QueryRedemptionHistoriesInput>;
  options?: InputMaybe<RedemptionHistoryListOptions>;
};


export type QueryGetRedemptionHistoryDiscountsArgs = {
  cartOrder: CartOrderDetail;
  redemptionIds: Array<Scalars['String']>;
};


export type QueryGetReservationSummaryArgs = {
  input: ReservationSummaryInput;
};


export type QueryGetReservationsArgs = {
  options?: InputMaybe<ReservationListOptions>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetRewardsArgs = {
  input?: InputMaybe<QueryRewardsInput>;
  options?: InputMaybe<RewardListOptions>;
};


export type QueryGetSearchedBannersArgs = {
  input: GetSearchedBannersInput;
  options?: InputMaybe<BannerListOptions>;
};


export type QueryGetShippingMethodsArgs = {
  options?: InputMaybe<ShippingMethodListOptions>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetShippingTierArgs = {
  id: Scalars['ID'];
};


export type QueryGetShippingTiersArgs = {
  options?: InputMaybe<ShippingTierListOptions>;
};


export type QueryGetSlotAvailabilityArgs = {
  input: GetSlotAvailabilityInput;
};


export type QueryGetStarAwardWithOrderIdArgs = {
  orderId: Scalars['ID'];
};


export type QueryGetStarTransactionHistoriesArgs = {
  input?: InputMaybe<QueryStarTransactionHistorysInput>;
  options?: InputMaybe<StarTransactionHistoryListOptions>;
};


export type QueryGetStockLocationsArgs = {
  options?: InputMaybe<StockLocationListOptions>;
};


export type QueryGetStockMovementsArgs = {
  id?: InputMaybe<IdOperators>;
  options?: InputMaybe<StockMovementListOptions>;
};


export type QueryGetStockTransfersArgs = {
  options?: InputMaybe<StockTransferListOptions>;
};


export type QueryGetTemplateArgs = {
  input: GetTemplateInput;
};


export type QueryGetUsersArgs = {
  options?: InputMaybe<UserListOptions>;
};


export type QueryJobArgs = {
  jobId: Scalars['ID'];
};


export type QueryJobBufferSizeArgs = {
  bufferIds?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryJobsArgs = {
  options?: InputMaybe<JobListOptions>;
};


export type QueryJobsByIdArgs = {
  jobIds: Array<Scalars['ID']>;
};


export type QueryMembershipTierArgs = {
  id: Scalars['String'];
};


export type QueryMetricSummaryArgs = {
  input?: InputMaybe<MetricSummaryInput>;
};


export type QueryModifierArgs = {
  id: Scalars['ID'];
};


export type QueryModifierGroupArgs = {
  id: Scalars['ID'];
};


export type QueryNotificationBlastArgs = {
  id: Scalars['ID'];
};


export type QueryNotificationBlastQueueArgs = {
  id: Scalars['ID'];
};


export type QueryNotificationBlastUserArgs = {
  id: Scalars['ID'];
};


export type QueryOrderArgs = {
  id: Scalars['ID'];
};


export type QueryOrderLineArgs = {
  id: Scalars['ID'];
};


export type QueryOrderSummaryArgs = {
  input: OrderSummaryInput;
};


export type QueryOrdersArgs = {
  options?: InputMaybe<OrderListOptions>;
};


export type QueryOutletArgs = {
  id: Scalars['ID'];
};


export type QueryOutletAcceptOnlineOrderArgs = {
  outletId?: InputMaybe<Scalars['ID']>;
};


export type QueryOutletDeviceArgs = {
  id: Scalars['ID'];
};


export type QueryOutletDeviceByDeviceTokenArgs = {
  deviceToken: Scalars['String'];
};


export type QueryOutletDeviceSettingsArgs = {
  options?: InputMaybe<OutletDeviceSettingListOptions>;
};


export type QueryOutletDevicesArgs = {
  options?: InputMaybe<OutletDeviceListOptions>;
};


export type QueryOutletTableArgs = {
  id: Scalars['ID'];
};


export type QueryOutletTableAreaArgs = {
  id: Scalars['ID'];
};


export type QueryPaymentArgs = {
  id: Scalars['String'];
};


export type QueryPaymentMethodArgs = {
  id: Scalars['ID'];
};


export type QueryPaymentMethodsArgs = {
  options?: InputMaybe<PaymentMethodListOptions>;
};


export type QueryPreviewCollectionVariantsArgs = {
  input: PreviewCollectionVariantsInput;
  options?: InputMaybe<ProductVariantListOptions>;
};


export type QueryPrinterArgs = {
  id: Scalars['ID'];
};


export type QueryPrinterTagArgs = {
  id: Scalars['ID'];
};


export type QueryPrinterTagsArgs = {
  options?: InputMaybe<PrinterTagListOptions>;
};


export type QueryPrinterTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryPrinterTemplatesArgs = {
  options?: InputMaybe<PrinterTemplateListOptions>;
};


export type QueryPrintersArgs = {
  options?: InputMaybe<PrinterListOptions>;
};


export type QueryProductArgs = {
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
};


export type QueryProductOptionGroupArgs = {
  id: Scalars['ID'];
};


export type QueryProductOptionGroupsArgs = {
  filterTerm?: InputMaybe<Scalars['String']>;
};


export type QueryProductVariantArgs = {
  id: Scalars['ID'];
};


export type QueryProductVariantsArgs = {
  options?: InputMaybe<ProductVariantListOptions>;
  productId?: InputMaybe<Scalars['ID']>;
};


export type QueryProductsArgs = {
  options?: InputMaybe<ProductListOptions>;
};


export type QueryPromoCodeArgs = {
  id: Scalars['String'];
};


export type QueryPromotionArgs = {
  id: Scalars['ID'];
};


export type QueryPromotionsArgs = {
  options?: InputMaybe<PromotionListOptions>;
};


export type QueryProvinceArgs = {
  id: Scalars['ID'];
};


export type QueryProvincesArgs = {
  options?: InputMaybe<ProvinceListOptions>;
};


export type QueryPublicChannelArgs = {
  id: Scalars['ID'];
};


export type QueryPublicSellerArgs = {
  id: Scalars['ID'];
};


export type QueryRedemptionHistoryArgs = {
  id: Scalars['String'];
};


export type QueryReservationArgs = {
  id: Scalars['ID'];
};


export type QueryRewardArgs = {
  id: Scalars['String'];
};


export type QueryRoleArgs = {
  id: Scalars['ID'];
};


export type QueryRolesArgs = {
  options?: InputMaybe<RoleListOptions>;
};


export type QuerySearchArgs = {
  input: SearchInput;
};


export type QuerySellerArgs = {
  id: Scalars['ID'];
};


export type QuerySellersArgs = {
  options?: InputMaybe<SellerListOptions>;
};


export type QueryShippingMethodArgs = {
  id: Scalars['ID'];
};


export type QueryShippingMethodsArgs = {
  options?: InputMaybe<ShippingMethodListOptions>;
};


export type QuerySnapshotArgs = {
  orderId: Scalars['ID'];
};


export type QueryStockLocationArgs = {
  id: Scalars['ID'];
};


export type QueryStockLocationsArgs = {
  options?: InputMaybe<StockLocationListOptions>;
};


export type QueryStockTransferArgs = {
  id: Scalars['ID'];
};


export type QuerySyncQueryAddressesArgs = {
  options?: InputMaybe<AddressListOptions>;
  syncFilter?: InputMaybe<GenericSyncFilter>;
};


export type QuerySyncQueryAddressesWithOptionsArgs = {
  options?: InputMaybe<AddressListOptions>;
};


export type QuerySyncQueryAdministratorClockEntriesArgs = {
  options?: InputMaybe<AdministratorClockEntryListOptions>;
  syncFilter?: InputMaybe<GenericSyncFilter>;
};


export type QuerySyncQueryAdministratorClockEntriesWithOptionsArgs = {
  options?: InputMaybe<AdministratorClockEntryListOptions>;
};


export type QuerySyncQueryAdministratorsArgs = {
  options?: InputMaybe<AdministratorListOptions>;
  syncFilter?: InputMaybe<GenericSyncFilter>;
};


export type QuerySyncQueryAdministratorsWithOptionsArgs = {
  options?: InputMaybe<AdministratorListOptions>;
};


export type QuerySyncQueryChannelsArgs = {
  options?: InputMaybe<ChannelListOptions>;
  syncFilter?: InputMaybe<GenericSyncFilter>;
};


export type QuerySyncQueryChannelsWithOptionsArgs = {
  options?: InputMaybe<ChannelListOptions>;
};


export type QuerySyncQueryCollectionsArgs = {
  options?: InputMaybe<CollectionListOptions>;
  syncFilter?: InputMaybe<GenericSyncFilter>;
};


export type QuerySyncQueryCollectionsWithOptionsArgs = {
  options?: InputMaybe<CollectionListOptions>;
};


export type QuerySyncQueryCountriesArgs = {
  options?: InputMaybe<CountryListOptions>;
  syncFilter?: InputMaybe<GenericSyncFilter>;
};


export type QuerySyncQueryCountriesWithOptionsArgs = {
  options?: InputMaybe<CountryListOptions>;
};


export type QuerySyncQueryCustomersArgs = {
  options?: InputMaybe<CustomerListOptions>;
  syncFilter?: InputMaybe<GenericSyncFilter>;
};


export type QuerySyncQueryCustomersWithOptionsArgs = {
  options?: InputMaybe<CustomerListOptions>;
};


export type QuerySyncQueryDeviceSettingsArgs = {
  options?: InputMaybe<OutletDeviceSettingListOptions>;
  syncFilter?: InputMaybe<GenericSyncFilter>;
};


export type QuerySyncQueryDeviceSettingsWithOptionsArgs = {
  options?: InputMaybe<OutletDeviceSettingListOptions>;
};


export type QuerySyncQueryFacetsArgs = {
  options?: InputMaybe<FacetListOptions>;
  syncFilter?: InputMaybe<GenericSyncFilter>;
};


export type QuerySyncQueryFacetsWithOptionsArgs = {
  options?: InputMaybe<FacetListOptions>;
};


export type QuerySyncQueryFulfillmentChannelsArgs = {
  options?: InputMaybe<FulfillmentChannelListOptions>;
  syncFilter?: InputMaybe<GenericSyncFilter>;
};


export type QuerySyncQueryFulfillmentChannelsWithOptionsArgs = {
  options?: InputMaybe<FulfillmentChannelListOptions>;
};


export type QuerySyncQueryFulfillmentsArgs = {
  options?: InputMaybe<FulfillmentListOptions>;
  syncFilter?: InputMaybe<GenericSyncFilter>;
};


export type QuerySyncQueryFulfillmentsWithOptionsArgs = {
  options?: InputMaybe<FulfillmentListOptions>;
};


export type QuerySyncQueryGiftOptionsArgs = {
  options?: InputMaybe<GiftOptionListOptions>;
  syncFilter?: InputMaybe<GenericSyncFilter>;
};


export type QuerySyncQueryGiftOptionsWithOptionsArgs = {
  options?: InputMaybe<GiftOptionListOptions>;
};


export type QuerySyncQueryOrderInvoicesArgs = {
  options?: InputMaybe<OrderInvoiceListOptions>;
  syncFilter?: InputMaybe<GenericSyncFilter>;
};


export type QuerySyncQueryOrderInvoicesWithOptionsArgs = {
  options?: InputMaybe<OrderInvoiceListOptions>;
};


export type QuerySyncQueryOrderLinesArgs = {
  options?: InputMaybe<OrderLineListOptions>;
  syncFilter?: InputMaybe<GenericSyncFilter>;
};


export type QuerySyncQueryOrderLinesWithOptionsArgs = {
  options?: InputMaybe<OrderLineListOptions>;
};


export type QuerySyncQueryOrdersArgs = {
  syncFilter?: InputMaybe<GenericSyncFilter>;
};


export type QuerySyncQueryOrdersWithOptionsArgs = {
  options?: InputMaybe<OrderListOptions>;
};


export type QuerySyncQueryOutletTableAreasArgs = {
  options?: InputMaybe<OutletTableAreaListOptions>;
  syncFilter?: InputMaybe<GenericSyncFilter>;
};


export type QuerySyncQueryOutletTableAreasWithOptionsArgs = {
  options?: InputMaybe<OutletTableAreaListOptions>;
};


export type QuerySyncQueryOutletTablesArgs = {
  options?: InputMaybe<OutletTableListOptions>;
  syncFilter?: InputMaybe<GenericSyncFilter>;
};


export type QuerySyncQueryOutletTablesWithOptionsArgs = {
  options?: InputMaybe<OutletTableListOptions>;
};


export type QuerySyncQueryOutletsArgs = {
  options?: InputMaybe<OutletListOptions>;
  syncFilter?: InputMaybe<GenericSyncFilter>;
};


export type QuerySyncQueryOutletsWithOptionsArgs = {
  options?: InputMaybe<OutletListOptions>;
};


export type QuerySyncQueryPaymentMethodsArgs = {
  options?: InputMaybe<PaymentMethodListOptions>;
  syncFilter?: InputMaybe<GenericSyncFilter>;
};


export type QuerySyncQueryPaymentMethodsWithOptionsArgs = {
  options?: InputMaybe<PaymentMethodListOptions>;
};


export type QuerySyncQueryPaymentsArgs = {
  options?: InputMaybe<PaymentListOptions>;
  syncFilter?: InputMaybe<GenericSyncFilter>;
};


export type QuerySyncQueryPaymentsWithOptionsArgs = {
  options?: InputMaybe<PaymentListOptions>;
};


export type QuerySyncQueryPdfGeneratorsArgs = {
  options?: InputMaybe<PdfGeneratorListOptions>;
  syncFilter?: InputMaybe<GenericSyncFilter>;
};


export type QuerySyncQueryPdfGeneratorsWithOptionsArgs = {
  options?: InputMaybe<PdfGeneratorListOptions>;
};


export type QuerySyncQueryPdfTemplatesArgs = {
  options?: InputMaybe<PdfTemplateListOptions>;
  syncFilter?: InputMaybe<GenericSyncFilter>;
};


export type QuerySyncQueryPdfTemplatesWithOptionsArgs = {
  options?: InputMaybe<PdfTemplateListOptions>;
};


export type QuerySyncQueryPrinterTagsArgs = {
  options?: InputMaybe<PrinterTagListOptions>;
  syncFilter?: InputMaybe<GenericSyncFilter>;
};


export type QuerySyncQueryPrinterTagsWithOptionsArgs = {
  options?: InputMaybe<PrinterTagListOptions>;
};


export type QuerySyncQueryPrinterTemplatesArgs = {
  options?: InputMaybe<PrinterTemplateListOptions>;
  syncFilter?: InputMaybe<GenericSyncFilter>;
};


export type QuerySyncQueryPrinterTemplatesWithOptionsArgs = {
  options?: InputMaybe<PrinterTemplateListOptions>;
};


export type QuerySyncQueryPrintersArgs = {
  options?: InputMaybe<PrinterListOptions>;
  syncFilter?: InputMaybe<GenericSyncFilter>;
};


export type QuerySyncQueryPrintersWithOptionsArgs = {
  options?: InputMaybe<PrinterListOptions>;
};


export type QuerySyncQueryProductVariantsArgs = {
  options?: InputMaybe<ProductVariantListOptions>;
  syncFilter?: InputMaybe<GenericSyncFilter>;
};


export type QuerySyncQueryProductVariantsWithOptionsArgs = {
  options?: InputMaybe<ProductVariantListOptions>;
};


export type QuerySyncQueryProductsArgs = {
  options?: InputMaybe<ProductListOptions>;
  syncFilter?: InputMaybe<GenericSyncFilter>;
};


export type QuerySyncQueryProductsWithOptionsArgs = {
  options?: InputMaybe<ProductListOptions>;
};


export type QuerySyncQueryRolesArgs = {
  options?: InputMaybe<RoleListOptions>;
  syncFilter?: InputMaybe<GenericSyncFilter>;
};


export type QuerySyncQueryRolesWithOptionsArgs = {
  options?: InputMaybe<RoleListOptions>;
};


export type QuerySyncQueryShippingMethodsArgs = {
  options?: InputMaybe<ShippingMethodListOptions>;
  syncFilter?: InputMaybe<GenericSyncFilter>;
};


export type QuerySyncQueryShippingMethodsWithOptionsArgs = {
  options?: InputMaybe<ShippingMethodListOptions>;
};


export type QuerySyncQueryShippingTiersArgs = {
  options?: InputMaybe<ShippingTierListOptions>;
  syncFilter?: InputMaybe<GenericSyncFilter>;
};


export type QuerySyncQueryShippingTiersWithOptionsArgs = {
  options?: InputMaybe<ShippingTierListOptions>;
};


export type QuerySyncQueryStockLocationsArgs = {
  options?: InputMaybe<StockLocationListOptions>;
  syncFilter?: InputMaybe<GenericSyncFilter>;
};


export type QuerySyncQueryStockLocationsWithOptionsArgs = {
  options?: InputMaybe<StockLocationListOptions>;
};


export type QuerySyncQueryStockMovementsArgs = {
  syncFilter?: InputMaybe<GenericSyncFilter>;
};


export type QuerySyncQueryStockMovementsWithOptionsArgs = {
  options?: InputMaybe<StockMovementListOptions>;
};


export type QuerySyncQueryStockTransfersArgs = {
  options?: InputMaybe<StockTransferListOptions>;
  syncFilter?: InputMaybe<GenericSyncFilter>;
};


export type QuerySyncQueryStockTransfersWithOptionsArgs = {
  options?: InputMaybe<StockTransferListOptions>;
};


export type QuerySyncQueryUsersArgs = {
  options?: InputMaybe<UserListOptions>;
  syncFilter?: InputMaybe<GenericSyncFilter>;
};


export type QuerySyncQueryUsersWithOptionsArgs = {
  options?: InputMaybe<UserListOptions>;
};


export type QueryTagArgs = {
  id: Scalars['ID'];
};


export type QueryTagsArgs = {
  options?: InputMaybe<TagListOptions>;
};


export type QueryTaxCategoriesArgs = {
  options?: InputMaybe<TaxCategoryListOptions>;
};


export type QueryTaxCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryTaxRateArgs = {
  id: Scalars['ID'];
};


export type QueryTaxRatesArgs = {
  options?: InputMaybe<TaxRateListOptions>;
};


export type QueryTestArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryTestEligibleShippingMethodsArgs = {
  input: TestEligibleShippingMethodsInput;
};


export type QueryTestShippingMethodArgs = {
  input: TestShippingMethodInput;
};


export type QueryUserArgs = {
  id: Scalars['String'];
};


export type QueryZoneArgs = {
  id: Scalars['ID'];
};


export type QueryZonesArgs = {
  options?: InputMaybe<ZoneListOptions>;
};

export type QueryBannersArgs = {
  options?: InputMaybe<BannerListOptions>;
};

export type QueryMembershipTierBenefitsArgs = {
  options?: InputMaybe<QueryOptions>;
};

export type QueryMembershipTiersArgs = {
  options?: InputMaybe<QueryOptions>;
};

export type QueryOptions = {
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ISortParameter>;
  take?: InputMaybe<Scalars['Int']>;
};

export type QueryPointTransactionHistoryArgs = {
  queryInput?: InputMaybe<QueryPointTransactionHistorysInput>;
};

export type QueryPointTransactionHistoryOptions = {
  options?: InputMaybe<QueryOptions>;
};

export type QueryPointTransactionHistorysInput = {
  endPointDate?: InputMaybe<Scalars['String']>;
  isPointReceive?: InputMaybe<Scalars['Boolean']>;
  pointToSearch?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<PointTransactionSourceType>;
  startingPointDate?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryPromoCodesInput = {
  customerTierIds?: InputMaybe<Array<Scalars['String']>>;
  endDateBegin?: InputMaybe<Scalars['String']>;
  endDateEnd?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  startDateBegin?: InputMaybe<Scalars['String']>;
  startDateEnd?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<PromoCodeStatus>;
  type?: InputMaybe<PromoCodeType>;
};

export type QueryPromoCodesOptions = {
  options?: InputMaybe<QueryOptions>;
};

export type QueryRedemptionHistoriesInput = {
  beginDateRangeCreatedAt?: InputMaybe<Scalars['String']>;
  beginDateRangeUsedDate?: InputMaybe<Scalars['String']>;
  endDateRangeCreatedAt?: InputMaybe<Scalars['String']>;
  endDateRangeUsedDate?: InputMaybe<Scalars['String']>;
  isComparePromoCode?: InputMaybe<Scalars['Boolean']>;
  isQueryActiveRewards?: InputMaybe<Scalars['Boolean']>;
  membershipUserId?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<RedemptionHistoryType>;
};

export type QueryRedemptionHistoryOptions = {
  options?: InputMaybe<QueryOptions>;
};

export type QueryReservationsArgs = {
  options?: InputMaybe<ReservationListOptions>;
};

export type QueryRewardOptions = {
  options?: InputMaybe<QueryOptions>;
};

export type QueryRewardsInput = {
  customerId?: InputMaybe<Scalars['String']>;
  customerTierIds?: InputMaybe<Array<Scalars['String']>>;
  endDateBegin?: InputMaybe<Scalars['String']>;
  endDateEnd?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  startDateBegin?: InputMaybe<Scalars['String']>;
  startDateEnd?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<RewardStatus>;
  type?: InputMaybe<RewardType>;
};

export type QueryStarTransactionHistoryArgs = {
  queryInput?: InputMaybe<QueryStarTransactionHistorysInput>;
};

export type QueryStarTransactionHistoryOptions = {
  options?: InputMaybe<QueryOptions>;
};

export type QueryStarTransactionHistorysInput = {
  endStarDate?: InputMaybe<Scalars['String']>;
  isStarReceive?: InputMaybe<Scalars['Boolean']>;
  pointToSearch?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<StarTransactionSourceType>;
  startingStarDate?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type RedeemForMultipleCustomerResult = {
  __typename?: 'RedeemForMultipleCustomerResult';
  createdRedemptions: Array<RedemptionHistory>;
  nonRedeemableMemberships: Array<NonRedeemableMemberships>;
  redeemedMemberships: Array<Membership>;
};

export enum RedeemerRole {
  Admin = 'ADMIN',
  Staff = 'STAFF',
  System = 'SYSTEM',
  User = 'USER'
}

export type RedemptionHistory = Node & {
  __typename?: 'RedemptionHistory';
  createdAt: Scalars['String'];
  expiresOn?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isUsed: Scalars['Boolean'];
  membership?: Maybe<Membership>;
  /**
   * This only needed for mobile app,
   * admin panel no need. Therefore make it optional.
   * Dont query this in admin API
   */
  parentType?: Maybe<PromotionType>;
  pointCost: Scalars['Float'];
  promoCode?: Maybe<PromoCode>;
  redeemerId: Scalars['String'];
  redeemerRole: RedeemerRole;
  reward?: Maybe<Reward>;
  type: RedemptionHistoryType;
  usedDate?: Maybe<Scalars['String']>;
};

export type RedemptionHistoryDiscountResult = {
  __typename?: 'RedemptionHistoryDiscountResult';
  discountType?: Maybe<PromoCodeType>;
  productVariantId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  totalDiscount: Scalars['Float'];
  type: RedemptionHistoryType;
  value?: Maybe<Scalars['Float']>;
};

export type RedemptionHistoryFilterParameter = {
  _and?: InputMaybe<Array<RedemptionHistoryFilterParameter>>;
  _or?: InputMaybe<Array<RedemptionHistoryFilterParameter>>;
  createdAt?: InputMaybe<StringOperators>;
  expiresOn?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  isUsed?: InputMaybe<BooleanOperators>;
  parentType?: InputMaybe<StringOperators>;
  pointCost?: InputMaybe<NumberOperators>;
  redeemerId?: InputMaybe<StringOperators>;
  redeemerRole?: InputMaybe<StringOperators>;
  type?: InputMaybe<StringOperators>;
  usedDate?: InputMaybe<StringOperators>;
};

export type RedemptionHistoryList = PaginatedList & {
  __typename?: 'RedemptionHistoryList';
  items: Array<RedemptionHistory>;
  totalItems: Scalars['Int'];
};

export type RedemptionHistoryListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<RedemptionHistoryFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<RedemptionHistorySortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type RedemptionHistorySortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  expiresOn?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  pointCost?: InputMaybe<SortOrder>;
  redeemerId?: InputMaybe<SortOrder>;
  usedDate?: InputMaybe<SortOrder>;
};

export enum RedemptionHistoryType {
  InAppItem = 'IN_APP_ITEM',
  InStoreItem = 'IN_STORE_ITEM',
  PromoCode = 'PROMO_CODE'
}

export type Refund = Node & {
  __typename?: 'Refund';
  adjustment: Scalars['Money'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  items: Scalars['Money'];
  lines: Array<RefundLine>;
  metadata?: Maybe<Scalars['JSON']>;
  method?: Maybe<Scalars['String']>;
  paymentId: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
  shipping: Scalars['Money'];
  state: Scalars['String'];
  total: Scalars['Money'];
  transactionId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** Returned if `amount` is greater than the maximum un-refunded amount of the Payment */
export type RefundAmountError = ErrorResult & {
  __typename?: 'RefundAmountError';
  errorCode: ErrorCode;
  maximumRefundable: Scalars['Int'];
  message: Scalars['String'];
};

export type RefundDetailInput = {
  createdAt: Scalars['Float'];
  deletedAt?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  isDeleted?: Scalars['Boolean'];
  lines?: InputMaybe<Array<RefundLineItemInput>>;
  method?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
  total: Scalars['Float'];
  transactionId?: InputMaybe<Scalars['String']>;
  updatedAt: Scalars['Float'];
};

export type RefundLine = {
  __typename?: 'RefundLine';
  orderLine: OrderLine;
  orderLineId: Scalars['ID'];
  quantity: Scalars['Int'];
  refund: Refund;
  refundId: Scalars['ID'];
};

export type RefundLineItemInput = {
  orderLineId: Scalars['String'];
  quantity: Scalars['Float'];
};

export type RefundOrderInput = {
  adjustment: Scalars['Money'];
  /**
   * If an amount is specified, this value will be used to create a Refund rather than calculating the
   * amount automatically. This was added in v2.2 and will be the preferred way to specify the refund
   * amount in the future. The `lines`, `shipping` and `adjustment` fields will likely be removed in a future
   * version.
   */
  amount?: InputMaybe<Scalars['Money']>;
  lines: Array<OrderLineInput>;
  paymentId: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
  shipping: Scalars['Money'];
};

export type RefundOrderResult = AlreadyRefundedError | MultipleOrderError | NothingToRefundError | OrderStateTransitionError | PaymentOrderMismatchError | QuantityTooGreatError | Refund | RefundAmountError | RefundOrderStateError | RefundStateTransitionError;

/** Returned if an attempting to refund an Order which is not in the expected state */
export type RefundOrderStateError = ErrorResult & {
  __typename?: 'RefundOrderStateError';
  errorCode: ErrorCode;
  message: Scalars['String'];
  orderState: Scalars['String'];
};

/**
 * Returned when a call to modifyOrder fails to include a refundPaymentId even
 * though the price has decreased as a result of the changes.
 */
export type RefundPaymentIdMissingError = ErrorResult & {
  __typename?: 'RefundPaymentIdMissingError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

/** Returned when there is an error in transitioning the Refund state */
export type RefundStateTransitionError = ErrorResult & {
  __typename?: 'RefundStateTransitionError';
  errorCode: ErrorCode;
  fromState: Scalars['String'];
  message: Scalars['String'];
  toState: Scalars['String'];
  transitionError: Scalars['String'];
};

export type Region = {
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  parent?: Maybe<Region>;
  parentId?: Maybe<Scalars['ID']>;
  translations: Array<RegionTranslation>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type RegionTranslation = {
  __typename?: 'RegionTranslation';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type RegisterAdminMobileInput = {
  customFields: AdminMobileInput;
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  roleIds: Array<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export enum RegistrationChannel {
  Backoffice = 'BACKOFFICE',
  MobileApp = 'MOBILE_APP',
  Pos = 'POS',
  WebApp = 'WEB_APP'
}

export type RelationCustomFieldConfig = CustomField & {
  __typename?: 'RelationCustomFieldConfig';
  description?: Maybe<Array<LocalizedString>>;
  entity: Scalars['String'];
  internal?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Array<LocalizedString>>;
  list: Scalars['Boolean'];
  name: Scalars['String'];
  nullable?: Maybe<Scalars['Boolean']>;
  readonly?: Maybe<Scalars['Boolean']>;
  requiresPermission?: Maybe<Array<Permission>>;
  scalarFields: Array<Scalars['String']>;
  type: Scalars['String'];
  ui?: Maybe<Scalars['JSON']>;
};

export type Release = Node & StockMovement & {
  __typename?: 'Release';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  orderLine: OrderLine;
  posId?: Maybe<Scalars['String']>;
  productVariant: ProductVariant;
  quantity: Scalars['Int'];
  stockLocation: StockLocation;
  type: StockMovementType;
  updatedAt: Scalars['DateTime'];
};

export type RemoveCollectionsFromChannelInput = {
  channelId: Scalars['ID'];
  collectionIds: Array<Scalars['ID']>;
};

export type RemoveFacetFromChannelResult = Facet | FacetInUseError;

export type RemoveFacetsFromChannelInput = {
  channelId: Scalars['ID'];
  facetIds: Array<Scalars['ID']>;
  force?: InputMaybe<Scalars['Boolean']>;
};

export type RemoveOptionGroupFromProductResult = Product | ProductOptionInUseError;

export type RemoveOrderItemsResult = Order | OrderModificationError;

export type RemovePaymentMethodsFromChannelInput = {
  channelId: Scalars['ID'];
  paymentMethodIds: Array<Scalars['ID']>;
};

export type RemoveProductVariantsFromChannelInput = {
  channelId: Scalars['ID'];
  productVariantIds: Array<Scalars['ID']>;
};

export type RemoveProductsFromChannelInput = {
  channelId: Scalars['ID'];
  productIds: Array<Scalars['ID']>;
};

export type RemovePromotionsFromChannelInput = {
  channelId: Scalars['ID'];
  promotionIds: Array<Scalars['ID']>;
};

export type RemoveShippingMethodsFromChannelInput = {
  channelId: Scalars['ID'];
  shippingMethodIds: Array<Scalars['ID']>;
};

export type RemoveStockLocationsFromChannelInput = {
  channelId: Scalars['ID'];
  stockLocationIds: Array<Scalars['ID']>;
};

export type Reservation = Node & {
  __typename?: 'Reservation';
  adultPax: Scalars['Int'];
  cancellationRemark?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  customer: Customer;
  diningDate: Scalars['DateTime'];
  guestName: Scalars['String'];
  id: Scalars['ID'];
  kidPax: Scalars['Int'];
  occasion?: Maybe<Array<Scalars['String']>>;
  phoneNumber: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
  status: ReservationStatusType;
  updatedAt: Scalars['DateTime'];
};

export type ReservationFilterParameter = {
  _and?: InputMaybe<Array<ReservationFilterParameter>>;
  _or?: InputMaybe<Array<ReservationFilterParameter>>;
  adultPax?: InputMaybe<NumberOperators>;
  cancellationRemark?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  diningDate?: InputMaybe<DateOperators>;
  guestName?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  kidPax?: InputMaybe<NumberOperators>;
  phoneNumber?: InputMaybe<StringOperators>;
  remark?: InputMaybe<StringOperators>;
  status?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type ReservationList = PaginatedList & {
  __typename?: 'ReservationList';
  items: Array<Reservation>;
  totalItems: Scalars['Int'];
};

export type ReservationListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<ReservationFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<ReservationSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type ReservationSortParameter = {
  adultPax?: InputMaybe<SortOrder>;
  cancellationRemark?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  diningDate?: InputMaybe<SortOrder>;
  guestName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  kidPax?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  remark?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ReservationStatusType {
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED',
  Modified = 'MODIFIED',
  Pending = 'PENDING'
}

export type ReservationSummary = {
  __typename?: 'ReservationSummary';
  date: Scalars['DateTime'];
  reservations: Array<Reservation>;
};

export type ReservationSummaryInput = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};

export enum ReservationSummaryType {
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY'
}

export type Return = Node & StockMovement & {
  __typename?: 'Return';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  posId?: Maybe<Scalars['String']>;
  productVariant: ProductVariant;
  quantity: Scalars['Int'];
  stockLocation: StockLocation;
  type: StockMovementType;
  updatedAt: Scalars['DateTime'];
};

export enum ReviewStateEnum {
  Created = 'CREATED',
  Hidden = 'HIDDEN',
  PendingModeration = 'PENDING_MODERATION',
  Updated = 'UPDATED'
}

export type Reward = Node & {
  __typename?: 'Reward';
  cappedDiscount?: Maybe<Scalars['Float']>;
  createdAt: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  endAt: Scalars['String'];
  id: Scalars['ID'];
  imageUrls?: Maybe<Array<Scalars['String']>>;
  isPrivate: Scalars['Boolean'];
  membershipTiers?: Maybe<Array<MembershipTier>>;
  minimumSpendAmount?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  onRedeemConfig?: Maybe<ExpiryOnRedeemConfig>;
  /**
   * This only needed for mobile app,
   * admin panel no need. Therefore make it optional.
   * Dont query this in admin API
   */
  parentType?: Maybe<PromotionType>;
  pointCost: Scalars['Float'];
  productVariantId?: Maybe<Scalars['String']>;
  productVariantImage?: Maybe<Scalars['String']>;
  productVariantName?: Maybe<Scalars['String']>;
  productVariantPrice?: Maybe<Scalars['String']>;
  productVariantQuantity?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  redeemCriteria: Scalars['String'];
  redeemPerUserLimit?: Maybe<Scalars['Int']>;
  redemptionCount: Scalars['Int'];
  rewardUsedCount: Scalars['Int'];
  runningId: Scalars['String'];
  startAt: Scalars['String'];
  status: RewardStatus;
  termCondition: Scalars['String'];
  /**
   * This is include Reward Type and PromoCode type
   * Both inclusive in 1, because in Mobile, both entity merge together
   * Both wont affect anything in admin panel.
   */
  type: RewardOrPromoCodeType;
  updatedAt: Scalars['String'];
};

export type RewardFilterParameter = {
  _and?: InputMaybe<Array<RewardFilterParameter>>;
  _or?: InputMaybe<Array<RewardFilterParameter>>;
  cappedDiscount?: InputMaybe<NumberOperators>;
  createdAt?: InputMaybe<StringOperators>;
  description?: InputMaybe<StringOperators>;
  endAt?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  isPrivate?: InputMaybe<BooleanOperators>;
  minimumSpendAmount?: InputMaybe<NumberOperators>;
  name?: InputMaybe<StringOperators>;
  parentType?: InputMaybe<StringOperators>;
  pointCost?: InputMaybe<NumberOperators>;
  productVariantId?: InputMaybe<StringOperators>;
  productVariantImage?: InputMaybe<StringOperators>;
  productVariantName?: InputMaybe<StringOperators>;
  productVariantPrice?: InputMaybe<StringOperators>;
  productVariantQuantity?: InputMaybe<StringOperators>;
  quantity?: InputMaybe<NumberOperators>;
  redeemCriteria?: InputMaybe<StringOperators>;
  redeemPerUserLimit?: InputMaybe<NumberOperators>;
  redemptionCount?: InputMaybe<NumberOperators>;
  rewardUsedCount?: InputMaybe<NumberOperators>;
  runningId?: InputMaybe<StringOperators>;
  startAt?: InputMaybe<StringOperators>;
  status?: InputMaybe<StringOperators>;
  termCondition?: InputMaybe<StringOperators>;
  type?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<StringOperators>;
};

export type RewardList = PaginatedList & {
  __typename?: 'RewardList';
  items: Array<Reward>;
  totalItems: Scalars['Int'];
};

export type RewardListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<RewardFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<RewardSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export enum RewardOrPromoCodeType {
  DiscountPercentage = 'DISCOUNT_PERCENTAGE',
  FixedValue = 'FIXED_VALUE',
  InApp = 'IN_APP',
  InStore = 'IN_STORE'
}

export type RewardSortParameter = {
  cappedDiscount?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  endAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  minimumSpendAmount?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  pointCost?: InputMaybe<SortOrder>;
  productVariantId?: InputMaybe<SortOrder>;
  productVariantImage?: InputMaybe<SortOrder>;
  productVariantName?: InputMaybe<SortOrder>;
  productVariantPrice?: InputMaybe<SortOrder>;
  productVariantQuantity?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  redeemCriteria?: InputMaybe<SortOrder>;
  redeemPerUserLimit?: InputMaybe<SortOrder>;
  redemptionCount?: InputMaybe<SortOrder>;
  rewardUsedCount?: InputMaybe<SortOrder>;
  runningId?: InputMaybe<SortOrder>;
  startAt?: InputMaybe<SortOrder>;
  termCondition?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum RewardStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum RewardType {
  InApp = 'IN_APP',
  InStore = 'IN_STORE'
}

export type Role = Node & {
  __typename?: 'Role';
  channels: Array<Channel>;
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  permissions: Array<Permission>;
  updatedAt: Scalars['DateTime'];
};

export type RoleFilterParameter = {
  _and?: InputMaybe<Array<RoleFilterParameter>>;
  _or?: InputMaybe<Array<RoleFilterParameter>>;
  code?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  description?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type RoleList = PaginatedList & {
  __typename?: 'RoleList';
  items: Array<Role>;
  totalItems: Scalars['Int'];
};

export type RoleListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<RoleFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<RoleSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type RoleSortParameter = {
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type Sale = Node & StockMovement & {
  __typename?: 'Sale';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  posId?: Maybe<Scalars['String']>;
  productVariant: ProductVariant;
  quantity: Scalars['Int'];
  stockLocation: StockLocation;
  type: StockMovementType;
  updatedAt: Scalars['DateTime'];
};

export enum SalesChannel {
  Backoffice = 'BACKOFFICE',
  MobileApp = 'MOBILE_APP',
  Pos = 'POS',
  Unknown = 'UNKNOWN',
  WebApp = 'WEB_APP'
}

export type SalesContributionChannelConfig = {
  __typename?: 'SalesContributionChannelConfig';
  enabled: Scalars['Boolean'];
};

export type SearchInput = {
  collectionId?: InputMaybe<Scalars['ID']>;
  collectionSlug?: InputMaybe<Scalars['String']>;
  facetValueFilters?: InputMaybe<Array<FacetValueFilterInput>>;
  groupByProduct?: InputMaybe<Scalars['Boolean']>;
  inStock?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SearchResultSortParameter>;
  take?: InputMaybe<Scalars['Int']>;
  term?: InputMaybe<Scalars['String']>;
};

export type SearchReindexResponse = {
  __typename?: 'SearchReindexResponse';
  success: Scalars['Boolean'];
};

export type SearchResponse = {
  __typename?: 'SearchResponse';
  collections: Array<CollectionResult>;
  facetValues: Array<FacetValueResult>;
  items: Array<SearchResult>;
  totalItems: Scalars['Int'];
};

export type SearchResult = {
  __typename?: 'SearchResult';
  /** An array of ids of the Channels in which this result appears */
  channelIds: Array<Scalars['ID']>;
  /** An array of ids of the Collections in which this result appears */
  collectionIds: Array<Scalars['ID']>;
  currencyCode: CurrencyCode;
  description: Scalars['String'];
  enabled: Scalars['Boolean'];
  facetIds: Array<Scalars['ID']>;
  facetValueIds: Array<Scalars['ID']>;
  inStock: Scalars['Boolean'];
  price: SearchResultPrice;
  priceWithTax: SearchResultPrice;
  productAsset?: Maybe<SearchResultAsset>;
  productId: Scalars['ID'];
  productName: Scalars['String'];
  productVariantAsset?: Maybe<SearchResultAsset>;
  productVariantId: Scalars['ID'];
  productVariantName: Scalars['String'];
  /** A relevance score for the result. Differs between database implementations */
  score: Scalars['Float'];
  sku: Scalars['String'];
  slug: Scalars['String'];
};

export type SearchResultAsset = {
  __typename?: 'SearchResultAsset';
  focalPoint?: Maybe<Coordinate>;
  id: Scalars['ID'];
  preview: Scalars['String'];
};

/** The price of a search result product, either as a range or as a single price */
export type SearchResultPrice = PriceRange | SinglePrice;

export type SearchResultSortParameter = {
  name?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
};

export type Seller = Node & {
  __typename?: 'Seller';
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<SellerCustomFields>;
  footerConfig?: Maybe<WebFooterConfig>;
  id: Scalars['ID'];
  introductionConfig?: Maybe<SellerIntroductionConfig>;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type SellerCustomFields = {
  __typename?: 'SellerCustomFields';
  description?: Maybe<Scalars['String']>;
  favicon?: Maybe<Scalars['String']>;
  footerConfig?: Maybe<Scalars['String']>;
  introductionConfig?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  logoWidthAndHeight?: Maybe<Scalars['String']>;
  metabaseDisplayIndex?: Maybe<Scalars['String']>;
  resources?: Maybe<Scalars['String']>;
};

export type SellerFilterParameter = {
  _and?: InputMaybe<Array<SellerFilterParameter>>;
  _or?: InputMaybe<Array<SellerFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  description?: InputMaybe<StringOperators>;
  favicon?: InputMaybe<StringOperators>;
  footerConfig?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  introductionConfig?: InputMaybe<StringOperators>;
  logo?: InputMaybe<StringOperators>;
  logoWidthAndHeight?: InputMaybe<StringOperators>;
  metabaseDisplayIndex?: InputMaybe<StringOperators>;
  name?: InputMaybe<StringOperators>;
  resources?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type SellerIntroductionConfig = {
  __typename?: 'SellerIntroductionConfig';
  banner?: Maybe<Scalars['String']>;
  heroText?: Maybe<Scalars['String']>;
};

export type SellerLink = {
  __typename?: 'SellerLink';
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type SellerList = PaginatedList & {
  __typename?: 'SellerList';
  items: Array<Seller>;
  totalItems: Scalars['Int'];
};

export type SellerListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<SellerFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<SellerSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export enum SellerResource {
  AuditLog = 'AUDIT_LOG',
  Banner = 'BANNER',
  Collection = 'COLLECTION',
  Customer = 'CUSTOMER',
  Dashboard = 'DASHBOARD',
  DiscountPromotion = 'DISCOUNT_PROMOTION',
  MembershipTier = 'MEMBERSHIP_TIER',
  Modifier = 'MODIFIER',
  NotificationBlast = 'NOTIFICATION_BLAST',
  Order = 'ORDER',
  PointAdjustment = 'POINT_ADJUSTMENT',
  Product = 'PRODUCT',
  Profile = 'PROFILE',
  PromoCode = 'PROMO_CODE',
  Reporting = 'REPORTING',
  Reservation = 'RESERVATION',
  Reward = 'REWARD',
  Setting = 'SETTING',
  Staff = 'STAFF'
}

export type SellerSocialMedia = {
  __typename?: 'SellerSocialMedia';
  link?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type SellerSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  favicon?: InputMaybe<SortOrder>;
  footerConfig?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  introductionConfig?: InputMaybe<SortOrder>;
  logo?: InputMaybe<SortOrder>;
  logoWidthAndHeight?: InputMaybe<SortOrder>;
  metabaseDisplayIndex?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  resources?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ServerConfig = {
  __typename?: 'ServerConfig';
  /**
   * This field is deprecated in v2.2 in favor of the entityCustomFields field,
   * which allows custom fields to be defined on user-supplies entities.
   */
  customFieldConfig: CustomFields;
  entityCustomFields: Array<EntityCustomFields>;
  moneyStrategyPrecision: Scalars['Int'];
  orderProcess: Array<OrderProcessState>;
  permissions: Array<PermissionDefinition>;
  permittedAssetTypes: Array<Scalars['String']>;
};

export type SetCustomerForDraftOrderResult = EmailAddressConflictError | Order;

export type SetOrderCustomerInput = {
  customerId: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
  orderId: Scalars['ID'];
};

export type SetOrderShippingMethodResult = IneligibleShippingMethodError | NoActiveOrderError | Order | OrderModificationError;

/** Returned if the Payment settlement fails */
export type SettlePaymentError = ErrorResult & {
  __typename?: 'SettlePaymentError';
  errorCode: ErrorCode;
  message: Scalars['String'];
  paymentErrorMessage: Scalars['String'];
};

export type SettlePaymentResult = OrderStateTransitionError | Payment | PaymentStateTransitionError | SettlePaymentError;

export type SettleRefundInput = {
  id: Scalars['ID'];
  transactionId: Scalars['String'];
};

export type SettleRefundResult = Refund | RefundStateTransitionError;

export type ShippingLine = {
  __typename?: 'ShippingLine';
  discountedPrice: Scalars['Money'];
  discountedPriceWithTax: Scalars['Money'];
  discounts: Array<Discount>;
  id: Scalars['ID'];
  price: Scalars['Money'];
  priceWithTax: Scalars['Money'];
  shippingMethod: ShippingMethod;
};

export type ShippingMethod = Node & {
  __typename?: 'ShippingMethod';
  calculator: ConfigurableOperation;
  checker: ConfigurableOperation;
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<ShippingMethodCustomFields>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  fulfillmentHandlerCode: Scalars['String'];
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  translations: Array<ShippingMethodTranslation>;
  updatedAt: Scalars['DateTime'];
};

export enum ShippingMethodCodeList {
  Delivery = 'DELIVERY',
  DineIn = 'DINE_IN',
  Pickup = 'PICKUP'
}

export type ShippingMethodCustomFields = {
  __typename?: 'ShippingMethodCustomFields';
  applyRounding?: Maybe<Scalars['Boolean']>;
  fulfillmentChannel?: Maybe<FulfillmentChannel>;
  includeServiceCharge?: Maybe<Scalars['Boolean']>;
  posAutoFulfillAllItemsOnPaid?: Maybe<Scalars['Boolean']>;
  tierRange?: Maybe<Array<ShippingTier>>;
};

export type ShippingMethodFilterParameter = {
  _and?: InputMaybe<Array<ShippingMethodFilterParameter>>;
  _or?: InputMaybe<Array<ShippingMethodFilterParameter>>;
  applyRounding?: InputMaybe<BooleanOperators>;
  code?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  description?: InputMaybe<StringOperators>;
  fulfillmentHandlerCode?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  includeServiceCharge?: InputMaybe<BooleanOperators>;
  languageCode?: InputMaybe<StringOperators>;
  name?: InputMaybe<StringOperators>;
  posAutoFulfillAllItemsOnPaid?: InputMaybe<BooleanOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type ShippingMethodList = PaginatedList & {
  __typename?: 'ShippingMethodList';
  items: Array<ShippingMethod>;
  totalItems: Scalars['Int'];
};

export type ShippingMethodListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<ShippingMethodFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<ShippingMethodSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type ShippingMethodQuote = {
  __typename?: 'ShippingMethodQuote';
  code: Scalars['String'];
  customFields?: Maybe<ShippingMethodCustomFields>;
  description: Scalars['String'];
  id: Scalars['ID'];
  /** Any optional metadata returned by the ShippingCalculator in the ShippingCalculationResult */
  metadata?: Maybe<Scalars['JSON']>;
  name: Scalars['String'];
  price: Scalars['Money'];
  priceWithTax: Scalars['Money'];
};

export type ShippingMethodSortParameter = {
  applyRounding?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  fulfillmentChannel?: InputMaybe<SortOrder>;
  fulfillmentHandlerCode?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  includeServiceCharge?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  posAutoFulfillAllItemsOnPaid?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ShippingMethodTranslation = {
  __typename?: 'ShippingMethodTranslation';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ShippingMethodTranslationInput = {
  customFields?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  languageCode: LanguageCode;
  name?: InputMaybe<Scalars['String']>;
};

export type ShippingTier = Node & {
  __typename?: 'ShippingTier';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  maxQuantity: Scalars['Int'];
  minQuantity: Scalars['Int'];
  shippingMethod?: Maybe<ShippingMethod>;
  shippingMethodId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['Float'];
};

export type ShippingTierFilterParameter = {
  _and?: InputMaybe<Array<ShippingTierFilterParameter>>;
  _or?: InputMaybe<Array<ShippingTierFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  maxQuantity?: InputMaybe<NumberOperators>;
  minQuantity?: InputMaybe<NumberOperators>;
  shippingMethodId?: InputMaybe<IdOperators>;
  updatedAt?: InputMaybe<DateOperators>;
  value?: InputMaybe<NumberOperators>;
};

export type ShippingTierList = PaginatedList & {
  __typename?: 'ShippingTierList';
  items: Array<ShippingTier>;
  totalItems: Scalars['Int'];
};

export type ShippingTierListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<ShippingTierFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<ShippingTierSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type ShippingTierSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  maxQuantity?: InputMaybe<SortOrder>;
  minQuantity?: InputMaybe<SortOrder>;
  shippingMethodId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

/** The price value where the result has a single price */
export type SinglePrice = {
  __typename?: 'SinglePrice';
  value: Scalars['Money'];
};

export type SlotAvailabilityResult = {
  __typename?: 'SlotAvailabilityResult';
  available: Scalars['Boolean'];
  slot: Scalars['DateTime'];
};

export type SnapshotInput = {
  addOns: Array<AddOnInput>;
  productVariantId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type SnapshotModifier = {
  __typename?: 'SnapshotModifier';
  modifierId: Scalars['String'];
  variants: Array<ModifierVariantSnapshot>;
};

export type SnapshotOrder = {
  __typename?: 'SnapshotOrder';
  active?: Maybe<Scalars['Boolean']>;
  billingAddress?: Maybe<OrderAddress>;
  code?: Maybe<Scalars['String']>;
  couponCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  customer?: Maybe<Customer>;
  lines?: Maybe<Array<SnapshotOrderLine>>;
  payments?: Maybe<Array<Maybe<Payment>>>;
  promotions?: Maybe<Array<Maybe<Promotion>>>;
  shipping?: Maybe<Scalars['Money']>;
  shippingAddress?: Maybe<OrderAddress>;
  shippingLines?: Maybe<Array<Maybe<ShippingLine>>>;
  shippingWithTax?: Maybe<Scalars['Money']>;
  subTotal?: Maybe<Scalars['Money']>;
  subTotalWithTax?: Maybe<Scalars['Money']>;
  surcharges?: Maybe<Array<Maybe<Surcharge>>>;
  taxSummary?: Maybe<Array<Maybe<OrderTaxSummary>>>;
  total?: Maybe<Scalars['Money']>;
  totalQuantity?: Maybe<Scalars['Int']>;
  totalWithTax?: Maybe<Scalars['Money']>;
};

export type SnapshotOrderLine = {
  __typename?: 'SnapshotOrderLine';
  discountedLinePrice?: Maybe<Scalars['Money']>;
  discountedLinePriceWithTax?: Maybe<Scalars['Money']>;
  discountedUnitPrice?: Maybe<Scalars['Money']>;
  discountedUnitPriceWithTax?: Maybe<Scalars['Money']>;
  discounts?: Maybe<Array<Maybe<Discount>>>;
  id?: Maybe<Scalars['ID']>;
  linePrice?: Maybe<Scalars['Money']>;
  linePriceWithTax?: Maybe<Scalars['Money']>;
  lineTax?: Maybe<Scalars['Money']>;
  orderPlacedQuantity?: Maybe<Scalars['Int']>;
  productVariant?: Maybe<ProductVariant>;
  productVariantId?: Maybe<Scalars['ID']>;
  proratedLinePrice?: Maybe<Scalars['Money']>;
  proratedLinePriceWithTax?: Maybe<Scalars['Money']>;
  proratedLineTax?: Maybe<Scalars['Money']>;
  proratedUnitPrice?: Maybe<Scalars['Money']>;
  proratedUnitPriceWithTax?: Maybe<Scalars['Money']>;
  quantity?: Maybe<Scalars['Int']>;
  taxRate?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Money']>;
  unitPriceChangeSinceAdded?: Maybe<Scalars['Money']>;
  unitPriceWithTax?: Maybe<Scalars['Money']>;
  unitPriceWithTaxChangeSinceAdded?: Maybe<Scalars['Money']>;
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Staff = {
  __typename?: 'Staff';
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
};

export type StarCalculationResult = {
  __typename?: 'StarCalculationResult';
  amountToCalculate: Scalars['String'];
  awardStarPerUnit: Scalars['String'];
  message: Scalars['String'];
  pointAwarded: Scalars['Int'];
  type: PointSettingType;
  unitValue: Scalars['String'];
};

export enum StarSignType {
  Negative = 'NEGATIVE',
  Positive = 'POSITIVE'
}

export type StarStaff = {
  __typename?: 'StarStaff';
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
};

export enum StarTransactionAdminActionType {
  Deduct = 'DEDUCT',
  Grant = 'GRANT'
}

export type StarTransactionHistory = Node & {
  __typename?: 'StarTransactionHistory';
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  membership: Membership;
  membershipId: Scalars['ID'];
  point: Scalars['Float'];
  pointSign: StarSignType;
  remarks?: Maybe<Scalars['String']>;
  source: StarTransactionSourceType;
  staff?: Maybe<StarStaff>;
};

export type StarTransactionHistoryFilterParameter = {
  _and?: InputMaybe<Array<StarTransactionHistoryFilterParameter>>;
  _or?: InputMaybe<Array<StarTransactionHistoryFilterParameter>>;
  createdAt?: InputMaybe<StringOperators>;
  createdBy?: InputMaybe<StringOperators>;
  description?: InputMaybe<StringOperators>;
  expiryDate?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  membershipId?: InputMaybe<IdOperators>;
  point?: InputMaybe<NumberOperators>;
  pointSign?: InputMaybe<StringOperators>;
  remarks?: InputMaybe<StringOperators>;
  source?: InputMaybe<StringOperators>;
};

export type StarTransactionHistoryList = PaginatedList & {
  __typename?: 'StarTransactionHistoryList';
  items: Array<StarTransactionHistory>;
  totalItems: Scalars['Int'];
};

export type StarTransactionHistoryListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<StarTransactionHistoryFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<StarTransactionHistorySortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type StarTransactionHistorySortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  expiryDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  membershipId?: InputMaybe<SortOrder>;
  point?: InputMaybe<SortOrder>;
  remarks?: InputMaybe<SortOrder>;
};

export enum StarTransactionSourceType {
  Deduct = 'DEDUCT',
  Earned = 'EARNED',
  Grant = 'GRANT',
  Spent = 'SPENT'
}

export type StockAdjustment = Node & StockMovement & {
  __typename?: 'StockAdjustment';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  posId?: Maybe<Scalars['String']>;
  productVariant: ProductVariant;
  quantity: Scalars['Int'];
  stockLocation: StockLocation;
  type: StockMovementType;
  updatedAt: Scalars['DateTime'];
};

export type StockLevel = Node & {
  __typename?: 'StockLevel';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  stockAllocated: Scalars['Int'];
  stockLocation: StockLocation;
  stockLocationId: Scalars['ID'];
  stockOnHand: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type StockLevelInput = {
  stockLocationId: Scalars['ID'];
  stockOnHand: Scalars['Int'];
};

export type StockLocation = Node & {
  __typename?: 'StockLocation';
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  outlets: Array<Maybe<Outlet>>;
  updatedAt: Scalars['DateTime'];
};

export type StockLocationFilterParameter = {
  _and?: InputMaybe<Array<StockLocationFilterParameter>>;
  _or?: InputMaybe<Array<StockLocationFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  description?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  name?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type StockLocationList = PaginatedList & {
  __typename?: 'StockLocationList';
  items: Array<StockLocation>;
  totalItems: Scalars['Int'];
};

export type StockLocationListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<StockLocationFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<StockLocationSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type StockLocationSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type StockMovement = {
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  productVariant: ProductVariant;
  quantity: Scalars['Int'];
  type: StockMovementType;
  updatedAt: Scalars['DateTime'];
};

export type StockMovementInputPushRow = {
  assumedMasterState?: InputMaybe<StockMovementSyncInput>;
  newDocumentState: StockMovementSyncInput;
};

export type StockMovementItem = Allocation | Cancellation | Release | Return | Sale | StockAdjustment;

export type StockMovementList = {
  __typename?: 'StockMovementList';
  items: Array<StockMovementItem>;
  totalItems: Scalars['Int'];
};

export type StockMovementListOptions = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<StockMovementType>;
};

export type StockMovementSyncInput = {
  createdAt: Scalars['Float'];
  deletedAt?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  orderLineId?: InputMaybe<Scalars['ID']>;
  productVariantId: Scalars['String'];
  quantity: Scalars['Int'];
  stockLocationId?: InputMaybe<Scalars['ID']>;
  type: StockMovementType;
  updatedAt: Scalars['Float'];
};

export type StockMovementSynchronizerResponse = {
  __typename?: 'StockMovementSynchronizerResponse';
  data?: Maybe<StockMovement>;
  error?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export enum StockMovementType {
  Adjustment = 'ADJUSTMENT',
  Allocation = 'ALLOCATION',
  Cancellation = 'CANCELLATION',
  Release = 'RELEASE',
  Return = 'RETURN',
  Sale = 'SALE'
}

export type StockTransfer = Node & {
  __typename?: 'StockTransfer';
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<StockTransferCustomFields>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  fromLocation: StockLocation;
  histories?: Maybe<Array<StockTransferHistoryEntry>>;
  id: Scalars['ID'];
  lines: Array<StockTransferLine>;
  posId?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  status: StockTransferStatus;
  toLocation: StockLocation;
  updatedAt: Scalars['DateTime'];
};

export type StockTransferCustomFields = {
  __typename?: 'StockTransferCustomFields';
  acceptedBy?: Maybe<Administrator>;
  metadata?: Maybe<Scalars['String']>;
  requestedBy?: Maybe<Administrator>;
};

export type StockTransferCustomFieldsInput = {
  acceptedBy?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['String']>;
  requestedBy?: InputMaybe<Scalars['String']>;
};

export type StockTransferFilterParameter = {
  _and?: InputMaybe<Array<StockTransferFilterParameter>>;
  _or?: InputMaybe<Array<StockTransferFilterParameter>>;
  code?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  posId?: InputMaybe<StringOperators>;
  remark?: InputMaybe<StringOperators>;
  status?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type StockTransferHistoryEntry = Node & {
  __typename?: 'StockTransferHistoryEntry';
  createdAt: Scalars['DateTime'];
  data?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  type: StockTransferHistoryEntryType;
  updatedAt: Scalars['DateTime'];
};

export enum StockTransferHistoryEntryType {
  StockTransferStateTransition = 'STOCK_TRANSFER_STATE_TRANSITION'
}

export type StockTransferInputPushRow = {
  assumedMasterState?: InputMaybe<StockTransferSyncInput>;
  newDocumentState: StockTransferSyncInput;
};

export type StockTransferLine = Node & {
  __typename?: 'StockTransferLine';
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  posId?: Maybe<Scalars['String']>;
  productVariant: ProductVariant;
  productVariantId: Scalars['ID'];
  quantity: Scalars['Int'];
  remark?: Maybe<Scalars['String']>;
  stockTransfer: StockTransfer;
  updatedAt: Scalars['DateTime'];
};

export type StockTransferLineSyncInput = {
  createdAt: Scalars['Float'];
  deletedAt?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  productVariantId: Scalars['ID'];
  quantity: Scalars['Int'];
  remark?: InputMaybe<Scalars['String']>;
  updatedAt: Scalars['Float'];
};

export type StockTransferList = PaginatedList & {
  __typename?: 'StockTransferList';
  items: Array<StockTransfer>;
  totalItems: Scalars['Int'];
};

export type StockTransferListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<StockTransferFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<StockTransferSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type StockTransferLocationSyncInput = {
  createdAt: Scalars['Float'];
  deletedAt?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  updatedAt: Scalars['Float'];
};

export type StockTransferSortParameter = {
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  posId?: InputMaybe<SortOrder>;
  remark?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum StockTransferStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Draft = 'DRAFT',
  InTransit = 'IN_TRANSIT',
  ToReceive = 'TO_RECEIVE'
}

export type StockTransferSyncHistoryInput = {
  createdAt: Scalars['Float'];
  data: Scalars['JSON'];
  deletedAt?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  type: StockTransferHistoryEntryType;
  updatedAt: Scalars['Float'];
};

export type StockTransferSyncInput = {
  code: Scalars['String'];
  createdAt: Scalars['Float'];
  customFields?: InputMaybe<StockTransferCustomFieldsInput>;
  deletedAt?: InputMaybe<Scalars['Float']>;
  fromLocation: StockTransferLocationSyncInput;
  histories?: InputMaybe<Array<InputMaybe<StockTransferSyncHistoryInput>>>;
  id: Scalars['ID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  lines?: InputMaybe<Array<StockTransferLineSyncInput>>;
  remark?: InputMaybe<Scalars['String']>;
  status: Scalars['String'];
  toLocation: StockTransferLocationSyncInput;
  updatedAt: Scalars['Float'];
};

export type StockTransferSynchronizerResponse = {
  __typename?: 'StockTransferSynchronizerResponse';
  data?: Maybe<StockTransfer>;
  error?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type StringCustomFieldConfig = CustomField & {
  __typename?: 'StringCustomFieldConfig';
  description?: Maybe<Array<LocalizedString>>;
  internal?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Array<LocalizedString>>;
  length?: Maybe<Scalars['Int']>;
  list: Scalars['Boolean'];
  name: Scalars['String'];
  nullable?: Maybe<Scalars['Boolean']>;
  options?: Maybe<Array<StringFieldOption>>;
  pattern?: Maybe<Scalars['String']>;
  readonly?: Maybe<Scalars['Boolean']>;
  requiresPermission?: Maybe<Array<Permission>>;
  type: Scalars['String'];
  ui?: Maybe<Scalars['JSON']>;
};

export type StringFieldOption = {
  __typename?: 'StringFieldOption';
  label?: Maybe<Array<LocalizedString>>;
  value: Scalars['String'];
};

/** Operators for filtering on a list of String fields */
export type StringListOperators = {
  inList: Scalars['String'];
};

/** Operators for filtering on a String field */
export type StringOperators = {
  contains?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  notContains?: InputMaybe<Scalars['String']>;
  notEq?: InputMaybe<Scalars['String']>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  regex?: InputMaybe<Scalars['String']>;
};

/** Indicates that an operation succeeded, where we do not want to return any more specific information. */
export type Success = {
  __typename?: 'Success';
  success: Scalars['Boolean'];
};

export type Surcharge = Node & {
  __typename?: 'Surcharge';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  price: Scalars['Money'];
  priceWithTax: Scalars['Money'];
  sku?: Maybe<Scalars['String']>;
  taxLines: Array<TaxLine>;
  taxRate: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type SurchargeInput = {
  description: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  price: Scalars['Money'];
  priceIncludesTax: Scalars['Boolean'];
  priceWithTax?: InputMaybe<Scalars['Float']>;
  sku?: InputMaybe<Scalars['String']>;
  taxDescription?: InputMaybe<Scalars['String']>;
  taxLines?: InputMaybe<Array<InputMaybe<SurchargeTaxLineInput>>>;
  taxRate?: InputMaybe<Scalars['Float']>;
};

export type SurchargeTaxLineInput = {
  description: Scalars['String'];
  taxRate: Scalars['Float'];
};

export enum SurchargeType {
  PackagingCharge = 'PACKAGING_CHARGE',
  Rounding = 'ROUNDING',
  ServiceCharge = 'SERVICE_CHARGE'
}

export type Tag = Node & {
  __typename?: 'Tag';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
};

export type TagFilterParameter = {
  _and?: InputMaybe<Array<TagFilterParameter>>;
  _or?: InputMaybe<Array<TagFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  updatedAt?: InputMaybe<DateOperators>;
  value?: InputMaybe<StringOperators>;
};

export type TagList = PaginatedList & {
  __typename?: 'TagList';
  items: Array<Tag>;
  totalItems: Scalars['Int'];
};

export type TagListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<TagFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<TagSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type TagSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type TaxCategory = Node & {
  __typename?: 'TaxCategory';
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type TaxCategoryFilterParameter = {
  _and?: InputMaybe<Array<TaxCategoryFilterParameter>>;
  _or?: InputMaybe<Array<TaxCategoryFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  isDefault?: InputMaybe<BooleanOperators>;
  name?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type TaxCategoryList = PaginatedList & {
  __typename?: 'TaxCategoryList';
  items: Array<TaxCategory>;
  totalItems: Scalars['Int'];
};

export type TaxCategoryListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<TaxCategoryFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<TaxCategorySortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type TaxCategorySortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type TaxLine = {
  __typename?: 'TaxLine';
  description: Scalars['String'];
  taxRate: Scalars['Float'];
};

export type TaxRate = Node & {
  __typename?: 'TaxRate';
  category: TaxCategory;
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<Scalars['JSON']>;
  customerGroup?: Maybe<CustomerGroup>;
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  registrationNumber?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  value: Scalars['Float'];
  zone: Zone;
};

export type TaxRateFilterParameter = {
  _and?: InputMaybe<Array<TaxRateFilterParameter>>;
  _or?: InputMaybe<Array<TaxRateFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  enabled?: InputMaybe<BooleanOperators>;
  id?: InputMaybe<IdOperators>;
  name?: InputMaybe<StringOperators>;
  registrationNumber?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
  value?: InputMaybe<NumberOperators>;
};

export type TaxRateList = PaginatedList & {
  __typename?: 'TaxRateList';
  items: Array<TaxRate>;
  totalItems: Scalars['Int'];
};

export type TaxRateListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<TaxRateFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<TaxRateSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type TaxRateSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  registrationNumber?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type TaxSummaryInput = {
  description?: InputMaybe<Scalars['String']>;
  taxBase?: InputMaybe<Scalars['Float']>;
  taxRate?: InputMaybe<Scalars['Float']>;
  taxTotal?: InputMaybe<Scalars['Float']>;
};

export type Template = {
  __typename?: 'Template';
  _id: Scalars['String'];
  critical: Scalars['Boolean'];
  name: Scalars['String'];
};

export type TemplateIdentifierInput = {
  NovuTemplateInput?: InputMaybe<NovuTemplateInput>;
};

export type TemplateUpdateInput = {
  NovuTemplateUpdateInput?: InputMaybe<NovuTemplateUpdateInput>;
};

export type TenantSetting = {
  __typename?: 'TenantSetting';
  id: Scalars['ID'];
  key: Scalars['String'];
  value: Scalars['String'];
  valueType: Scalars['String'];
};

export type TestEligibleShippingMethodsInput = {
  lines: Array<TestShippingMethodOrderLineInput>;
  shippingAddress: CreateAddressInput;
};

export type TestShippingMethodInput = {
  calculator: ConfigurableOperationInput;
  checker: ConfigurableOperationInput;
  lines: Array<TestShippingMethodOrderLineInput>;
  shippingAddress: CreateAddressInput;
};

export type TestShippingMethodOrderLineInput = {
  productVariantId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type TestShippingMethodQuote = {
  __typename?: 'TestShippingMethodQuote';
  metadata?: Maybe<Scalars['JSON']>;
  price: Scalars['Money'];
  priceWithTax: Scalars['Money'];
};

export type TestShippingMethodResult = {
  __typename?: 'TestShippingMethodResult';
  eligible: Scalars['Boolean'];
  quote?: Maybe<TestShippingMethodQuote>;
};

export type TextCustomFieldConfig = CustomField & {
  __typename?: 'TextCustomFieldConfig';
  description?: Maybe<Array<LocalizedString>>;
  internal?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Array<LocalizedString>>;
  list: Scalars['Boolean'];
  name: Scalars['String'];
  nullable?: Maybe<Scalars['Boolean']>;
  readonly?: Maybe<Scalars['Boolean']>;
  requiresPermission?: Maybe<Array<Permission>>;
  type: Scalars['String'];
  ui?: Maybe<Scalars['JSON']>;
};

export type Tier = {
  __typename?: 'Tier';
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  minimumPoints: Scalars['Int'];
  name: Scalars['String'];
};

export type TierBenefit = Node & {
  __typename?: 'TierBenefit';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type TierBenefitFilterParameter = {
  _and?: InputMaybe<Array<TierBenefitFilterParameter>>;
  _or?: InputMaybe<Array<TierBenefitFilterParameter>>;
  description?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  imageUrl?: InputMaybe<StringOperators>;
  title?: InputMaybe<StringOperators>;
};

export type TierBenefitList = PaginatedList & {
  __typename?: 'TierBenefitList';
  items: Array<TierBenefit>;
  totalItems: Scalars['Int'];
};

export type TierBenefitListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<TierBenefitFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<TierBenefitSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type TierBenefitSortParameter = {
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageUrl?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type TierProgress = {
  __typename?: 'TierProgress';
  currentTier: Tier;
  description: Scalars['String'];
  isHighestTier: Scalars['Boolean'];
  nextTier?: Maybe<Tier>;
  pointToNextTier: Scalars['Int'];
};

export type TimeSlot = {
  __typename?: 'TimeSlot';
  date: Scalars['String'];
  slots: Array<OutletFulfillmentSlot>;
};

export type ToggleShouldResetInput = {
  isShouldReset: Scalars['Boolean'];
};

export type TransitionFulfillmentToStateResult = Fulfillment | FulfillmentStateTransitionError;

export type TransitionOrderToStateResult = Order | OrderStateTransitionError;

export type TransitionPaymentToStateResult = Payment | PaymentStateTransitionError;

export type UpdateActiveAdministratorInput = {
  customFields?: InputMaybe<UpdateAdministratorCustomFieldsInput>;
  emailAddress?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type UpdateAddressCustomFieldsInput = {
  customerAddressId?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  posId?: InputMaybe<Scalars['String']>;
};

/**
 * Input used to update an Address.
 *
 * The countryCode must correspond to a `code` property of a Country that has been defined in the
 * Vendure server. The `code` property is typically a 2-character ISO code such as "GB", "US", "DE" etc.
 * If an invalid code is passed, the mutation will fail.
 */
export type UpdateAddressInput = {
  city?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  customFields?: InputMaybe<UpdateAddressCustomFieldsInput>;
  defaultBillingAddress?: InputMaybe<Scalars['Boolean']>;
  defaultShippingAddress?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  province?: InputMaybe<Scalars['String']>;
  streetLine1?: InputMaybe<Scalars['String']>;
  streetLine2?: InputMaybe<Scalars['String']>;
};

export type UpdateAdministratorAccountStatusInput = {
  id: Scalars['ID'];
  verified: Scalars['Boolean'];
};

export type UpdateAdministratorCustomFieldsInput = {
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  sellerId?: InputMaybe<Scalars['ID']>;
  verificationNumber?: InputMaybe<Scalars['String']>;
};

export type UpdateAdministratorInput = {
  customFields?: InputMaybe<UpdateAdministratorCustomFieldsInput>;
  emailAddress?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  roleIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateAppVersionInput = {
  id: Scalars['ID'];
  minimumBuildNumber?: InputMaybe<Scalars['Int']>;
  minimumVersionName?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  platform: Platform;
  url?: InputMaybe<Scalars['String']>;
  versionName: Scalars['String'];
  versionNumber: Scalars['Int'];
};

export type UpdateAssetInput = {
  customFields?: InputMaybe<Scalars['JSON']>;
  focalPoint?: InputMaybe<CoordinateInput>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateBannerInput = {
  assetId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  displayType?: InputMaybe<BannerDisplayType>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  productIds?: InputMaybe<Array<Scalars['ID']>>;
  rewardId?: InputMaybe<Scalars['ID']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<BannerType>;
  url?: InputMaybe<Scalars['String']>;
};

export type UpdateBlastQueue = {
  notificationBlastId?: InputMaybe<Scalars['ID']>;
  trigger?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateBlastQueueInput = {
  id: Scalars['ID'];
  update: UpdateBlastQueue;
};

export type UpdateBlastableUserInput = {
  id: Scalars['ID'];
  notificationBlastId?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
  userType?: InputMaybe<BlastableRole>;
};

export type UpdateChannelCustomFieldsInput = {
  applyTaxOnServiceCharge?: InputMaybe<Scalars['Boolean']>;
  availableFulfillmentMethods?: InputMaybe<Array<Scalars['String']>>;
  companyDetail?: InputMaybe<Scalars['String']>;
  configuration?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['ID']>;
  days?: InputMaybe<Scalars['Int']>;
  eghlConfig?: InputMaybe<Scalars['String']>;
  enableMobileVerificationBypass?: InputMaybe<Scalars['Boolean']>;
  enableTableOccupancy?: InputMaybe<Scalars['Boolean']>;
  hours?: InputMaybe<Scalars['Int']>;
  info?: InputMaybe<Scalars['String']>;
  invoicePrefixDateFormat?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  loyaltyEnabled?: InputMaybe<Scalars['Boolean']>;
  maintenanceContent?: InputMaybe<Scalars['String']>;
  mergeDining?: InputMaybe<Scalars['Boolean']>;
  miniApps?: InputMaybe<Scalars['String']>;
  minutes?: InputMaybe<Scalars['Int']>;
  mobileVerificationBypassOTPCode?: InputMaybe<Scalars['String']>;
  months?: InputMaybe<Scalars['Int']>;
  orderFlagConfig?: InputMaybe<Scalars['String']>;
  posMustCreatePaidPayment?: InputMaybe<Scalars['Boolean']>;
  posMustFulfillAllItemAtOnce?: InputMaybe<Scalars['Boolean']>;
  posOrderListingDateType?: InputMaybe<Scalars['String']>;
  posSplitPaymentEnabled?: InputMaybe<Scalars['Boolean']>;
  posTableOrderingEnabled?: InputMaybe<Scalars['Boolean']>;
  referralContent?: InputMaybe<Scalars['String']>;
  referralContentImage?: InputMaybe<Scalars['String']>;
  referralContentTitle?: InputMaybe<Scalars['String']>;
  referralShareContent?: InputMaybe<Scalars['String']>;
  referralShareUrl?: InputMaybe<Scalars['String']>;
  salesContributionConfig?: InputMaybe<Scalars['String']>;
  serviceCharge?: InputMaybe<Scalars['Float']>;
  serviceChargeEnabled?: InputMaybe<Scalars['Boolean']>;
  setOrderAsInactiveAfterCheckout?: InputMaybe<Scalars['Boolean']>;
  supportedPhoneCodes?: InputMaybe<Array<Scalars['String']>>;
  taxRateRegistrationNumber?: InputMaybe<Scalars['String']>;
  tenantApiKey?: InputMaybe<Scalars['String']>;
  tenantCode?: InputMaybe<Scalars['String']>;
  tenantEndpoint?: InputMaybe<Scalars['String']>;
  underMaintenance?: InputMaybe<Scalars['Boolean']>;
  userGroupWithMobileVerificationBypassIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateChannelInput = {
  availableCurrencyCodes?: InputMaybe<Array<CurrencyCode>>;
  availableLanguageCodes?: InputMaybe<Array<LanguageCode>>;
  code?: InputMaybe<Scalars['String']>;
  customFields?: InputMaybe<UpdateChannelCustomFieldsInput>;
  defaultCurrencyCode?: InputMaybe<CurrencyCode>;
  defaultLanguageCode?: InputMaybe<LanguageCode>;
  defaultShippingZoneId?: InputMaybe<Scalars['ID']>;
  defaultTaxZoneId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  outOfStockThreshold?: InputMaybe<Scalars['Int']>;
  pricesIncludeTax?: InputMaybe<Scalars['Boolean']>;
  sellerId?: InputMaybe<Scalars['ID']>;
  token?: InputMaybe<Scalars['String']>;
  trackInventory?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateChannelResult = Channel | LanguageNotAvailableError;

export type UpdateCollectionCustomFieldsInput = {
  gradient?: InputMaybe<Scalars['String']>;
  renderType?: InputMaybe<Scalars['String']>;
};

export type UpdateCollectionInput = {
  assetIds?: InputMaybe<Array<Scalars['ID']>>;
  customFields?: InputMaybe<UpdateCollectionCustomFieldsInput>;
  featuredAssetId?: InputMaybe<Scalars['ID']>;
  filters?: InputMaybe<Array<ConfigurableOperationInput>>;
  id: Scalars['ID'];
  inheritFilters?: InputMaybe<Scalars['Boolean']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  parentId?: InputMaybe<Scalars['ID']>;
  translations?: InputMaybe<Array<UpdateCollectionTranslationInput>>;
};

export type UpdateCollectionProductInput = {
  assetIds?: InputMaybe<Array<Scalars['ID']>>;
  customFields?: InputMaybe<Scalars['JSON']>;
  featuredAssetId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  inheritFilters?: InputMaybe<Scalars['Boolean']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  parentId?: InputMaybe<Scalars['ID']>;
  productIds?: InputMaybe<Array<Scalars['ID']>>;
  translations?: InputMaybe<Array<CreateCollectionTranslationInput>>;
};

export type UpdateCollectionTranslationInput = {
  customFields?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  languageCode: LanguageCode;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type UpdateCountryInput = {
  code?: InputMaybe<Scalars['String']>;
  customFields?: InputMaybe<Scalars['JSON']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  translations?: InputMaybe<Array<CountryTranslationInput>>;
};

export type UpdateCustomerCustomFieldsInput = {
  avatar?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  gender?: InputMaybe<Scalars['String']>;
  isDefaultGuest?: InputMaybe<Scalars['Boolean']>;
  medium?: InputMaybe<Scalars['String']>;
  posId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referralCodeApplied?: InputMaybe<Scalars['String']>;
  signupThrough?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type UpdateCustomerGroupInput = {
  customFields?: InputMaybe<Scalars['JSON']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateCustomerInput = {
  customFields?: InputMaybe<UpdateCustomerCustomFieldsInput>;
  emailAddress?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateCustomerNoteInput = {
  note: Scalars['String'];
  noteId: Scalars['ID'];
};

export type UpdateCustomerResult = Customer | EmailAddressConflictError;

export type UpdateDineInOrderStatusInput = {
  orderId: Scalars['ID'];
  status: DineInOrderStatus;
};

export type UpdateDiscountPromotionInput = {
  amount?: InputMaybe<Scalars['Int']>;
  assetIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  featuredAssetId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  productVariantIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<DiscountType>;
};

export type UpdateFacetInput = {
  code?: InputMaybe<Scalars['String']>;
  customFields?: InputMaybe<Scalars['JSON']>;
  id: Scalars['ID'];
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  translations?: InputMaybe<Array<FacetTranslationInput>>;
};

export type UpdateFacetValueCustomFieldsInput = {
  maxModifier?: InputMaybe<Scalars['Int']>;
  minModifier?: InputMaybe<Scalars['Int']>;
  modifierSorting?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateFacetValueInput = {
  code?: InputMaybe<Scalars['String']>;
  customFields?: InputMaybe<UpdateFacetValueCustomFieldsInput>;
  id: Scalars['ID'];
  translations?: InputMaybe<Array<FacetValueTranslationInput>>;
};

export type UpdateFulfillmentChannelInput = {
  code?: InputMaybe<FulfillmentMethodCode>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateFulfillmentCustomFieldsInput = {
  additionalProperties?: InputMaybe<Scalars['String']>;
  orderInvoicesIds?: InputMaybe<Array<Scalars['ID']>>;
  posId?: InputMaybe<Scalars['String']>;
};

export type UpdateFulfillmentInput = {
  customFields?: InputMaybe<UpdateFulfillmentCustomFieldsInput>;
  fulfillmentMethod?: InputMaybe<FulfillmentMethodInput>;
  id: Scalars['ID'];
  state: FulfillmentState;
};

export type UpdateFulfillmentProcessOrderInput = {
  fulfillmentId: Scalars['ID'];
  state: FulfillmentState;
  trackingNumber?: InputMaybe<Scalars['String']>;
};

export type UpdateGiftOptionInput = {
  description?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  label?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
};

export type UpdateGlobalSettingsInput = {
  availableLanguages?: InputMaybe<Array<LanguageCode>>;
  customFields?: InputMaybe<Scalars['JSON']>;
  outOfStockThreshold?: InputMaybe<Scalars['Int']>;
  trackInventory?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateGlobalSettingsResult = ChannelDefaultLanguageError | GlobalSettings;

export type UpdateManyOutletSettingsInput = {
  lastCallTime?: InputMaybe<Scalars['Int']>;
  outletId?: InputMaybe<Scalars['ID']>;
  preorderBuffer?: InputMaybe<Scalars['Int']>;
  preparationTime?: InputMaybe<Scalars['Int']>;
  timeSlotInterval?: InputMaybe<Scalars['Int']>;
};

export type UpdateMembershipInput = {
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  pointValidUntil?: InputMaybe<Scalars['String']>;
  tierId?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type UpdateMembershipTierBenefitInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateMembershipTierInput = {
  id: Scalars['String'];
  imageUrl?: InputMaybe<Scalars['String']>;
  level?: InputMaybe<Scalars['Int']>;
  minimumPoints?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  pointMultiplier?: InputMaybe<Scalars['String']>;
};

export type UpdateNotificationBlastInput = {
  blastableChannels?: InputMaybe<Array<BlastableChannel>>;
  id: Scalars['ID'];
  link?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  receiverType?: InputMaybe<BlastableReceiverType>;
  subject?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<BlastContentType>;
};

export type UpdateOrderAddressInput = {
  city?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  customFields?: InputMaybe<UpdateAddressCustomFieldsInput>;
  fullName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  province?: InputMaybe<Scalars['String']>;
  streetLine1?: InputMaybe<Scalars['String']>;
  streetLine2?: InputMaybe<Scalars['String']>;
};

export type UpdateOrderCustomFieldsInput = {
  administratorId?: InputMaybe<Scalars['ID']>;
  cancellationRemark?: InputMaybe<Scalars['String']>;
  checkoutThrough?: InputMaybe<Scalars['String']>;
  customShippingPrice?: InputMaybe<Scalars['Int']>;
  customerSnapshot?: InputMaybe<Scalars['String']>;
  dineInMetadata?: InputMaybe<Scalars['String']>;
  documentsIds?: InputMaybe<Array<Scalars['ID']>>;
  giftFee?: InputMaybe<Scalars['Float']>;
  giftOptionId?: InputMaybe<Scalars['ID']>;
  orderPlacementMetadata?: InputMaybe<Scalars['String']>;
  pointAwarded?: InputMaybe<Scalars['Int']>;
  posAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  posAcceptedDeviceId?: InputMaybe<Scalars['ID']>;
  posCustomAmountDiscount?: InputMaybe<Scalars['Int']>;
  posCustomRateDiscount?: InputMaybe<Scalars['Int']>;
  posId?: InputMaybe<Scalars['String']>;
  posOrderCode?: InputMaybe<Scalars['String']>;
  posShippingMethods?: InputMaybe<Scalars['String']>;
  promoCodeId?: InputMaybe<Scalars['String']>;
  promoCodeName?: InputMaybe<Scalars['String']>;
  purchaseOutletId?: InputMaybe<Scalars['ID']>;
  purchaseStockLocationId?: InputMaybe<Scalars['ID']>;
  redemptionHistoryIds?: InputMaybe<Array<Scalars['String']>>;
  remark?: InputMaybe<Scalars['String']>;
  rewardId?: InputMaybe<Scalars['String']>;
  shippingMethodCode?: InputMaybe<Scalars['String']>;
  snapshot?: InputMaybe<Scalars['String']>;
  tableId?: InputMaybe<Scalars['ID']>;
};

export type UpdateOrderInfoInput = {
  adultPax?: InputMaybe<Scalars['Int']>;
  kidPax?: InputMaybe<Scalars['Int']>;
  orderId: Scalars['ID'];
  table?: InputMaybe<Scalars['String']>;
};

export type UpdateOrderInput = {
  customFields?: InputMaybe<UpdateOrderCustomFieldsInput>;
  id: Scalars['ID'];
};

export type UpdateOrderItemsResult = InsufficientStockError | NegativeQuantityError | Order | OrderLimitError | OrderModificationError;

export type UpdateOrderNoteInput = {
  isPublic?: InputMaybe<Scalars['Boolean']>;
  note?: InputMaybe<Scalars['String']>;
  noteId: Scalars['ID'];
};

export type UpdateOrderStatusInput = {
  cancellationRemark?: InputMaybe<Scalars['String']>;
  fulfillmentOptions?: InputMaybe<OrderFulfillmentInput>;
  orderId: Scalars['ID'];
  paymentMetadata?: InputMaybe<PaymentMetadataRemarkInput>;
  status: OrderStatus;
};

export type UpdateOutletDeviceInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateOutletInput = {
  address?: InputMaybe<CreateOutletAddressInput>;
  contactNumber?: InputMaybe<Scalars['String']>;
  customFields?: InputMaybe<CustomOutletFieldsInput>;
  id: Scalars['String'];
  managerId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  onlineOrdering?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<OutletStatus>;
};

export type UpdateOutletSettingInput = {
  lastCallTime?: InputMaybe<Scalars['Int']>;
  outletId?: InputMaybe<Scalars['ID']>;
  preorderBuffer?: InputMaybe<Scalars['Int']>;
  preparationTime?: InputMaybe<Scalars['Int']>;
  timeSlotInterval?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<OutletFulfillmentType>;
};

export type UpdateOutletTableAreaInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  outletId?: InputMaybe<Scalars['ID']>;
  position?: InputMaybe<Scalars['Int']>;
  prefix?: InputMaybe<Scalars['String']>;
};

export type UpdateOutletTableInput = {
  code?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isAvailable?: InputMaybe<Scalars['Boolean']>;
  outletTableAreaId?: InputMaybe<Scalars['ID']>;
  remarks?: InputMaybe<Scalars['String']>;
  seatCapacity?: InputMaybe<Scalars['Int']>;
};

export type UpdatePaymentMethodCustomFieldsInput = {
  exactAmountOnly?: InputMaybe<Scalars['Boolean']>;
  paymentChannel?: InputMaybe<Scalars['String']>;
  trackReference?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
};

export type UpdatePaymentMethodInput = {
  checker?: InputMaybe<ConfigurableOperationInput>;
  code?: InputMaybe<Scalars['String']>;
  customFields?: InputMaybe<UpdatePaymentMethodCustomFieldsInput>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  handler?: InputMaybe<ConfigurableOperationInput>;
  id: Scalars['ID'];
  translations?: InputMaybe<Array<PaymentMethodTranslationInput>>;
};

export type UpdatePaymentSettingInput = {
  registrationNumber?: InputMaybe<Scalars['String']>;
  serviceCharge?: InputMaybe<Scalars['Float']>;
  serviceChargeEnabled?: InputMaybe<Scalars['Boolean']>;
  taxName?: InputMaybe<Scalars['String']>;
  taxRate?: InputMaybe<Scalars['Float']>;
};

export type UpdatePointSettingInput = {
  awardPointPerUnit?: InputMaybe<Scalars['Int']>;
  id: Scalars['String'];
  unitValue?: InputMaybe<Scalars['String']>;
};

export type UpdatePrinterInput = {
  cutAfterPrint?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  ip?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  openCashboxAfterPrint?: InputMaybe<Scalars['Boolean']>;
  paperWidth?: InputMaybe<Scalars['Int']>;
  port?: InputMaybe<Scalars['Int']>;
  timeout?: InputMaybe<Scalars['Int']>;
};

export type UpdatePrinterTagInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdatePrinterTemplateInput = {
  handler?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  template?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type UpdateProductCustomFieldsInput = {
  fulfillChannelsIds?: InputMaybe<Array<Scalars['ID']>>;
  modifierGroupSorting?: InputMaybe<Array<Scalars['String']>>;
  posId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  warrantyConfig?: InputMaybe<Scalars['String']>;
};

export type UpdateProductInput = {
  assetIds?: InputMaybe<Array<Scalars['ID']>>;
  customFields?: InputMaybe<UpdateProductCustomFieldsInput>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  facetValueIds?: InputMaybe<Array<Scalars['ID']>>;
  featuredAssetId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  translations?: InputMaybe<Array<ProductTranslationInput>>;
};

export type UpdateProductOptionGroupInput = {
  code?: InputMaybe<Scalars['String']>;
  customFields?: InputMaybe<Scalars['JSON']>;
  id: Scalars['ID'];
  translations?: InputMaybe<Array<ProductOptionGroupTranslationInput>>;
};

export type UpdateProductOptionInput = {
  code?: InputMaybe<Scalars['String']>;
  customFields?: InputMaybe<Scalars['JSON']>;
  id: Scalars['ID'];
  translations?: InputMaybe<Array<ProductOptionGroupTranslationInput>>;
};

export type UpdateProductVariantCustomFieldsInput = {
  omitFromOutletId?: InputMaybe<Array<Scalars['String']>>;
  outletPricing?: InputMaybe<Array<Scalars['String']>>;
  posId?: InputMaybe<Scalars['String']>;
};

export type UpdateProductVariantInput = {
  assetIds?: InputMaybe<Array<Scalars['ID']>>;
  customFields?: InputMaybe<UpdateProductVariantCustomFieldsInput>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  facetValueIds?: InputMaybe<Array<Scalars['ID']>>;
  featuredAssetId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  optionIds?: InputMaybe<Array<Scalars['ID']>>;
  outOfStockThreshold?: InputMaybe<Scalars['Int']>;
  /** Sets the price for the ProductVariant in the Channel's default currency */
  price?: InputMaybe<Scalars['Money']>;
  /** Allows multiple prices to be set for the ProductVariant in different currencies. */
  prices?: InputMaybe<Array<ProductVariantPriceInput>>;
  sku?: InputMaybe<Scalars['String']>;
  stockLevels?: InputMaybe<Array<StockLevelInput>>;
  stockOnHand?: InputMaybe<Scalars['Int']>;
  taxCategoryId?: InputMaybe<Scalars['ID']>;
  trackInventory?: InputMaybe<GlobalFlag>;
  translations?: InputMaybe<Array<ProductVariantTranslationInput>>;
  useGlobalOutOfStockThreshold?: InputMaybe<Scalars['Boolean']>;
};

export type UpdatePromoCodeInput = {
  cappedDiscount?: InputMaybe<Scalars['Float']>;
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endAt?: InputMaybe<Scalars['String']>;
  entitledTierIds?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  imageUrl?: InputMaybe<Scalars['String']>;
  minimumSpendAmount?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  pointCost?: InputMaybe<Scalars['Float']>;
  quantity?: InputMaybe<Scalars['Int']>;
  redeemCriteria?: InputMaybe<Scalars['String']>;
  redeemPerUserLimit?: InputMaybe<Scalars['Int']>;
  startAt?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<PromoCodeStatus>;
  termCondition?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PromoCodeType>;
  value?: InputMaybe<Scalars['Float']>;
};

export type UpdatePromotionInput = {
  actions?: InputMaybe<Array<ConfigurableOperationInput>>;
  conditions?: InputMaybe<Array<ConfigurableOperationInput>>;
  couponCode?: InputMaybe<Scalars['String']>;
  customFields?: InputMaybe<Scalars['JSON']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  endsAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  perCustomerUsageLimit?: InputMaybe<Scalars['Int']>;
  startsAt?: InputMaybe<Scalars['DateTime']>;
  translations?: InputMaybe<Array<PromotionTranslationInput>>;
  usageLimit?: InputMaybe<Scalars['Int']>;
};

export type UpdatePromotionResult = MissingConditionsError | Promotion;

export type UpdateProvinceInput = {
  code?: InputMaybe<Scalars['String']>;
  customFields?: InputMaybe<Scalars['JSON']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  translations?: InputMaybe<Array<ProvinceTranslationInput>>;
};

export type UpdateRedemptionHistoryIsUsedInput = {
  isUsed: Scalars['String'];
  promoCodeId?: InputMaybe<Scalars['String']>;
  redemptionHistoryId: Scalars['String'];
  rewardId?: InputMaybe<Scalars['String']>;
};

export type UpdateReservationInput = {
  adultPax?: InputMaybe<Scalars['Int']>;
  diningDate?: InputMaybe<Scalars['DateTime']>;
  guestName?: InputMaybe<Scalars['String']>;
  kidPax?: InputMaybe<Scalars['Int']>;
  occasion?: InputMaybe<Array<InputMaybe<OccasionType>>>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  remark?: InputMaybe<Scalars['String']>;
  reservationId: Scalars['ID'];
};

export type UpdateReservationStatusInput = {
  cancellationRemark?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  status: ReservationStatusType;
};

export type UpdateRewardInput = {
  description?: InputMaybe<Scalars['String']>;
  endAt?: InputMaybe<Scalars['String']>;
  entitledTierIds?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  imageUrl?: InputMaybe<Scalars['String']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  pointCost?: InputMaybe<Scalars['Float']>;
  productVariantId?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  redeemCriteria?: InputMaybe<Scalars['String']>;
  redeemPerUserLimit?: InputMaybe<Scalars['Int']>;
  startAt?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<RewardStatus>;
  termCondition?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<RewardType>;
};

export type UpdateRoleInput = {
  channelIds?: InputMaybe<Array<Scalars['ID']>>;
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  permissions?: InputMaybe<Array<Permission>>;
};

export type UpdateSellerCustomFieldsInput = {
  description?: InputMaybe<Scalars['String']>;
  favicon?: InputMaybe<Scalars['String']>;
  footerConfig?: InputMaybe<Scalars['String']>;
  introductionConfig?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoWidthAndHeight?: InputMaybe<Scalars['String']>;
  metabaseDisplayIndex?: InputMaybe<Scalars['String']>;
  resources?: InputMaybe<Scalars['String']>;
};

export type UpdateSellerInput = {
  customFields?: InputMaybe<UpdateSellerCustomFieldsInput>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateShippingMethodCustomFieldsInput = {
  applyRounding?: InputMaybe<Scalars['Boolean']>;
  fulfillmentChannelId?: InputMaybe<Scalars['ID']>;
  includeServiceCharge?: InputMaybe<Scalars['Boolean']>;
  posAutoFulfillAllItemsOnPaid?: InputMaybe<Scalars['Boolean']>;
  tierRangeIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateShippingMethodInput = {
  calculator?: InputMaybe<ConfigurableOperationInput>;
  checker?: InputMaybe<ConfigurableOperationInput>;
  code?: InputMaybe<Scalars['String']>;
  customFields?: InputMaybe<UpdateShippingMethodCustomFieldsInput>;
  fulfillmentHandler?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  translations: Array<ShippingMethodTranslationInput>;
};

export type UpdateShippingTierInput = {
  id: Scalars['ID'];
  maxQuantity?: InputMaybe<Scalars['Int']>;
  minQuantity?: InputMaybe<Scalars['Int']>;
  shippingMethodId?: InputMaybe<Scalars['ID']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type UpdateStockLocationInput = {
  customFields?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateTagInput = {
  id: Scalars['ID'];
  value?: InputMaybe<Scalars['String']>;
};

export type UpdateTaxCategoryInput = {
  customFields?: InputMaybe<Scalars['JSON']>;
  id: Scalars['ID'];
  isDefault?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateTaxRateInput = {
  categoryId?: InputMaybe<Scalars['ID']>;
  customFields?: InputMaybe<Scalars['JSON']>;
  customerGroupId?: InputMaybe<Scalars['ID']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Float']>;
  zoneId?: InputMaybe<Scalars['ID']>;
};

export type UpdateTemplateInput = {
  body: TemplateUpdateInput;
  handlerCode: Scalars['String'];
  identifier: TemplateIdentifierInput;
  sourceCode: Scalars['String'];
};

export type UpdateUserVerificationInput = {
  userId: Scalars['String'];
  verificationStatus: Scalars['Boolean'];
};

export type UpdateZoneInput = {
  customFields?: InputMaybe<Scalars['JSON']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type User = Node & {
  __typename?: 'User';
  authenticationMethods: Array<AuthenticationMethod>;
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  identifier: Scalars['String'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  roles: Array<Role>;
  updatedAt: Scalars['DateTime'];
  verified: Scalars['Boolean'];
};

export enum UserActionType {
  Create = 'CREATE',
  Delete = 'DELETE',
  Redeem = 'REDEEM',
  Update = 'UPDATE'
}

export type UserFilterParameter = {
  _and?: InputMaybe<Array<UserFilterParameter>>;
  _or?: InputMaybe<Array<UserFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  deletedAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  identifier?: InputMaybe<StringOperators>;
  lastLogin?: InputMaybe<DateOperators>;
  updatedAt?: InputMaybe<DateOperators>;
  verified?: InputMaybe<BooleanOperators>;
};

export type UserList = PaginatedList & {
  __typename?: 'UserList';
  items: Array<User>;
  totalItems: Scalars['Int'];
};

export type UserListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<UserFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<UserSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type UserSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  identifier?: InputMaybe<SortOrder>;
  lastLogin?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type VerifyOtpInput = {
  phoneNumber: Scalars['String'];
  type: MobileVerificationType;
};

export type VerifyOtpVerifyInput = {
  otp: Scalars['String'];
  phoneNumber: Scalars['String'];
  type: MobileVerificationType;
};

export type WarrantyConfig = {
  __typename?: 'WarrantyConfig';
  unit: Scalars['String'];
  value: Scalars['Int'];
  warrantyDetails?: Maybe<Wysiwyg>;
  warrantyId?: Maybe<Scalars['String']>;
};

export type WarrantyConfigInput = {
  unit?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Int']>;
};

export type WebFooterConfig = {
  __typename?: 'WebFooterConfig';
  address?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  copyright?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  footerLinks?: Maybe<Array<SellerLink>>;
  footerText?: Maybe<Scalars['String']>;
  links?: Maybe<Array<SellerLink>>;
  socialMedias?: Maybe<Array<SellerSocialMedia>>;
};

export type Wysiwyg = Node & {
  __typename?: 'Wysiwyg';
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type: WysiwygType;
  updatedAt: Scalars['DateTime'];
};

export enum WysiwygType {
  FaQ = 'FaQ',
  Help = 'Help',
  Privacy = 'Privacy',
  Refund = 'Refund',
  Shipping = 'Shipping',
  TermsAndConditions = 'TermsAndConditions',
  Warranty = 'Warranty'
}

export type WysiwygUpdateInput = {
  content?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<WysiwygType>;
};

export type Zone = Node & {
  __typename?: 'Zone';
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  members: Array<Region>;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ZoneFilterParameter = {
  _and?: InputMaybe<Array<ZoneFilterParameter>>;
  _or?: InputMaybe<Array<ZoneFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  name?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type ZoneList = PaginatedList & {
  __typename?: 'ZoneList';
  items: Array<Zone>;
  totalItems: Scalars['Int'];
};

export type ZoneListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<ZoneFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<ZoneSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type ZoneSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type FulfillmentInput = {
  additionalProperties?: InputMaybe<FulfillmentAdditionalPropertiesSyncInput>;
  createdAt: Scalars['Float'];
  deletedAt?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  isDeleted?: Scalars['Boolean'];
  method: Scalars['String'];
  orderInvoices?: InputMaybe<Array<OrderInvoiceInput>>;
  orderLines?: InputMaybe<Array<FullfillmentLineSyncInput>>;
  state: Scalars['String'];
  trackingCode?: InputMaybe<Scalars['String']>;
  updatedAt: Scalars['Float'];
};

export type UpdatePreferenceInput = {
  channel?: InputMaybe<ChannelsInput>;
  enable?: InputMaybe<Scalars['Boolean']>;
};

export type AdministratorInfoFragment = { __typename?: 'Administrator', id: string };

export type GetAdministratorsQueryVariables = Exact<{
  options?: InputMaybe<AdministratorListOptions>;
  search?: InputMaybe<Scalars['String']>;
}>;


export type GetAdministratorsQuery = { __typename?: 'Query', getAdministrators: { __typename?: 'AdministratorList', totalItems: number, items: Array<{ __typename?: 'Administrator', id: string }> } };

export type UpdateAdministratorAccountStatusMutationVariables = Exact<{
  input: UpdateAdministratorAccountStatusInput;
}>;


export type UpdateAdministratorAccountStatusMutation = { __typename?: 'Mutation', updateAdministratorAccountStatus: { __typename?: 'Administrator', id: string } };

export type ChannelInfoFragment = { __typename?: 'Channel', id: string, code: string, token: string, availableCurrencyCodes: Array<CurrencyCode>, defaultCurrencyCode: CurrencyCode, defaultLanguageCode: LanguageCode, customFields?: { __typename?: 'ChannelCustomFields', availableFulfillmentMethods?: Array<string> | null } | null };

export type ActiveChannelQueryVariables = Exact<{ [key: string]: never; }>;


export type ActiveChannelQuery = { __typename?: 'Query', activeChannel: { __typename?: 'Channel', id: string, code: string, token: string, availableCurrencyCodes: Array<CurrencyCode>, defaultCurrencyCode: CurrencyCode, defaultLanguageCode: LanguageCode, customFields?: { __typename?: 'ChannelCustomFields', availableFulfillmentMethods?: Array<string> | null } | null } };

export type CustomerInfoFragment = { __typename?: 'Customer', id: string, createdAt: any, updatedAt: any, title?: string | null, firstName: string, lastName: string, phoneNumber?: string | null, emailAddress: string, user?: { __typename?: 'User', id: string } | null };

export type GetCustomersQueryVariables = Exact<{
  options?: InputMaybe<CustomerListOptions>;
  search?: InputMaybe<Scalars['String']>;
}>;


export type GetCustomersQuery = { __typename?: 'Query', getCustomers: { __typename?: 'CustomerList', totalItems: number, items: Array<{ __typename?: 'Customer', id: string, createdAt: any, updatedAt: any, title?: string | null, firstName: string, lastName: string, phoneNumber?: string | null, emailAddress: string, user?: { __typename?: 'User', id: string } | null }> } };

export type CreateCustomerMutationVariables = Exact<{
  input: CreateCustomerInput;
  password?: InputMaybe<Scalars['String']>;
}>;


export type CreateCustomerMutation = { __typename?: 'Mutation', createCustomer: { __typename?: 'Customer', id: string, createdAt: any, updatedAt: any, title?: string | null, firstName: string, lastName: string, phoneNumber?: string | null, emailAddress: string, user?: { __typename?: 'User', id: string } | null } | { __typename?: 'EmailAddressConflictError', message: string } };

export type UpdateCustomerMutationVariables = Exact<{
  input: UpdateCustomerInput;
}>;


export type UpdateCustomerMutation = { __typename?: 'Mutation', updateCustomer: { __typename?: 'Customer', id: string, createdAt: any, updatedAt: any, title?: string | null, firstName: string, lastName: string, phoneNumber?: string | null, emailAddress: string, user?: { __typename?: 'User', id: string } | null } | { __typename?: 'EmailAddressConflictError', message: string } };

export type UpdateGiftOptionMutationVariables = Exact<{
  input: UpdateGiftOptionInput;
}>;


export type UpdateGiftOptionMutation = { __typename?: 'Mutation', updateGiftOption: { __typename?: 'GiftOption', id: string } };

export type GetGiftOptionsQueryVariables = Exact<{
  option?: InputMaybe<GiftOptionListOptions>;
}>;


export type GetGiftOptionsQuery = { __typename?: 'Query', getGiftOptions: { __typename?: 'GiftOptionList', totalItems: number, items: Array<{ __typename?: 'GiftOption', id: string, createdAt: any, updatedAt: any, name: string, label?: any | null, enabled: boolean, description?: string | null, price: number }> } };

export type DeleteGiftOptionMutationVariables = Exact<{
  deleteGiftOptionId: Scalars['ID'];
}>;


export type DeleteGiftOptionMutation = { __typename?: 'Mutation', deleteGiftOption: { __typename?: 'DeletionResponse', result: DeletionResult, message?: string | null } };

export type CreateGiftOptionMutationVariables = Exact<{
  input: CreateGiftOptionInput;
}>;


export type CreateGiftOptionMutation = { __typename?: 'Mutation', createGiftOption: { __typename?: 'GiftOption', id: string } };

export type CreateAssetsMutationVariables = Exact<{
  input: Array<CreateAssetInput> | CreateAssetInput;
}>;


export type CreateAssetsMutation = { __typename?: 'Mutation', createAssets: Array<{ __typename?: 'Asset', id: string, source: string } | { __typename?: 'MimeTypeError', errorCode: ErrorCode, message: string, fileName: string, mimeType: string }> };

export type PublicSellerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PublicSellerQuery = { __typename?: 'Query', publicSeller: { __typename?: 'PublicSeller', id: string, name: string, logo: string, resourceConfig?: any | null, channels?: Array<{ __typename?: 'PublicChannel', id: string, code: string, name?: string | null, token: string, icon?: string | null, description: string }> | null } };

export type LoginMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
  rememberMe?: InputMaybe<Scalars['Boolean']>;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'CurrentUser', id: string } | { __typename?: 'InvalidCredentialsError' } | { __typename?: 'NativeAuthStrategyError' } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'CurrentUser', id: string, identifier: string, channels: Array<{ __typename?: 'CurrentUserChannel', id: string, token: string, code: string, permissions: Array<Permission> }> } | null };

export type ActiveAdministratorQueryVariables = Exact<{ [key: string]: never; }>;


export type ActiveAdministratorQuery = { __typename?: 'Query', activeAdministrator?: { __typename?: 'Administrator', id: string, createdAt: any, updatedAt: any, firstName: string, lastName: string, emailAddress: string, customFields?: { __typename?: 'AdministratorCustomFields', phoneNumber?: string | null, seller?: { __typename?: 'Seller', id: string, name: string } | null } | null } | null };

export type GetPointTransactionHistoriesQueryVariables = Exact<{
  input?: InputMaybe<QueryPointTransactionHistorysInput>;
  options?: InputMaybe<PointTransactionHistoryListOptions>;
}>;


export type GetPointTransactionHistoriesQuery = { __typename?: 'Query', getPointTransactionHistories: { __typename?: 'PointTransactionHistoryList', totalItems: number, items: Array<{ __typename?: 'PointTransactionHistory', id: string, source: PointTransactionSourceType, point: number, pointSign: PointSignType, description?: string | null, expiryDate?: string | null, createdBy?: string | null, remarks?: string | null, membershipId: string, createdAt?: string | null, membership: { __typename?: 'Membership', id: string, firstName: string, lastName: string }, staff?: { __typename?: 'Staff', id: string, firstName?: string | null, lastName?: string | null } | null }> } };

export type GetRedemptionHistoriesQueryVariables = Exact<{
  input?: InputMaybe<QueryRedemptionHistoriesInput>;
  options?: InputMaybe<RedemptionHistoryListOptions>;
}>;


export type GetRedemptionHistoriesQuery = { __typename?: 'Query', getRedemptionHistories: { __typename?: 'RedemptionHistoryList', totalItems: number, items: Array<{ __typename?: 'RedemptionHistory', id: string, type: RedemptionHistoryType, pointCost: number, redeemerId: string, redeemerRole: RedeemerRole, isUsed: boolean, usedDate?: string | null, createdAt: string, parentType?: PromotionType | null, reward?: { __typename?: 'Reward', name: string, id: string, createdAt: string, updatedAt: string, runningId: string, description?: string | null, productVariantId?: string | null, productVariantImage?: string | null, productVariantName?: string | null, productVariantPrice?: string | null, status: RewardStatus, pointCost: number, redemptionCount: number, rewardUsedCount: number, quantity?: number | null, redeemCriteria: string, termCondition: string, startAt: string, endAt: string, imageUrls?: Array<string> | null, parentType?: PromotionType | null, type: RewardOrPromoCodeType, membershipTiers?: Array<{ __typename?: 'MembershipTier', id: string, name: string, level: number, minimumPoints: number, imageUrl?: string | null }> | null } | null, promoCode?: { __typename?: 'PromoCode', id: string, name: string, createdAt: string, updatedAt: string, runningId: string, description?: string | null, redeemCriteria: string, termCondition: string, type: PromoCodeType, status: PromoCodeStatus, code: string, value: number, minimumSpendAmount: number, cappedDiscount?: number | null, pointCost: number, startAt: string, endAt: string, imageUrls?: Array<string> | null, quantity?: number | null, membershipTiers?: Array<{ __typename?: 'MembershipTier', id: string, name: string, level: number, minimumPoints: number, imageUrl?: string | null }> | null } | null, membership?: { __typename?: 'Membership', id: string, firstName: string, lastName: string } | null }> } };

export type ManualAdjustPointsMutationVariables = Exact<{
  input: ManualAdjustPointInput;
}>;


export type ManualAdjustPointsMutation = { __typename?: 'Mutation', manualAdjustPoints?: Array<{ __typename?: 'PointTransactionHistory', id: string }> | null };

export type MembershipTierQueryVariables = Exact<{
  membershipTierId: Scalars['String'];
}>;


export type MembershipTierQuery = { __typename?: 'Query', membershipTier: { __typename?: 'MembershipTier', id: string, name: string, level: number, minimumPoints: number, imageUrl?: string | null } };

export type CreateMembershipTierMutationVariables = Exact<{
  input: CreateMembershipTierInput;
}>;


export type CreateMembershipTierMutation = { __typename?: 'Mutation', createMembershipTier?: { __typename?: 'MembershipTier', id: string } | null };

export type DeleteMembershipTierMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
}>;


export type DeleteMembershipTierMutation = { __typename?: 'Mutation', deleteMembershipTier: boolean };

export type UpdateMembershipTierMutationVariables = Exact<{
  input: UpdateMembershipTierInput;
}>;


export type UpdateMembershipTierMutation = { __typename?: 'Mutation', updateMembershipTier?: { __typename?: 'MembershipTier', id: string } | null };

export type GetPromoCodesQueryVariables = Exact<{
  options?: InputMaybe<PromoCodeListOptions>;
  input?: InputMaybe<QueryPromoCodesInput>;
}>;


export type GetPromoCodesQuery = { __typename?: 'Query', getPromoCodes: { __typename?: 'PromoCodeList', totalItems: number, items: Array<{ __typename?: 'PromoCode', id: string, name: string, createdAt: string, updatedAt: string, runningId: string, description?: string | null, redeemCriteria: string, termCondition: string, type: PromoCodeType, status: PromoCodeStatus, code: string, value: number, minimumSpendAmount: number, cappedDiscount?: number | null, pointCost: number, startAt: string, endAt: string, imageUrls?: Array<string> | null, quantity?: number | null, membershipTiers?: Array<{ __typename?: 'MembershipTier', id: string, name: string, level: number, minimumPoints: number, imageUrl?: string | null }> | null }> } };

export type GetRewardsQueryVariables = Exact<{
  input?: InputMaybe<QueryRewardsInput>;
  options?: InputMaybe<RewardListOptions>;
}>;


export type GetRewardsQuery = { __typename?: 'Query', getRewards: { __typename?: 'RewardList', totalItems: number, items: Array<{ __typename?: 'Reward', name: string, id: string, createdAt: string, updatedAt: string, runningId: string, description?: string | null, productVariantId?: string | null, productVariantImage?: string | null, productVariantName?: string | null, productVariantPrice?: string | null, status: RewardStatus, pointCost: number, redemptionCount: number, rewardUsedCount: number, quantity?: number | null, redeemCriteria: string, termCondition: string, startAt: string, endAt: string, imageUrls?: Array<string> | null, parentType?: PromotionType | null, type: RewardOrPromoCodeType, membershipTiers?: Array<{ __typename?: 'MembershipTier', id: string, name: string, level: number, minimumPoints: number, imageUrl?: string | null }> | null }> } };

export type RewardQueryVariables = Exact<{
  rewardId: Scalars['String'];
}>;


export type RewardQuery = { __typename?: 'Query', reward: { __typename?: 'Reward', name: string, id: string, createdAt: string, updatedAt: string, runningId: string, description?: string | null, productVariantId?: string | null, productVariantImage?: string | null, productVariantName?: string | null, productVariantPrice?: string | null, status: RewardStatus, pointCost: number, redemptionCount: number, rewardUsedCount: number, quantity?: number | null, redeemCriteria: string, termCondition: string, startAt: string, endAt: string, imageUrls?: Array<string> | null, parentType?: PromotionType | null, type: RewardOrPromoCodeType, membershipTiers?: Array<{ __typename?: 'MembershipTier', id: string, name: string, level: number, minimumPoints: number, imageUrl?: string | null }> | null } };

export type PromoCodeQueryVariables = Exact<{
  promoCodeId: Scalars['String'];
}>;


export type PromoCodeQuery = { __typename?: 'Query', promoCode: { __typename?: 'PromoCode', id: string, name: string, createdAt: string, updatedAt: string, runningId: string, description?: string | null, redeemCriteria: string, termCondition: string, type: PromoCodeType, status: PromoCodeStatus, code: string, value: number, minimumSpendAmount: number, cappedDiscount?: number | null, pointCost: number, startAt: string, endAt: string, imageUrls?: Array<string> | null, quantity?: number | null, membershipTiers?: Array<{ __typename?: 'MembershipTier', id: string, name: string, level: number, minimumPoints: number, imageUrl?: string | null }> | null } };

export type RewardInfoFragment = { __typename?: 'Reward', name: string, id: string, createdAt: string, updatedAt: string, runningId: string, description?: string | null, productVariantId?: string | null, productVariantImage?: string | null, productVariantName?: string | null, productVariantPrice?: string | null, status: RewardStatus, pointCost: number, redemptionCount: number, rewardUsedCount: number, quantity?: number | null, redeemCriteria: string, termCondition: string, startAt: string, endAt: string, imageUrls?: Array<string> | null, parentType?: PromotionType | null, type: RewardOrPromoCodeType, membershipTiers?: Array<{ __typename?: 'MembershipTier', id: string, name: string, level: number, minimumPoints: number, imageUrl?: string | null }> | null };

export type PromoCodeInfoFragment = { __typename?: 'PromoCode', id: string, name: string, createdAt: string, updatedAt: string, runningId: string, description?: string | null, redeemCriteria: string, termCondition: string, type: PromoCodeType, status: PromoCodeStatus, code: string, value: number, minimumSpendAmount: number, cappedDiscount?: number | null, pointCost: number, startAt: string, endAt: string, imageUrls?: Array<string> | null, quantity?: number | null, membershipTiers?: Array<{ __typename?: 'MembershipTier', id: string, name: string, level: number, minimumPoints: number, imageUrl?: string | null }> | null };

export type CreateRewardMutationVariables = Exact<{
  input: CreateRewardInput;
}>;


export type CreateRewardMutation = { __typename?: 'Mutation', createReward?: { __typename?: 'Reward', id: string, name: string } | null };

export type DeleteRewardMutationVariables = Exact<{
  deleteRewardId?: InputMaybe<Scalars['String']>;
}>;


export type DeleteRewardMutation = { __typename?: 'Mutation', deleteReward: boolean };

export type CreatePromoCodeMutationVariables = Exact<{
  input: CreatePromoCodeInput;
}>;


export type CreatePromoCodeMutation = { __typename?: 'Mutation', createPromoCode?: { __typename?: 'PromoCode', id: string } | null };

export type DeletePromoCodeMutationVariables = Exact<{
  deletePromoCodeId: Scalars['String'];
}>;


export type DeletePromoCodeMutation = { __typename?: 'Mutation', deletePromoCode: boolean };

export type UpdateRewardMutationVariables = Exact<{
  input: UpdateRewardInput;
}>;


export type UpdateRewardMutation = { __typename?: 'Mutation', updateReward?: { __typename?: 'Reward', id: string } | null };

export type UpdatePromoCodeMutationVariables = Exact<{
  input: UpdatePromoCodeInput;
}>;


export type UpdatePromoCodeMutation = { __typename?: 'Mutation', updatePromoCode?: { __typename?: 'PromoCode', id: string } | null };

export type RedeemForCustomerMutationVariables = Exact<{
  input: CreateRedemptionHistoryInput;
}>;


export type RedeemForCustomerMutation = { __typename?: 'Mutation', redeemForCustomer?: { __typename?: 'RedemptionHistory', id: string, isUsed: boolean, pointCost: number } | null };

export type RedeemForMultipleCustomerMutationVariables = Exact<{
  input: CreateMultipleRedemptionHistoryInput;
}>;


export type RedeemForMultipleCustomerMutation = { __typename?: 'Mutation', redeemForMultipleCustomer?: { __typename?: 'RedeemForMultipleCustomerResult', redeemedMemberships: Array<{ __typename?: 'Membership', id: string }>, createdRedemptions: Array<{ __typename?: 'RedemptionHistory', id: string }> } | null };

export type UpdatePointSettingMutationVariables = Exact<{
  input: Array<UpdatePointSettingInput> | UpdatePointSettingInput;
}>;


export type UpdatePointSettingMutation = { __typename?: 'Mutation', updatePointSetting?: Array<{ __typename?: 'PointSetting', id: string }> | null };

export type GetPointSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPointSettingsQuery = { __typename?: 'Query', getPointSettings: { __typename?: 'PointSettingList', totalItems: number, items: Array<{ __typename?: 'PointSetting', id: string, type: PointSettingType, unitValue: string, awardPointPerUnit: number }> } };

export type MembershipInfoFragment = { __typename?: 'Membership', id: string, firstName: string, lastName: string, pointEarned: number, pointBalance: number, pointRequired: number, periodEndAt: string, pointValidUntil?: string | null, tierProgress: { __typename?: 'TierProgress', pointToNextTier: number, description: string, isHighestTier: boolean, currentTier: { __typename?: 'Tier', id: string } } };

export type GetMembershipQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>;
}>;


export type GetMembershipQuery = { __typename?: 'Query', getMembership?: { __typename?: 'Membership', id: string, firstName: string, lastName: string, pointEarned: number, pointBalance: number, pointRequired: number, periodEndAt: string, pointValidUntil?: string | null, tierProgress: { __typename?: 'TierProgress', pointToNextTier: number, description: string, isHighestTier: boolean, currentTier: { __typename?: 'Tier', id: string } } } | null };

export type GetMembershipTiersQueryVariables = Exact<{
  options?: InputMaybe<MembershipTierListOptions>;
  search?: InputMaybe<Scalars['String']>;
}>;


export type GetMembershipTiersQuery = { __typename?: 'Query', getMembershipTiers: { __typename?: 'MembershipTierList', totalItems: number, items: Array<{ __typename?: 'MembershipTier', id: string, imageUrl?: string | null, level: number, minimumPoints: number, name: string }> } };

export type GetModifierGroupsQueryVariables = Exact<{
  options?: InputMaybe<ModifierGroupListOptions>;
  search?: InputMaybe<Scalars['String']>;
}>;


export type GetModifierGroupsQuery = { __typename?: 'Query', getModifierGroups: { __typename?: 'ModifierGroupList', items: Array<{ __typename?: 'FacetValue', id: string, createdAt: any, facetId: string, name: string, code: string, facet: { __typename?: 'Facet', id: string, code: string } }> } };

export type AssignModifierGroupsToProductMutationVariables = Exact<{
  input: AssignModifierGroupsToProductInput;
}>;


export type AssignModifierGroupsToProductMutation = { __typename?: 'Mutation', assignModifierGroupsToProduct: { __typename?: 'Product', id: string } };

export type NovuSubscriberInfoFragment = { __typename?: 'NovuSubscriber', subscriberId?: string | null, firstName?: string | null, lastName?: string | null };

export type GetAdminSubscriberProfileQueryVariables = Exact<{
  adminId?: InputMaybe<Scalars['ID']>;
}>;


export type GetAdminSubscriberProfileQuery = { __typename?: 'Query', getAdminSubscriberProfile?: { __typename?: 'NovuSubscriber', subscriberId?: string | null, firstName?: string | null, lastName?: string | null } | null };

export type GetOutletsQueryVariables = Exact<{
  options?: InputMaybe<OutletListOptions>;
}>;


export type GetOutletsQuery = { __typename?: 'Query', getOutlets: { __typename?: 'OutletList', items: Array<{ __typename?: 'Outlet', id: string, name: string }> } };

export type GetOutletFulfillmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOutletFulfillmentsQuery = { __typename?: 'Query', getOutletFulfillments: { __typename?: 'OutletFulfillmentList', items: Array<{ __typename?: 'OutletFulfillment', id: string, type: OutletFulfillmentType, day: DayType, openingTime: string, closingTime: string, startDate?: any | null, endDate?: any | null, outlet: { __typename?: 'Outlet', name: string } }> } };

export type CreateOutletMutationVariables = Exact<{
  input: CreateOutletInput;
}>;


export type CreateOutletMutation = { __typename?: 'Mutation', createOutlet: { __typename?: 'Outlet', id: string } };

export type UpdateOutletMutationVariables = Exact<{
  input: UpdateOutletInput;
}>;


export type UpdateOutletMutation = { __typename?: 'Mutation', updateOutlet: { __typename?: 'Outlet', id: string } };

export type UpdateOutletFulfillmentsMutationVariables = Exact<{
  input: CreateOutletFulfillmentInput;
}>;


export type UpdateOutletFulfillmentsMutation = { __typename?: 'Mutation', updateOutletFulfillments: Array<{ __typename?: 'OutletFulfillment', id: string } | null> };

export type CreateProductMutationVariables = Exact<{
  input: CreateProductInput;
}>;


export type CreateProductMutation = { __typename?: 'Mutation', createProduct: { __typename?: 'Product', id: string } };

export type UpdateProductMutationVariables = Exact<{
  input: UpdateProductInput;
}>;


export type UpdateProductMutation = { __typename?: 'Mutation', updateProduct: { __typename?: 'Product', id: string } };

export type CreateProductVariantsMutationVariables = Exact<{
  input: Array<CreateProductVariantInput> | CreateProductVariantInput;
}>;


export type CreateProductVariantsMutation = { __typename?: 'Mutation', createProductVariants: Array<{ __typename?: 'ProductVariant', id: string } | null> };

export type UpdateProductVariantsMutationVariables = Exact<{
  input: Array<UpdateProductVariantInput> | UpdateProductVariantInput;
}>;


export type UpdateProductVariantsMutation = { __typename?: 'Mutation', updateProductVariants: Array<{ __typename?: 'ProductVariant', id: string } | null> };

export type DeleteProductVariantsMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type DeleteProductVariantsMutation = { __typename?: 'Mutation', deleteProductVariants: Array<{ __typename?: 'DeletionResponse', result: DeletionResult }> };

export type CreateProductOptionGroupMutationVariables = Exact<{
  input: CreateProductOptionGroupInput;
}>;


export type CreateProductOptionGroupMutation = { __typename?: 'Mutation', createProductOptionGroup: { __typename?: 'ProductOptionGroup', id: string, name: string, options: Array<{ __typename?: 'ProductOption', id: string, name: string, code: string }> } };

export type CreateProductOptionMutationVariables = Exact<{
  input: CreateProductOptionInput;
}>;


export type CreateProductOptionMutation = { __typename?: 'Mutation', createProductOption: { __typename?: 'ProductOption', id: string, name: string } };

export type AddOptionGroupToProductMutationVariables = Exact<{
  productId: Scalars['ID'];
  optionGroupId: Scalars['ID'];
}>;


export type AddOptionGroupToProductMutation = { __typename?: 'Mutation', addOptionGroupToProduct: { __typename?: 'Product', id: string } };

export type RemoveOptionGroupFromProductMutationVariables = Exact<{
  productId: Scalars['ID'];
  optionGroupId: Scalars['ID'];
}>;


export type RemoveOptionGroupFromProductMutation = { __typename?: 'Mutation', removeOptionGroupFromProduct: { __typename?: 'Product', id: string } | { __typename?: 'ProductOptionInUseError' } };

export type UpdateProductOptionGroupMutationVariables = Exact<{
  input: UpdateProductOptionGroupInput;
}>;


export type UpdateProductOptionGroupMutation = { __typename?: 'Mutation', updateProductOptionGroup: { __typename?: 'ProductOptionGroup', id: string } };

export type DeleteProductOptionMutationVariables = Exact<{
  deleteProductOptionId: Scalars['ID'];
}>;


export type DeleteProductOptionMutation = { __typename?: 'Mutation', deleteProductOption: { __typename?: 'DeletionResponse', message?: string | null, result: DeletionResult } };

export type GetProductsQueryVariables = Exact<{
  options?: InputMaybe<ProductListOptions>;
  search?: InputMaybe<Scalars['String']>;
}>;


export type GetProductsQuery = { __typename?: 'Query', getProducts: { __typename?: 'ProductList', totalItems: number, items: Array<{ __typename?: 'Product', createdAt: any, id: string, name: string, enabled: boolean, description: string, variants: Array<{ __typename?: 'ProductVariant', price: any, currencyCode: CurrencyCode }> }> } };

export type GetProductVariantsQueryVariables = Exact<{
  options?: InputMaybe<ProductVariantListOptions>;
  search?: InputMaybe<Scalars['String']>;
}>;


export type GetProductVariantsQuery = { __typename?: 'Query', getProductVariants: { __typename?: 'ProductVariantList', totalItems: number, items: Array<{ __typename?: 'ProductVariant', createdAt: any, currencyCode: CurrencyCode, enabled: boolean, id: string, languageCode: LanguageCode, name: string, price: any, priceWithTax: any, productId: string, sku: string }> } };

export type CreateCollectionWithFacetMutationVariables = Exact<{
  input: CreateCollectionProductInput;
}>;


export type CreateCollectionWithFacetMutation = { __typename?: 'Mutation', createCollectionWithFacet: { __typename?: 'Collection', id: string } };

export type GetCollectionsQueryVariables = Exact<{
  options?: InputMaybe<CollectionListOptions>;
}>;


export type GetCollectionsQuery = { __typename?: 'Query', getCollections: { __typename?: 'CollectionList', totalItems: number, items: Array<{ __typename?: 'Collection', id: string, name: string }> } };

export type CollectionQueryVariables = Exact<{
  collectionId?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
}>;


export type CollectionQuery = { __typename?: 'Query', collection?: { __typename?: 'Collection', id: string, name: string, isPrivate: boolean, createdAt: any, assets: Array<{ __typename?: 'Asset', id: string, name: string, source: string }>, filters: Array<{ __typename?: 'ConfigurableOperation', code: string, args: Array<{ __typename?: 'ConfigArg', value: string, name: string }> }>, gradient?: { __typename?: 'CollectionGradient', type: GradientEnum, gradient: Array<string> } | null, productVariants: { __typename?: 'ProductVariantList', items: Array<{ __typename?: 'ProductVariant', product: { __typename?: 'Product', id: string, name: string } }> }, products: { __typename?: 'ProductList', items: Array<{ __typename?: 'Product', id: string }> }, parent?: { __typename?: 'Collection', name: string, id: string } | null } | null };

export type UpdateCollectionWithFacetMutationVariables = Exact<{
  input: UpdateCollectionProductInput;
}>;


export type UpdateCollectionWithFacetMutation = { __typename?: 'Mutation', updateCollectionWithFacet: { __typename?: 'Collection', id: string } };

export type MoveCollectionMutationVariables = Exact<{
  input: MoveCollectionInput;
}>;


export type MoveCollectionMutation = { __typename?: 'Mutation', moveCollection: { __typename?: 'Collection', id: string } };

export type GetShippingMethodsQueryVariables = Exact<{
  options?: InputMaybe<ShippingMethodListOptions>;
  search?: InputMaybe<Scalars['String']>;
}>;


export type GetShippingMethodsQuery = { __typename?: 'Query', getShippingMethods: { __typename?: 'ShippingMethodList', totalItems: number, items: Array<{ __typename?: 'ShippingMethod', id: string, createdAt: any, updatedAt: any, languageCode: LanguageCode, code: string, name: string, description: string, fulfillmentHandlerCode: string, translations: Array<{ __typename?: 'ShippingMethodTranslation', id: string, createdAt: any, updatedAt: any, languageCode: LanguageCode, name: string, description: string }> }> } };

export type GetFulfillmentChannelsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFulfillmentChannelsQuery = { __typename?: 'Query', getFulfillmentChannels: { __typename?: 'FulfillmentChannelList', totalItems: number, items: Array<{ __typename?: 'FulfillmentChannel', id: string, name: string, description?: string | null, createdAt: any, updatedAt: any, code: FulfillmentMethodCode, isPrivate: boolean }> } };

export type GetAssigningFacetCollectionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAssigningFacetCollectionQuery = { __typename?: 'Query', getAssigningFacetCollection?: { __typename?: 'Facet', id: string, name: string, values: Array<{ __typename?: 'FacetValue', id: string, name: string }> } | null };

export type AssignModifiersToProductMutationVariables = Exact<{
  input: BulkProductModifierInput;
}>;


export type AssignModifiersToProductMutation = { __typename?: 'Mutation', assignModifiersToProduct: Array<{ __typename?: 'ProductModifier', id: string, modifierId: string }> };

export type ReservationInfoFragment = { __typename?: 'Reservation', id: string, createdAt: any, diningDate: any, status: ReservationStatusType, guestName: string, phoneNumber: string, adultPax: number, kidPax: number, occasion?: Array<string> | null, remark?: string | null, cancellationRemark?: string | null };

export type UpdateReservationStatusMutationVariables = Exact<{
  input: UpdateReservationStatusInput;
}>;


export type UpdateReservationStatusMutation = { __typename?: 'Mutation', updateReservationStatus: { __typename?: 'Reservation', id: string, createdAt: any, diningDate: any, status: ReservationStatusType, guestName: string, phoneNumber: string, adultPax: number, kidPax: number, occasion?: Array<string> | null, remark?: string | null, cancellationRemark?: string | null } };

export type CheckConflictingPostcodesQueryVariables = Exact<{
  postcodes: Array<Scalars['String']> | Scalars['String'];
}>;


export type CheckConflictingPostcodesQuery = { __typename?: 'Query', checkConflictingPostcodes: Array<string> };

export type PaymentMethodsQueryVariables = Exact<{
  options?: InputMaybe<PaymentMethodListOptions>;
}>;


export type PaymentMethodsQuery = { __typename?: 'Query', paymentMethods: { __typename?: 'PaymentMethodList', totalItems: number, items: Array<{ __typename?: 'PaymentMethod', id: string, createdAt: any, updatedAt: any, name: string, code: string, description: string, enabled: boolean, channelType?: PaymentMethodType | null, checker?: { __typename?: 'ConfigurableOperation', code: string, args: Array<{ __typename?: 'ConfigArg', name: string, value: string }> } | null, handler: { __typename?: 'ConfigurableOperation', code: string }, translations: Array<{ __typename?: 'PaymentMethodTranslation', id: string, createdAt: any, updatedAt: any, languageCode: LanguageCode, name: string, description: string }>, customFields?: { __typename?: 'PaymentMethodCustomFields', type?: string | null } | null }> } };

export type GetValidPostCodesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetValidPostCodesQuery = { __typename?: 'Query', getValidPostCodes: Array<string> };

export type ShippingTierInfoFragment = { __typename?: 'ShippingTier', id: string, maxQuantity: number, minQuantity: number, shippingMethodId: string, value: number };

export type CreateShippingTiersMutationVariables = Exact<{
  input: Array<CreateShippingTierInput> | CreateShippingTierInput;
}>;


export type CreateShippingTiersMutation = { __typename?: 'Mutation', createShippingTiers: Array<{ __typename?: 'ShippingTier', id: string, maxQuantity: number, minQuantity: number, shippingMethodId: string, value: number }> };

export type AssignShippingTierToShippingMethodMutationVariables = Exact<{
  shippingMethodId: Scalars['ID'];
  input: Array<InputMaybe<AssignShippingTierInput>> | InputMaybe<AssignShippingTierInput>;
}>;


export type AssignShippingTierToShippingMethodMutation = { __typename?: 'Mutation', assignShippingTierToShippingMethod: Array<{ __typename?: 'ShippingTier', id: string, maxQuantity: number, minQuantity: number, shippingMethodId: string, value: number }> };

export const AdministratorInfoFragmentDoc = gql`
    fragment AdministratorInfo on Administrator {
  id
}
    `;
export const ChannelInfoFragmentDoc = gql`
    fragment ChannelInfo on Channel {
  id
  code
  token
  customFields {
    availableFulfillmentMethods
  }
  availableCurrencyCodes
  defaultCurrencyCode
  defaultLanguageCode
}
    `;
export const CustomerInfoFragmentDoc = gql`
    fragment CustomerInfo on Customer {
  id
  createdAt
  updatedAt
  title
  firstName
  lastName
  phoneNumber
  emailAddress
  user {
    id
  }
}
    `;
export const RewardInfoFragmentDoc = gql`
    fragment RewardInfo on Reward {
  name
  id
  createdAt
  updatedAt
  runningId
  description
  productVariantId
  productVariantImage
  productVariantName
  productVariantPrice
  status
  pointCost
  redemptionCount
  rewardUsedCount
  quantity
  redeemCriteria
  termCondition
  startAt
  endAt
  imageUrls
  parentType
  type
  membershipTiers {
    id
    name
    level
    minimumPoints
    imageUrl
  }
}
    `;
export const PromoCodeInfoFragmentDoc = gql`
    fragment PromoCodeInfo on PromoCode {
  id
  name
  createdAt
  updatedAt
  runningId
  description
  redeemCriteria
  termCondition
  type
  status
  code
  value
  minimumSpendAmount
  cappedDiscount
  pointCost
  startAt
  endAt
  imageUrls
  quantity
  membershipTiers {
    id
    name
    level
    minimumPoints
    imageUrl
  }
}
    `;
export const MembershipInfoFragmentDoc = gql`
    fragment MembershipInfo on Membership {
  id
  firstName
  lastName
  pointEarned
  pointBalance
  pointRequired
  periodEndAt
  pointValidUntil
  tierProgress {
    pointToNextTier
    description
    isHighestTier
    currentTier {
      id
    }
  }
}
    `;
export const NovuSubscriberInfoFragmentDoc = gql`
    fragment NovuSubscriberInfo on NovuSubscriber {
  subscriberId
  firstName
  lastName
}
    `;
export const ReservationInfoFragmentDoc = gql`
    fragment ReservationInfo on Reservation {
  id
  createdAt
  diningDate
  status
  guestName
  phoneNumber
  adultPax
  kidPax
  occasion
  remark
  cancellationRemark
}
    `;
export const ShippingTierInfoFragmentDoc = gql`
    fragment ShippingTierInfo on ShippingTier {
  id
  maxQuantity
  minQuantity
  shippingMethodId
  value
}
    `;
export const GetAdministratorsDocument = gql`
    query getAdministrators($options: AdministratorListOptions, $search: String) {
  getAdministrators(options: $options, search: $search) {
    items {
      ...AdministratorInfo
    }
    totalItems
  }
}
    ${AdministratorInfoFragmentDoc}`;
export const UpdateAdministratorAccountStatusDocument = gql`
    mutation UpdateAdministratorAccountStatus($input: UpdateAdministratorAccountStatusInput!) {
  updateAdministratorAccountStatus(input: $input) {
    id
  }
}
    `;
export const ActiveChannelDocument = gql`
    query activeChannel {
  activeChannel {
    ...ChannelInfo
  }
}
    ${ChannelInfoFragmentDoc}`;
export const GetCustomersDocument = gql`
    query getCustomers($options: CustomerListOptions, $search: String) {
  getCustomers(options: $options, search: $search) {
    items {
      ...CustomerInfo
    }
    totalItems
  }
}
    ${CustomerInfoFragmentDoc}`;
export const CreateCustomerDocument = gql`
    mutation createCustomer($input: CreateCustomerInput!, $password: String) {
  createCustomer(input: $input, password: $password) {
    ... on Customer {
      ...CustomerInfo
    }
    ... on EmailAddressConflictError {
      message
    }
  }
}
    ${CustomerInfoFragmentDoc}`;
export const UpdateCustomerDocument = gql`
    mutation updateCustomer($input: UpdateCustomerInput!) {
  updateCustomer(input: $input) {
    ... on Customer {
      ...CustomerInfo
    }
    ... on EmailAddressConflictError {
      message
    }
  }
}
    ${CustomerInfoFragmentDoc}`;
export const UpdateGiftOptionDocument = gql`
    mutation updateGiftOption($input: UpdateGiftOptionInput!) {
  updateGiftOption(input: $input) {
    id
  }
}
    `;
export const GetGiftOptionsDocument = gql`
    query getGiftOptions($option: GiftOptionListOptions) {
  getGiftOptions(option: $option) {
    items {
      id
      createdAt
      updatedAt
      name
      label
      enabled
      description
      price
    }
    totalItems
  }
}
    `;
export const DeleteGiftOptionDocument = gql`
    mutation deleteGiftOption($deleteGiftOptionId: ID!) {
  deleteGiftOption(id: $deleteGiftOptionId) {
    result
    message
  }
}
    `;
export const CreateGiftOptionDocument = gql`
    mutation createGiftOption($input: CreateGiftOptionInput!) {
  createGiftOption(input: $input) {
    id
  }
}
    `;
export const CreateAssetsDocument = gql`
    mutation createAssets($input: [CreateAssetInput!]!) {
  createAssets(input: $input) {
    ... on Asset {
      id
      source
    }
    ... on MimeTypeError {
      errorCode
      message
      fileName
      mimeType
    }
  }
}
    `;
export const PublicSellerDocument = gql`
    query publicSeller($id: ID!) {
  publicSeller(id: $id) {
    id
    name
    logo
    channels {
      id
      code
      name
      token
      icon
      description
    }
    resourceConfig
  }
}
    `;
export const LoginDocument = gql`
    mutation login($username: String!, $password: String!, $rememberMe: Boolean) {
  login(username: $username, password: $password, rememberMe: $rememberMe) {
    ... on CurrentUser {
      id
    }
  }
}
    `;
export const MeDocument = gql`
    query me {
  me {
    id
    identifier
    channels {
      id
      token
      code
      permissions
    }
  }
}
    `;
export const ActiveAdministratorDocument = gql`
    query activeAdministrator {
  activeAdministrator {
    id
    createdAt
    updatedAt
    firstName
    lastName
    emailAddress
    customFields {
      phoneNumber
      seller {
        id
        name
      }
    }
  }
}
    `;
export const GetPointTransactionHistoriesDocument = gql`
    query getPointTransactionHistories($input: QueryPointTransactionHistorysInput, $options: PointTransactionHistoryListOptions) {
  getPointTransactionHistories(input: $input, options: $options) {
    items {
      id
      source
      point
      pointSign
      description
      expiryDate
      createdBy
      remarks
      membershipId
      membership {
        id
        firstName
        lastName
      }
      createdAt
      staff {
        id
        firstName
        lastName
      }
    }
    totalItems
  }
}
    `;
export const GetRedemptionHistoriesDocument = gql`
    query GetRedemptionHistories($input: QueryRedemptionHistoriesInput, $options: RedemptionHistoryListOptions) {
  getRedemptionHistories(input: $input, options: $options) {
    items {
      id
      type
      pointCost
      redeemerId
      redeemerRole
      isUsed
      usedDate
      createdAt
      reward {
        ...RewardInfo
      }
      promoCode {
        ...PromoCodeInfo
      }
      membership {
        id
        firstName
        lastName
      }
      createdAt
      parentType
    }
    totalItems
  }
}
    ${RewardInfoFragmentDoc}
${PromoCodeInfoFragmentDoc}`;
export const ManualAdjustPointsDocument = gql`
    mutation ManualAdjustPoints($input: ManualAdjustPointInput!) {
  manualAdjustPoints(input: $input) {
    id
  }
}
    `;
export const MembershipTierDocument = gql`
    query MembershipTier($membershipTierId: String!) {
  membershipTier(id: $membershipTierId) {
    id
    name
    level
    minimumPoints
    imageUrl
  }
}
    `;
export const CreateMembershipTierDocument = gql`
    mutation CreateMembershipTier($input: CreateMembershipTierInput!) {
  createMembershipTier(input: $input) {
    id
  }
}
    `;
export const DeleteMembershipTierDocument = gql`
    mutation DeleteMembershipTier($id: String) {
  deleteMembershipTier(id: $id)
}
    `;
export const UpdateMembershipTierDocument = gql`
    mutation UpdateMembershipTier($input: UpdateMembershipTierInput!) {
  updateMembershipTier(input: $input) {
    id
  }
}
    `;
export const GetPromoCodesDocument = gql`
    query getPromoCodes($options: PromoCodeListOptions, $input: QueryPromoCodesInput) {
  getPromoCodes(options: $options, input: $input) {
    items {
      ...PromoCodeInfo
    }
    totalItems
  }
}
    ${PromoCodeInfoFragmentDoc}`;
export const GetRewardsDocument = gql`
    query getRewards($input: QueryRewardsInput, $options: RewardListOptions) {
  getRewards(input: $input, options: $options) {
    items {
      ...RewardInfo
    }
    totalItems
  }
}
    ${RewardInfoFragmentDoc}`;
export const RewardDocument = gql`
    query Reward($rewardId: String!) {
  reward(id: $rewardId) {
    ...RewardInfo
  }
}
    ${RewardInfoFragmentDoc}`;
export const PromoCodeDocument = gql`
    query PromoCode($promoCodeId: String!) {
  promoCode(id: $promoCodeId) {
    ...PromoCodeInfo
  }
}
    ${PromoCodeInfoFragmentDoc}`;
export const CreateRewardDocument = gql`
    mutation CreateReward($input: CreateRewardInput!) {
  createReward(input: $input) {
    id
    name
  }
}
    `;
export const DeleteRewardDocument = gql`
    mutation DeleteReward($deleteRewardId: String) {
  deleteReward(id: $deleteRewardId)
}
    `;
export const CreatePromoCodeDocument = gql`
    mutation CreatePromoCode($input: CreatePromoCodeInput!) {
  createPromoCode(input: $input) {
    id
  }
}
    `;
export const DeletePromoCodeDocument = gql`
    mutation DeletePromoCode($deletePromoCodeId: String!) {
  deletePromoCode(id: $deletePromoCodeId)
}
    `;
export const UpdateRewardDocument = gql`
    mutation UpdateReward($input: UpdateRewardInput!) {
  updateReward(input: $input) {
    id
  }
}
    `;
export const UpdatePromoCodeDocument = gql`
    mutation UpdatePromoCode($input: UpdatePromoCodeInput!) {
  updatePromoCode(input: $input) {
    id
  }
}
    `;
export const RedeemForCustomerDocument = gql`
    mutation redeemForCustomer($input: CreateRedemptionHistoryInput!) {
  redeemForCustomer(input: $input) {
    id
    isUsed
    pointCost
  }
}
    `;
export const RedeemForMultipleCustomerDocument = gql`
    mutation RedeemForMultipleCustomer($input: CreateMultipleRedemptionHistoryInput!) {
  redeemForMultipleCustomer(input: $input) {
    redeemedMemberships {
      id
    }
    createdRedemptions {
      id
    }
  }
}
    `;
export const UpdatePointSettingDocument = gql`
    mutation updatePointSetting($input: [UpdatePointSettingInput!]!) {
  updatePointSetting(input: $input) {
    id
  }
}
    `;
export const GetPointSettingsDocument = gql`
    query getPointSettings {
  getPointSettings {
    items {
      id
      type
      unitValue
      awardPointPerUnit
    }
    totalItems
  }
}
    `;
export const GetMembershipDocument = gql`
    query getMembership($userId: String) {
  getMembership(userId: $userId) {
    ...MembershipInfo
  }
}
    ${MembershipInfoFragmentDoc}`;
export const GetMembershipTiersDocument = gql`
    query getMembershipTiers($options: MembershipTierListOptions, $search: String) {
  getMembershipTiers(options: $options, search: $search) {
    totalItems
    items {
      id
      imageUrl
      level
      minimumPoints
      name
    }
  }
}
    `;
export const GetModifierGroupsDocument = gql`
    query getModifierGroups($options: ModifierGroupListOptions, $search: String) {
  getModifierGroups(options: $options, search: $search) {
    items {
      id
      createdAt
      facetId
      facet {
        id
        code
      }
      name
      code
    }
  }
}
    `;
export const AssignModifierGroupsToProductDocument = gql`
    mutation assignModifierGroupsToProduct($input: AssignModifierGroupsToProductInput!) {
  assignModifierGroupsToProduct(input: $input) {
    id
  }
}
    `;
export const GetAdminSubscriberProfileDocument = gql`
    query getAdminSubscriberProfile($adminId: ID) {
  getAdminSubscriberProfile(adminId: $adminId) {
    ...NovuSubscriberInfo
  }
}
    ${NovuSubscriberInfoFragmentDoc}`;
export const GetOutletsDocument = gql`
    query getOutlets($options: OutletListOptions) {
  getOutlets(options: $options) {
    items {
      id
      name
    }
  }
}
    `;
export const GetOutletFulfillmentsDocument = gql`
    query GetOutletFulfillments {
  getOutletFulfillments {
    items {
      id
      type
      day
      openingTime
      closingTime
      startDate
      endDate
      outlet {
        name
      }
    }
  }
}
    `;
export const CreateOutletDocument = gql`
    mutation CreateOutlet($input: CreateOutletInput!) {
  createOutlet(input: $input) {
    id
  }
}
    `;
export const UpdateOutletDocument = gql`
    mutation UpdateOutlet($input: UpdateOutletInput!) {
  updateOutlet(input: $input) {
    id
  }
}
    `;
export const UpdateOutletFulfillmentsDocument = gql`
    mutation UpdateOutletFulfillments($input: CreateOutletFulfillmentInput!) {
  updateOutletFulfillments(input: $input) {
    id
  }
}
    `;
export const CreateProductDocument = gql`
    mutation createProduct($input: CreateProductInput!) {
  createProduct(input: $input) {
    id
  }
}
    `;
export const UpdateProductDocument = gql`
    mutation updateProduct($input: UpdateProductInput!) {
  updateProduct(input: $input) {
    id
  }
}
    `;
export const CreateProductVariantsDocument = gql`
    mutation createProductVariants($input: [CreateProductVariantInput!]!) {
  createProductVariants(input: $input) {
    id
  }
}
    `;
export const UpdateProductVariantsDocument = gql`
    mutation updateProductVariants($input: [UpdateProductVariantInput!]!) {
  updateProductVariants(input: $input) {
    id
  }
}
    `;
export const DeleteProductVariantsDocument = gql`
    mutation deleteProductVariants($ids: [ID!]!) {
  deleteProductVariants(ids: $ids) {
    result
  }
}
    `;
export const CreateProductOptionGroupDocument = gql`
    mutation createProductOptionGroup($input: CreateProductOptionGroupInput!) {
  createProductOptionGroup(input: $input) {
    id
    name
    options {
      id
      name
      code
    }
  }
}
    `;
export const CreateProductOptionDocument = gql`
    mutation createProductOption($input: CreateProductOptionInput!) {
  createProductOption(input: $input) {
    id
    name
  }
}
    `;
export const AddOptionGroupToProductDocument = gql`
    mutation addOptionGroupToProduct($productId: ID!, $optionGroupId: ID!) {
  addOptionGroupToProduct(productId: $productId, optionGroupId: $optionGroupId) {
    id
  }
}
    `;
export const RemoveOptionGroupFromProductDocument = gql`
    mutation removeOptionGroupFromProduct($productId: ID!, $optionGroupId: ID!) {
  removeOptionGroupFromProduct(
    productId: $productId
    optionGroupId: $optionGroupId
  ) {
    ... on Product {
      id
    }
  }
}
    `;
export const UpdateProductOptionGroupDocument = gql`
    mutation updateProductOptionGroup($input: UpdateProductOptionGroupInput!) {
  updateProductOptionGroup(input: $input) {
    id
  }
}
    `;
export const DeleteProductOptionDocument = gql`
    mutation deleteProductOption($deleteProductOptionId: ID!) {
  deleteProductOption(id: $deleteProductOptionId) {
    message
    result
  }
}
    `;
export const GetProductsDocument = gql`
    query getProducts($options: ProductListOptions, $search: String) {
  getProducts(options: $options, search: $search) {
    totalItems
    items {
      createdAt
      id
      name
      enabled
      description
      variants {
        price
        currencyCode
      }
    }
  }
}
    `;
export const GetProductVariantsDocument = gql`
    query getProductVariants($options: ProductVariantListOptions, $search: String) {
  getProductVariants(options: $options, search: $search) {
    totalItems
    items {
      createdAt
      currencyCode
      enabled
      id
      languageCode
      name
      price
      priceWithTax
      productId
      sku
    }
  }
}
    `;
export const CreateCollectionWithFacetDocument = gql`
    mutation createCollectionWithFacet($input: CreateCollectionProductInput!) {
  createCollectionWithFacet(input: $input) {
    id
  }
}
    `;
export const GetCollectionsDocument = gql`
    query getCollections($options: CollectionListOptions) {
  getCollections(options: $options) {
    totalItems
    items {
      id
      name
    }
  }
}
    `;
export const CollectionDocument = gql`
    query Collection($collectionId: ID, $slug: String) {
  collection(id: $collectionId, slug: $slug) {
    assets {
      id
      name
      source
    }
    id
    name
    isPrivate
    filters {
      code
      args {
        value
        name
      }
    }
    createdAt
    gradient {
      type
      gradient
    }
    productVariants {
      items {
        product {
          id
          name
        }
      }
    }
    products {
      items {
        id
      }
    }
    parent {
      name
      id
    }
  }
}
    `;
export const UpdateCollectionWithFacetDocument = gql`
    mutation updateCollectionWithFacet($input: UpdateCollectionProductInput!) {
  updateCollectionWithFacet(input: $input) {
    id
  }
}
    `;
export const MoveCollectionDocument = gql`
    mutation MoveCollection($input: MoveCollectionInput!) {
  moveCollection(input: $input) {
    id
  }
}
    `;
export const GetShippingMethodsDocument = gql`
    query GetShippingMethods($options: ShippingMethodListOptions, $search: String) {
  getShippingMethods(options: $options, search: $search) {
    totalItems
    items {
      id
      createdAt
      updatedAt
      languageCode
      code
      name
      description
      fulfillmentHandlerCode
      translations {
        id
        createdAt
        updatedAt
        languageCode
        name
        description
      }
    }
  }
}
    `;
export const GetFulfillmentChannelsDocument = gql`
    query GetFulfillmentChannels {
  getFulfillmentChannels {
    items {
      id
      name
      description
      createdAt
      updatedAt
      code
      isPrivate
    }
    totalItems
  }
}
    `;
export const GetAssigningFacetCollectionDocument = gql`
    query getAssigningFacetCollection {
  getAssigningFacetCollection {
    id
    name
    values {
      id
      name
    }
  }
}
    `;
export const AssignModifiersToProductDocument = gql`
    mutation assignModifiersToProduct($input: BulkProductModifierInput!) {
  assignModifiersToProduct(input: $input) {
    id
    modifierId
  }
}
    `;
export const UpdateReservationStatusDocument = gql`
    mutation updateReservationStatus($input: UpdateReservationStatusInput!) {
  updateReservationStatus(input: $input) {
    ...ReservationInfo
  }
}
    ${ReservationInfoFragmentDoc}`;
export const CheckConflictingPostcodesDocument = gql`
    query CheckConflictingPostcodes($postcodes: [String!]!) {
  checkConflictingPostcodes(postcodes: $postcodes)
}
    `;
export const PaymentMethodsDocument = gql`
    query PaymentMethods($options: PaymentMethodListOptions) {
  paymentMethods(options: $options) {
    items {
      id
      createdAt
      updatedAt
      name
      code
      description
      enabled
      checker {
        code
        args {
          name
          value
        }
      }
      handler {
        code
      }
      translations {
        id
        createdAt
        updatedAt
        languageCode
        name
        description
      }
      channelType
      customFields {
        type
      }
    }
    totalItems
  }
}
    `;
export const GetValidPostCodesDocument = gql`
    query getValidPostCodes {
  getValidPostCodes
}
    `;
export const CreateShippingTiersDocument = gql`
    mutation CreateShippingTiers($input: [CreateShippingTierInput!]!) {
  createShippingTiers(input: $input) {
    ...ShippingTierInfo
  }
}
    ${ShippingTierInfoFragmentDoc}`;
export const AssignShippingTierToShippingMethodDocument = gql`
    mutation AssignShippingTierToShippingMethod($shippingMethodId: ID!, $input: [AssignShippingTierInput]!) {
  assignShippingTierToShippingMethod(
    shippingMethodId: $shippingMethodId
    input: $input
  ) {
    ...ShippingTierInfo
  }
}
    ${ShippingTierInfoFragmentDoc}`;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    getAdministrators(variables?: GetAdministratorsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetAdministratorsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetAdministratorsQuery>(GetAdministratorsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAdministrators', 'query');
    },
    UpdateAdministratorAccountStatus(variables: UpdateAdministratorAccountStatusMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateAdministratorAccountStatusMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateAdministratorAccountStatusMutation>(UpdateAdministratorAccountStatusDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateAdministratorAccountStatus', 'mutation');
    },
    activeChannel(variables?: ActiveChannelQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ActiveChannelQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ActiveChannelQuery>(ActiveChannelDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'activeChannel', 'query');
    },
    getCustomers(variables?: GetCustomersQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetCustomersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetCustomersQuery>(GetCustomersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCustomers', 'query');
    },
    createCustomer(variables: CreateCustomerMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateCustomerMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateCustomerMutation>(CreateCustomerDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createCustomer', 'mutation');
    },
    updateCustomer(variables: UpdateCustomerMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateCustomerMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateCustomerMutation>(UpdateCustomerDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateCustomer', 'mutation');
    },
    updateGiftOption(variables: UpdateGiftOptionMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateGiftOptionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateGiftOptionMutation>(UpdateGiftOptionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateGiftOption', 'mutation');
    },
    getGiftOptions(variables?: GetGiftOptionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetGiftOptionsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetGiftOptionsQuery>(GetGiftOptionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getGiftOptions', 'query');
    },
    deleteGiftOption(variables: DeleteGiftOptionMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteGiftOptionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteGiftOptionMutation>(DeleteGiftOptionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteGiftOption', 'mutation');
    },
    createGiftOption(variables: CreateGiftOptionMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateGiftOptionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateGiftOptionMutation>(CreateGiftOptionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createGiftOption', 'mutation');
    },
    createAssets(variables: CreateAssetsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateAssetsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateAssetsMutation>(CreateAssetsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createAssets', 'mutation');
    },
    publicSeller(variables: PublicSellerQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PublicSellerQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PublicSellerQuery>(PublicSellerDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'publicSeller', 'query');
    },
    login(variables: LoginMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<LoginMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<LoginMutation>(LoginDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'login', 'mutation');
    },
    me(variables?: MeQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<MeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<MeQuery>(MeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'me', 'query');
    },
    activeAdministrator(variables?: ActiveAdministratorQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ActiveAdministratorQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ActiveAdministratorQuery>(ActiveAdministratorDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'activeAdministrator', 'query');
    },
    getPointTransactionHistories(variables?: GetPointTransactionHistoriesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetPointTransactionHistoriesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetPointTransactionHistoriesQuery>(GetPointTransactionHistoriesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getPointTransactionHistories', 'query');
    },
    GetRedemptionHistories(variables?: GetRedemptionHistoriesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetRedemptionHistoriesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetRedemptionHistoriesQuery>(GetRedemptionHistoriesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetRedemptionHistories', 'query');
    },
    ManualAdjustPoints(variables: ManualAdjustPointsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ManualAdjustPointsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ManualAdjustPointsMutation>(ManualAdjustPointsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ManualAdjustPoints', 'mutation');
    },
    MembershipTier(variables: MembershipTierQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<MembershipTierQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<MembershipTierQuery>(MembershipTierDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'MembershipTier', 'query');
    },
    CreateMembershipTier(variables: CreateMembershipTierMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateMembershipTierMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateMembershipTierMutation>(CreateMembershipTierDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateMembershipTier', 'mutation');
    },
    DeleteMembershipTier(variables?: DeleteMembershipTierMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteMembershipTierMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteMembershipTierMutation>(DeleteMembershipTierDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteMembershipTier', 'mutation');
    },
    UpdateMembershipTier(variables: UpdateMembershipTierMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateMembershipTierMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateMembershipTierMutation>(UpdateMembershipTierDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateMembershipTier', 'mutation');
    },
    getPromoCodes(variables?: GetPromoCodesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetPromoCodesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetPromoCodesQuery>(GetPromoCodesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getPromoCodes', 'query');
    },
    getRewards(variables?: GetRewardsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetRewardsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetRewardsQuery>(GetRewardsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getRewards', 'query');
    },
    Reward(variables: RewardQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RewardQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<RewardQuery>(RewardDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Reward', 'query');
    },
    PromoCode(variables: PromoCodeQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PromoCodeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PromoCodeQuery>(PromoCodeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'PromoCode', 'query');
    },
    CreateReward(variables: CreateRewardMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateRewardMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateRewardMutation>(CreateRewardDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateReward', 'mutation');
    },
    DeleteReward(variables?: DeleteRewardMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteRewardMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteRewardMutation>(DeleteRewardDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteReward', 'mutation');
    },
    CreatePromoCode(variables: CreatePromoCodeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreatePromoCodeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreatePromoCodeMutation>(CreatePromoCodeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreatePromoCode', 'mutation');
    },
    DeletePromoCode(variables: DeletePromoCodeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeletePromoCodeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeletePromoCodeMutation>(DeletePromoCodeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeletePromoCode', 'mutation');
    },
    UpdateReward(variables: UpdateRewardMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateRewardMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateRewardMutation>(UpdateRewardDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateReward', 'mutation');
    },
    UpdatePromoCode(variables: UpdatePromoCodeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdatePromoCodeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdatePromoCodeMutation>(UpdatePromoCodeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdatePromoCode', 'mutation');
    },
    redeemForCustomer(variables: RedeemForCustomerMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RedeemForCustomerMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RedeemForCustomerMutation>(RedeemForCustomerDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'redeemForCustomer', 'mutation');
    },
    RedeemForMultipleCustomer(variables: RedeemForMultipleCustomerMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RedeemForMultipleCustomerMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RedeemForMultipleCustomerMutation>(RedeemForMultipleCustomerDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RedeemForMultipleCustomer', 'mutation');
    },
    updatePointSetting(variables: UpdatePointSettingMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdatePointSettingMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdatePointSettingMutation>(UpdatePointSettingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updatePointSetting', 'mutation');
    },
    getPointSettings(variables?: GetPointSettingsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetPointSettingsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetPointSettingsQuery>(GetPointSettingsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getPointSettings', 'query');
    },
    getMembership(variables?: GetMembershipQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetMembershipQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetMembershipQuery>(GetMembershipDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getMembership', 'query');
    },
    getMembershipTiers(variables?: GetMembershipTiersQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetMembershipTiersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetMembershipTiersQuery>(GetMembershipTiersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getMembershipTiers', 'query');
    },
    getModifierGroups(variables?: GetModifierGroupsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetModifierGroupsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetModifierGroupsQuery>(GetModifierGroupsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getModifierGroups', 'query');
    },
    assignModifierGroupsToProduct(variables: AssignModifierGroupsToProductMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AssignModifierGroupsToProductMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AssignModifierGroupsToProductMutation>(AssignModifierGroupsToProductDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'assignModifierGroupsToProduct', 'mutation');
    },
    getAdminSubscriberProfile(variables?: GetAdminSubscriberProfileQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetAdminSubscriberProfileQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetAdminSubscriberProfileQuery>(GetAdminSubscriberProfileDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAdminSubscriberProfile', 'query');
    },
    getOutlets(variables?: GetOutletsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetOutletsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetOutletsQuery>(GetOutletsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getOutlets', 'query');
    },
    GetOutletFulfillments(variables?: GetOutletFulfillmentsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetOutletFulfillmentsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetOutletFulfillmentsQuery>(GetOutletFulfillmentsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetOutletFulfillments', 'query');
    },
    CreateOutlet(variables: CreateOutletMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateOutletMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateOutletMutation>(CreateOutletDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateOutlet', 'mutation');
    },
    UpdateOutlet(variables: UpdateOutletMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateOutletMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateOutletMutation>(UpdateOutletDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateOutlet', 'mutation');
    },
    UpdateOutletFulfillments(variables: UpdateOutletFulfillmentsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateOutletFulfillmentsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateOutletFulfillmentsMutation>(UpdateOutletFulfillmentsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateOutletFulfillments', 'mutation');
    },
    createProduct(variables: CreateProductMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateProductMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateProductMutation>(CreateProductDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createProduct', 'mutation');
    },
    updateProduct(variables: UpdateProductMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateProductMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateProductMutation>(UpdateProductDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateProduct', 'mutation');
    },
    createProductVariants(variables: CreateProductVariantsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateProductVariantsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateProductVariantsMutation>(CreateProductVariantsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createProductVariants', 'mutation');
    },
    updateProductVariants(variables: UpdateProductVariantsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateProductVariantsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateProductVariantsMutation>(UpdateProductVariantsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateProductVariants', 'mutation');
    },
    deleteProductVariants(variables: DeleteProductVariantsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteProductVariantsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteProductVariantsMutation>(DeleteProductVariantsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteProductVariants', 'mutation');
    },
    createProductOptionGroup(variables: CreateProductOptionGroupMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateProductOptionGroupMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateProductOptionGroupMutation>(CreateProductOptionGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createProductOptionGroup', 'mutation');
    },
    createProductOption(variables: CreateProductOptionMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateProductOptionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateProductOptionMutation>(CreateProductOptionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createProductOption', 'mutation');
    },
    addOptionGroupToProduct(variables: AddOptionGroupToProductMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddOptionGroupToProductMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddOptionGroupToProductMutation>(AddOptionGroupToProductDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'addOptionGroupToProduct', 'mutation');
    },
    removeOptionGroupFromProduct(variables: RemoveOptionGroupFromProductMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RemoveOptionGroupFromProductMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RemoveOptionGroupFromProductMutation>(RemoveOptionGroupFromProductDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'removeOptionGroupFromProduct', 'mutation');
    },
    updateProductOptionGroup(variables: UpdateProductOptionGroupMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateProductOptionGroupMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateProductOptionGroupMutation>(UpdateProductOptionGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateProductOptionGroup', 'mutation');
    },
    deleteProductOption(variables: DeleteProductOptionMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteProductOptionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteProductOptionMutation>(DeleteProductOptionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteProductOption', 'mutation');
    },
    getProducts(variables?: GetProductsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetProductsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetProductsQuery>(GetProductsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getProducts', 'query');
    },
    getProductVariants(variables?: GetProductVariantsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetProductVariantsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetProductVariantsQuery>(GetProductVariantsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getProductVariants', 'query');
    },
    createCollectionWithFacet(variables: CreateCollectionWithFacetMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateCollectionWithFacetMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateCollectionWithFacetMutation>(CreateCollectionWithFacetDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createCollectionWithFacet', 'mutation');
    },
    getCollections(variables?: GetCollectionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetCollectionsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetCollectionsQuery>(GetCollectionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCollections', 'query');
    },
    Collection(variables?: CollectionQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CollectionQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CollectionQuery>(CollectionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Collection', 'query');
    },
    updateCollectionWithFacet(variables: UpdateCollectionWithFacetMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateCollectionWithFacetMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateCollectionWithFacetMutation>(UpdateCollectionWithFacetDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateCollectionWithFacet', 'mutation');
    },
    MoveCollection(variables: MoveCollectionMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<MoveCollectionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<MoveCollectionMutation>(MoveCollectionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'MoveCollection', 'mutation');
    },
    GetShippingMethods(variables?: GetShippingMethodsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetShippingMethodsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetShippingMethodsQuery>(GetShippingMethodsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetShippingMethods', 'query');
    },
    GetFulfillmentChannels(variables?: GetFulfillmentChannelsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetFulfillmentChannelsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetFulfillmentChannelsQuery>(GetFulfillmentChannelsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetFulfillmentChannels', 'query');
    },
    getAssigningFacetCollection(variables?: GetAssigningFacetCollectionQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetAssigningFacetCollectionQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetAssigningFacetCollectionQuery>(GetAssigningFacetCollectionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAssigningFacetCollection', 'query');
    },
    assignModifiersToProduct(variables: AssignModifiersToProductMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AssignModifiersToProductMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AssignModifiersToProductMutation>(AssignModifiersToProductDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'assignModifiersToProduct', 'mutation');
    },
    updateReservationStatus(variables: UpdateReservationStatusMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateReservationStatusMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateReservationStatusMutation>(UpdateReservationStatusDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateReservationStatus', 'mutation');
    },
    CheckConflictingPostcodes(variables: CheckConflictingPostcodesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CheckConflictingPostcodesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CheckConflictingPostcodesQuery>(CheckConflictingPostcodesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CheckConflictingPostcodes', 'query');
    },
    PaymentMethods(variables?: PaymentMethodsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PaymentMethodsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PaymentMethodsQuery>(PaymentMethodsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'PaymentMethods', 'query');
    },
    getValidPostCodes(variables?: GetValidPostCodesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetValidPostCodesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetValidPostCodesQuery>(GetValidPostCodesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getValidPostCodes', 'query');
    },
    CreateShippingTiers(variables: CreateShippingTiersMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateShippingTiersMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateShippingTiersMutation>(CreateShippingTiersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateShippingTiers', 'mutation');
    },
    AssignShippingTierToShippingMethod(variables: AssignShippingTierToShippingMethodMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AssignShippingTierToShippingMethodMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AssignShippingTierToShippingMethodMutation>(AssignShippingTierToShippingMethodDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AssignShippingTierToShippingMethod', 'mutation');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;