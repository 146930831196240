import React, { useCallback, useState, useEffect } from 'react';
import { PublicSeller, getSdk } from '~/api';
import { GQLClient } from '~/config/gql-client';
import { SellerContext } from './context';
import { useTranslate } from '@refinedev/core';
import { currentSellerId } from '~/config/auth-provider';

export const SellerContextProvider = React.memo((props: any) => {
  const { children } = props;

  // ===================== HOOKS
  const translate = useTranslate();

  // ===================== STATES
  const [loading, setLoading] = useState(true);
  const [seller, setSeller] = useState<PublicSeller | null>(null);
  const [error, setError] = useState<string | undefined>();

  // ===================== VARIABLES
  const client = GQLClient.getInstance();

  // ===================== EVENTS
  const fetchSeller = useCallback(() => {
    setLoading(true);
    const sellerId = localStorage.getItem(currentSellerId);
    if (!sellerId) {
      console.error(`No seller found`);
      setLoading(false);
      return;
    }

    setLoading(true);
    getSdk(client)
      .publicSeller({ id: sellerId })
      .then((data) => {
        setSeller(data?.publicSeller as PublicSeller);
      })
      .catch((err) => {
        console.error(err);
        setError(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [client]);

  /**
   * Note:
   * This might be null, should use `loading` together
   * if it's initial fetch
   */
  const getCurrentSeller = useCallback(() => {
    if (!seller) fetchSeller();
    return seller;
  }, [seller]);

  const getResourceConfig = () => {
    return seller?.resourceConfig || [];
  };

  // ===================== EFFECTS
  useEffect(() => {
    if (!seller) {
      fetchSeller();
    }
  }, [fetchSeller, seller]);

  // ===================== VIEWS
  return (
    <SellerContext.Provider
      value={{
        loading,
        error,
        getCurrentSeller,
        getResourceConfig,
        refetchSeller: fetchSeller,
      }}
    >
      {children}
    </SellerContext.Provider>
  );
});
