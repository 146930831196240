import { useApiUrl, useCustomMutation, useTranslate } from '@refinedev/core';
import { MutateOptions } from '@tanstack/react-query';
import { UpdateFacetValueInput } from '~/api';

export const useModifierGroup = () => {
  // ======================= HOOKS
  const apiUrl = useApiUrl();
  const { mutate, isLoading: mutating } = useCustomMutation();
  const t = useTranslate();

  // ======================= EVENTS
  const updateModifierGroup = (
    input: UpdateFacetValueInput[],
    options?: MutateOptions<any, any, any, any>
  ) => {
    mutate(
      {
        method: 'post',
        url: apiUrl,
        values: {},
        meta: {
          operation: 'updateFacetValues',
          fields: ['id'],
          variables: {
            input: {
              value: input,
              type: '[UpdateFacetValueInput!]!',
            },
          },
        },
        errorNotification: {
          message: t('modifierGroup.edit.failed', {}, 'Failed to update modifier group info'),
          type: 'error',
        },
        successNotification: {
          message: t('modifierGroup.edit.success', {}, 'Updated modifier group info'),
          type: 'success',
        },
      },
      options
    );
  };

  return { updateModifierGroup, mutating };
};
