import React, { useEffect, useState } from 'react';
import { paddingCard } from '~/components';
import { GQLClient } from '~/config/gql-client';
import { PointSetting, PointSettingType, getSdk } from '~/api';
import { Props } from './props';
import { numeralThousandFormat } from '~/config/helper';
import { useTranslate } from '@refinedev/core';

export const PointSettingsView: React.FC<Props> = (props) => {
  const { pointsData } = props;
  const spendingData = pointsData?.find((subItem) => subItem?.type === PointSettingType?.Spending);
  const reviewData = pointsData?.find((subItem) => subItem?.type === PointSettingType?.Review);
  const t = useTranslate();

  const renderTitleText = (title: string) => {
    return <p style={{ flex: 1 }} className='text-gray-500'>{title}</p>;
  };
  const renderTextBody = ({ title, value }: { title: string; value: string }) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {renderTitleText(title)}
        <p style={{ flex: 6 }}>{value}</p>
      </div>
    );
  };

  return (
    <div style={{ ...paddingCard }}>
      {renderTextBody({
        title: t('membershipTiers.pointSettings.spending'),
        value: t('membershipTiers.pointSettings.spendingAmount', {
          amount: numeralThousandFormat(spendingData?.unitValue || 0, true),
          points: numeralThousandFormat(spendingData?.awardPointPerUnit || 0),
        }),
      })}
      <div className="mt-3">
        {renderTextBody({
          title: t('membershipTiers.pointSettings.review'),
          value: t('membershipTiers.pointSettings.reviewAmount', {
            posts: parseInt(reviewData?.unitValue || '0'),
            points: numeralThousandFormat(reviewData?.awardPointPerUnit || 0),
          }),
        })}
      </div>
    </div>
  );
};
