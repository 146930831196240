import { useNotification, useTranslate } from '@refinedev/core';
import { IconButton } from '@scalingworks/react-admin-ui';
import React from 'react';
import { Props } from './props';
import { FiCopy } from 'react-icons/fi';

export const CopyButton: React.FC<Props> = (props) => {
  const { textToCopy, iconClassName = '' } = props;

  // ================== HOOKS
  const t = useTranslate();
  const notification = useNotification();

  // ================== EVENTS
  const onCopy = () => {
    if (!textToCopy) return;

    navigator.clipboard.writeText(textToCopy).then(() =>
      notification?.open?.({
        message: t('common.copy', undefined, 'Copied to clipboard'),
        type: 'success',
      })
    );
  };

  // ================== VIEWS
  if (!textToCopy) return null;
  return (
    <IconButton onClick={onCopy}>
      <FiCopy className={`!text-primary-500 ${iconClassName}`} />
    </IconButton>
  );
};
