import React, { useState } from 'react';
import { Props } from './props';
import { useApiUrl, useCustom, useTranslate } from '@refinedev/core';
import { IconButton, Skeleton } from '@scalingworks/react-admin-ui';
import { FiEdit3, FiPlusCircle } from 'react-icons/fi';
import { Membership } from '~/api';
import { getMembershipFields } from '~/config/gql-metadata';
import { format } from 'date-fns';
import { AdjustLoyaltyPoints, UpdateLoyaltyValidity, UpdateTier } from '~/components';
import { dateFormatter } from '~/resources/helpers';
import { ValidUntilDateFormat } from '~/config/constant';

export const MembershipBar: React.FC<Props> = (props) => {
  const { userId, loading, onMutationComplete } = props || {};

  // =================== STATES
  const [openUpdateDate, setOpenUpdateDate] = useState(false);
  const [openAdjust, setOpenAdjust] = useState(false);
  const [openUpdateTier, setOpenUpdateTier] = useState(false);

  // =================== HOOKS
  const t = useTranslate();
  const apiUrl = useApiUrl();
  const {
    data,
    isLoading: loadingMembership,
    refetch,
  } = useCustom<Membership>({
    url: apiUrl,
    method: 'get',
    metaData: {
      fields: getMembershipFields,
      operation: 'getMembership',
      variables: {
        userId: {
          value: userId,
          type: 'String!',
        },
      },
    },
  });
  const { pointBalance, tierProgress, pointValidUntil } = data?.data || {};
  const { isHighestTier } = tierProgress || {};
  const currentTierId = tierProgress?.currentTier.id;
  const validityDate = pointValidUntil ? new Date(pointValidUntil) : undefined;

  // =================== VIEWS
  const Row: React.FC<any> = (props) => {
    return (
      <div className="flex flex-row w-full items-center justify-between">{props?.children}</div>
    );
  };
  const Col: React.FC<any> = (props) => {
    return (
      <div className="flex flex-col w-full p-4 px-10 border-b-2 2xl:border-r-2 2xl:border-b-0 2xl:px-4">
        {props?.children}
      </div>
    );
  };
  const Value: React.FC<any> = (props) => {
    return <h3 className="text-white text-xl font-semibold">{props?.children}</h3>;
  };
  const Loading: React.FC = () => <Skeleton heightClass="h-5" width="wide" />;

  return (
    <section className="bg-smoke-900 rounded-lg text-smoke-100 flex flex-col items-center justify-center 2xl:flex-row 2xl:space-x-2">
      {openUpdateTier && (
        <UpdateTier
          userId={userId}
          open
          setOpen={setOpenUpdateTier}
          originalTierId={currentTierId}
          onCompleted={() => {
            refetch();
            onMutationComplete?.();
          }}
        />
      )}

      {openAdjust && (
        <AdjustLoyaltyPoints
          userId={userId}
          open
          setOpen={setOpenAdjust}
          onCompleted={() => {
            refetch();
            onMutationComplete?.();
          }}
        />
      )}

      {openUpdateDate && (
        <UpdateLoyaltyValidity
          userId={userId}
          open
          setOpen={setOpenUpdateDate}
          originalDate={validityDate}
          onCompleted={() => {
            refetch();
            onMutationComplete?.();
          }}
        />
      )}
      <Col>
        <Row>
          <h3>{t('customer.details.membership.membershipTier', undefined, 'Membership tier')}</h3>
          <IconButton onClick={() => setOpenUpdateTier(true)}>
            <FiEdit3 className="!text-success-300" size={30} />
          </IconButton>
        </Row>
        {loadingMembership || loading ? (
          <Loading />
        ) : (
          <Row>
            <Value>{tierProgress?.currentTier?.name || '-'}</Value>
            {isHighestTier ? (
              <span>{t('customer.details.membership.highest', '(Highest Tier)')}</span>
            ) : tierProgress?.nextTier?.name ? (
              <span className="text-smoke-300">
                {tierProgress?.pointToNextTier?.toLocaleString()} pts to{' '}
                {tierProgress?.nextTier?.name}
              </span>
            ) : null}
          </Row>
        )}
      </Col>

      {/* Point Balance */}
      <Col>
        <Row>
          <h3>{t('customer.details.membership.loyaltyPoints', undefined, 'Loyalty points')}</h3>
          <IconButton onClick={() => setOpenAdjust(true)}>
            <FiPlusCircle className="!text-success-300" size={30} />
          </IconButton>
        </Row>
        {loadingMembership || loading ? (
          <Loading />
        ) : (
          <Value>{pointBalance?.toLocaleString() || 0} PTS</Value>
        )}
      </Col>

      {/* Valid Until */}
      <Col>
        <Row>
          <h3>{t('customer.details.membership.validUntil', undefined, 'Valid until')}</h3>
          <IconButton onClick={() => setOpenUpdateDate(true)}>
            <FiEdit3 className="!text-success-300" size={30} />
          </IconButton>
        </Row>
        {loadingMembership || loading ? (
          <Loading />
        ) : (
          <Value>{dateFormatter(validityDate, ValidUntilDateFormat)}</Value>
        )}
      </Col>
    </section>
  );
};
