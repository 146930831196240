import React, { useEffect, useRef, useState } from 'react';
import { useForm } from '@refinedev/react-hook-form';
import {
  useApiUrl,
  useCustomMutation,
  useNavigation,
  useOne,
  useTranslate,
  useUpdate,
} from '@refinedev/core';

import {
  BlastUserModal,
  Loading,
  ModalWrapper,
  NotificationForm,
  ShowPageWrapper,
  SomethingWentWrong,
  TriggerConfirmModal,
} from '~/components';
import { resourceNames } from '~/resources/resource-names';
import {
  Button,
  Card,
  Checkbox,
  DateTimeField,
  IconButton,
  Tag,
} from '@scalingworks/react-admin-ui';
import { Props } from './props';
import { notificationBasicFields } from '../notification-blast-resource';
import { ResourceField, createResourceListingPage } from '@scalingworks/refine-react-admin';
import {
  BlastableRole,
  CreateBlastQueueInput,
  NotificationBlast,
  NotificationBlastUser,
  UpdateNotificationBlastInput,
  BlastableReceiverType as ReceiverType,
  AssignBlastableUserInput,
  NotificationBlastQueue,
  DeletionResponse,
} from '~/api';
import { AiOutlinePlus, AiOutlineUserAdd } from 'react-icons/ai';
import isEmpty from 'lodash/isEmpty';
import { dateFormatter, formatFullName, toCamelCaseWord } from '~/resources/helpers';
import { FiCalendar } from 'react-icons/fi';
import { HiOutlineTrash } from 'react-icons/hi';
import { FullDateTimeFormat } from '~/config/constant';
import difference from 'lodash/difference';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

export const NotificationShowPage: React.FC<Props> = (props) => {
  const { queryResult } = props;

  const { data, isLoading } = queryResult;
  const {
    id,
    name: notificationName,
    subject,
    message,
    type,
    blastableChannels,
    link,
    queues,
    blastableUsers,
  } = data?.data || {};

  if (isLoading) return <Loading />;

  // ======================= HOOKS
  const t = useTranslate();
  const apiUrl = useApiUrl();
  const form = useForm();
  const navigateTo = useNavigation();

  // ======================= API
  // NOTE: query again with more detailed fragment
  // Avoid large fragment in listing (table)
  const {
    data: details,
    isLoading: loadingDetails,
    refetch: refetchDetails,
  } = useOne<NotificationBlast>({
    resource: resourceNames.notificationBlast,
    id,
    meta: {
      fields: [
        ...notificationBasicFields,
        {
          blastableUsers: ['id', 'userId', 'userType'],
          queues: ['id', 'isSent', 'trigger'],
        },
      ] as ResourceField<NotificationBlast>[],
    },
    queryOptions: {
      enabled: !!id,
    },
  });
  const { mutate: updateInfo, isLoading: updating } = useUpdate();
  const { mutate: patchQueue, isLoading: patchingQueue } = useCustomMutation();
  const { mutate: patchUser, isLoading: patchingUser } = useCustomMutation();

  // ======================= STATES
  const [openUserSelect, setOpenUserSelect] = useState(false);
  const [openDate, setOpenDate] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(new Date());
  const [deleteUserIds, setDeleteUserIds] = useState<string[]>([]);
  const [deleteQueueId, setDeleteQueueId] = useState<string>('');
  const userRefetchRef = useRef<() => void>(() => null);
  const queueRefetchRef = useRef<() => void>(() => null);

  // ======================= VARIABLES
  const noAudience = isEmpty(details?.data?.blastableUsers || []);
  const noQueue = isEmpty(details?.data?.queues || []);
  const selectedPastDate = dayjs().isAfter(dayjs(selectedDate));
  const formReceiverType = form.watch('receiverType') as ReceiverType;
  const sendToAll = formReceiverType === ReceiverType.All;
  const initAudienceUserIds = details?.data?.blastableUsers?.map((user) => user.userId) || [];

  // ======================= EVENTS
  const onUpdate = (data: any) => {
    const { name, subject, type, message, link, blastableChannels, receiverType } = data;

    updateInfo(
      {
        id: id!,
        resource: resourceNames.notificationBlast,
        values: {
          id,
          name,
          subject,
          type,
          link,
          message,
          blastableChannels,
          receiverType,
        } as UpdateNotificationBlastInput,
      },
      {
        onSuccess: () => navigateTo.list(resourceNames.notificationBlast),
      }
    );
  };

  const assignUser = (userIds: string[]) => {
    patchUser(
      {
        method: 'post',
        url: apiUrl,
        values: {},
        meta: {
          fields: ['id'],
          operation: 'assignUserToNotificationBlast',
          variables: {
            notificationBlastId: {
              value: id!,
              type: 'ID!',
            },
            users: {
              value: userIds?.map((userId) => ({
                userId,
                userType: BlastableRole.Customer,
              })) as AssignBlastableUserInput[],
              type: `[AssignBlastableUserInput!]!`,
            },
          },
        },
        successNotification: {
          message: t('notificationBlasts.edit.notificationBlasts'),
          type: 'success',
        },
        errorNotification: {
          message: t('notificationBlasts.edit.audienceFailed'),
          type: 'error',
        },
      },
      {
        onSuccess: () => {
          refetchDetails();
          userRefetchRef.current();
          setOpenUserSelect(false);
        },
      }
    );
  };

  const onAddUsers = (userIds: string[]) => {
    assignUser(userIds);
  };

  const onRemoveUser = () => {
    const clean = difference(initAudienceUserIds, deleteUserIds);
    assignUser(clean);
  };

  const onRemoveQueue = () => {
    patchQueue(
      {
        method: 'post',
        url: apiUrl,
        values: {},
        meta: {
          fields: ['message'] as ResourceField<DeletionResponse>[],
          operation: 'deleteNotificationBlastQueue',
          variables: {
            id: {
              value: deleteQueueId,
              type: 'ID!',
            },
          },
        },
        successNotification: {
          message: t('notificationBlasts.edit.dateSuccess'),
          type: 'success',
        },
        errorNotification: {
          message: t('notificationBlasts.edit.dateFailed'),
          type: 'success',
        },
      },
      {
        onSuccess: () => {
          refetchDetails();
          queueRefetchRef.current();
        },
      }
    );
  };

  const onAddDate = () => {
    patchQueue(
      {
        method: 'post',
        url: apiUrl,
        values: {},
        meta: {
          fields: ['id'],
          operation: 'createNotificationBlastQueue',
          variables: {
            input: {
              value: {
                trigger: selectedDate?.toISOString(),
                notificationBlastId: id,
              } as CreateBlastQueueInput,
              type: `CreateBlastQueueInput!`,
            },
          },
        },
        successNotification: {
          message: t('notificationBlasts.edit.dateAdded'),
          type: 'success',
        },
        errorNotification: {
          message: t('notificationBlasts.edit.dateDidNotAdd'),
          type: 'error',
        },
      },
      {
        onSuccess: () => {
          refetchDetails();
          queueRefetchRef.current();
          setOpenDate(false);
        },
      }
    );
  };

  // ======================= EFFECTS
  // Extra Effect to ensure everything is sync
  useEffect(() => {
    queueRefetchRef?.current();
  }, [patchingQueue]);

  useEffect(() => {
    queueRefetchRef?.current();
  }, [patchingUser]);

  // ======================= VIEWS
  const audienceListing = createResourceListingPage({
    showTitle: false,
    resourceName: 'notificationBlastUsers',
    allowCreate: false,
    allowSearch: true,
    searchConfig: {
      placeholder: ({ t }) =>
        t('notificationBlasts.placeholder.searchCustomer', {
          fallback: 'Search by Customer Name',
          ns: 'common',
        }),
    },
    customHelmet: 'Notification',
    fields: [
      'id',
      'userId',
      {
        administrator: ['id', 'firstName', 'lastName', 'emailAddress'],
        customer: ['id', 'firstName', 'lastName', 'emailAddress', 'phoneNumber'],
      },
    ] as ResourceField<NotificationBlastUser>[],
    tabs: {
      options: [
        {
          filterValue: { field: 'notificationBlastId', operator: 'eq', value: id! },
          label: () => 'ALL',
        },
      ],
    },
    columns: ({ refetchData, t }) => [
      {
        id: 'name',
        header: t('notificationBlasts.column.name', { fallback: 'Name', ns: 'common' }),
        cell: (data) => {
          const { id, customer, administrator } = data.row.original;
          const index = data.cell.row.index;
          const customerName = formatFullName(customer?.firstName, customer?.lastName);
          const adminName = formatFullName(administrator?.firstName, administrator?.lastName);

          // a bit hacky way to refetchData
          // only process on first render first index
          useEffect(() => {
            if (index === 0) {
              userRefetchRef.current = refetchData;
            }
          }, []);

          return (
            <div className="flex flex-row items-center space-x-2">
              <span>{customerName || adminName}</span>
            </div>
          );
        },
      },
      {
        id: 'phoneNum',
        header: t('notificationBlasts.column.contact', { fallback: 'Contact No.', ns: 'common' }),
        cell: (data) => {
          const { id, customer } = data.row.original;
          const { phoneNumber } = customer || {};

          return (
            <div>
              <span>{phoneNumber}</span>
            </div>
          );
        },
      },
      {
        id: 'actions',
        header: () => <div />,
        enableSorting: false,
        cell: (data) => {
          const { userId } = data.row.original;

          return (
            <IconButton onClick={() => setDeleteUserIds([userId])}>
              <HiOutlineTrash size={25} className="text-error-300" />
            </IconButton>
          );
        },
      },
    ],
  })();
  const dateListing = createResourceListingPage({
    showTitle: false,
    resourceName: 'notificationBlastQueues',
    allowCreate: false,
    allowSearch: false,
    customHelmet: 'Notification',
    fields: ['id', 'isSent', 'trigger'] as ResourceField<NotificationBlastQueue>[],
    tabs: {
      options: [
        {
          filterValue: { field: 'notificationBlastId', operator: 'eq', value: id! },
          label: () => 'ALL',
        },
      ],
    },
    defaultSorter: [
      { field: 'trigger', order: 'desc' },
      { field: 'isSent', order: 'asc' },
    ],
    columns: ({ refetchData, t }) => [
      {
        id: 'trigger',
        header: t('notificationBlasts.queue.column.date', { fallback: 'Date', ns: 'common' }),
        cell: (data) => {
          const { id, trigger } = data.row.original;
          const index = data.cell.row.index;

          // a bit hacky way to refetchData
          // only process on first render first index
          useEffect(() => {
            if (index === 0) {
              queueRefetchRef.current = refetchData;
            }
          }, []);

          return (
            <div>
              <span>{dateFormatter(trigger, FullDateTimeFormat)}</span>
            </div>
          );
        },
      },
      {
        id: 'timeLeft',
        header: t('notificationBlasts.queue.column.timeLeft', {
          fallback: 'Time Left',
          ns: 'common',
        }),
        cell: (data) => {
          const { id, trigger } = data.row.original;

          if (!trigger) return;
          const now = dayjs();
          const target = dayjs(trigger);
          if (now.isAfter(target)) {
            return <></>;
          }
          const duration = dayjs.duration(target.diff(now));

          const daysLeft = duration.days();
          const hoursLeft = duration.hours();
          const minsLeft = duration.minutes();

          const dayDisplay = !!daysLeft ? `${daysLeft} days ` : '';
          const hourDisplay = !!hoursLeft ? `${hoursLeft} hours ` : '';
          const minDisplay = !!minsLeft ? `${minsLeft} mins ` : '';

          return (
            <div>
              <span>
                {dayDisplay} {hourDisplay} {minDisplay}
              </span>
            </div>
          );
        },
      },
      {
        id: 'isSent',
        header: t('notificationBlasts.queue.column.sent', { fallback: 'Sent', ns: 'common' }),
        cell: (data) => {
          const { id, isSent } = data.row.original;

          return (
            <div>
              <Checkbox checked={isSent} disabled />
            </div>
          );
        },
      },
      {
        id: 'actions',
        header: () => <div />,
        enableSorting: false,
        cell: (data) => {
          const { id: queueId, isSent } = data.row.original;

          if (isSent) return null;
          return (
            <IconButton onClick={() => setDeleteQueueId(queueId)}>
              <HiOutlineTrash size={25} className="text-error-300" />
            </IconButton>
          );
        },
      },
    ],
  })();
  if (!isLoading && !id) return <SomethingWentWrong />;
  return (
    <main className="overflow-y-scroll">
      <TriggerConfirmModal
        renderTrigger={() => <></>}
        visible={!!deleteUserIds.length}
        onOpenChange={(open) => {
          if (!open) setDeleteUserIds([]);
        }}
        onPressConfirm={onRemoveUser}
        description={
          <span>
            {t('warnings.remove', {
              determiner: t(`common.${deleteUserIds.length > 1 ? 'these' : 'this'}`).toLowerCase(),
              quantifier: t(
                `customer.name.${deleteUserIds.length > 1 ? 'customers' : 'customer'}`
              ).toLowerCase(),
            })}
            <span className="font-semibold"> {notificationName}</span>{' '}
            {t('notificationBlasts.name').toLowerCase()}?
          </span>
        }
      />
      <TriggerConfirmModal
        renderTrigger={() => <></>}
        visible={!!deleteQueueId}
        onOpenChange={(open) => {
          if (!open) setDeleteQueueId('');
        }}
        onPressConfirm={onRemoveQueue}
        description={
          <span>
            {t('warnings.remove', {
              determiner: t(`common.${deleteQueueId.length > 1 ? 'these' : 'this'}`).toLowerCase(),
              quantifier: t(`common.date`).toLowerCase(),
            })}
            <span className="font-semibold"> {notificationName}</span>{' '}
            {t('notificationBlasts.name').toLowerCase()}?
          </span>
        }
      />
      {openDate && (
        <ModalWrapper
          open
          setOpen={setOpenDate}
          title={t('notificationBlasts.send.date')}
          disabledConfirm={selectedPastDate}
          onConfirm={onAddDate}
          loading={patchingQueue}
        >
          <section className="p-4">
            <DateTimeField
              label={t('notificationBlasts.send.sendOn')}
              value={selectedDate}
              onValue={(date) => setSelectedDate(date)}
              min={new Date()}
            />
          </section>
        </ModalWrapper>
      )}
      {openUserSelect && (
        <BlastUserModal
          open
          setOpen={setOpenUserSelect}
          onConfirm={(userIds) => onAddUsers(userIds)}
          loading={patchingUser}
          initialUserIds={initAudienceUserIds}
        />
      )}
      <ShowPageWrapper
        resourceName={resourceNames.notificationBlast}
        loading={isLoading}
        title={notificationName}
        extra={
          <Button
            loading={updating}
            onClick={form.handleSubmit(onUpdate)}
            variant="solid"
            size="md"
            className="mx-5"
          >
            {t('actions.update', {}, 'Update')}
          </Button>
        }
      >
        <section className="flex flex-col space-y-8">
          {/* General Info Form */}
          <NotificationForm actionType="update" initialValues={data?.data} form={form} />

          {!sendToAll && (
            <Card>
              <Card.Header bordered>
                <div className="flex flex-row items-center justify-between">
                  <div className="flex flex-row items-center space-x-2 ">
                    <h3 className="font-bold">{t('notificationBlasts.send.targetAudience')}</h3>
                    <Tag color={formReceiverType === ReceiverType.Exclude ? 'error' : 'success'}>
                      {t(`notificationBlasts.audienceType.${formReceiverType?.toLowerCase()}`)}
                    </Tag>
                  </div>
                  {!noAudience && (
                    <Button
                      onClick={() => setOpenUserSelect(true)}
                      size="sm"
                      className="!px-4 border-primary-500"
                    >
                      <div className="flex flex-row items-center justify-between space-x-2 text-primary-500">
                        <AiOutlinePlus className="!text-primary-500" />
                        <span>{t('notificationBlasts.audienceType.assign')}</span>
                      </div>
                    </Button>
                  )}
                </div>
              </Card.Header>
              <Card.Body>
                {loadingDetails ? (
                  <Loading />
                ) : noAudience ? (
                  <section className="flex flex-col items-center justify-center w-full min-h-full p-20 space-y-6">
                    <div className="flex flex-col items-center justify-center space-y-2">
                      <AiOutlineUserAdd className="!text-primary-500" size={55} />
                      <span className="text-smoke-600">
                        {t('notificationBlasts.empty.targetAudience', { name: notificationName })}
                      </span>
                    </div>
                    <Button className="border-primary-500" onClick={() => setOpenUserSelect(true)}>
                      <div className="flex flex-row items-center justify-between space-x-2 text-primary-500">
                        <AiOutlinePlus className="!text-primary-500" />
                        <span>{t('actions.addCustomers')}</span>
                      </div>
                    </Button>
                  </section>
                ) : (
                  audienceListing
                )}
              </Card.Body>
            </Card>
          )}

          <Card>
            <Card.Header bordered>
              <div className="flex flex-row items-center justify-between">
                <div className="flex flex-row items-center space-x-2 ">
                  <h3 className="font-bold">{t('notificationBlasts.sendDate', 'Send Date')}</h3>
                </div>
                {!noQueue && (
                  <Button
                    onClick={() => setOpenDate(true)}
                    size="sm"
                    className="!px-4 border-primary-500"
                  >
                    <div className="flex flex-row items-center justify-between space-x-2 text-primary-500">
                      <AiOutlinePlus className="!text-primary-500" />
                      <span>{t('actions.addDate')}</span>
                    </div>
                  </Button>
                )}
              </div>
            </Card.Header>
            <Card.Body>
              {loadingDetails || patchingQueue ? (
                <Loading />
              ) : noQueue ? (
                <section className="flex flex-col items-center justify-center w-full min-h-full p-20 space-y-6">
                  <div className="flex flex-col items-center justify-center space-y-2">
                    <FiCalendar className="!text-primary-500" size={55} />
                    <span className="text-smoke-600">
                      {t('notificationBlasts.empty.date', { name: notificationName })}
                    </span>
                  </div>
                  <Button className="border-primary-500" onClick={() => setOpenDate(true)}>
                    <div className="flex flex-row items-center justify-between space-x-2 text-primary-500">
                      <AiOutlinePlus className="!text-primary-500" />
                      <span>{t('actions.addDate', 'Add Date')}</span>
                    </div>
                  </Button>
                </section>
              ) : (
                dateListing
              )}
            </Card.Body>
          </Card>
        </section>
      </ShowPageWrapper>
    </main>
  );
};
