import React, { useEffect, useRef, useState } from 'react';
import { useForm } from '@refinedev/react-hook-form';
import {
  useApiUrl,
  useCreate,
  useCustom,
  useCustomMutation,
  useDelete,
  useNavigation,
  useTranslate,
} from '@refinedev/core';

import {
  Draggable,
  FormBuilder,
  Loading,
  ModalWrapper,
  ModifierGroupForm,
  MultiProductSelection,
  ShowPageWrapper,
  SomethingWentWrong,
  TriggerConfirmModal,
} from '~/components';
import { resourceNames } from '~/resources/resource-names';
import { LanguageCode, Product, ProductList, UpdateFacetValueInput } from '~/api';

import isEmpty from 'lodash/isEmpty';
import { AddItemModal } from '~/resources/order/add-item';
import { Button, Card, IconButton, ImageViewer, Tag } from '@scalingworks/react-admin-ui';
import { ResourceField, createResourceListingPage } from '@scalingworks/refine-react-admin';
import { AiOutlinePlus } from 'react-icons/ai';
import { HiOutlineTrash } from 'react-icons/hi';
import { Props } from './props';
import { FaSitemap } from 'react-icons/fa6';
import { formBuilder } from '~/config/ProductResource';
import { FormInputs } from '../../products/product-resource';
import { parsePrice } from '~/resources/helpers';
import { LinkModifierSelect } from './LinkModifierSelect';
import head from 'lodash/head';
import { FiMenu } from 'react-icons/fi';
import { useModifierGroup } from '../hooks';

export const ModifierGroupShowPage: React.FC<Props> = (props) => {
  const { queryResult } = props;

  const { data, isLoading, refetch: refetchModifierGroup } = queryResult;
  const { id: modifierGroupId, name: modifierGroupName } = data?.data || {};

  // ======================= HOOKS
  const t = useTranslate();
  const apiUrl = useApiUrl();
  const form = useForm();
  const navigateTo = useNavigation();

  // ======================= API
  // const { mutate: updateModifierGroup, isLoading: updating } = useCustomMutation();
  const {
    data: modifiers,
    isLoading: loadingModifiers,
    refetch: refetchModifiers,
  } = useCustom<ProductList>({
    method: 'get',
    url: apiUrl,
    meta: {
      fields: [
        {
          items: [
            'id',
            'name',
            { assets: ['id', 'source'] },
            { variants: ['currencyCode', 'price', 'stockOnHand'] },
          ],
        },
      ] as ResourceField<ProductList>[],
      operation: 'getProductsByModifierGroup',
      variables: {
        id: {
          value: modifierGroupId,
          type: 'ID!',
        },
      },
    },
  });

  // const { data: products, isLoading: loadingProducts } = useCustom<ProductList>({
  //   method: 'get',
  //   url: apiUrl,
  //   meta: {
  //     fields: [{ items: ['id', 'name'] }] as ResourceField<ProductList>[],
  //     operation: 'getMainProductsByModifierId',
  //     variables: {
  //       id: {
  //         value: modifierGroupId,
  //         type: 'ID!',
  //       },
  //     },
  //   },
  // });

  const { mutate: createModifierProd, isLoading: creatingModifier } = useCreate<Product>();
  const { mutate: removeModifierFromGroup, isLoading: removingModifier } = useCustomMutation();
  const { updateModifierGroup, mutating: updating } = useModifierGroup();

  // ======================= STATES
  const [openProduct, setOpenProduct] = useState(false);
  // Modal to let user decide to create new or link from existing
  const [openAddModifier, setOpenAddModifier] = useState(false);
  const [openCreateModifier, setOpenCreateModifier] = useState(false);
  const [removeModifierId, setRemoveModifierId] = useState('');
  const [displayModifiers, setDisplayModifiers] = useState<Product[]>([]);

  // ======================= VARIABLES
  const modifierIds = (modifiers?.data?.items || []).map((mod) => mod.id);
  const noModifiers = isEmpty(modifiers?.data?.items);

  // ======================= EVENTS
  const onUpdateInfo = (data: any) => {
    const { name, minSelect, maxSelect } = data;
    if (!modifierGroupId) return;

    updateModifierGroup(
      [
        {
          id: modifierGroupId,
          translations: [
            {
              languageCode: LanguageCode.En,
              name,
            },
          ],
          customFields: {
            minModifier: +minSelect || null,
            maxModifier: +maxSelect || null,
          },
        },
      ],
      {
        onSuccess: () => {
          refetchModifierGroup();
          navigateTo.list(resourceNames.modifierGroup);
        },
      }
    );
  };

  const onRemoveModifier = () => {
    removeModifierFromGroup(
      {
        method: 'post',
        url: apiUrl,
        values: {},
        meta: {
          operation: 'removeModifiersFromGroup',
          variables: {
            modifierGroupId: {
              value: modifierGroupId,
              type: 'String!',
            },
            modifierIds: {
              value: [removeModifierId],
              type: '[String!]!',
            },
          },
        },
        errorNotification: {
          message: t('modifier.remove.failed', 'Failed to remove modifier'),
          type: 'error',
        },
        successNotification: {
          message: t('modifier.remove.success', 'Modifier removed'),
          type: 'success',
        },
      },
      {
        onSuccess: () => {
          setRemoveModifierId('');
          refetchModifierGroup();
          refetchModifiers();
        },
      }
    );
  };

  const onCreateModifier = (data: any) => {
    createModifierProd(
      {
        resource: resourceNames.product,
        values: {
          ...data,
          facetValueIds: [modifierGroupId],
          customFields: { type: 'MODIFIER' },
          enabled: true,
        } as FormInputs,
        errorNotification: {
          message: t('modifierGroup.addModifier.failed', 'Failed to create modifier'),
          type: 'error',
        },
        successNotification: {
          message: t('modifierGroup.addModifier.success', 'Created modifier'),
          type: 'success',
        },
      },
      {
        onSuccess: () => {
          refetchModifierGroup();
          refetchModifiers();
          setOpenCreateModifier(false);
        },
      }
    );
  };

  // ======================= EFFECTS
  useEffect(() => {
    if (modifiers) {
      setDisplayModifiers(modifiers.data?.items || []);
    }
  }, [modifiers]);

  // ======================= VIEWS

  const modifierListing = () => {
    return (
      <section className="flex flex-col space-y-4 py-4">
        {displayModifiers.map((mod: Product, index) => {
          const firstVariant = head(mod.variants);

          const item = (
            <div className="flex flex-row items-center justify-between cursor-grab">
              <div className="flex flex-row items-center space-x-2">
                <FiMenu size={20} />
                <ImageViewer
                  src={mod?.assets?.[0]?.source}
                  className="!w-14 !h-14 rounded-lg object-cover mr-2"
                  alt="Product"
                />
                <span>{mod.name}</span>
              </div>
              <div className="flex flex-row items-center space-x-2">
                <span>
                  {firstVariant?.currencyCode} {parsePrice(firstVariant?.price)}
                </span>
                <IconButton onClick={() => setRemoveModifierId(mod.id)}>
                  <HiOutlineTrash className="!text-error-300" size={30} />
                </IconButton>
              </div>
            </div>
          );

          return (
            <Draggable
              children={item}
              data={mod}
              index={index}
              moveRow={(from, to, data) => {
                // Do it on frontend here for the optimistic result
                // Then update to the backend
                const arrange = [...displayModifiers];
                const [moved] = arrange.splice(from, 1);
                arrange.splice(to, 0, moved);

                setDisplayModifiers(arrange);

                // Update the new sorting
                updateModifierGroup([
                  {
                    id: modifierGroupId!,
                    customFields: {
                      modifierSorting: arrange.map((mod) => mod.id),
                    },
                  },
                ]);
              }}
            />
          );
        })}
      </section>
    );
  };

  if (isLoading) return <Loading />;
  if (!isLoading && !modifierGroupId) return <SomethingWentWrong />;
  return (
    <main className="overflow-y-scroll">
      <ShowPageWrapper
        resourceName={resourceNames.modifierGroup}
        loading={isLoading}
        title={modifierGroupName}
        extra={
          <Button
            loading={updating}
            onClick={form.handleSubmit(onUpdateInfo)}
            variant="solid"
            size="md"
            className="mx-5"
          >
            {t('actions.update', {}, 'Update')}
          </Button>
        }
      >
        {/* Product Configuration */}
        {openProduct && (
          <ModalWrapper
            open
            setOpen={setOpenProduct}
            title={t('modifierGroup.show.selectProduct', 'Select Product')}
          >
            <section className="flex flex-col space-y-4 py-4">
              <MultiProductSelection
                isField
                fieldLabel={t('modifierGroup', 'Products')}
                className="h-32"
                onSelect={(ids) => console.log('ids', ids)}
              />
            </section>
          </ModalWrapper>
        )}
        {openAddModifier && (
          <ModalWrapper
            open
            setOpen={setOpenAddModifier}
            hideButton
            title={t('modifierGroup.addModifierToGroup', { group: modifierGroupName })}
          >
            <section className="flex flex-col space-y-2 py-4">
              <LinkModifierSelect
                modifierGroupId={modifierGroupId!}
                initialModifierIds={modifierIds}
                onComplete={() => {
                  // Temporary solution to delay refetch,
                  // seems like backend haven't done reflecting the changes so quickly
                  setInterval(() => {
                    refetchModifiers();
                    refetchModifierGroup();
                  }, 1000);
                  setOpenAddModifier(false);
                }}
              />
              <Button
                onClick={() => {
                  setOpenAddModifier(false);
                  setOpenCreateModifier(true);
                }}
              >
                {t('modifierGroup.createNewModifier')}
              </Button>
            </section>
          </ModalWrapper>
        )}

        {openCreateModifier && (
          <ModalWrapper open setOpen={setOpenCreateModifier} hideButton>
            <FormBuilder
              resourceName={resourceNames.modifierGroup}
              onSubmit={onCreateModifier}
              title={t('modifier.create.modifier')}
              items={(formHook) => formBuilder({ formHook, type: 'modifiers' })}
              loading={creatingModifier}
              showBackButton={false}
              showHeaderButton={false}
            />
          </ModalWrapper>
        )}

        <TriggerConfirmModal
          renderTrigger={() => <></>}
          visible={!!removeModifierId}
          onOpenChange={(open) => {
            if (!open) setRemoveModifierId('');
          }}
          title={t('common.removeItem')}
          onPressConfirm={onRemoveModifier}
          description={
            <span>
              {t('warnings.removeFromConfirmation', {
                item: t('modifier.name'),
                list: modifierGroupName,
              })}
            </span>
          }
          loadingConfirm={removingModifier}
        />
        <section className="flex flex-col space-y-8">
          {/* General Info Form */}
          <ModifierGroupForm actionType="update" initialValues={data?.data} form={form} />

          <Card>
            <Card.Header className="flex flex-row justify-between items-center font-bold border-b">
              <div className="flex flex-row items-center space-x-2">
                <h3>{t('modifier.name', 'Modifiers')}</h3>
              </div>
              {!noModifiers && (
                <Button
                  onClick={() => {
                    setOpenAddModifier(true);
                  }}
                  size="sm"
                  className="!px-4 border-primary-500"
                >
                  <div className="flex flex-row items-center justify-between space-x-2 text-primary-500">
                    <AiOutlinePlus className="!text-primary-500" />
                    <span>{t('modifier.create.add', {}, 'Add Modifier')}</span>
                  </div>
                </Button>
              )}
            </Card.Header>
            <Card.Body>
              {loadingModifiers ? (
                <Loading />
              ) : noModifiers ? (
                <section className="flex flex-col items-center justify-center w-full min-h-full p-20 space-y-6">
                  <div className="flex flex-col items-center justify-center space-y-2">
                    <FaSitemap className="!text-primary-500" size={55} />
                    <span className="text-smoke-600">
                      {t('modifier.empty', {
                        fallback: `Add Modifiers to ${modifierGroupName || ''} Modifier`,
                        ns: 'common',
                        name: modifierGroupName,
                      })}
                    </span>
                  </div>
                  <Button className="border-primary-500" onClick={() => setOpenAddModifier(true)}>
                    <div className="flex flex-row items-center justify-between space-x-2 text-primary-500">
                      <AiOutlinePlus className="!text-primary-500" />
                      <span>{t('modifier.create.add', 'Add Modifiers')}</span>
                    </div>
                  </Button>
                </section>
              ) : (
                modifierListing()
              )}
            </Card.Body>
          </Card>
        </section>
      </ShowPageWrapper>
    </main>
  );
};
