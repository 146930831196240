import React from 'react';
import SearchAndSelect, { MultiSearchAndSelectProp } from '../base';
import { ProductListOptions, ProductType, ProductVariant } from '~/api';
import { useTranslate } from '@refinedev/core';
import { head } from 'lodash';
import { parsePrice } from '~/resources/helpers';

type Props = Omit<MultiSearchAndSelectProp, 'queryKey'> & {
  isWithName?: boolean;
  onSelect?: (id: string) => void;
  // ==== For Query Options
  enabledOnly?: boolean;
  excludeIds?: string[];
};

export const MultiModifierSelection: React.FC<Props> = (props) => {
  const { onSelect, isWithName, enabledOnly = false, excludeIds = [], ...restProps } = props;
  const t = useTranslate();

  return (
    <SearchAndSelect
      {...restProps}
      onSelect={(id) => onSelect?.(id)}
      queryKey="getProducts"
      getValueAndLabel={({ id, name, variants = [] }) => {
        const { currencyCode, price } = (head(variants) || {}) as ProductVariant;
        let label = `${name}`.trim() + ` (${currencyCode} ${parsePrice(price)})`;
        return {
          value: isWithName ? `${id}-split-${label}` : `${id}`,
          label: label,
        };
      }}
      variables={{
        options: {
          filter: {
            type: { eq: ProductType.Modifier },
            id: {
              notIn: excludeIds,
            },
            ...(enabledOnly && { enabled: { eq: true } }),
          },
        } as ProductListOptions,
      }}
      placeholder={t('product.placeholder.modifiers')}
    />
  );
};

export type MultiModifierSelectionProps = Props;
