import React, { useRef } from 'react';
import { FormBuilder, Loading, SomethingWentWrong, VariantForm } from '~/components';
import { Props } from './props';
import { useParams } from 'react-router-dom';
import { useNavigation, useOne, useTranslate, useUpdate } from '@refinedev/core';
import { RefProps as VariantFormRefProps } from '~/components/VariantForm/props';
import { Product } from '~/api';
import isEmpty from 'lodash/isEmpty';
import { formBuilder } from '~/config/ProductResource';
import { variantFormBuilder } from '~/components/VariantForm/config';
import { resourceNames } from '~/resources/resource-names';

export const ProductEditPage: React.FC<Props> = (props) => {
  const { resourceName, productFields = [] } = props;

  // ========================== HOOKS
  const { id } = useParams();
  const t = useTranslate();
  const navigation = useNavigation();
  const variantFormRef = useRef<VariantFormRefProps>(null);
  if (!id) return <SomethingWentWrong />;

  const { data, isLoading } = useOne({
    resource: resourceName,
    id,
    metaData: {
      fields: productFields,
    },
  });
  // ========================== VARIABLES
  const mainData = data?.data as Product;
  const isCreatedNoVariant = !isEmpty(mainData?.variants) && isEmpty(mainData?.optionGroups);
  const { mutate: update } = useUpdate();

  // ========================== VIEWS
  if (isLoading) return <Loading />;
  return (
    <FormBuilder
      resourceName={resourceName}
      isUpdate
      title={t('product.edit.withProduct', { name: mainData?.name })}
      onSubmit={async (data) => {
        try {
          await update({
            id,
            resource: resourceName,
            values: {
              data,
              productData: mainData,
            },
          });
          navigation?.goBack();
        } catch (err) {
          console.error(err);
        }
      }}
      items={(formHook) =>
        formBuilder({
          product: mainData,
          formHook,
          setProductVariants: variantFormRef?.current?.setProductVariants,
          type: resourceName === resourceNames.modifier ? 'modifiers' : 'products',
        })
      }
      keyToWatch={['optionGroups', 'variants']}
      keyToHide={['quantity', 'basePrice']}
      extra={[
        {
          render: (renderProps) => (
            <VariantForm
              {...renderProps}
              ref={variantFormRef}
              isUpdate
              isCreatedNoVariant={isCreatedNoVariant}
              items={variantFormBuilder({ isUpdate: true })}
              data={mainData}
            />
          ),
          validate: true,
          key: 'variants',
        },
      ]}
    />
  );
};
