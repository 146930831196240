import { customAlphabet } from 'nanoid';

export const generateCode = (options?: { prefix?: string; length?: number }) => {
  const finalOptions = {
    prefix: '',
    length: 10,
    ...(options ?? {}),
  };
  const alphabet = '123456789ABCDEFGHJKLMNPQRSTUVWXYZ';
  const effectiveLength = finalOptions.length - finalOptions.prefix.length;
  const nanoid = customAlphabet(alphabet, effectiveLength > 0 ? effectiveLength : 0);
  const code = nanoid();
  if (finalOptions.prefix) return finalOptions.prefix + '#' + code;
  return finalOptions.prefix + code;
};
