import { useApiUrl, useCustom } from '@refinedev/core';
import { MetabaseIframeUrlOptions } from '~/api';

export const useMetabaseApi = () => {
  // ====================== HOOKS
  const apiUrl = useApiUrl();

  const useGetMetabaseUrl = (variables: MetabaseIframeUrlOptions) => {
    const api = useCustom<String>({
      method: 'get',
      url: apiUrl,
      meta: {
        fields: [],
        variables: {
          options: {
            value: variables,
            type: 'MetabaseIframeUrlOptions!',
          },
        },
        operation: 'getMetabaseIframeUrl',
      },
    });

    return api;
  };

  return {
    useGetMetabaseUrl,
  };
};
