import { CreateResourceOptions } from '@scalingworks/refine-react-admin';
import isEmpty from 'lodash/isEmpty';
import kebabCase from 'lodash/kebabCase';
import {
  CreateProductMutationVariables,
  CreateProductOptionMutation,
  getSdk,
  LanguageCode,
  Product,
} from '~/api';
import { ProductOptionGroupFormInput } from '~/components/VariantForm/props';
import {
  createVariantInput,
  formatBackendPrice,
  getDifferenceById,
  getOptionsDifference,
  loopCreateOptionGroup,
  mergeOptions,
} from '~/resources/helpers';
import { FormInputs } from './product-resource';
import compact from 'lodash/compact';

export const productDataProvider: CreateResourceOptions<
  Product,
  { name: string; description: string }
>['dataProvider'] = {
  // Note: ignore for now since the promise
  // too complex
  // @ts-ignore
  create: async ({ client, variables }) => {
    const {
      basePrice,
      categories,
      description,
      enable,
      // handlingFee,
      images,
      name,
      quantity,
      shipping,
      variants,
      optionGroups: oldOptionGroup,
      customFields,
      facetValueIds = [],
      modifierGroups = [],
    } = variables as FormInputs;
    const slugName = kebabCase(name);
    const filteredOptionGroups = oldOptionGroup?.filter((obj) => obj?.options?.length > 0);

    let assetsUrl: any;
    if (!isEmpty(images) && typeof images?.[0] !== 'string') {
      assetsUrl = await getSdk(client)?.createAssets({
        input: images?.map((subItem: any) => {
          return {
            file: subItem,
          };
        }),
      });
    }

    try {
      // 1) Create the product
      const createProductRes = await getSdk(client).createProduct({
        input: {
          translations: [
            {
              languageCode: LanguageCode?.En,
              name,
              description,
              slug: slugName,
            },
          ],
          enabled: enable,
          assetIds: !isEmpty(assetsUrl) ? assetsUrl?.createAssets?.[0]?.id : undefined,
          // NOTE: might need to refactor this data injection
          customFields: {
            ...customFields,
            fulfillChannelsIds: shipping ? compact(shipping) : undefined,
          },
          facetValueIds: [
            ...facetValueIds,
            ...(categories?.length
              ? categories.map((subItem) => subItem?.split('-split-')?.[0])
              : []),
          ],
        },
      } as CreateProductMutationVariables);

      // 2) Loop async function to call api to create option groups and options.
      // 3) Each loop ends, link the created option group to the product

      // 5) Loop and get the ids from the created options from api, then merge it into the variants form value
      // 6) create variants and also link the options' ids.
      // Only ONE variant
      if (isEmpty(variants) && createProductRes.createProduct) {
        await getSdk(client).createProductVariants({
          input: {
            productId: createProductRes.createProduct.id,
            sku: slugName,
            translations: [
              {
                languageCode: LanguageCode?.En,
                name,
              },
            ],
            price: formatBackendPrice({ price: parseFloat(basePrice) }),
            stockOnHand: parseInt(quantity),
            assetIds: !isEmpty(assetsUrl) ? assetsUrl?.createAssets?.[0]?.id : undefined,
          },
        });
      }
      // Create Many variants with product options
      else {
        const createOptionRes = await loopCreateOptionGroup({
          client,
          productId: createProductRes?.createProduct?.id,
          value: filteredOptionGroups,
          getSdk,
        });

        await getSdk(client).createProductVariants({
          input: await createVariantInput({
            innerVariants: variants?.map((subItem) => {
              return {
                ...subItem,
                price: formatBackendPrice({ price: parseFloat(subItem?.price) })?.toString(),
                options: subItem.options,
              };
            }),
            productId: createProductRes?.createProduct?.id,
            optionResCombine: createOptionRes?.optionResCombine,
          }),
        });
      }

      if (!isEmpty(modifierGroups)) {
        const productId = createProductRes.createProduct.id;
        await getSdk(client).assignModifierGroupsToProduct({
          input: {
            facetValueIds: modifierGroups,
            productId,
          },
        });
      }

      return Promise.resolve(createProductRes);
    } catch (err) {
      console.error(err);
      return Promise.reject('Failed to create product');
    }
  },
  // Note: ignore for now since the promise
  // handling too complex
  // @ts-ignore
  update: async ({ client, variables, id }) => {
    const { data, productData } = variables as any;
    const mainProductData = productData as Product;
    const {
      // basePrice,
      categories,
      description,
      enable,
      // handlingFee,
      images,
      name,
      // quantity,
      shipping,
      optionGroups: oldOptionGroup,
      variants,
      customFields,
      modifierGroups = [],
    } = data as FormInputs;
    const filteredOptionGroups = oldOptionGroup?.filter((obj) => obj?.options?.length > 0);

    // ================= PRODUCTS
    const slugName = kebabCase(name);

    let assetsUrl: any;
    if (!isEmpty(images) && typeof images?.[0] !== 'string') {
      assetsUrl = await getSdk(client)?.createAssets({
        input: images?.map((subItem: any) => {
          return {
            file: subItem,
          };
        }),
      });
    }

    getSdk(client)
      .updateProduct({
        input: {
          id: id as string,
          translations: [
            {
              languageCode: LanguageCode?.En,
              name,
              description,
              slug: slugName,
            },
          ],
          enabled: enable,
          assetIds: !isEmpty(assetsUrl) ? assetsUrl?.createAssets?.[0]?.id : undefined,
          customFields: !isEmpty(shipping)
            ? {
                type: customFields?.type,
                fulfillChannelsIds: shipping?.filter((subItem) => !isEmpty(subItem)),
              }
            : undefined,
          facetValueIds: !isEmpty(categories)
            ? categories?.map((subItem) => subItem?.split('-split-')?.[0])
            : undefined,
        },
      })
      .then((res) => {
        return {
          data: res.updateProduct,
        };
      });

    // ================= PRODUCT VARIANTS
    // DELETION
    /**
     * 1) Delete all not needed variant
     * 2) Delete all not needed options
     * 3) Delete all not needed option groups
     */
    // =================  Delete removed variants. Compare the form variants with api variants.
    const variantsDifference = getDifferenceById(mainProductData?.variants, variants);
    if (!isEmpty(variantsDifference)) {
      await getSdk(client).deleteProductVariants({
        ids: variantsDifference?.map((subItem) => subItem?.id),
      });
    }

    // =================  Delete removed options. Compare the form options with api options.
    const optionsDifference = getOptionsDifference(
      filteredOptionGroups,
      mainProductData?.optionGroups
    );
    if (!isEmpty(optionsDifference)) {
      for (var optionsDifferenceItem of optionsDifference) {
        await getSdk(client).deleteProductOption({
          deleteProductOptionId: optionsDifferenceItem?.id,
        });
      }
    }

    // =================  Delete removed options groups. Compare the form option groups with api option groups.
    const optionGroupsDifference = getDifferenceById(
      mainProductData?.optionGroups,
      filteredOptionGroups
    );
    if (!isEmpty(optionGroupsDifference)) {
      for (var optionGroupsDifferenceItem of optionGroupsDifference) {
        await getSdk(client).removeOptionGroupFromProduct({
          optionGroupId: optionGroupsDifferenceItem?.id,
          productId: mainProductData?.id,
        });
      }
    }

    // ADDITION
    /**
     * 1) Add new option groups
     * 2) Add new options
     * 3) Add new variants
     */
    // =================  Add new option groups. Get form option groups that does not have id.
    const newOptionGroups = filteredOptionGroups?.filter((subItem) => !subItem?.id);
    let optionGroupsWithId = filteredOptionGroups?.filter((subItem) => subItem?.id);
    let optionResCombine;
    if (!isEmpty(newOptionGroups)) {
      optionResCombine = await loopCreateOptionGroup({
        client,
        productId: mainProductData?.id,
        value: newOptionGroups,
        getSdk,
      });
      optionGroupsWithId?.push(optionResCombine?.response as ProductOptionGroupFormInput);
    }
    // =================  Add new options. Get form options that does not have id.
    const newOptions = filteredOptionGroups?.flatMap((obj) => {
      const parentId = obj?.id;
      return obj?.options
        ?.filter((option) => !option.id && parentId !== undefined)
        ?.map((option) => ({ parentId, name: option?.name }));
    });
    if (!isEmpty(newOptions)) {
      for (var newOptionsItem of newOptions) {
        const optionQueryResponse = await getSdk(client).createProductOption({
          input: {
            code: newOptionsItem?.name,
            productOptionGroupId: newOptionsItem?.parentId || '',
            translations: [
              {
                languageCode: LanguageCode?.En,
                name: newOptionsItem?.name,
              },
            ],
          },
        });
        const optionResponse: CreateProductOptionMutation['createProductOption'] & {
          parentId?: string;
        } = optionQueryResponse?.createProductOption;
        optionResponse['parentId'] = newOptionsItem?.parentId;
        mergeOptions({
          optionGroupsWithId,
          optionResponse,
        });
      }
    }

    // =================  Add new variants. Get form variants that does not have id.
    const flattenedOptions = optionGroupsWithId?.flatMap((group) => group.options);
    const newVariants = variants?.filter((subItem) => !subItem?.id);
    if (!isEmpty(newVariants)) {
      await getSdk(client).createProductVariants({
        input: await createVariantInput({
          innerVariants: newVariants?.map((subItem) => {
            return {
              ...subItem,
              price: formatBackendPrice({ price: parseFloat(subItem?.price) })?.toString(),
              options: subItem.options,
            };
          }),
          productId: mainProductData?.id,
          optionResCombine: flattenedOptions,
        }),
      });
    }

    // UPDATES
    // =================  Update old variants. Get form variants that have id.
    const oldVariants = variants?.filter((subItem) => subItem?.id);
    if (!isEmpty(oldVariants)) {
      for (var oldVariantsItem of oldVariants) {
        let variantAssetsUrl: any;
        if (!isEmpty(oldVariantsItem?.image) && typeof oldVariantsItem?.image?.[0] !== 'string') {
          variantAssetsUrl = await getSdk(client)?.createAssets({
            input:
              oldVariantsItem?.image?.map((subItem: any) => {
                return {
                  file: subItem,
                };
              }) || [],
          });
        }

        await getSdk(client).updateProductVariants({
          input: {
            id: oldVariantsItem?.id || '',
            price: formatBackendPrice({ price: parseFloat(oldVariantsItem?.price) }),
            stockOnHand: parseInt(`${oldVariantsItem?.stockOnHand}`),
            enabled: oldVariantsItem?.enabled,
            assetIds: !isEmpty(variantAssetsUrl)
              ? variantAssetsUrl?.createAssets?.[0]?.id
              : undefined,
          },
        });
      }
    }

    // =================  Update old option groups. Get form option groups that have id.
    const oldOptionGroups = filteredOptionGroups?.filter((subItem) => subItem?.id);
    if (!isEmpty(oldOptionGroups)) {
      for (var oldOptionGroupsItem of oldOptionGroups) {
        await getSdk(client).updateProductOptionGroup({
          input: {
            id: oldOptionGroupsItem?.id || '',
            code: oldOptionGroupsItem?.name,
            translations: [
              {
                languageCode: LanguageCode.En,
                name: oldOptionGroupsItem?.name,
              },
            ],
          },
        });
      }
    }

    if (!isEmpty(modifierGroups)) {
      await getSdk(client).assignModifierGroupsToProduct({
        input: {
          facetValueIds: modifierGroups,
          productId: id as string,
        },
      });
    }
  },
};
