import React from 'react';
import SearchAndSelect, { MultiSearchAndSelectProp } from '../base';
import { FacetValue, ModifierGroupListOptions, SortOrder } from '~/api';
import { useTranslate } from '@refinedev/core';

type Props = Omit<MultiSearchAndSelectProp, 'queryKey'> & {
  onSelect?: (id: string) => void;
};

export const MultiModifierGroupSelection: React.FC<Props> = (props) => {
  const { onSelect, ...restProps } = props;
  const t = useTranslate();

  return (
    <SearchAndSelect
      {...restProps}
      onSelect={(id) => onSelect?.(id)}
      queryKey="getModifierGroups"
      getValueAndLabel={({ id, name }: FacetValue) => {
        const label = name.trim();
        return {
          label,
          value: id,
        };
      }}
      variables={{
        options: {
          filter: {},
          sort: {
            name: SortOrder.Asc,
          },
        } as ModifierGroupListOptions,
      }}
      placeholder={t('product.placeholder.modifiers')}
    />
  );
};

export type MultiModifierGroupSelectionProps = Props;
