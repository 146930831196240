import dayjs from 'dayjs';
import { OutletFulfillment } from '~/api';
import { DateFormatInput, DayDateFormat, ShortDateFormat, TimeFormat } from '~/config/constant';

export const isValidDate = (date: Date) => {
  return date instanceof Date && !isNaN(date.valueOf());
};

export const dateTimeString = (e: string) => {
  const defaultDate = dayjs().format('YYYY-MM-DD');
  return `${defaultDate} ${e}`;
};

export const sortByDayOutletOperation = (data: any) => {
  const daysOfWeek = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

  return data.sort((a: any, b: any) => {
    const indexA = daysOfWeek.indexOf(a?.day?.toUpperCase());
    const indexB = daysOfWeek.indexOf(b?.day?.toUpperCase());

    if (indexA < indexB) return -1;
    if (indexA > indexB) return 1;

    return 0;
  });
};

export const sortByTimeOutletOperation = (data: any) => {
  return data?.sort((a: any, b: any) => {
    const openingTimeA = new Date(`2000-01-01T${a?.openingTime}`);
    const openingTimeB = new Date(`2000-01-01T${b?.openingTime}`);

    if (openingTimeA < openingTimeB) return -1;
    if (openingTimeA > openingTimeB) return 1;

    return 0;
  });
};
/**
 * @description
 * determine now() is between a date range OR
 * after startDate if endDate is not provided
 */
export const isBetweenRange = (startDate: Date, endDate?: Date) => {
  const now = dayjs();
  const isSameOrAfterStart = now.isSame(startDate) || now.isAfter(startDate);

  if (endDate) {
    return isSameOrAfterStart && now.isBefore(endDate);
  }

  return isSameOrAfterStart;
};

/**
 * @description
 * Helps to display the outlet fulfillment's openingTime/closingTime to a TimeFormat
 */
export const outletFulfillmentTimeDisplay = (slot: OutletFulfillment) => {
  return `${dayjs(dayjs().format(DateFormatInput) + slot.openingTime).format(TimeFormat)} - ${dayjs(
    dayjs().format(DateFormatInput) + slot.closingTime
  ).format(TimeFormat)}`;
};

/**
 * @description
 * Helps to determine DAY | DATE display type
 */
export const outletFulfillmentDateDisplay = (slot: OutletFulfillment) => {
  // DATE
  if (slot.startDate) {
    return (
      `${dayjs(slot.startDate).format(ShortDateFormat)} - ${dayjs(slot.endDate).format(
        ShortDateFormat
      )}   ` + outletFulfillmentTimeDisplay(slot)
    );
  }

  // DAY
  else {
    return `${slot.day} ${outletFulfillmentTimeDisplay(slot)}`;
  }
};
