import React, { useRef, useState } from 'react';
import { ResourceField, createHelpers, createResource } from '@scalingworks/refine-react-admin';
import '@szhsin/react-menu/dist/index.css';
import { Channel, Collection, CollectionQuery, GradientEnum, LanguageCode, getSdk } from '~/api';
import {
  ActionButton,
  TriggerConfirmModal,
  ActionButtonRefProps,
  Loading,
  SomethingWentWrong,
  Draggable,
} from 'components';
import {
  useCreate,
  useNavigation,
  useOne,
  useTable,
  useTranslate,
  useUpdate,
} from '@refinedev/core';
import isEmpty from 'lodash/isEmpty';
import kebabCase from 'lodash/kebabCase';
import { ImageViewer } from '@scalingworks/react-admin-ui';
import { numeralThousandFormat } from '~/config/helper';
import { useParams } from 'react-router-dom';
import { renderStatusTag } from '~/resources/helpers';
import { FiMenu } from 'react-icons/fi';
import { GQLClient } from '~/config/gql-client';
import { resourceNames } from '../../resource-names';
import { CollectionCreatePage } from './create';
import { CollectionShow } from './show';
import { BatchRequestDocument } from 'graphql-request';
import { FiLayers } from "react-icons/fi";

const { defineFields, defineCardSection, defineShowPage, defineFilterControls } =
  createHelpers<Collection>({
    resourceName: resourceNames.collection,
  });

const defaultValues = {
  name: '',
  description: '',
};

const collectionFields: ResourceField<Collection>[] = [
  'id',
  'createdAt',
  'updatedAt',
  'name',
  'description',
  'slug',
  'position',
  'isPrivate',
  { gradient: ['type', 'gradient'] },
  { assets: ['id', 'source'] },
  { products: [{ items: ['id', 'name', { assets: ['id', 'source'] }] }] },
  { productVariants: [{ items: ['id', 'name', { assets: ['id', 'source'] }] }] },
  { filters: ['code', { args: ['name', 'value'] }] },
  { parent: ['id', 'name'] },
];

export const collectionResource = (channel?: Channel) => {
  return createResource({
    name: resourceNames.collection,
    label: 'Collections',
    icon: <FiLayers />,
    // @ts-ignore
    fields: defineFields(collectionFields),
    defaultValues,
    formatBeforeSubmit: (data) => {
      return data;
    },
    defaultPageSize: 25,
    defaultSorter: [{ field: 'position', order: 'asc' }],
    allowSearch: true,
    searchConfig: {
      placeholder: ({ t }) =>
        t('collection.placeholder.search', {
          ns: 'common',
          fallback: 'Search',
        }),
    },
    allowDelete: true,
    // TODO: temporary disabled since the created collection dont have proper facetValues & filterArgs
    allowCreate: false,
    createConfig: {
      title: ({ t }) =>
        t('collection.create.name', { fallback: 'Create Collection', ns: 'common' }),
    },
    filterConfig: {
      alwaysExpanded: true,
    },
    columns: ({ LinkToDetails, navigateToEdit, invokeDelete, refetchData, t }) => {
      return [
        // TODO: temporary hide the draggable because there are some backend issue with `moveCollection` api
        // {
        //   id: 'position',
        //   header: '',
        //   cell: (data) => {
        //     const { position } = data.row.original;
        //     const gqlClient = GQLClient?.getInstance();

        //     return (
        //       <Draggable
        //         children={
        //           <div style={{ cursor: 'grab' }} className="flex items-center justify-center">
        //             <FiMenu size={20} />
        //           </div>
        //         }
        //         data={data.row.original}
        //         index={position}
        //         moveRow={async (from, to, innerData) => {
        //           await getSdk(gqlClient)?.MoveCollection({
        //             input: {
        //               collectionId: innerData?.id,
        //               index: to,
        //               parentId: innerData?.parent?.id,
        //             },
        //           });
        //           refetchData();
        //         }}
        //       />
        //     );
        //   },
        // },
        // {
        //   id: 'position',
        //   header: t('collection.column.position', { fallback: 'Position', ns: 'common' }),
        //   cell: (data) => {
        //     const { id, position } = data.row.original;
        //     return (
        //       <LinkToDetails resourceId={id}>
        //         <span>{`${position + 1}`}</span>
        //       </LinkToDetails>
        //     );
        //   },
        // },
        {
          id: 'name',
          header: t('collection.column.name', { fallback: 'Name', ns: 'common' }),
          cell: (data) => {
            const { id, name, assets } = data.row.original;
            return (
              <LinkToDetails resourceId={id} style={{ flexDirection: 'row' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <ImageViewer
                    src={assets?.[0]?.source}
                    className="!w-14 !h-14 rounded-lg object-cover mr-2"
                    alt="Product"
                  />
                  <span>{name}</span>
                </div>
              </LinkToDetails>
            );
          },
        },
        {
          id: 'item',
          header: t('collection.column.products', { fallback: 'Products', ns: 'common' }),
          cell: (data) => {
            const { id, products } = data.row.original;
            return (
              <LinkToDetails resourceId={id}>
                <span>{numeralThousandFormat(products?.items?.length)}</span>
              </LinkToDetails>
            );
          },
        },
        {
          id: 'status',
          header: t('collection.column.status', { fallback: 'Status', ns: 'common' }),
          cell: (data) => {
            const { id, isPrivate } = data.row.original;

            return <LinkToDetails resourceId={id}>{renderStatusTag(!isPrivate)}</LinkToDetails>;
          },
        },
        {
          id: 'actions',
          header: () => '',
          accessorKey: 'id',
          enableSorting: false,
          cell: (data) => {
            const t = useTranslate();
            const { id: collectionId } = data?.row?.original;
            const actionButtonRef = useRef<ActionButtonRefProps>(null);
            const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
            return (
              <ActionButton
                ref={actionButtonRef}
                actions={[
                  {
                    label: t('actions.edit'),
                    name: 'edit',
                    onAction: () => navigateToEdit({ id: collectionId }),
                  },
                  {
                    label: t('actions.delete'),
                    name: 'delete',
                    onAction: () => invokeDelete({ id: collectionId }),
                    render: (onAction) => {
                      return (
                        <button type="button">
                          <TriggerConfirmModal
                            visible={showDeleteConfirmation}
                            onOpenChange={(val) => {
                              const actionButtonSetOpen = actionButtonRef?.current?.setOpen;
                              setShowDeleteConfirmation(val);
                              actionButtonSetOpen && actionButtonSetOpen(val);
                            }}
                            onPressConfirm={onAction}
                          />
                        </button>
                      );
                    },
                  },
                ]}
              />
            );
          },
        },
      ];
    },
    dataProvider: {
      create: async ({ client, variables }) => {
        const mainVariables: any = variables;
        const slugName = kebabCase(mainVariables?.name);
        const productIds = mainVariables?.products?.map(
          (subItem: string) => `${subItem?.split('-split-')?.[0]}`
        );
        const colorGradient = {
          gradient: [mainVariables?.primaryColor, mainVariables?.secondaryColor],
          type: GradientEnum?.Linear,
        };

        let assetsUrl;
        if (!isEmpty(mainVariables?.images)) {
          assetsUrl = await getSdk(client)?.createAssets({
            input: mainVariables?.images?.map((subItem: any) => {
              return {
                file: subItem,
              };
            }),
          });
        }

        return getSdk(client)
          ?.createCollectionWithFacet({
            input: {
              isPrivate: !!mainVariables?.status ? mainVariables?.status === 'hide' : undefined,
              translations: [
                {
                  description: `<p>${mainVariables?.name}</p>`,
                  languageCode: LanguageCode?.En,
                  name: mainVariables?.name,
                  slug: slugName,
                },
              ],
              assetIds: !isEmpty(assetsUrl?.createAssets)
                ? assetsUrl?.createAssets?.map((sub: any) => sub?.id)
                : [],
              productIds: productIds,
              // @ts-ignore
              customFields: {
                gradient: colorGradient,
              },
            },
          })
          ?.then((res) => ({
            data: res?.createCollectionWithFacet,
          }));
      },
      getOne: ({ client, id }) => {
        return getSdk(client)
          ?.Collection({
            collectionId: id as string,
          })
          ?.then((res) => {
            return {
              data: res,
            };
          });
      },
      update: async ({ client, variables, id, dataLoader }) => {
        const mainVariables = variables as any;
        const formData = mainVariables?.data;
        const collectionId = id as string;
        const slugName = kebabCase(formData?.name);

        const productIds = formData?.products?.map(
          (subItem: string) => `${subItem?.split('-split-')?.[0]}`
        );
        const colorGradient = {
          gradient: [formData?.primaryColor, formData?.secondaryColor],
          type: GradientEnum?.Linear,
        };

        let assetsUrl: any;
        if (!isEmpty(formData?.images) && typeof formData?.images?.[0] !== 'string') {
          assetsUrl = await getSdk(client)?.createAssets({
            input: formData?.images?.map((subItem: any) => {
              return {
                file: subItem,
              };
            }),
          });
        }

        return getSdk(client)
          ?.updateCollectionWithFacet({
            input: {
              id: collectionId,
              isPrivate: !!formData?.status ? formData?.status === 'hide' : undefined,
              translations: !isEmpty(formData?.name)
                ? [
                    {
                      description: `<p>${formData?.name}</p>`,
                      languageCode: LanguageCode?.En,
                      name: formData?.name,
                      slug: slugName,
                    },
                  ]
                : undefined,
              assetIds: !isEmpty(assetsUrl?.createAssets)
                ? assetsUrl?.createAssets?.map((sub: any) => sub?.id)
                : undefined,
              productIds: productIds,
              // @ts-ignore
              customFields: {
                gradient: colorGradient,
              },
            },
          })
          ?.then((res) => {
            return {
              data: res,
            };
          });
      },
    },
    create: {
      render: (helpers) => {
        const navigation = useNavigation();
        const { mutate } = useCreate();
        const {
          tableQueryResult: { refetch },
        } = useTable();
        const [isLoading, setIsLoading] = useState(false);

        const onSubmit = (data: any) => {
          setIsLoading(true);
          mutate(
            {
              resource: resourceNames?.collection,
              values: data,
            },
            {
              onSuccess: async () => {
                await refetch();
                // Have to put timeout after refetch. If not table will not reflect
                // Using await / then also does not fix it.
                setTimeout(() => {
                  setIsLoading(false);
                  navigation?.goBack();
                }, 500);
              },
              onError: () => {
                setIsLoading(false);
              },
            }
          );
        };
        return <CollectionCreatePage onSubmit={onSubmit} isLoading={isLoading} />;
      },
    },
    show: {
      component: (helpers) => {
        const collectionData = helpers?.queryResult?.data?.data as CollectionQuery;
        const { edit } = useNavigation();
        if (helpers?.queryResult?.isLoading) return <Loading />;

        return (
          <CollectionShow
            collectionData={collectionData?.collection as Collection}
            helpers={helpers}
            editNavigation={edit}
          />
        );
      },
    },
    edit: {
      render: (helpers) => {
        const { id } = useParams();
        const navigation = useNavigation();
        const { mutate: updateCollection, isLoading: updating } = useUpdate();

        if (!id) return <SomethingWentWrong />;

        const { data, isLoading } = useOne({
          resource: resourceNames?.collection,
          id,
        });

        const onSubmit = (formData: any) => {
          updateCollection(
            {
              id,
              resource: resourceNames.collection,
              values: {
                data: formData,
                collectionData: data?.data?.collection,
              },
            },
            {
              onSuccess: () => {
                navigation?.goBack();
              },
            }
          );
        };

        if (isLoading) return <Loading />;
        return (
          <CollectionCreatePage
            isUpdate
            onSubmit={onSubmit}
            data={data?.data?.collection}
            isLoading={updating}
          />
        );
      },
    },
  });
};
