import React, { useCallback, useState, useEffect } from 'react';
import { Channel, PublicSeller, getSdk } from '~/api';
import { GQLClient, GQLClientHeader } from '~/config/gql-client';
import { useTranslate } from '@refinedev/core';
import { authStorageKey, currentChannelToken, currentSellerId } from '~/config/auth-provider';
import { ChannelContext } from './context';
import {
  currencyForColHeader as currencyForColHeaderHelper,
  onlyOneCurrency,
} from '~/resources/helpers';
import { SomethingWentWrong } from '~/components';
import { NetworkRequestFailed } from '~/config/constant';
import isEmpty from 'lodash/isEmpty';

export const ChannelContextProvider = React.memo((props: any) => {
  const { children } = props;

  // ===================== HOOKS
  const translate = useTranslate();

  // ===================== STATES
  const [loading, setLoading] = useState(true);
  const [channel, setChannel] = useState<Channel | null>(null);
  const [error, setError] = useState<string | undefined>();

  // ===================== VARIABLES
  const client = GQLClient.getInstance();

  // ===================== EVENTS
  const fetchChannel = (token?: string) => {
    const auth = localStorage.getItem(authStorageKey);
    const channelToken = token || localStorage.getItem(currentChannelToken);

    setLoading(true);
    if (!channelToken || !auth) {
      console.error(`Unauthorized to get active channel`);
      setLoading(false);
      return;
    }

    // TODO: check why previous function call `changeChannel` already set
    // but `client` here request won't have the header, need to set again to be safe
    client.setHeader(GQLClientHeader.authorization, 'Bearer ' + auth);
    client.setHeader(GQLClientHeader.vendureToken, channelToken);

    getSdk(client)
      .activeChannel()
      .then((data) => {
        setChannel(data?.activeChannel as Channel);
      })
      .catch((err) => {
        console.error(err);
        setError(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const changeChannel = (token: string) => {
    client.setHeader(GQLClientHeader.vendureToken, token);
    localStorage.setItem(currentChannelToken, token);
    fetchChannel(token);

    // TODO: enhancement
    // check for more seamless refresh to re-call apis.
    window?.location?.reload();
  };

  const currencyForColHeader = () => {
    return currencyForColHeaderHelper(channel);
  };

  // ===================== EFFECTS
  useEffect(() => {
    if (!channel && !error) {
      fetchChannel();
    }
  }, [fetchChannel, channel]);

  // ===================== VIEWS
  return (
    <ChannelContext.Provider
      value={{
        loading,
        error,
        channel,
        changeChannel,
        onlyOneCurrency: onlyOneCurrency(channel),
        currencyForColHeader,
      }}
    >
      {!isEmpty(error) ? <SomethingWentWrong /> : children}
    </ChannelContext.Provider>
  );
});
