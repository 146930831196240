import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Card } from '@scalingworks/react-admin-ui';
import { useForm } from '@refinedev/react-hook-form';
import { useNavigation, useTranslate } from '@refinedev/core';
import isEmpty from 'lodash/isEmpty';
import camelCase from 'lodash/camelCase';
import { renderTextField } from './TextField';
import { renderCheckBoxButtons } from './Checkbox';
import { renderNumberField } from './NumberField';
import { renderUploadInput } from './UploadInput';
import { renderRadioButtons } from './Radio';
import { renderMultiTextField } from './MultiTextField';
import { renderSwitchField } from './Switch';
import { renderDataSelection } from './DataSelection';
import { renderDatePicker } from './DatePicker';
import { renderTimePicker } from './TimePicker';
import { renderDateTextInput } from './DateText';
import { renderSelectField } from './Select';
import { renderPhoneField } from './PhoneField';
import { Props, RefProps } from './props';
import { ShowPageWrapper } from '../ShowPageWrapper';
import { resourceNames } from '~/resources/resource-names';

export const flexRow: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};

export type FormBuilderRefProps = RefProps;

export const FormBuilder = forwardRef<RefProps, Props>((props, ref) => {
  const {
    title,
    items,
    onSubmit,
    isUpdate,
    resourceName,
    extra,
    keyToWatch,
    keyToHide,
    renderBody,
    disableSubmit,
    customForm,
    loading,
    showBackButton = true,
    showHeaderButton = true,
  } = props;

  const useFormHook = customForm
    ? customForm
    : useForm<any>({
        refineCoreProps: {
          dataProviderName: undefined,
          resource: 'no-resource',
        },
        defaultValues: {},
        values: {},
      });
  const t = useTranslate();

  const { register, handleSubmit, setValue, watch, formState, getValues, control, reset } =
    useFormHook;
  const navigateTo = useNavigation();
  const [filteredKeyToWatch, setfilteredKeyToWatch] = useState<any[]>();

  useImperativeHandle(ref, () => ({
    get useFormHook() {
      return useFormHook;
    },
  }));

  //============== Force Re-render
  const [booleanRender, setBooleanRender] = useState(false);
  const forceRerender = () => {
    setBooleanRender(!booleanRender);
  };
  useEffect(() => {
    setfilteredKeyToWatch(
      keyToWatch?.flatMap((subItem) => watch(subItem))?.filter((subItem) => subItem) || []
    );
  }, [keyToWatch, watch, booleanRender]);

  const onPressSubmit = (data: any) => {
    onSubmit && onSubmit(data);
  };

  const renderItems = () => {
    if (!items) return;
    return items(useFormHook)?.map((subItem, index) => {
      if (
        !isEmpty(filteredKeyToWatch?.flatMap((subItem) => watch(subItem))) &&
        // TODO: Form builder is currently being update in another pr.
        // Will need to change the subItem?.title -> subItem?.name when the other pr is in main.
        keyToHide?.includes(camelCase(subItem?.title))
      )
        return;

      const inputVal = { input: { item: subItem, ...useFormHook } };
      const renderSwitch = () => {
        switch (subItem?.type) {
          case 'upload':
            return renderUploadInput(inputVal);
          case 'checkbox':
            return renderCheckBoxButtons(inputVal);
          case 'radio':
            return renderRadioButtons(inputVal);
          case 'number':
            return renderNumberField(inputVal);
          case 'multitext':
            return renderMultiTextField(inputVal);
          case 'switch':
            return renderSwitchField(inputVal);
          case 'select':
            return renderSelectField(inputVal);
          case 'phone':
            return renderPhoneField(inputVal);
          case 'date-text-input':
            return renderDateTextInput(inputVal);
          case 'date-picker':
            return renderDatePicker(inputVal);
          case 'time-picker':
            return renderTimePicker(inputVal);
          case 'data-selection':
            if (!subItem.dataSelectionProps) return null;
            return renderDataSelection({ input: inputVal.input, ...subItem.dataSelectionProps });
          default:
            return renderTextField(inputVal);
        }
      };
      if (subItem?.render) return subItem?.render(inputVal);
      return <div className={index !== 0 ? 'mt-5' : ''}>{renderSwitch()}</div>;
    });
  };

  const renderExtra = () => {
    return extra?.map((subItem, index) => {
      const registerKey = `${subItem?.key}`;
      register(registerKey);
      return (
        <Card className="p-5">
          {subItem?.render({
            registerKey,
            forceRerender,
            ...useFormHook,
          })}
        </Card>
      );
    });
  };

  return (
    <form onSubmit={handleSubmit(onPressSubmit)}>
      <div className="max-w-screen-2xl mx-auto py-3 px-6">
        {/* Headers */}
        <ShowPageWrapper
          resourceName={resourceName}
          showBackButton={showBackButton}
          onBack={() => navigateTo.goBack()}
          title={title}
          extra={
            showHeaderButton && (
              <div style={{ ...flexRow }}>
                <Button size="sm" className="mr-2" onClick={() => navigateTo.goBack()}>
                  {t('actions.cancel')}
                </Button>
                <Button
                  variant="solid"
                  size="sm"
                  type="submit"
                  disabled={disableSubmit}
                  loading={loading}
                >
                  {isUpdate ? t('actions.update') : t('actions.create')}
                </Button>
              </div>
            )
          }
        >
          <div className="flex flex-col gap-3" onSubmit={handleSubmit(onPressSubmit)}>
            <Card className="p-5">{renderBody ? renderBody(useFormHook) : renderItems()}</Card>
            {!isEmpty(extra) && renderExtra()}
            <Button variant="solid" type="submit" disabled={disableSubmit} loading={loading}>
              {isUpdate ? t('actions.update') : t('actions.create')}
            </Button>
          </div>
        </ShowPageWrapper>
      </div>
    </form>
  );
});
