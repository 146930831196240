import { BaseRecord, HttpError, useTranslate } from '@refinedev/core';
import { UseFormReturnType } from '@refinedev/react-hook-form';
import { FieldGroup, Label } from '@scalingworks/react-admin-ui';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import { getSdk, type Product, GetFulfillmentChannelsQuery } from '~/api';
import { MultiCollectionSelection, MultiModifierGroupSelection } from '~/components';
import { FormItemProps } from '~/components/FormBuilder/props';
import { ProductVariantFormInput } from '~/components/VariantForm/props';
import { GQLClient } from '../gql-client';
import { CollectionFacetCode, ModifierFacetCode } from '../constant';
import { sortProductModifierGroup } from '~/resources/helpers';
import { resourceNames } from '~/resources/resource-names';
import { singular } from 'pluralize';

export const formBuilder = ({
  product,
  formHook,
  setProductVariants,
  type = resourceNames.product,
}: {
  product?: Product;
  formHook: UseFormReturnType<BaseRecord, HttpError, FieldValues, {}>;
  setProductVariants?: React.Dispatch<React.SetStateAction<ProductVariantFormInput[] | undefined>>;
  type?: 'products' | 'modifiers'; // resourceName constant's value
}): FormItemProps[] => {
  const { name, description, enabled, assets, facetValues, customFields } = product || {};
  const { modifierGroupSorting = [] } = customFields || {};
  const { watch, setValue, control } = formHook || {};
  const t = useTranslate();
  const placeholderType = t(`product.name.${[singular(type.toLowerCase())]}`).toLowerCase();
  // as

  // Getting shipping data
  const [shippingData, setShippingData] =
    useState<GetFulfillmentChannelsQuery['getFulfillmentChannels']['items']>();
  const gqlClient = GQLClient?.getInstance();
  useEffect(() => {
    getSdk(gqlClient)
      ?.GetFulfillmentChannels()
      ?.then((res) => {
        setShippingData(res?.getFulfillmentChannels?.items);
      });
  }, []);

  // Setting default value
  useEffect(() => {
    setValue(
      'categories',
      facetValues?.map((subItem) => `${subItem?.id}-split-${subItem?.name}`)
    );
    setValue('customFields', product?.customFields);
  }, [product]);
  return [
    {
      name: 'name',
      title: t('product.form.name'),
      placeholder: t('product.placeholder.name', {
        type: placeholderType,
      }),
      defaultValue: name,
      required: true,
    },
    {
      name: 'description',
      title: t('product.form.description'),
      placeholder: t('product.placeholder.description', {
        type: placeholderType,
      }),
      defaultValue: description,
      multiline: true,
    },
    {
      name: 'images',
      title: t('product.form.images'),
      placeholder: t('product.placeholder.images'),
      hint: t('messages.acceptedFileTypes', { files: 'JPG, JPEG, PNG' }),
      defaultValue: !isEmpty(assets) ? [assets?.[0]?.source] : undefined,
      type: 'upload',
    },
    {
      name: 'basePrice',
      title: t('product.form.basePrice'),
      placeholder: '0.00',
      prefix: 'RM',
      type: 'number',
      willHide: true,
      required: true,
    },
    {
      name: 'quantity',
      title: t('product.form.quantity'),
      placeholder: t('product.placeholder.quantity', {
        type: placeholderType,
      }),
      type: 'number',
      willHide: true,
      required: true,
    },
    ...(type === 'products'
      ? ([
          {
            name: 'enable',
            title: t('product.form.enable'),
            type: 'switch',
            defaultValue: !isEmpty(product) ? enabled : true,
            hint: t('warnings.disableProduct'),
            onChange: !isEmpty(product)
              ? (val: boolean) => {
                  const mainVariants = watch('variants') as ProductVariantFormInput[];
                  mainVariants?.forEach((subItem, index) => {
                    setValue(`variants.${index}.enabled`, val);
                  });
                  setProductVariants &&
                    setProductVariants((prev) => {
                      const updatedVariants = [...(prev || [])] as ProductVariantFormInput[];
                      updatedVariants?.forEach((subItem, index) => {
                        updatedVariants[index]['enabled'] = val;
                      });
                      return updatedVariants;
                    });
                }
              : undefined,
          },
          {
            title: t('product.form.salesChannel'),
            name: 'shipping',
            type: 'checkbox',
            defaultValue: !isEmpty(product)
              ? product?.customFields?.fulfillChannels?.map((subItem) => subItem?.id)
              : undefined,
            options: shippingData?.map((res) => {
              return {
                label: t(`product.fulfillmentMethod.${res?.name?.toLowerCase()}`),
                value: res?.id,
              };
            }),
            required: true,
          },
          {
            title: t('product.form.collections'),
            render: () => {
              return (
                <Controller
                  name="categories"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  defaultValue={facetValues?.map((subItem) => {
                    return `${subItem?.id}-split-${subItem?.name}`;
                  })}
                  render={({ field: { onChange, value }, fieldState: { error } }) => {
                    const collectionFacetValues = (facetValues || []).filter(
                      (fv) => fv.facet.code === CollectionFacetCode
                    );

                    return (
                      <FieldGroup className="my-4">
                        <Label showRequiredIndicator>{t('product.form.collections')}</Label>
                        <div className="w-full">
                          <MultiCollectionSelection
                            isWithName
                            required
                            initialOption={collectionFacetValues.map((subItem) => {
                              return {
                                label: subItem?.name,
                                value: `${subItem?.id}-split-${subItem?.name}`,
                              };
                            })}
                            onSelect={onChange}
                            value={value}
                          />
                          {!isEmpty(error) && (
                            <div className="mt-2">
                              <p className="text-error-300 text-sm mt-3">
                                {t('product.errors.collectionsRequired')}
                              </p>
                            </div>
                          )}
                        </div>
                      </FieldGroup>
                    );
                  }}
                />
              );
            },
          },
          {
            title: t('product.form.modifierGroups'),
            render: () => {
              const unsortedModifierGroups =
                facetValues?.filter((fv) => fv.facet.code === ModifierFacetCode) || [];
              const allModifierGroups = sortProductModifierGroup(
                unsortedModifierGroups,
                modifierGroupSorting || []
              );

              return (
                <Controller
                  name="modifierGroups"
                  control={control}
                  defaultValue={allModifierGroups.map((fv) => fv.id)}
                  render={({ field: { onChange, value }, fieldState: { error } }) => {
                    return (
                      <div className="w-full">
                        <MultiModifierGroupSelection
                          isField
                          fieldLabel={t('product.form.modifierGroups')}
                          initialOption={allModifierGroups.map((fv) => ({
                            label: fv.name,
                            value: fv.id,
                          }))}
                          onSelect={onChange}
                          value={value}
                        />
                      </div>
                    );
                  }}
                />
              );
            },
          },
        ] as FormItemProps[])
      : []),
  ];
};
