import { createContext } from 'react';
import { Channel } from '~/api';

export type ChannelContextProps = {
  loading: boolean;
  error?: string;
  channel?: Channel | null;
  changeChannel: (token: string) => void;
  /**
   * @description
   * return the currency used for table column header. 
   * If channel have more than 1 available currency, it'll return empty string
   * since it should return the respective currency in each row.
   * @example
   * (MYR)
   */
  currencyForColHeader: () => string;
  /**
   * @description
   * channel only have one available currency
   * `channel.availableCurrencyCodes.length === 1`
   */
  onlyOneCurrency: boolean;
};

export const ChannelContext = createContext<ChannelContextProps>({
  loading: false,
  changeChannel: () => null,
  onlyOneCurrency: true,
  currencyForColHeader: () => '',
});
