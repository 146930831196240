import React from 'react';
import { IconButton } from '@scalingworks/react-admin-ui';
import { Props } from './props';
import { useTranslate } from '@refinedev/core';
import { MdRefresh } from 'react-icons/md';

export const IframeRefreshButton: React.FC<Props> = (props) => {
  const { onClick } = props;

  // ================== HOOKS
  const t = useTranslate();

  // ================== VIEWS
  return (
    <IconButton className="flex flex-row items-center" onClick={onClick}>
      <h3 className="mx-2">{t('actions.refresh', {}, 'Refresh')}</h3>
      <MdRefresh size={30} className="!text-smoke-600" />
    </IconButton>
  );
};
