import React, { useRef, useState } from 'react';
import { ResourceField, createHelpers, createResource } from '@scalingworks/refine-react-admin';
import '@szhsin/react-menu/dist/index.css';
import { FacetValue } from '~/api';
import { ActionButton, TriggerConfirmModal, ActionButtonRefProps } from 'components';
import { resourceNames } from '../../resource-names';
import { ModifierGroupCreatePage } from './create';
import { ModifierGroupShowPage } from './show';
import { useTranslate } from '@refinedev/core';
import { FiFolderPlus } from "react-icons/fi";

const { defineFields, defineCardSection, defineShowPage, defineFilterControls } =
  createHelpers<FacetValue>({
    resourceName: resourceNames.modifierGroup,
  });

const defaultValues = {
  name: '',
  description: '',
};

const modifierGroupFields: ResourceField<FacetValue>[] = [
  'id',
  'createdAt',
  'updatedAt',
  'name',
  'code',
  { customFields: ['minModifier', 'maxModifier'] },
];

// NOTE: This is actually facetValues under Facet.code `modifier`
export const modifierGroupResource = () => {
  return createResource({
    name: resourceNames.modifierGroup,
    label: 'Modifier Group',
    icon: <FiFolderPlus />,
    // @ts-ignore
    fields: defineFields(modifierGroupFields),
    defaultValues,
    defaultPageSize: 25,
    defaultSorter: [{ field: 'createdAt', order: 'asc' }],
    allowSearch: true,
    allowDelete: true,
    filterConfig: {
      alwaysExpanded: true,
    },
    createConfig: {
      title: ({ t }) =>
        t('modifierGroup.create.name', { fallback: 'Create Modifier Group', ns: 'common' }),
    },
    searchConfig: {
      placeholder: ({ t }) =>
        t('modifierGroup.placeholder.search', {
          fallback: 'Search by modifier group name',
          ns: 'common',
        }),
    },
    columns: ({ LinkToDetails, navigateToEdit, invokeDelete, navigateToDetails, t }) => {
      return [
        {
          id: 'name',
          header: t('modifier.column.name', { fallback: 'Name', ns: 'common' }),
          cell: (data) => {
            const { id, name } = data.row.original;
            return (
              <LinkToDetails resourceId={id}>
                <span>{name}</span>
              </LinkToDetails>
            );
          },
        },
        {
          id: 'actions',
          header: () => '',
          accessorKey: 'id',
          enableSorting: false,
          cell: (data) => {
            const { id: modifierGroupId } = data?.row?.original;
            const actionButtonRef = useRef<ActionButtonRefProps>(null);
            const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
            return (
              <ActionButton
                ref={actionButtonRef}
                actions={[
                  {
                    label: t('actions.edit', { fallback: 'Edit', ns: 'common' }),
                    name: 'edit',
                    onAction: () => navigateToDetails({ id: modifierGroupId }),
                  },
                  // TODO: allow delete after it's stable
                  // {
                  //   label: t('actions.delete', { fallback: 'Delete', ns: 'common' }),
                  //   name: 'delete',
                  //   onAction: () => invokeDelete({ id: modifierGroupId }),
                  //   render: (onAction) => {
                  //     return (
                  //       <button type="button">
                  //         <TriggerConfirmModal
                  //           visible={showDeleteConfirmation}
                  //           onOpenChange={(val) => {
                  //             const actionButtonSetOpen = actionButtonRef?.current?.setOpen;
                  //             setShowDeleteConfirmation(val);
                  //             actionButtonSetOpen && actionButtonSetOpen(val);
                  //           }}
                  //           onPressConfirm={onAction}
                  //         />
                  //       </button>
                  //     );
                  //   },
                  // },
                ]}
              />
            );
          },
        },
      ];
    },
    create: {
      render: () => {
        return <ModifierGroupCreatePage />;
      },
    },
    show: defineShowPage({
      component: (props) => {
        return <ModifierGroupShowPage queryResult={props.queryResult} />;
      },
    }),
  });
};
