import { CustomResponse, HttpError } from '@refinedev/core';
import { QueryKey, UseQueryOptions } from '@tanstack/react-query';
import { OutletFulfillmentByDayAndType } from '~/api';

export enum OrderStatus {
  'Draft' = 'Draft',
  'Pending' = 'Pending',
  'Confirmed' = 'Confirmed',
  'Preparing' = 'Preparing',
  'Shipping' = 'Shipping',
  'Completed' = 'Completed',
  'Cancelled' = 'Cancelled',
}

export type OrderStates =
  // Grouped AS:
  // ============= Draft
  | 'Draft'
  | 'Created'
  | 'AddingItems'
  | 'Modifying'

  // ============= Cancelled
  | 'Cancelled'

  // ============= Accepted
  | 'ArrangingPayment'
  | 'PaymentAuthorized'
  | 'ArrangingAdditionalPayment'
  | 'PartiallyShipped'
  | 'PartiallyDelivered'
  | 'PaymentSettled'
  | 'Preparing'
  | 'Shipped'

  // ============= Completed
  | 'Delivered'

  // ============= Refunded
  | 'Refunded';

export type TranslateFunction = {
  (key: string, options?: any, defaultMessage?: string | undefined): string;
  (key: string, defaultMessage?: string | undefined): string;
};

export type QueryOptions<T> =
  | UseQueryOptions<CustomResponse<T>, HttpError, CustomResponse<T>, QueryKey>
  | undefined;

export type PaymentStates = 'Authorized' | 'Arranging' | 'Settled' | 'Cancelled' | 'Error' | 'Declined';
