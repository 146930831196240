export const resourceNames = {
  dashboard: 'dashboard',
  customer: 'customers',
  reservation: 'reservations',
  product: 'products',
  order: 'orders',
  outlet: 'outlet',
  productReviews: 'productReviews',
  reward: 'rewards',
  promoCode: 'promoCodes',
  staff: 'administrators',
  pointTransaction: 'pointTransactionHistories',
  redemptionHistory: 'redemptionHistories',
  membershiptier: 'membershipTiers',
  discountPromotion: 'discountPromotions',
  banner: 'banners',
  collection: 'collections',
  shippingMethod: 'shippingMethods',
  setting: 'settings',
  notificationBlast: 'notificationBlasts',
  modifier: 'modifiers',
  modifierGroup: 'modifierGroups',
  auditLog: 'auditLogs',
  reservationCalendar: 'reservationCalendar',
  profile: 'profile',
  report: 'reports',
} as const;
