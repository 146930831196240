import React from 'react';
import { Props } from './props';
import {
  FileItem,
  FileUpload,
  ImageItem,
  NumberField,
  Switch,
  Table,
  TextField,
} from '@scalingworks/react-admin-ui';
import { Controller } from 'react-hook-form';
import { RiDeleteBin5Line } from 'react-icons/ri';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { VariantItemProps, renderInvisibleId } from '../config';
import { CommonInputType, ProductVariantFormInput, ProductVariantFormInputKey } from '../props';
import { useTranslate } from '@refinedev/core';
import camelCase from 'lodash/camelCase';

export type VariantTableProps = Props;

export const VariantTable: React.FC<Props> = (props) => {
  const {
    data,
    items,
    productVariants,
    registerKey,
    setProductVariants,
    setValue,
    control,
    productName,
    allowDelete = true,
    forceRerender,
    watch,
  } = props;

  const t = useTranslate();

  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          {items.map(({ title }) => (
            <Table.Th>{t(`productVariant.column.${camelCase(title)}`)}</Table.Th>
          ))}
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {productVariants?.map((variantItem, index) => {
          const variantRegisterKey = `${registerKey}.${index}`;
          const deleteVariants = () => {
            setProductVariants((prev) => {
              if (!prev) return;
              const newArr = [...prev];
              newArr?.splice(index, 1);
              return newArr;
            });
          };

          const onChangeInput = ({
            key,
            value,
          }: {
            key?: ProductVariantFormInputKey;
            value: CommonInputType;
          }) => {
            if (!key) return;
            setValue && setValue(`${variantRegisterKey}.${key}`, value);
            setProductVariants((prev) => {
              if (!prev) return;
              const updatedVariants = [...prev] as ProductVariantFormInput[];
              updatedVariants[index][key] = value as string;
              return updatedVariants;
            });
          };

          const renderInput = (item: VariantItemProps) => {
            const { type, key: itemKey } = item;
            const key = itemKey as ProductVariantFormInputKey;
            const controllerProps = {
              control,
              name: `${variantRegisterKey}.${key}`,
              defaultValue: variantItem[key],
            };
            switch (type) {
              case 'text':
                return (
                  <Controller
                    {...controllerProps}
                    render={() => {
                      return <p>{`${variantItem[key]}`}</p>;
                    }}
                  />
                );
              case 'number':
                return (
                  <Controller
                    {...controllerProps}
                    render={() => {
                      return (
                        <NumberField
                          prefix={item?.prefix}
                          value={variantItem[key]}
                          onChange={(event) => onChangeInput({ key, value: event?.target?.value })}
                          required
                        />
                      );
                    }}
                  />
                );
              case 'upload':
                return (
                  <Controller
                    {...controllerProps}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <>
                          {isEmpty(value) ? (
                            <FileUpload hint="" label="" onFilesSelected={onChange} />
                          ) : (
                            <div>
                              {value?.map((subItem: any, index: number) => {
                                if (typeof subItem === 'string') {
                                  return (
                                    <ImageItem
                                      url={subItem}
                                      imageViewerClassName="max-h-20"
                                      onRemove={() => {
                                        const cloneArr = cloneDeep(value);
                                        cloneArr?.splice(index, 1);
                                        setValue?.(controllerProps?.name, cloneArr);
                                      }}
                                    />
                                  );
                                }
                                return (
                                  <FileItem
                                    file={subItem}
                                    imageViewerClassName="max-h-20"
                                    onRemove={() => {
                                      const cloneArr = cloneDeep(value);
                                      cloneArr?.splice(index, 1);
                                      setValue?.(controllerProps?.name, cloneArr);
                                    }}
                                  />
                                );
                              })}
                            </div>
                          )}
                        </>
                      );
                    }}
                  />
                );
              case 'switch':
                return (
                  <Controller
                    {...controllerProps}
                    render={() => {
                      return (
                        <Switch
                          onCheckedChange={async (value) => {
                            await onChangeInput({ key, value });

                            const currentVariants = (await watch(
                              'variants'
                            )) as ProductVariantFormInput[];
                            const allDisabled = currentVariants?.every(
                              (item) => item.enabled === false
                            );
                            if (allDisabled) {
                              setValue && (await setValue('enable', false));
                            } else {
                              setValue && (await setValue('enable', true));
                            }
                            forceRerender && forceRerender();
                          }}
                          checked={!!variantItem[key]}
                        />
                      );
                    }}
                  />
                );
              case 'custom':
                return (
                  <div>
                    <button type="button" onClick={deleteVariants}>
                      <RiDeleteBin5Line color="red" size={25} />
                    </button>
                  </div>
                );
              default:
                return <TextField />;
            }
          };

          return (
            <Table.Tr key={`${variantItem?.name}_${index}`}>
              {!isEmpty(data) &&
                renderInvisibleId({
                  key: `${variantRegisterKey}`,
                  control,
                  defaultValue: variantItem['id'],
                })}
              {items.map((item, index) => (
                <Table.Td width={item?.width}>{renderInput(item)}</Table.Td>
              ))}
            </Table.Tr>
          );
        })}
      </Table.Tbody>
    </Table>
  );
};
