import { createResourceListingPage } from '@scalingworks/refine-react-admin';
import React, { useRef } from 'react';
import {
  PromoCode,
  RedemptionHistory,
  RedemptionHistoryType as RedemptionType,
  Reward,
} from '~/api';
import { resourceNames } from '~/resources/resource-names';
import { Props } from './props';
import { ActionButton, ActionButtonRefProps, ActionModalWithTrigger, Loading } from '~/components';
import { dateFormatter, removeUnderscore, toCamelCaseWord } from '~/resources/helpers';
import { FullDateTimeFormat } from '~/config/constant';
import { Checkbox, ImageViewer } from '@scalingworks/react-admin-ui';
import { renderTextWithPrefix } from '~/resources/loyalty/helpers';
import { numeralThousandFormat } from '~/config/helper';
import startCase from 'lodash/startCase';
import { useApiUrl, useCustomMutation, useTranslate, useUpdate } from '@refinedev/core';

export const CustomerRedemptionHistory: React.FC<Props> = (props) => {
  const { loading, userId } = props;

  // ==================== HOOKS
  /**
   * NOTE: This table utilize the redemption resource's
   * data provider overrided config
   */
  const listing = createResourceListingPage<RedemptionHistory>({
    showTitle: false,
    customHelmet: 'Customer',
    resourceName: resourceNames.redemptionHistory,
    fields: ['id', 'createdAt', 'isUsed', 'pointCost', 'redeemerRole', 'type', 'usedDate'],
    allowCreate: false,
    allowSearch: false,
    filterConfig: { alwaysExpanded: true },
    defaultPageSize: 10,
    defaultSorter: [{ field: 'createdAt', order: 'desc' }],
    extraMeta: {
      userId,
    },
    filterControls: {
      type: {
        // @ts-ignore
        type: 'select',
        label: 'Type',
        operator: 'eq',
        config: {
          label: 'Type',
          placeholder: 'Select type',
          // @ts-ignore
          options: Object.keys(RedemptionType).map((key) => ({
            label: startCase(key),
            value: RedemptionType[key as keyof typeof RedemptionType],
          })),
        },
      },
      createdAt: {
        type: 'daterange',
        config: {
          label: 'Redeemed at',
        },
      },
      usedDate: {
        type: 'daterange',
        config: {
          label: 'Used at',
        },
      },
    },
    columns: ({ refetchData }) => [
      {
        id: 'rewards',
        header: 'Rewards',
        cell: (data) => {
          const { id, reward, promoCode } = data.row.original;
          const { name, imageUrls } = (reward || {}) as Reward;
          const { name: promoName, imageUrls: promoImages } = (promoCode || {}) as PromoCode;
          return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <ImageViewer
                src={imageUrls?.[0] || promoImages?.[0]}
                className="!w-14 !h-14 rounded-lg object-cover mr-2"
                alt="Reward"
              />
              <span>{name || promoName}</span>
            </div>
          );
        },
      },
      {
        id: 'type',
        header: 'Type',
        cell: (data) => {
          const { id, type } = data.row.original;
          return (
            <div>
              <span>{toCamelCaseWord(removeUnderscore(type))}</span>
            </div>
          );
        },
      },
      {
        id: 'createdAt',
        header: 'Redeemed at',
        cell: (data) => {
          const { id, createdAt } = data.row.original;
          return (
            <div>
              <span>{dateFormatter(createdAt, FullDateTimeFormat)}</span>
            </div>
          );
        },
      },
      {
        id: 'usedAt',
        header: 'Used at',
        cell: (data) => {
          const { id, usedDate } = data.row.original;
          return (
            <div>
              <span>{dateFormatter(usedDate, FullDateTimeFormat)}</span>
            </div>
          );
        },
      },
      {
        id: 'points',
        header: 'Points',
        cell: (data) => {
          const { id, pointCost } = data.row.original;
          return (
            <div>
              <span>
                {renderTextWithPrefix({
                  prefixText: 'PTS',
                  text: numeralThousandFormat(pointCost),
                  prefixPosition: 'right',
                })}
              </span>
            </div>
          );
        },
      },
      {
        id: 'actions',
        header: () => <div />,
        accessorKey: 'id',
        enableSorting: false,
        cell: (data) => {
          const actionButtonRef = useRef<ActionButtonRefProps>(null);
          const apiUrl = useApiUrl();
          const t = useTranslate();
          const { mutate: markAsUsed } = useCustomMutation();

          const onMarkAsUsed = () => {
            markAsUsed(
              {
                url: apiUrl,
                method: 'post',
                metaData: {
                  fields: ['id'],
                  operation: 'markAsUsed',
                  variables: {
                    input: {
                      type: 'UpdateRedemptionHistoryIsUsedInput!',
                      value: {
                        redemptionHistoryId: data.row.original.id,
                        rewardId: data.row.original.reward?.id,
                        promoCodeId: data.row.original.promoCode?.id,
                        isUsed: 'true',
                      },
                    },
                  },
                },
                successNotification: {
                  message: t('customer.membership.markSuccess', {}, 'Successfully marked as used'),
                  type: 'success',
                },
                errorNotification: {
                  message: t('customer.membership.markFailed', {}, 'Failed to update status'),
                  type: 'error',
                },
                values: {},
              },
              {
                onSuccess: () => {
                  refetchData();
                },
              }
            );
          };

          return (
            <ActionButton
              ref={actionButtonRef}
              actions={[
                {
                  label: 'Mark as used',
                  name: 'markAsUsed',
                  onAction: () => onMarkAsUsed(),
                  render: (onAction) => {
                    return (
                      <button onClick={onAction} type="button">
                        Mark as used
                      </button>
                    );
                  },
                },
              ]}
            />
          );
        },
      },
    ],
  });
  // ==================== VIEWS
  if (loading) return <Loading />;
  if (!userId) return null;
  return listing();
};
