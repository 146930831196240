import {
  Form,
  ResourceField,
  createHelpers,
  createResource,
} from '@scalingworks/refine-react-admin';
import { FiSettings } from 'react-icons/fi';

import { resourceNames } from '../resource-names';
import { Administrator, Channel, ShippingMethod, UpdateActiveAdministratorInput } from '~/api';
import {
  useApiUrl,
  useCustom,
  useCustomMutation,
  useGetIdentity,
  useNotification,
  useTranslate,
} from '@refinedev/core';
import { Loading, ShowPageWrapper } from '~/components';
import { Button, Card } from '@scalingworks/react-admin-ui';
import { useForm } from '@refinedev/react-hook-form';
import { FormTextField } from '@scalingworks/refine-react-admin/src/modules/form/components/form-text-field';
import { FormPhoneField } from '@scalingworks/refine-react-admin/src/modules/form/components/form-phone-field';
import { VendureUserIdentity } from '@scalingworks/refine-react-admin/src/types';
import head from 'lodash/head';
import { parsePhoneNumber } from 'libphonenumber-js';
import { MdOutlineManageAccounts } from 'react-icons/md';

/**
 * NOTE:
 * the entire setting resource is using a shipping method
 * with custom rendered listing / create / edit page
 * there are other misc setting in listing as well
 */

export const Post_Code_Arg = 'postCode';

export const shippingMethodFields: ResourceField<ShippingMethod>[] = [
  'id',
  'code',
  'createdAt',
  'name',
  { checker: ['code', { args: ['name', 'value'] }] },
  { calculator: ['code', { args: ['name', 'value'] }] },
  { customFields: [{ tierRange: ['id', 'minQuantity', 'maxQuantity', 'value'] }] },
];

const { defineShowPage } = createHelpers<ShippingMethod>({
  resourceName: resourceNames.profile,
});

const Profile: React.FC = () => {
  const url = useApiUrl();
  const {
    data: profile,
    isLoading: loadingProfile,
    refetch: refetchProfile,
  } = useCustom<Administrator>({
    method: 'get',
    url,
    meta: {
      fields: [
        'id',
        'firstName',
        'lastName',
        { customFields: ['phoneNumber'] },
      ] as ResourceField<Administrator>[],
      operation: 'activeAdministrator',
    },
  });

  const { mutate: updateProfile, isLoading: updatingProfile } = useCustomMutation();

  const form = useForm();
  const t = useTranslate();
  const notif = useNotification();
  const { id, firstName, lastName, customFields } = profile?.data || {};
  const { phoneNumber } = customFields || {};

  const onUpdate = (data: any) => {
    const parsed = parsePhoneNumber(data.phoneNumber);
    if (!parsed.isValid()) {
      notif?.open?.({
        message: t('messages.invalidPhoneFormat', {}, 'Invalid Phone Format'),
        type: 'error',
      });
      return;
    }

    updateProfile(
      {
        method: 'post',
        url,
        values: {},
        meta: {
          fields: ['id'],
          operation: 'updateActiveAdministrator',
          variables: {
            input: {
              value: {
                firstName: data.firstName,
                lastName: data.lastName,
                customFields: {
                  phoneNumber: data.phoneNumber,
                },
              } as UpdateActiveAdministratorInput,
              type: 'UpdateActiveAdministratorInput!',
            },
          },
        },
        errorNotification: {
          message: t('profile.update.failed', 'Failed to update profile'),
          type: 'error',
        },
        successNotification: {
          message: t('profile.update.success', 'Profile updated'),
          type: 'success',
        },
      },
      {
        onSuccess: () => {
          refetchProfile();
        },
      }
    );
  };

  return (
    <ShowPageWrapper
      resourceName={resourceNames.profile}
      title={t('profile.name', 'Profile')}
      showBackButton={false}
      extra={
        <Button variant="solid" loading={updatingProfile} onClick={form.handleSubmit(onUpdate)}>
          Update
        </Button>
      }
    >
      <section className="flex flex-col w-full min-h-screen items-center">
        {loadingProfile ? (
          <Loading />
        ) : (
          <Card className="w-full">
            <Card.Header bordered>
              <h3 className="font-bold">General</h3>
            </Card.Header>
            <Card.Body>
              <Form form={form}>
                <div className="flex flex-col space-y-4">
                  <FormTextField
                    label={t('profile.form.firstName')}
                    name="firstName"
                    defaultValue={firstName}
                  />
                  <FormTextField
                    label={t('profile.form.lastName')}
                    name="lastName"
                    defaultValue={lastName}
                  />
                  <FormPhoneField
                    label={t('profile.form.contact', 'Contact No.')}
                    name="phoneNumber"
                    defaultValue={phoneNumber || ''}
                  />
                </div>
              </Form>
            </Card.Body>
          </Card>
        )}
      </section>
    </ShowPageWrapper>
  );
};

export const profileResource = (channel?: Channel) => {
  return createResource({
    name: resourceNames.profile,
    label: 'Profile',
    icon: <MdOutlineManageAccounts />,
    defaultValues: {},
    fields: ['id'],
    columns: () => [],
    allowCreate: false,
    allowDelete: false,
    allowEdit: false,
    allowSearch: false,
    list: {
      render: () => {
        return <Profile />;
      },
    },
    show: defineShowPage({
      component: () => {
        return <Profile />;
      },
    }),
  });
};
