import React, { useContext, useEffect, useState } from 'react';
import { currentChannelToken } from '../auth-provider';
import { ImageViewer, Select } from '@scalingworks/react-admin-ui';
import { SellerContext } from '~/providers/Seller/context';
import { head, isEmpty, startCase } from 'lodash';
import { ChannelContext } from '~/providers/Channel/context';

// Avoid calling guarded api here using gqlClient
//  as it might race with authentication
export const SidebarHeader: React.FC = () => {
  // ==================== HOOKS
  const { loading, getCurrentSeller } = useContext(SellerContext);
  const { changeChannel } = useContext(ChannelContext);

  // ==================== STATES
  const tokenFromStorage = localStorage.getItem(currentChannelToken);
  const [selectedToken, setSelectedToken] = useState(tokenFromStorage);

  // ==================== VARIABLES
  const seller = getCurrentSeller();
  const sellerChannels = seller?.channels || [];

  const [imgErr, setImgErr] = useState(false);

  // ==================== EVENTS
  const onChangeChannel = (channelToken: string) => {
    setSelectedToken(channelToken);
    changeChannel(channelToken);
  };

  // ==================== EFFECTS
  // If image link changes reset error and try again
  useEffect(() => {
    if (seller?.logo) setImgErr(false);
  }, [seller?.logo]);

  useEffect(() => {
    if (!selectedToken && seller) {
      onChangeChannel(head(seller?.channels)?.token || '');
    }
  }, [loading, seller, onChangeChannel, selectedToken]);

  // ==================== VIEWS
  return (
    <div className="px-4 pb-3">
      {seller?.logo && !imgErr && (
        <ImageViewer onError={(ev) => setImgErr(true)} src={seller.logo} alt={seller?.name} />
      )}
      {!isEmpty(seller) && sellerChannels.length > 1 && (
        <Select
          className="!bg-secondary-100"
          options={sellerChannels.map((channel) => ({
            label: channel.name || startCase(channel.code),
            value: channel.token,
          }))}
          value={selectedToken || ''}
          onValue={(newChannelToken) => {
            onChangeChannel(newChannelToken);
          }}
        />
      )}
    </div>
  );
};
