import { gql } from 'graphql-request';
import { GQLClient, GQLClientHeader } from './gql-client';
import { AuthBindings } from '@refinedev/core';
import { SalesChannel, getSdk } from '~/api';

export const authStorageKey = 'auth';
export const subscriberStorageKey = 'subscriber_id';
export const currentChannelToken = 'current_channel_token';
export const currentSellerId = 'current_seller_id';

export const createAuthProvider = (): AuthBindings => {
  const gqlClient = GQLClient.getInstance();
  return {
    login: async ({ phoneNumber, otp, type }) => {
      try {
        // NOTE: cannot use useContext hooks here because not a FC
        const query = gql`
          mutation verifyMobileOtp($input: VerifyOTPVerifyInput!) {
            verifyMobileOtp(input: $input) {
              status
              token
            }
          }
        `;

        const variables = {
          phoneNumber,
          type,
          otp,
        };

        const rawResponse = await gqlClient.rawRequest(query, { input: variables });
        if (!rawResponse.data?.verifyMobileOtp) {
          throw new Error(`Something went wrong`);
        }
        if (rawResponse.data?.verifyMobileOtp?.status === 'INVALID') {
          throw new Error(`Invalid OTP`);
        }
        let authToken = rawResponse?.data?.verifyMobileOtp?.token || '';

        if (!authToken) {
          authToken = rawResponse.headers.get('vendure-auth-token') || '';
        }

        gqlClient.setHeaders({
          [GQLClientHeader.authorization]: `Bearer ${authToken}`,
          [GQLClientHeader.salesChannel]: SalesChannel.Backoffice,
        });

        const adminSubProfile = await getSdk(gqlClient).getAdminSubscriberProfile();
        localStorage.setItem(authStorageKey, authToken);
        localStorage.setItem(
          subscriberStorageKey,
          adminSubProfile.getAdminSubscriberProfile?.subscriberId || ''
        );

        // Get admin seller & channel info
        const adminInfo = await getSdk(gqlClient).activeAdministrator();
        // Channel token will be set to localStorage in SwitchChannel component in SideBar setup
        // after seller is fully loaded, can't do it here since seller yet to fully load here

        const sellerId = adminInfo.activeAdministrator?.customFields?.seller?.id || '';
        if (!sellerId) {
          throw new Error('Invalid Seller');
        }
        localStorage.setItem(currentSellerId, sellerId);

        // NOTE: simply add an alert here for dev purpose if adding any console.log above
        // else it'll redirect to new page and clear log
        // OR just use `preserve log` setting in browser
        // alert('break')
        return Promise.resolve({ success: true });
      } catch (error: any) {
        console.log('login ERROR', error);
        return Promise.resolve({ success: false, error });
      }
    },
    logout: () => {
      localStorage.removeItem(authStorageKey);
      localStorage.removeItem(subscriberStorageKey);
      localStorage.removeItem(currentChannelToken);
      localStorage.removeItem(currentSellerId);
      setTimeout(() => window.location.reload(), 500);
      return Promise.resolve({ success: true, redirectTo: '/login' });
    },
    onError: (error) => {
      if (error?.message?.includes?.('not currently authorized')) {
        const token = localStorage.getItem(authStorageKey);
        if (!token) {
          return Promise.reject('/login');
        }
      }
      return Promise.resolve({});
    },
    check: () => {
      const token = localStorage.getItem(authStorageKey);

      if (token) {
        return Promise.resolve({ authenticated: true });
      }

      return Promise.reject({ redirectPath: '/login' });
    },
    getPermissions: () => Promise.resolve(),
    getIdentity: async () => {
      const authToken = localStorage.getItem(authStorageKey);
      const channelToken = localStorage.getItem(currentChannelToken);

      if (!authToken || !channelToken) {
        return Promise.reject();
      }

      gqlClient.setHeaders({
        [GQLClientHeader.salesChannel]: SalesChannel.Backoffice,
        [GQLClientHeader.authorization]: `Bearer ${authToken}`,
        [GQLClientHeader.vendureToken]: channelToken,
      });

      try {
        const query = gql`
          {
            me {
              id
              identifier
              channels {
                id
                token
                code
                permissions
              }
            }
          }
        `;

        const response = await gqlClient.request(query);

        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject();
      }
    },
  };
};
