import React, { useState } from 'react';
import { Props } from './props';
import { DetailRenderer, RedeemReward, ShowPageWrapper, SomethingWentWrong } from '~/components';
import {
  useApiUrl,
  useCustom,
  useNavigation,
  useNotification,
  useOne,
  useTranslate,
} from '@refinedev/core';
import { resourceNames } from '~/resources/resource-names';
import { BsChevronUp } from 'react-icons/bs';
import { Button, Card, Checkbox, IconButton, Tabs, Tag } from '@scalingworks/react-admin-ui';
import { customerFields, statusColor } from '../customer-resource';
import { Customer, Membership, NotificationSettingList, NovuSubscriberPreference } from '~/api';
import { ResourceField } from '@scalingworks/refine-react-admin';
import { formatFullName, sortUserAddress } from '~/resources/helpers';
import { FiEdit3 } from 'react-icons/fi';
import { MembershipBar } from './membership';
import { CustomerOrder } from './order';
import { CustomerPointTransactions } from './point-transactions';
import { AddressContent } from './general/address';
import { CustomerRedemptionHistory } from './redemption-history';

export const CustomerShowPage: React.FC<Props> = (props) => {
  const { resourceId: customerId } = props;

  // ======================= API
  const apiUrl = useApiUrl();
  const {
    data: customer,
    isLoading,
    refetch,
  } = useOne<Customer>({
    id: customerId,
    resource: resourceNames.customer,
    metaData: {
      fields: [
        ...customerFields,
        {
          addresses: [
            'id',
            'defaultBillingAddress',
            'defaultShippingAddress',
            'fullName',
            'phoneNumber',
            'streetLine1',
            'streetLine2',
            'postalCode',
            'city',
            'province',
            { country: ['name'] },
          ],
          orders: [{ items: ['id'] }],
          user: ['id'],
        },
      ] as ResourceField<Customer>[],
    },
  });

  const { firstName, lastName, emailAddress, phoneNumber, customFields, user, orders } =
    customer?.data || {};
  const fullName = formatFullName(firstName, lastName);
  const { status } = customFields || {};
  const sortedAddress = sortUserAddress(customer?.data?.addresses || []);

  const { data: membership, refetch: refetchMembership } = useCustom<Membership>({
    url: apiUrl,
    method: 'get',
    metaData: {
      fields: [
        'pointBalance',
        { tierProgress: [{ currentTier: ['id'] }] },
      ] as ResourceField<Membership>[],
      operation: 'getMembership',
      variables: {
        userId: {
          value: user?.id,
          type: 'String!',
        },
      },
    },
    queryOptions: {
      enabled: !!user && !!user.id,
    },
  });

  const { data: novuPreference, isLoading: loadingNovuPreference } = useCustom<
    NovuSubscriberPreference[]
  >({
    method: 'get',
    url: apiUrl,
    meta: {
      operation: 'getCustomerSubscriberPreference',
      fields: [
        { preference: ['enabled'], template: ['_id', 'name'] },
      ] as ResourceField<NovuSubscriberPreference>[],
      variables: {
        customerId: {
          value: customerId,
          type: 'ID',
        },
      },
    },
  });
  const customerPreferenceObj: { [key: string]: boolean } =
    novuPreference?.data?.reduce((result, item) => {
      return { ...result, [item.template.name]: item.preference.enabled };
    }, {}) || {};

  // ======================= HOOKS
  const t = useTranslate();
  const navigateTo = useNavigation();
  const notification = useNotification();

  // ======================= STATES
  const [showGeneral, setShowGeneral] = useState(true);
  const [showOrder, setShowOrder] = useState(true);
  const [showLoyalty, setShowLoyalty] = useState(true);
  const [openRedeemReward, setOpenRedeemReward] = useState(false);

  // ======================= EVENTS
  const refetchAll = () => {
    refetch();
    refetchMembership();
  };

  // ======================= VIEWS
  const notificationRender = () => {
    const renderer = {};
    Object.keys(customerPreferenceObj).forEach((key) => {
      Object.assign(renderer, { [key]: () => notificationCheck(customerPreferenceObj[key]) });
    });

    return renderer;
  };
  const notificationCheck = (on: boolean) => {
    return (
      <span>
        <Checkbox checked={on} disabled />
      </span>
    );
  };
  if (!isLoading && !customerId) return <SomethingWentWrong />;
  return (
    <div className="overflow-y-scroll">
      {openRedeemReward && (
        <RedeemReward
          open
          setOpen={setOpenRedeemReward}
          customerName={fullName}
          userId={user?.id || ''}
          pointBalance={membership?.data?.pointBalance}
          onCompleted={refetchAll}
          currentTierId={membership?.data?.tierProgress?.currentTier?.id}
        />
      )}
      <ShowPageWrapper resourceName={resourceNames.customer} loading={isLoading} title={fullName}>
        <main className="flex flex-col space-y-8">
          {/* Membership */}
          <MembershipBar loading={isLoading} userId={user?.id || ''} onMutationComplete={refetchAll} />

          <Card className="relative z-0">
            <Card.Header
              className="font-bold cursor-pointer"
              bordered
              onClick={() => setShowGeneral(!showGeneral)}
            >
              <section className="flex flex-row items-center justify-between">
                <h1>{t('common.general', undefined, 'General')}</h1>
                <div className={`flex flex-row space-x-2`}>
                  <BsChevronUp
                    size={30}
                    className={`transition-transform ${showGeneral ? 'rotate-180' : 'rotate-0'}`}
                  />
                </div>
              </section>
            </Card.Header>
            {showGeneral && (
              <Card.Body>
                <Tabs.Root defaultValue="profile">
                  <Tabs.List>
                    <Tabs.Trigger value="profile">Profile</Tabs.Trigger>
                    <Tabs.Trigger value="address">Address</Tabs.Trigger>
                    <Tabs.Trigger value="notification">Notification</Tabs.Trigger>
                  </Tabs.List>
                  <Tabs.Content value="profile">
                    <section className="flex flex-row justify-between items-start py-2">
                      <DetailRenderer
                        resource="customer"
                        data={{
                          name: `${firstName} ${lastName}`,
                          contact: phoneNumber,
                          email: emailAddress,
                          status,
                        }}
                        render={{
                          status: (val) => (
                            <Tag className={`${statusColor[status as string]}`}>{val}</Tag>
                          ),
                        }}
                        loading={isLoading}
                      />
                      <div className="px-4">
                        <IconButton
                          onClick={() => navigateTo.edit(resourceNames.customer, customerId!)}
                        >
                          <FiEdit3 className="!text-success-300" size={30} />
                        </IconButton>
                      </div>
                    </section>
                  </Tabs.Content>
                  <Tabs.Content value="address">
                    <AddressContent
                      customerId={customerId}
                      customerFullName={fullName}
                      isLoading={isLoading}
                      sortedAddress={sortedAddress}
                      refetch={refetchAll}
                    />
                  </Tabs.Content>
                  <Tabs.Content value="notification">
                    <DetailRenderer
                      ignoreTranslations={true}
                      resource="notificationBlasts"
                      loading={loadingNovuPreference}
                      data={customerPreferenceObj}
                      // render={Object.keys(customerPreferenceObj).forEach(key => ())}
                      render={notificationRender()}
                    />
                  </Tabs.Content>
                </Tabs.Root>
              </Card.Body>
            )}
          </Card>

          <Card className="relative z-0">
            <Card.Header
              className="font-bold cursor-pointer"
              bordered
              onClick={() => setShowOrder(!showOrder)}
            >
              <section className="flex flex-row items-center justify-between">
                <h1>{t('customer.details.orders', undefined, 'Orders')}</h1>
                <div className="flex flex-row space-x-2">
                  <BsChevronUp
                    size={30}
                    className={`transition-transform ${showOrder ? 'rotate-180' : 'rotate-0'}`}
                  />
                </div>
              </section>
            </Card.Header>
            {showOrder && (
              <Card.Body className="px-0 py-0">
                <CustomerOrder
                  loading={isLoading}
                  customerId={customerId}
                  customerOrderIds={orders?.items?.map((order) => order.id)}
                />
              </Card.Body>
            )}
          </Card>

          <Card className="relative z-0">
            <Card.Header
              className="font-bold cursor-pointer"
              bordered
              onClick={() => setShowLoyalty(!showLoyalty)}
            >
              <section className="flex flex-row items-center justify-between">
                <h1>{t('customer.details.loyalty', undefined, 'Loyalty')}</h1>
                <div className="flex flex-row items-center space-x-2">
                  <Button
                    variant="solid"
                    size="sm"
                    onClick={(e) => {
                      e.stopPropagation(); // prevent from triggering header's onclick
                      setOpenRedeemReward(true);
                    }}
                  >
                    {t('customer.show.redeemReward', {}, 'Redeem Reward')}
                  </Button>
                  <BsChevronUp
                    size={30}
                    className={`transition-transform ${showLoyalty ? 'rotate-180' : 'rotate-0'}`}
                  />
                </div>
              </section>
            </Card.Header>
            {showLoyalty && (
              <Card.Body className="px-0 py-0">
                <Tabs.Root defaultValue="points">
                  <Tabs.List>
                    <Tabs.Trigger value="points">Points</Tabs.Trigger>
                    <Tabs.Trigger value="redemption">Rewards</Tabs.Trigger>
                  </Tabs.List>
                  <Tabs.Content value="points">
                    <CustomerPointTransactions
                      loading={isLoading}
                      userId={customer?.data?.user?.id || ''}
                    />
                  </Tabs.Content>
                  <Tabs.Content value="redemption">
                    <CustomerRedemptionHistory
                      loading={isLoading}
                      userId={customer?.data?.user?.id || ''}
                    />
                  </Tabs.Content>
                </Tabs.Root>
              </Card.Body>
            )}
          </Card>
        </main>
      </ShowPageWrapper>
    </div>
  );
};
