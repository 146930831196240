import { Channel } from '~/api';

export const onlyOneCurrency = (channel?: Channel | null) => {
  return channel?.availableCurrencyCodes?.length === 1;
};

export const currencyForColHeader = (channel?: Channel | null) => {
  if (!channel) return '';

  const oneCurrency = onlyOneCurrency(channel);

  if (channel && oneCurrency && channel.defaultCurrencyCode) {
    return `(${channel.defaultCurrencyCode})`;
  }

  return '';
};
