import { Card } from '@scalingworks/react-admin-ui';
import React, { useState } from 'react';
import { Props } from './props';
import { ActionButton, DetailRenderer, Divider, ModalWrapper } from '~/components';
import startCase from 'lodash/startCase';
import { useApiUrl, useCustomMutation, useNotification, useTranslate } from '@refinedev/core';
import { Action } from '~/components/ActionButton/props';
import { PaymentStates } from '~/config/types';
import { ResourceField } from '@scalingworks/refine-react-admin';
import { CurrencyCode, SettlePaymentResult } from '~/api';
import { PaymentRow } from '~/components/PaymentRow';
import isEmpty from 'lodash/isEmpty';
import { currentChannelToken } from '~/config/auth-provider';

export const OrderPaymentSection: React.FC<Props> = (props) => {
  const { isLoading, payments = [], refetch, currency = '' } = props;

  // ======================= HOOKS
  const apiUrl = useApiUrl();
  const t = useTranslate();
  const notif = useNotification();
  const { mutate, isLoading: mutating } = useCustomMutation();

  // ======================= STATES
  const [markPaidConfirmPayment, setMarkPaidConfirmPayment] = useState('');

  // ======================= VARIABLES
  const currentChannel = localStorage.getItem(currentChannelToken);

  // ======================= EVENTS
  const onMarkPaymentAsPaid = (paymentId: string) => {
    mutate(
      {
        url: apiUrl,
        method: 'post',
        meta: {
          fields: ['__typename'] as ResourceField<SettlePaymentResult>[],
          operation: 'settlePayment',
          variables: {
            id: {
              value: paymentId,
              type: 'ID!',
            },
          },
        },
        // Can't show success immediately since it might return error result with code 200 respond
        // successNotification: {}
        errorNotification: {
          message: t('order.details.failedUpdatePayment'),
          type: 'error',
        },
        values: {},
      },
      {
        onSuccess: (result) => {
          if (result?.data?.__typename === 'Payment') {
            setMarkPaidConfirmPayment('');
            refetch?.();
            notif?.open?.({
              message: t('order.details.markedPaymentAsPaid'),
              type: 'success',
            });
          }
        },
      }
    );
  };

  // ======================= VIEWS
  if (isEmpty(payments)) return null;
  return (
    <>
      <Card className="z-0">
        <Card.Header className="font-bold" bordered>
          <section className="flex flex-row items-center justify-between">
            <h1>{t('order.details.payment', undefined, 'Order')}</h1>
          </section>
        </Card.Header>
        <Card.Body>
          {payments?.map((payment, index) => {
            const { id: paymentId } = payment;
            const paymentActions: Action[] = [];

            // TODO: remove, temp hide for ybd
            if (currentChannel !== 'yellowbakeday') {
              if (payment.state === ('Arranging' as PaymentStates)) {
                paymentActions.push({
                  label: t('order.details.markAsPaid'),
                  name: 'mark-as-paid',
                  onAction: () => setMarkPaidConfirmPayment(paymentId),
                });
              }
            }

            return (
              <div className="flex flex-col space-y-3 mb-3">
                <PaymentRow payment={payment} actions={paymentActions} currency={currency} />
                {index < payments.length - 1 && <Divider />}
              </div>
            );
          })}
        </Card.Body>
      </Card>

      {!!markPaidConfirmPayment && (
        <ModalWrapper
          open={!!markPaidConfirmPayment}
          setOpen={() => setMarkPaidConfirmPayment('')}
          contentClassName="px-8 py-6"
          onConfirm={() => onMarkPaymentAsPaid(markPaidConfirmPayment)}
          title={t(`order.details.markPaymentPaidConfirm`)}
        >
          <div className="py-4" />
        </ModalWrapper>
      )}
    </>
  );
};
