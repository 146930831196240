import { createContext } from 'react';
import { GroupResource, PublicSeller, Seller, SellerResource } from '~/api';

export type SellerContextProps = {
  loading: boolean;
  error?: string;
  getCurrentSeller: () => PublicSeller | null;
  getResourceConfig: () => (GroupResource | SellerResource)[];
  refetchSeller: () => void;
};

export const SellerContext = createContext<SellerContextProps>({
  loading: false,
  getCurrentSeller: () => null,
  getResourceConfig: () => [],
  refetchSeller: () => null,
});
