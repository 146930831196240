import React, { useRef, useState } from 'react';
import { ResourceField, createHelpers, createResource } from '@scalingworks/refine-react-admin';
import '@szhsin/react-menu/dist/index.css';
import { Channel, Product } from '~/api';
import { ActionButton, ActionButtonRefProps } from 'components';
import { resourceNames } from '../../resource-names';
import { FiPlus } from 'react-icons/fi';
import { ProductShowPage } from '../products/show/product';
import { renderStatusTag } from '~/resources/helpers';
import { ProductEditPage } from '../products/edit';
import { productDataProvider } from '../products/data-provider';

const { defineFields, defineCardSection, defineShowPage, defineFilterControls } =
  createHelpers<Product>({
    resourceName: resourceNames.modifier,
  });

const defaultValues = {
  name: '',
  description: '',
};

const modifierFields: ResourceField<Product>[] = [
  'id',
  'description',
  'createdAt',
  'updatedAt',
  'name',
  'enabled',
  'slug',
  {
    variants: [
      'id',
      'name',
      'currencyCode',
      'price',
      'enabled',
      'stockOnHand',
      'stockLevel',
      'sku',
      { assets: ['source'] },
    ],
  },
  {
    optionGroups: ['id', 'name', 'code', { options: ['code', 'id', 'name'] }],
  },
  { assets: ['source'] },
];

// NOTE: This is actually facetValues under Facet.code `modifier`
export const modifierResource = (channel: Channel) => {
  return createResource({
    name: resourceNames.modifier,
    label: 'Modifier',
    icon: <FiPlus />,
    // @ts-ignore
    fields: defineFields(modifierFields),
    defaultValues,
    defaultPageSize: 25,
    defaultSorter: [{ field: 'createdAt', order: 'asc' }],
    allowSearch: true,
    allowDelete: false,
    allowCreate: false,
    filterConfig: {
      alwaysExpanded: true,
    },
    // createConfig: {
    //   title: ({ t }) => t('modifier.create.name', { fallback: 'Create Modifier', ns: 'common' }),
    // },
    searchConfig: {
      placeholder: ({ t }) =>
        t('modifier.placeholder.search', {
          fallback: 'Search by modifier name',
          ns: 'common',
        }),
    },
    dataProvider: productDataProvider,
    edit: {
      render() {
        return (
          <ProductEditPage productFields={modifierFields} resourceName={resourceNames.modifier} />
        );
      },
    },
    columns: ({ LinkToDetails, navigateToEdit, invokeDelete, navigateToDetails, t }) => {
      return [
        {
          id: 'name',
          header: t('modifier.column.name', { fallback: 'Name', ns: 'common' }),
          cell: (data) => {
            const { id, name } = data.row.original;
            return (
              <LinkToDetails resourceId={id}>
                <span>{name}</span>
              </LinkToDetails>
            );
          },
        },
        {
          id: 'status',
          header: t('modifier.column.status', { fallback: 'Status', ns: 'common' }),
          cell: (data) => {
            const { id, enabled } = data.row.original;
            return <LinkToDetails resourceId={id}>{renderStatusTag(enabled)}</LinkToDetails>;
          },
        },
        {
          id: 'actions',
          header: () => '',
          accessorKey: 'id',
          enableSorting: false,
          cell: (data) => {
            const { id } = data?.row?.original;
            const actionButtonRef = useRef<ActionButtonRefProps>(null);
            const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
            return (
              <ActionButton
                ref={actionButtonRef}
                actions={[
                  {
                    label: t('actions.edit', { fallback: 'Edit', ns: 'common' }),
                    name: 'edit',
                    onAction: () => navigateToEdit({ id }),
                  },
                  // Temporary not allowing delete for now to avoid issue on POS
                  // {
                  //   label: t('actions.delete', { fallback: 'Delete', ns: 'common' }),
                  //   name: 'delete',
                  //   onAction: () => invokeDelete({ id }),
                  //   render: (onAction) => {
                  //     return (
                  //       <button type="button">
                  //         <TriggerConfirmModal
                  //           visible={showDeleteConfirmation}
                  //           onOpenChange={(val) => {
                  //             const actionButtonSetOpen = actionButtonRef?.current?.setOpen;
                  //             setShowDeleteConfirmation(val);
                  //             actionButtonSetOpen && actionButtonSetOpen(val);
                  //           }}
                  //           onPressConfirm={onAction}
                  //         />
                  //       </button>
                  //     );
                  //   },
                  // },
                ]}
              />
            );
          },
        },
      ];
    },
    // create: {
    //   render: () => {
    //     return <ModifierCreatePage />;
    //   },
    // },
    show: defineShowPage({
      component: (props) => {
        return (
          <ProductShowPage
            pageProps={props}
            resourceName={resourceNames.modifier}
            channel={channel}
          />
        );
      },
    }),
  });
};
