import { createHelpers, createResource, ResourceField } from '@scalingworks/refine-react-admin';
import { RewardStatus, Reward, RewardType, getSdk, Channel } from '~/api';
import { useEffect, useRef, useState } from 'react';
import {
  ActionButton,
  ActionButtonRefProps,
  ActionModalWithTrigger,
  Loading,
  RadioGroup,
  SomethingWentWrong,
  TriggerConfirmModal,
} from '~/components';
import { MaybeTranslationCallback } from '@scalingworks/refine-react-admin/src/types';
import { FilterControlRecord } from '@scalingworks/refine-react-admin/src/components/filter-controls.types';
import { dateFormatter, toCamelCaseWord } from '~/resources/helpers';
import { useCreate, useNavigation, useOne, useTranslate, useUpdate } from '@refinedev/core';
import dayjs from 'dayjs';
import { Button, ImageViewer } from '@scalingworks/react-admin-ui';
import { useParams } from 'react-router-dom';
import { HiPlusCircle } from 'react-icons/hi';
import { numeralThousandFormat } from '~/config/helper';
import { resourceNames } from '../../resource-names';
import { renderStatusTag, renderTextWithPrefix } from '../helpers';
import { RewardShow } from './show';
import { RewardCreate } from './create';
import isEmpty from 'lodash/isEmpty';

const { defineFields, defineCardSection, defineShowPage, defineFilterControls } =
  createHelpers<Reward>({
    resourceName: resourceNames.reward,
  });

const fields: ResourceField<Reward>[] = [
  'id',
  'createdAt',
  'updatedAt',
  'name',
  'type',
  'pointCost',
  'status',
  'productVariantId',
  'productVariantName',
  'productVariantImage',
  'productVariantPrice',
  'runningId',
  'redemptionCount',
  'rewardUsedCount',
  { membershipTiers: ['id', 'name'] },
];

export const rewardResource = (channel?: Channel) => {
  return createResource({
    name: resourceNames?.reward,
    label: 'Rewards',
    fields: defineFields(fields),
    defaultValues: {} as any,
    defaultPageSize: 25,
    defaultSorter: [{ field: 'id', order: 'desc' }],
    allowCreate: true,
    allowSearch: true,
    createConfig: {
      title: ({ t }) => t('rewards.create.name', { fallback: 'Create Rewards', ns: 'common' }),
    },
    searchConfig: {
      placeholder: ({ t }) =>
        t('rewards.placeholder.search', { fallback: 'Search by Reward Name', ns: 'common' }),
    },
    filterConfig: {
      alwaysExpanded: true,
    },
    filterControls: {
      startAt: {
        type: 'daterange',
        config: {
          label: 'Start At',
        },
      },
      endAt: {
        type: 'daterange',
        config: {
          label: 'End At',
        },
      },
      status: {
        type: 'select',
        config: {
          options: [
            {
              label: 'All',
              value: 'all',
            },
            ...Object.keys(RewardStatus).map((key) => ({
              label: key,
              // @ts-ignore
              value: RewardStatus[key],
            })),
          ],
          label: 'Status',
          placeholder: 'Select Status',
        },
      },
    } as MaybeTranslationCallback<FilterControlRecord<Reward>>,
    dataProvider: {
      getList: ({ metaData, client, pagination, sort }) => {
        const current = pagination?.current || 1;
        const pageSize = pagination?.pageSize || 25;

        const queryKey = metaData?.queryContext?.queryKey as any[];
        const searchField = queryKey?.[3]?.filters?.find(
          (subItem: any) => subItem?.field === 'search'
        );
        const statusField = queryKey?.[3]?.filters?.find(
          (subItem: any) => subItem?.field === 'status'
        );
        const startAtBegin = queryKey?.[3]?.filters?.find(
          (subItem: any) => subItem?.field === 'startAt' && subItem?.operator === 'gte'
        );
        const startAtEnd = queryKey?.[3]?.filters?.find(
          (subItem: any) => subItem?.field === 'startAt' && subItem?.operator === 'lte'
        );
        const endAtBegin = queryKey?.[3]?.filters?.find(
          (subItem: any) => subItem?.field === 'endAt' && subItem?.operator === 'gte'
        );
        const endAtEnd = queryKey?.[3]?.filters?.find(
          (subItem: any) => subItem?.field === 'endAt' && subItem?.operator === 'lte'
        );
        const type = queryKey?.[3]?.filters?.find((subItem: any) => subItem?.key === 'listingTab');
        return getSdk(client)
          ?.getRewards({
            input: {
              search: searchField?.value,
              type: type?.value?.[0]?.value,
              status: statusField?.value === 'all' ? undefined : statusField?.value,
              startDateBegin: startAtBegin?.value,
              startDateEnd: startAtEnd?.value,
              endDateBegin: endAtBegin?.value,
              endDateEnd: endAtEnd?.value,
            },
            options: {
              skip: (current - 1) * pageSize,
              take: pageSize,
            },
          })
          ?.then((res) => {
            return {
              data: res?.getRewards?.items,
              total: res?.getRewards?.totalItems,
            };
          });
      },
      getOne: ({ client, id }) => {
        return getSdk(client)
          ?.Reward({
            rewardId: id as string,
          })
          ?.then((res) => {
            return {
              data: res?.reward,
            };
          });
      },
      create: async ({ client, variables, metaData }) => {
        let assetsUrl;
        if (!isEmpty(variables?.image)) {
          assetsUrl = await getSdk(client)?.createAssets({
            input: variables?.image?.map((subItem: any) => {
              return {
                file: subItem,
              };
            }),
          });
        }
        return getSdk(client)
          ?.CreateReward({
            input: {
              name: variables?.name,
              description: variables?.description,
              pointCost: parseInt(variables?.pointCost),
              quantity: parseInt(variables?.quantity),
              productVariantId: variables?.variantId,
              redeemCriteria: variables?.redeemCriteria || '-',
              startAt: dayjs(variables?.startDate)?.toISOString(),
              endAt: dayjs(variables?.endDate)?.toISOString(),
              termCondition: variables?.termsConditions || '-',
              type: variables?.type,
              entitledTierIds: variables?.membershipTiers,
              imageUrls: assetsUrl?.createAssets?.map((subItem: any) => subItem?.source),
            },
          })
          ?.then((res) => ({
            data: res,
          }));
      },
      deleteOne: ({ client, id, metaData }) => {
        return getSdk(client)
          ?.DeleteReward({
            deleteRewardId: id?.toString() || '',
          })
          ?.then((res) => ({
            data: res,
          }));
      },
      update: async ({ client, variables, id }) => {
        const formData = variables?.data;
        const rewardData = variables?.rewardData as Reward;
        const rewardId = id as string;

        let assetsUrl: any;
        if (!isEmpty(formData?.image) && typeof formData?.image?.[0] !== 'string') {
          assetsUrl = await getSdk(client)?.createAssets({
            input: formData?.image?.map((subItem: any) => {
              return {
                file: subItem,
              };
            }),
          });
        }

        return getSdk(client)
          ?.UpdateReward({
            input: {
              id: rewardId,
              description: formData?.description || rewardData?.description,
              name: formData?.name || rewardData?.name,
              pointCost: parseInt(formData?.pointCost || rewardData?.pointCost),
              quantity: parseInt(formData?.quantity || rewardData?.quantity),
              productVariantId: formData?.variantId,
              redeemCriteria: formData?.redeemCriteria || rewardData?.redeemCriteria || '-',
              startAt: formData?.startDate
                ? dayjs(formData?.startDate)?.toISOString()
                : rewardData?.startAt,
              endAt: formData?.endDate
                ? dayjs(formData?.endDate)?.toISOString()
                : rewardData?.endAt,
              termCondition: formData?.termsConditions || rewardData?.termCondition || '-',
              entitledTierIds:
                formData?.membershipTiers ||
                rewardData?.membershipTiers?.map((subItem) => subItem?.id),
              status: formData?.status || rewardData?.status,
              imageUrl: assetsUrl?.createAssets?.[0]?.source || rewardData?.imageUrls?.[0],
              type: formData?.type,
            },
          })
          ?.then((res) => ({
            data: res,
          }));
      },
    },
    columns: ({ LinkToDetails, navigateToEdit, invokeDelete, t }) => {
      return [
        {
          id: 'name',
          header: t('rewards.column.name', { fallback: 'Reward Name', ns: 'common' }),
          cell: (data) => {
            const { id, name } = data.row.original as Reward;
            return (
              <LinkToDetails resourceId={id}>
                <span>{name}</span>
              </LinkToDetails>
            );
          },
        },
        {
          id: 'quantity',
          header: t('rewards.column.quantity', { fallback: 'Quantity', ns: 'common' }),
          cell: (data) => {
            const { id, quantity } = data.row.original as Reward;
            return (
              <LinkToDetails resourceId={id}>
                <span>{numeralThousandFormat(quantity || 0)}</span>
              </LinkToDetails>
            );
          },
        },
        {
          id: 'redemptionCount',
          header: t('rewards.column.redeemedQuantity', {
            fallback: 'Redeemed Quantity',
            ns: 'common',
          }),
          cell: (data) => {
            const { id, redemptionCount } = data.row.original as Reward;
            return (
              <LinkToDetails resourceId={id}>
                <div className="w-16">{numeralThousandFormat(redemptionCount || 0)}</div>
              </LinkToDetails>
            );
          },
        },
        {
          id: 'rewardUsedCount',

          header: t('rewards.column.usedQuantity', { fallback: 'Used Quantity', ns: 'common' }),
          cell: (data) => {
            const { id, rewardUsedCount } = data.row.original as Reward;
            return (
              <LinkToDetails resourceId={id}>
                <div className="w-16">{numeralThousandFormat(rewardUsedCount || 0)}</div>
              </LinkToDetails>
            );
          },
        },
        {
          id: 'price',
          header: t('rewards.column.price', { fallback: 'Price', ns: 'common' }),
          cell: (data) => {
            const { id, productVariantPrice } = data.row.original as Reward;
            return (
              <LinkToDetails resourceId={id}>
                {renderTextWithPrefix({
                  text: `${
                    numeralThousandFormat(productVariantPrice?.split(' ')?.[1] || 0, true) || 0
                  }`,
                  prefixText: `${productVariantPrice?.split(' ')?.[0] || 'MYR'}`,
                })}
              </LinkToDetails>
            );
          },
        },
        {
          id: 'pointsCost',
          header: t('rewards.column.points', { fallback: 'Points Cost', ns: 'common' }),
          cell: (data) => {
            const { id, pointCost } = data.row.original as Reward;
            return (
              <LinkToDetails resourceId={id}>
                {renderTextWithPrefix({
                  text: `${numeralThousandFormat(pointCost)}`,
                  prefixText: 'PTS',
                  prefixPosition: 'right',
                })}
              </LinkToDetails>
            );
          },
        },
        {
          id: 'startAt',
          header: t('rewards.column.start', { fallback: 'Starts At', ns: 'common' }),
          cell: (data) => {
            const { id, startAt } = data.row.original as Reward;
            return (
              <LinkToDetails resourceId={id}>
                <span>{dateFormatter(startAt)}</span>
              </LinkToDetails>
            );
          },
        },
        {
          id: 'endAt',
          header: t('rewards.column.end', { fallback: 'Ends At', ns: 'common' }),
          cell: (data) => {
            const { id, endAt } = data.row.original as Reward;
            return (
              <LinkToDetails resourceId={id}>
                <span>{dateFormatter(endAt)}</span>
              </LinkToDetails>
            );
          },
        },
        {
          id: 'status',
          header: t('rewards.column.status', { fallback: 'Status', ns: 'common' }),
          cell: (data) => {
            const { id, status } = data.row.original as Reward;
            return (
              <LinkToDetails resourceId={id}>
                {renderStatusTag(status === RewardStatus?.Active, resourceNames?.reward)}
              </LinkToDetails>
            );
          },
        },
        {
          id: 'actions',
          header: () => '',
          accessorKey: 'id',
          enableSorting: false,
          cell: (data) => {
            const { id, status } = data?.row?.original as Reward;
            const actionButtonRef = useRef<ActionButtonRefProps>(null);
            const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
            const [showUpdateModal, setShowUpdateModal] = useState(false);
            const [statusVal, setStatusVal] = useState<string>(status);
            const { mutate } = useUpdate();
            const t = useTranslate();

            useEffect(() => {
              setStatusVal(status);
            }, [data?.row?.original]);

            return (
              <>
                <ActionButton
                  ref={actionButtonRef}
                  actions={[
                    {
                      label: t('actions.updateStatus'),
                      name: 'edit',
                      onAction: () => navigateToEdit({ id }),
                      render: (onAction) => {
                        return (
                          <button type="button">
                            <ActionModalWithTrigger
                              triggerText={t('actions.updateStatus')}
                              visible={showUpdateModal}
                              title={t('actions.updateStatus')}
                              renderBody={() => {
                                return (
                                  <div>
                                    <RadioGroup
                                      isField
                                      label={t('rewards.column.status')}
                                      options={[
                                        ...Object.keys(RewardStatus).map((key) => ({
                                          label: t(`rewards.status.${key.toLowerCase()}`),
                                          // @ts-ignore
                                          value: RewardStatus[key],
                                        })),
                                      ]}
                                      value={statusVal}
                                      onValueChange={setStatusVal}
                                    />
                                  </div>
                                );
                              }}
                              onOpenChange={(val) => {
                                const actionButtonSetOpen = actionButtonRef?.current?.setOpen;
                                actionButtonSetOpen?.(val);
                                setShowUpdateModal(val);
                              }}
                              onPressCancel={() => setStatusVal(status)}
                              onPressConfirm={() => {
                                mutate({
                                  id,
                                  resource: resourceNames?.reward,
                                  values: {
                                    data: {
                                      status: statusVal,
                                    },
                                    rewardData: data?.row?.original,
                                  },
                                });
                              }}
                            />
                          </button>
                        );
                      },
                    },
                    {
                      label: t('actions.delete'),
                      name: 'delete',
                      onAction: () => invokeDelete({ id }),
                      render: (onAction) => {
                        return (
                          <button type="button">
                            <TriggerConfirmModal
                              visible={showDeleteConfirmation}
                              onOpenChange={(val) => {
                                const actionButtonSetOpen = actionButtonRef?.current?.setOpen;
                                setShowDeleteConfirmation(val);
                                actionButtonSetOpen?.(val);
                              }}
                              onPressConfirm={onAction}
                            />
                          </button>
                        );
                      },
                    },
                  ]}
                />
              </>
            );
          },
        },
      ];
    },
    list: {
      tabs: {
        options: [
          {
            // @ts-ignore
            filterValue: {},
            label: ({ t }) => t(`rewards.tabs.all`, { fallback: 'All', ns: 'common' }),
          },
          {
            // @ts-ignore
            filterValue: { field: 'type', operator: 'eq', value: RewardType?.InStore },
            label: ({ t }) => t(`rewards.tabs.inStore`, { fallback: 'In-Store', ns: 'common' }),
          },
          {
            // @ts-ignore
            filterValue: { field: 'type', operator: 'eq', value: RewardType?.InApp },
            label: ({ t }) => t(`rewards.tabs.inApp`, { fallback: 'In-App', ns: 'common' }),
          },
        ],
      },
    },
    show: {
      component: (helpers) => {
        return <RewardShow helpers={helpers} />;
      },
    },
    create: {
      render: (helpers) => {
        const navigation = useNavigation();
        const { mutate } = useCreate();

        const onSubmit = async (formData: any) => {
          await mutate(
            {
              resource: resourceNames.reward,
              values: formData,
            },
            {
              onSuccess: () => {
                navigation?.goBack();
              },
            }
          );
        };
        return <RewardCreate onSubmit={onSubmit} />;
      },
    },
    edit: {
      render: () => {
        const { id } = useParams();
        const navigation = useNavigation();
        const { mutate } = useUpdate();
        if (!id) return <SomethingWentWrong />;

        const { data, isLoading } = useOne({
          resource: 'rewards',
          id,
          metaData: {
            fields,
          },
        });

        const rewardData = data?.data as Reward;

        const onSubmit = async (formData: any) => {
          await mutate(
            {
              id,
              resource: resourceNames.reward,
              values: {
                data: formData,
                rewardData,
              },
            },
            {
              onSuccess: () => {
                navigation?.goBack();
              },
            }
          );
        };
        if (isLoading) return <Loading />;
        return <RewardCreate onSubmit={onSubmit} rewardData={rewardData} />;
      },
    },
  });
};
