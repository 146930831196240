import React from 'react';
import { useCreate, useNavigation, useTranslate } from '@refinedev/core';
import { useForm } from '@refinedev/react-hook-form';
import { Button } from '@scalingworks/react-admin-ui';

import { NotificationForm, ShowPageWrapper } from '~/components';
import { resourceNames } from '~/resources/resource-names';
import { BlastableReceiverType, CreateNotificationBlastInput } from '~/api';

export const NotificationCreatePage: React.FC = () => {
  // ========================= HOOKS
  const t = useTranslate();
  const form = useForm();
  const navigateTo = useNavigation();

  // ========================= API
  const { mutate: createNotification, isLoading: creating } = useCreate();

  // ========================= EVENTS
  const onCreate = (data: any) => {
    const { blastableChannels, name, subject, message, type, link = '' } = data;

    createNotification(
      {
        resource: resourceNames.notificationBlast,
        values: {
          name,
          subject,
          message,
          type,
          link,
          blastableChannels,
          receiverType: BlastableReceiverType.All,
        } as CreateNotificationBlastInput,
        errorNotification: {
          message: t('notificationBlasts.create.failed'),
          type: 'error',
        },
        successNotification: {
          message: t('notificationBlasts.create.success'),
          type: 'success',
        },
      },
      {
        onSuccess: (data) => {
          const id = data?.data?.id;
          if (id) navigateTo.show(resourceNames.notificationBlast, id);
          else navigateTo.list(resourceNames.notificationBlast);
        },
      }
    );
  };

  // ========================= VIEWS
  return (
    <div className="overflow-y-scroll">
      <ShowPageWrapper
        resourceName={resourceNames.notificationBlast}
        title={t('notificationBlasts.create.name')}
        extra={
          <Button
            loading={creating}
            onClick={form.handleSubmit(onCreate)}
            variant="solid"
            size="md"
            className="mx-5"
          >
            {t('actions.create', {}, 'Create')}
          </Button>
        }
      >
        <NotificationForm form={form} />
      </ShowPageWrapper>
    </div>
  );
};
