import { useEffect, useRef, useState } from 'react';
import { assertExists, Popover, Tag } from '@scalingworks/react-admin-ui';
import { createHelpers, createResource, ResourceField } from '@scalingworks/refine-react-admin';
import { FiUser } from 'react-icons/fi';
import { getSdk, type Customer, CreateCustomerInput, UpdateCustomerInput, Channel } from '~/api';
import {
  ActionButton,
  ActionButtonRefProps,
  ActionModalWithTrigger,
  FormBuilder,
  Loading,
  RadioGroup,
  SomethingWentWrong,
  TriggerConfirmModal,
} from '~/components';
import {
  useCreate,
  useNavigation,
  useNotification,
  useOne,
  useTranslate,
  useUpdate,
} from '@refinedev/core';
import { parsePhoneNumber } from 'libphonenumber-js';
import { customerFormBuilder } from '~/config/Customer/form';
import { useParams } from 'react-router-dom';
import { customerEmailExist, customerPhoneExist } from '../helpers/customer';
import { isValidDate } from '../helpers/date';
import { resourceNames } from '../resource-names';
import { CustomerShowPage } from './show';

const { defineFields, defineCardSection, defineShowPage, defineFilterControls } =
  createHelpers<Customer>({
    resourceName: resourceNames.customer,
  });

const formatData = (data: any) => {
  const { birthDate, ...rest } = data;
  const date = new Date(birthDate);
  return {
    birthDate: isValidDate(date) ? date : undefined,
    ...rest,
  };
};

export const statusColor: Record<string, string> = {
  ACTIVE: 'text-success-500 bg-success-100 border-success-500',
  INACTIVE: 'text-gray-500 bg-gray-100 border-gray-500',
};

export const customerFields: ResourceField<Customer>[] = [
  'id',
  'createdAt',
  'updatedAt',
  'firstName',
  'lastName',
  'phoneNumber',
  'emailAddress',
  { customFields: ['status', 'dateOfBirth', 'gender'] },
  { user: ['id'] },
];

export const customerResource = (channel: Channel) => {
  return createResource({
    name: resourceNames.customer,
    label: 'Customers',
    icon: <FiUser />,
    fields: defineFields(customerFields),
    defaultValues: {} as any,
    defaultPageSize: 25,
    defaultSorter: [{ field: 'createdAt', order: 'desc' }],
    allowSearch: true,
    searchConfig: {
      placeholder: ({ t }) =>
        t('customer.placeholder.search', {
          fallback: 'Search by Name, Email or Contact No.',
          ns: 'common',
        }),
    },
    createConfig: {
      title: ({ t }) =>
        t('customer.create.add', {
          fallback: 'Add Customer',
          ns: 'common',
        }),
    },
    filterConfig: {
      alwaysExpanded: true,
    },
    allowDelete: true,
    columns: ({ LinkToDetails, navigateToEdit, invokeDelete, t }) => [
      {
        id: 'name',
        header: t('customer.column.name', { fallback: 'Name', ns: 'common' }),
        cell: (data) => {
          const { id, firstName = '', lastName = '' } = data.row.original;
  
          return <LinkToDetails resourceId={id}>{`${firstName} ${lastName}`.trim()}</LinkToDetails>;
        },
      },
      {
        id: 'phoneNumber',
        header: t('customer.column.contact', { fallback: 'Contact No.', ns: 'common' }),
        accessorKey: 'phoneNumber',
        cell: (data) => {
          const phone = data.row.original.phoneNumber || '-';
          return (
            <LinkToDetails resourceId={data.row.original.id}>
              <span>{phone}</span>
            </LinkToDetails>
          );
        },
      },
      {
        id: 'email',
        header: t('customer.column.email', { fallback: 'Email', ns: 'common' }),
        accessorKey: 'emailAddress',
        cell: (data) => {
          const { id, emailAddress } = data.row.original;
          return <LinkToDetails resourceId={id}>{emailAddress || '-'}</LinkToDetails>;
        },
      },
      {
        id: 'status',
        header: t('customer.column.status', { fallback: 'Status', ns: 'common' }),
        cell: (data) => {
          const { id, customFields } = data?.row?.original;
          const { status } = customFields || {};
  
          return (
            <LinkToDetails resourceId={id}>
              <Tag className={`rounded-lg ${statusColor[status as string]}`}>
                {t(`customer.status.${status?.toLowerCase()}`, {
                  fallback: status as string,
                  ns: 'common',
                }).toUpperCase()}
              </Tag>
            </LinkToDetails>
          );
        },
      },
      {
        id: 'actions',
        header: () => <div />,
        accessorKey: 'id',
        enableSorting: false,
        cell: (data) => {
          const userId = data.cell.getValue<string>();
          const actionButtonRef = useRef<ActionButtonRefProps>(null);
          const { firstName, lastName, customFields, id } = data.row.original;
          const fullName = `${firstName || ''} ${lastName || ''}`;
          const { status } = customFields || {};
          const [statusVal, setStatusVal] = useState<string>(status || '');
          const [showUpdateModal, setShowUpdateModal] = useState(false);
          const { mutate } = useUpdate();
          const t = useTranslate();
  
          useEffect(() => {
            setStatusVal(status || '');
          }, [data?.row?.original]);
  
          return (
            <ActionButton
              ref={actionButtonRef}
              actions={[
                {
                  label: t('actions.edit'),
                  name: 'edit',
                  onAction: () => navigateToEdit({ id: userId }),
                },
                {
                  label: t('actions.updateStatus'),
                  name: 'updateStatus',
                  onAction: () => {
                    return;
                  },
                  render: (onAction) => {
                    return (
                      <button type="button">
                        <ActionModalWithTrigger
                          triggerText={t('actions.updateStatus')}
                          visible={showUpdateModal}
                          title={t('actions.updateStatus')}
                          renderBody={() => {
                            return (
                              <div>
                                <RadioGroup
                                  isField
                                  label={t('customer.column.status')}
                                  options={['ACTIVE', 'INACTIVE'].map((value) => ({
                                    label: t(`customer.status.selection.${value.toLowerCase()}`),
                                    value,
                                  }))}
                                  value={statusVal}
                                  onValueChange={setStatusVal}
                                />
                              </div>
                            );
                          }}
                          onOpenChange={(val) => {
                            const actionButtonSetOpen = actionButtonRef?.current?.setOpen;
                            actionButtonSetOpen?.(val);
                            setShowUpdateModal(val);
                          }}
                          onPressCancel={() => setStatusVal(status || '')}
                          onPressConfirm={() => {
                            mutate({
                              id,
                              resource: resourceNames?.customer,
                              values: {
                                status: statusVal,
                              },
                            });
                          }}
                        />
                      </button>
                    );
                  },
                },
                {
                  label: t('actions.delete'),
                  name: 'delete',
                  onAction: () => invokeDelete({ id: userId }),
                  render: (onAction) => {
                    const [show, setShow] = useState(false);
                    return (
                      <button type="button">
                        <TriggerConfirmModal
                          visible={show}
                          onOpenChange={setShow}
                          onPressConfirm={onAction}
                          description={
                            <span>
                              {t('warnings.deleteConfirmationWithName', {
                                resource: t('staff.name').toLocaleLowerCase(),
                              })}{' '}
                              <span className="font-semibold text-error-300">{`${fullName} (${t(
                                `customer.status.${status?.toLowerCase()}`
                              )})`}</span>
                              ? {t('warnings.cannotUndo')}
                            </span>
                          }
                        />
                      </button>
                    );
                  },
                },
              ]}
            />
          );
        },
      },
    ],
    dataProvider: {
      create: ({ client, variables }) => {
        const { password, status, gender, birthDate, ...restInput } = variables;
  
        return getSdk(client)
          .createCustomer({
            input: {
              ...restInput,
              customFields: { status, dateOfBirth: birthDate, gender },
            } as CreateCustomerInput,
            password,
          })
          .then((res) => ({
            data: res.createCustomer,
          }));
      },
      update: ({ client, variables, id }) => {
        const { password, status, gender, birthDate, ...restInput } = variables;
  
        return getSdk(client)
          .updateCustomer({
            input: {
              id: id as string,
              ...restInput,
              customFields: { status, dateOfBirth: birthDate, gender },
            } as UpdateCustomerInput,
          })
          .then((res) => {
            assertExists(res.updateCustomer, 'value returned by updateCustomer is undefined');
  
            return {
              data: res.updateCustomer,
            };
          });
      },
    },
    create: {
      render: (helpers) => {
        const navigateTo = useNavigation();
        const notif = useNotification();
        const t = useTranslate();
        const { mutate: createCustomer } = useCreate();
  
        return (
          <FormBuilder
            title={t('customer.create.add')}
            resourceName={resourceNames.customer}
            onSubmit={async (data) => {
              const { phoneNumber } = data;
              const parsed = parsePhoneNumber(phoneNumber);
              if (!parsed.isValid()) {
                notif?.open?.({
                  message: t('messages.invalidPhoneFormat', {}, 'Invalid Phone Format'),
                  type: 'error',
                });
                return;
              }
              const payload = formatData(data);
  
              const emailExisted = await customerEmailExist(payload.emailAddress);
              if (emailExisted) {
                notif?.open?.({
                  message: t('messages.emailExisted', 'Email address already existed'),
                  type: 'error',
                });
                return;
              }
  
              const phoneExisted = await customerPhoneExist(parsed.number);
              if (phoneExisted) {
                notif?.open?.({
                  message: t('messages.phoneExisted', 'Phone number already existed'),
                  type: 'error',
                });
                return;
              }
  
              createCustomer(
                {
                  resource: resourceNames.customer,
                  values: payload,
                },
                {
                  onSuccess: () => navigateTo.list(resourceNames.customer),
                }
              );
            }}
            items={() => customerFormBuilder()}
          />
        );
      },
    },
    edit: {
      render(helpers) {
        const { id } = useParams();
        const navigation = useNavigation();
        const t = useTranslate();
        const notif = useNotification();
  
        if (!id) return <SomethingWentWrong />;
  
        const { data, isLoading } = useOne({
          resource: resourceNames.customer,
          id,
          metaData: {
            fields: customerFields,
          },
        });
        const mainData = data?.data as Customer;
  
        const { mutate: updateCustomer } = useUpdate();
        if (isLoading) return <Loading />;
        return (
          <FormBuilder
            isUpdate
            resourceName={resourceNames.customer}
            title={t('customer.update.name')}
            onSubmit={async (data) => {
              const { phoneNumber } = data;
              const parsed = parsePhoneNumber(phoneNumber);
              if (!parsed.isValid()) {
                notif?.open?.({
                  message: t('messages.invalidPhoneFormat', {}, 'Invalid Phone Format'),
                  type: 'error',
                });
                return;
              }
              const payload = formatData(data);
  
              const emailExisted = await customerEmailExist(payload.emailAddress, id);
              if (emailExisted) {
                notif?.open?.({
                  message: t('messages.emailExisted', 'Email address already existed'),
                  type: 'error',
                });
                return;
              }
  
              const phoneExisted = await customerPhoneExist(parsed.number, id);
              if (phoneExisted) {
                notif?.open?.({
                  message: t('messages.phoneExisted', 'Phone number already existed'),
                  type: 'error',
                });
                return;
              }
  
              updateCustomer(
                {
                  id,
                  resource: resourceNames.customer,
                  values: payload,
                },
                { onSuccess: () => navigation.list(resourceNames.customer) }
              );
            }}
            items={() => customerFormBuilder(mainData)}
          />
        );
      },
    },
    show: defineShowPage({
      component: (props) => {
        return <CustomerShowPage resourceId={props?.resourceId} />;
      },
    }),
  });
}
