import isEmpty from 'lodash/isEmpty';
import { Fulfillment, FulfillmentState } from '~/api';

export const haveOngoingFulfillments = (fulfillments: Fulfillment[] = []) => {
  const onGoingStates = [
    FulfillmentState.Pending,
    FulfillmentState.Created,
    FulfillmentState.Shipped,
  ] as FulfillmentState[];

  const haveOnGoing = !isEmpty(
    fulfillments.filter((fulfillment) =>
      onGoingStates.includes(fulfillment.state as FulfillmentState)
    )
  );

  return haveOnGoing;
};
